import { EditIcon } from '@chakra-ui/icons'
import { Box, CircularProgress, Collapse, HStack, Text, VStack } from '@chakra-ui/react'
import {
  CheckboxField,
  Collection,
  colors,
  consentFormTemplatesCollection,
  defaultInitialValue,
  DropdownField,
  FieldMap,
  FieldTypes,
  getPracticeConsentFormsCollection,
  getPracticeConsentFormsCollectionPath,
  IdField,
  PracticeConsentForm,
  Template,
  TextField,
  WithId,
  WithoutMetadata,
} from '@hb/shared'
import { doc, updateDoc } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { db } from '../../../backend/db'
import { PopUpMessageContext, useApp } from '../../../contexts'
import { addItem, getCollectionRef, useCollection } from '../../../hooks'
import { ActionButton, SolidActionButton } from '../../Buttons'
import { CollectionListEdit, StandaloneInput } from '../../forms'
import { EditableCheckbox } from '../../forms/Input/EditableCheckbox'
import { DefaultModal } from '../../Modals'
import { ConsentFormTemplateModal } from '../../Templates/TemplateEditModal'
import { StandaloneTemplatePreview } from '../../Templates/TemplatePreview'

const isActiveField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  inputType: 'switch',
  yesText: 'Active',
  noText: 'Inactive',
  placeholder: 'Is Active',
}

const consentFormField: FieldMap = {
  name: 'Consent Form',
  children: {
    name: {
      type: FieldTypes.TEXT,
      placeholder: 'Consent Form Name',
    },
    isActive: isActiveField,
  },
}

const togglePracticeConsentFormActive = async (
  practiceId: string,
  id: string,
  isActive: boolean,
) => {
  try {
    await updateDoc(doc(db, getPracticeConsentFormsCollectionPath(practiceId), id), {
      isActive,
    })
    return { success: 'Consent form updated' }
  } catch (err: any) {
    return { error: err?.message || 'An error occurred' }
  }
}

const ConsentFormPreview = ({
  value,
  practiceId,
  onOpen,
  openText = 'Edit Template',
}: {
  value: WithId<PracticeConsentForm>
  practiceId: string
  onOpen?: () => void
  openText?: string
}) => (
  <VStack py={1} w="100%" px={3} fontFamily="Hero-New" align="flex-start">
    <HStack w="100%">
      <VStack align="flex-start" spacing={0} flex={1}>
        <EditableCheckbox
          onSubmit={isActive => togglePracticeConsentFormActive(practiceId, value.id, isActive)}
          id=""
          field={isActiveField}
          value={value.isActive}
        />
        {value.isActive ? (
          <Text px={2} fontSize="sm" color="gray.600" fontFamily="Open Sans">
            This consent form is currently active. Patients{' '}
            <b style={{ textDecoration: 'underline' }}>will</b> see it before they complete the
            sign-on process.
          </Text>
        ) : (
          <Text px={2} fontSize="sm" color="gray.600" fontFamily="Open Sans">
            This consent form is currently inactive. Patients{' '}
            <b style={{ textDecoration: 'underline' }}>will not</b> see it before they complete the
            sign-on process.
          </Text>
        )}
      </VStack>
      <SolidActionButton gap={2} size="sm" onClick={onOpen}>
        <EditIcon filter="drop-shadow(1px 1px 3px #00000077)" />
        <Text>{openText}</Text>
      </SolidActionButton>
    </HStack>
  </VStack>
)

const modeField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  options: [
    { id: 'template', text: 'Copy from a template (you can edit it later)' },
    { id: 'new', text: 'Create a consent form from scratch' },
  ],
  placeholder: 'Mode',
}

const nameField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Consent Form Name',
}

const NewConsentFormModal = ({
  isOpen,
  onClose,
  collection,
}: {
  isOpen: boolean
  onClose: () => void
  collection: Collection<PracticeConsentForm>
}) => {
  const [mode, setMode] = useState<'new' | 'template'>('template')
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null)

  const [submitting, setSubmitting] = useState(false)
  const [name, setName] = useState('')
  // const shouldFetchTemplates = useMemo(() => mode === 'template', [mode])
  const { items: templates, loading: loadingTemplates } = useCollection(
    consentFormTemplatesCollection,
  )
  const templatesField = useMemo<IdField<Template> | null>(
    () =>
      mode === 'template'
        ? {
            type: FieldTypes.ID,
            collection: consentFormTemplatesCollection,
            placeholder: 'Template',
            renderOption: (v: WithId<Template>) => (
              <StandaloneTemplatePreview
                collection={consentFormTemplatesCollection}
                templateType="consentForm"
                item={v}
              />
            ),
          }
        : null,
    [mode],
  )

  const valid = useMemo(() => {
    if (!name) return false
    if (mode === 'template' && !selectedTemplateId) return false
    return true
  }, [mode, name, selectedTemplateId])

  const { appName } = useApp()
  const { processResponse } = useContext(PopUpMessageContext)

  const onCreate = useCallback(async () => {
    if (!valid) {
      return processResponse({ error: 'Please fill out all required fields' })
    }
    // create the consent form
    const selectedTemplate =
      mode === 'template' ? templates?.find(t => t.id === selectedTemplateId) : null
    if (mode === 'template' && !selectedTemplate) {
      return processResponse({ error: 'Template not found' })
    }
    const newConsentForm: WithoutMetadata<Omit<PracticeConsentForm, 'rank'>> = {
      templateText: selectedTemplate?.templateText ?? defaultInitialValue,
      name,
      isExternalPdf: selectedTemplate?.isExternalPdf ?? false,
      externalPdf: selectedTemplate?.externalPdf ?? null,
      isActive: false,
      editorVersion: selectedTemplate?.editorVersion || 'v2',
    }
    try {
      setSubmitting(true)
      await addItem(appName, collection, newConsentForm)
    } catch (err: any) {
      return processResponse({ error: err?.message || 'An error occurred' })
    } finally {
      setSubmitting(false)
    }
    const res = processResponse({ success: 'Consent form created' })
    onClose()
    return res
  }, [
    valid,
    mode,
    selectedTemplateId,
    templates,
    appName,
    processResponse,
    name,
    collection,
    onClose,
  ])

  return (
    <DefaultModal
      overlayHeader
      isOpen={isOpen}
      onClose={onClose}
      render={() => (
        <VStack p={3} bg="white" w="100%">
          <HStack w="100%" px={2}>
            <Text fontSize="lg" color="gray.600" fontFamily="Hero-New">
              New Consent Form
            </Text>
          </HStack>
          <Box w="100%" py={1}>
            <StandaloneInput theme="detailed" field={modeField} value={mode} onChange={setMode} />
          </Box>
          <Collapse in={!templates?.length && loadingTemplates}>
            <HStack>
              <CircularProgress isIndeterminate color={colors.green.hex} size={6} />
              <Text color="gray.600" fontStyle="italic">
                Loading templates...
              </Text>
            </HStack>
          </Collapse>
          <Collapse
            unmountOnExit
            style={{ width: '100%' }}
            in={!!templatesField && mode === 'template'}>
            <Box py="0.1rem" px={1}>
              {templatesField && mode === 'template' ? (
                <StandaloneInput
                  theme="detailed"
                  field={templatesField}
                  value={selectedTemplateId}
                  onChange={setSelectedTemplateId}
                />
              ) : null}
            </Box>
          </Collapse>
          <Box px={1} w="100%">
            <StandaloneInput field={nameField} theme="detailed" value={name} onChange={setName} />
          </Box>
          <HStack w="100%" p={2} justify="flex-end">
            <ActionButton isLoading={submitting} colorScheme="gray" size="sm" onClick={onClose}>
              Cancel
            </ActionButton>
            <SolidActionButton
              colorScheme="green"
              opacity={valid ? 1 : 0.5}
              isLoading={submitting}
              pointerEvents={valid ? 'auto' : 'none'}
              size="sm"
              onClick={onCreate}>
              Create
            </SolidActionButton>
          </HStack>
        </VStack>
      )}
    />
  )
}

export const PracticeConsentFormsView = ({ practiceId }: { practiceId: string }) => {
  const collection = useMemo(() => getPracticeConsentFormsCollection(practiceId), [practiceId])
  const [selectedConsentFormId, setSelectedConsentFormId] = useState<string | null>(null)
  const [creatingNew, setCreatingNew] = useState(false)
  const ref = useMemo(() => getCollectionRef(collection), [collection])
  const collectionState = useCollection(collection)
  const { items: consentForms = [] } = collectionState ?? {}
  return (
    <>
      <CollectionListEdit<PracticeConsentForm>
        ItemPreview={({ value }) => (
          <ConsentFormPreview
            onOpen={() => setSelectedConsentFormId(value.id)}
            value={value}
            key={value.id}
            practiceId={practiceId}
          />
        )}
        collectionRef={ref}
        onNewItemClick={() => setCreatingNew(true)}
        itemField={consentFormField}
        getItemTitle={item => item.name}
        noItemEdit
        itemName="Consent Form"
        listName="Consent Forms"
        value={consentForms}
      />
      {selectedConsentFormId ? (
        <ConsentFormTemplateModal
          templateId={selectedConsentFormId}
          isOpen
          onClose={() => setSelectedConsentFormId(null)}
        />
      ) : null}
      {creatingNew ? (
        <NewConsentFormModal
          isOpen={creatingNew}
          collection={collection}
          onClose={() => setCreatingNew(false)}
        />
      ) : null}
    </>
  )
}

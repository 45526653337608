import { CheckIcon, CloseIcon, LinkIcon } from '@chakra-ui/icons'
import {
  Button,
  HStack,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react'
import { colors, EditorVersion, Mode } from '@hb/shared'
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Path } from 'slate'
import { ReactEditor, RenderElementProps, useSlate, useSlateSelection } from 'slate-react'
import { useElementStyle } from '../hooks'

const LinkElementTooltip = ({
  url,
  selected,
  onSave,
}: {
  url: string | null
  selected: boolean
  onSave: (updated: string) => void
}) => {
  const [editing, setEditing] = useState(false)
  const [newUrl, setUrl] = useState(url ?? '')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (selected && !url) {
      setEditing(true)
    }
  }, [selected, url])
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus()
      inputRef.current?.select()
    }
  }, [editing])
  return (
    <HStack
      spacing={1}
      style={{
        opacity: selected ? 1 : 0,
        userSelect: 'none',
        transition: 'opacity 0.2s',
        borderRadius: '6px',
        fontFamily: 'Open Sans',
        fontSize: '0.9rem',
        whiteSpace: 'nowrap',
      }}>
      {editing ? (
        <>
          <HStack p={1} spacing={1} borderRadius={6} bg="gray.50">
            <Input
              ref={inputRef}
              size="sm"
              bg="transparent"
              _focus={{
                border: 'none',
                boxShadow: 'none',
              }}
              w="220px"
              border="none"
              placeholder="Link URL"
              value={newUrl}
              onChange={e => {
                setUrl(e.target.value)
              }}
            />
            <IconButton
              size="sm"
              variant="outline"
              color="gray.600"
              bg="white"
              icon={<CloseIcon />}
              onClick={() => {
                setEditing(false)
                setUrl(url ?? '')
              }}
              aria-label="Cancel"
            />
            <IconButton
              size="sm"
              bg={colors.green.hex}
              color="white"
              icon={<CheckIcon />}
              onClick={() => {
                setEditing(false)
                onSave(newUrl)
              }}
              aria-label="Save"
            />
          </HStack>
        </>
      ) : (
        <Button
          onClick={e => {
            e.stopPropagation()
            setEditing(true)
          }}
          color="white"
          size="sm"
          bg={url ? colors.green.hex : colors.red.hex}
          gap={2}
          _hover={{
            bg: url ? 'green.300' : 'red.300',
          }}>
          <LinkIcon />
          <Text fontSize="xs" fontFamily="Open Sans">
            {url || 'Missing URL'}
          </Text>
        </Button>
      )}
    </HStack>
  )
}

export const LinkElement: React.FC<
  RenderElementProps & { title?: string; version: EditorVersion; mode: Mode }
> = ({ children, title, element, attributes, version, mode }) => {
  const selection = useSlateSelection()
  const editor = useSlate()
  const { url } = element

  const urlWithHttps = useMemo(() => {
    if (!url) return ''
    if (url && !url.startsWith('http')) {
      return `https://${url}`
    }
    return url
  }, [url])
  const { isSelected, nodePath } = useMemo(() => {
    const nPath = ReactEditor.findPath(editor, element)
    if (mode === 'View') {
      return { isSelected: false, nodePath: nPath }
    }
    if (selection) {
      const selectionPath = editor.path(selection)
      return {
        isSelected:
          Path.equals(nPath, selectionPath) || Path.isParent(nPath, editor.path(selection)),
        nodePath: nPath,
      }
    }
    return { isSelected: false, nodePath: nPath }
  }, [selection, editor, element, mode])

  const onUrlUpdate = useCallback(
    (updated: string) => {
      editor.setNodes({ url: updated }, { at: nodePath })
    },
    [nodePath, editor],
  )

  const baseStyle = useElementStyle(mode, element, version)
  const style = useMemo<CSSProperties>(
    () => ({
      ...baseStyle,
      position: 'relative',
      textDecoration: 'underline',
      color: colors.blue.hex,
      boxShadow:
        isSelected || !urlWithHttps
          ? `0 0 0 1px ${urlWithHttps ? colors.blue.hex : colors.red.hex}`
          : 'none',
      borderRadius: '4px',
    }),
    [baseStyle, isSelected, urlWithHttps],
  )
  if (mode === 'View' || !isSelected) {
    return (
      <div {...attributes} title={title} style={style}>
        <a href={urlWithHttps} target="_blank">
          {children}
        </a>
      </div>
    )
  }
  return (
    <Popover strategy="fixed" isOpen>
      <PopoverTrigger>
        <div {...attributes} title={title} style={style}>
          {children}
        </div>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="auto">
          <LinkElementTooltip onSave={onUrlUpdate} selected={isSelected} url={urlWithHttps} />
          <PopoverArrow bg={urlWithHttps ? colors.green.hex : colors.red.hex} />
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export interface SubTab<Path extends string = string> {
  path: Path
  name: string
}
// export type SubTabGroup = Omit<SubTab, 'component'> & { children: SubTab[] }
export type SubTabGroup<
  Path extends string = string,
  SubPath extends string = string,
> = SubTab<Path> & { children: SubTab<SubPath>[] }
export const isSubTabGroup = (tab: SubTab | SubTabGroup): tab is SubTabGroup =>
  (tab as SubTabGroup).children !== undefined

export type Tabs = Array<SubTab | SubTabGroup>

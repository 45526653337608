import { DownloadIcon, ViewIcon, WarningIcon } from '@chakra-ui/icons'
import {
  ButtonProps,
  Center,
  Flex,
  IconButton,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import React, {
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { downloadBlob } from '../../../../backend'
import { ScreenContext } from '../../../../contexts/ScreenContext'
import loadingIcon from '../../../../icons/loading.svg'
import { ActionButton } from '../../../Buttons'
import { ImageViewModal } from '../../../Modals/ImageViewModal'
import { PdfViewModal } from '../../../Modals/PdfView/PdfViewModal'

interface FileViewProps {
  blob?: Blob
  src?: string
  loading?: boolean
  error?: string
  noFullView?: boolean
  header?: string
  fileName?: string
  small?: boolean
  children?: React.ReactNode
  withDownload?: boolean
  onSelect?: () => void
  selected?: boolean
}

interface ImageViewProps extends FileViewProps {
  blob: Blob
  withDownload?: boolean
}

const useDataURL = (blob: Blob) => {
  const [dataUrl, setDataUrl] = useState<string | undefined>()
  useEffect(() => {
    if (blob) {
      const a = new FileReader()
      a.onload = e => {
        setDataUrl((e.target?.result ?? undefined) as string | undefined)
      }
      a.readAsDataURL(blob)
      return () => {
        a.abort()
      }
    }
    setDataUrl(undefined)
    return () => {}
  }, [blob])
  return dataUrl
}

const extensions: Record<string, string> = {
  'application/pdf': 'pdf',
  'image/jpg': 'jpg',
  'image/jpeg': 'jpeg',
  'image/gif': 'gif',
  'image/svg': 'svg',
  'image/png': 'png',
  'application/x-zip-compressed': 'zip',
}

const getPx = (size: 'xs' | 'sm' | 'md' | 'lg') => {
  switch (size) {
    case 'xs':
      return 1
    case 'sm':
      return 2
    case 'md':
      return 3
    default:
      return 4
  }
}
const BaseFileViewButton = (
  { size = 'md', ...props }: ButtonProps & { size?: 'xs' | 'sm' | 'md' | 'lg' },
  ref: ForwardedRef<HTMLButtonElement>,
) => (
  <ActionButton
    size={size}
    ref={ref}
    gap={2}
    px={getPx(size)}
    colorScheme="gray"
    bg="white"
    {...props}
  />
)

export const FileViewButton = forwardRef(BaseFileViewButton)

export const DownloadFileView: React.FC<PropsWithChildren<ImageViewProps>> = ({
  blob,
  header,
  small,
  children,
}) => {
  const [ext, setExt] = useState('')

  useEffect(() => {
    if (extensions[blob?.type]) {
      setExt(extensions[blob.type])
    }
  }, [blob])

  const { isMobile } = useContext(ScreenContext)

  return (
    <Flex w={isMobile ? '100%' : 'auto'} gap={2}>
      <Tooltip label="Download File" placement="top" hasArrow bg="gray.50" color="gray.600">
        <FileViewButton
          size={small ? 'sm' : 'md'}
          aria-label="Download"
          onClick={() => downloadBlob(blob, `${header}${ext ? `.${ext}` : ''}`)}>
          <DownloadIcon pointerEvents="none" color="gray.600" width={4} height={4} />
          {small ? null : (
            <Text lineHeight={1} fontSize="sm">
              Download File
            </Text>
          )}
        </FileViewButton>
      </Tooltip>
      {children}
    </Flex>
  )
}

export const PdfViewButton: React.FC<ImageViewProps> = ({ blob, header, children, small }) => {
  const [ext, setExt] = useState('')
  const dataUrl = useDataURL(blob)

  const [viewOpen, setViewOpen] = useState(false)

  useEffect(() => {
    if (extensions[blob?.type]) {
      setExt(extensions[blob.type])
    } else {
      console.warn(`unsupported file type: ${blob?.type}}`)
    }
  }, [blob])

  const { isMobile } = useContext(ScreenContext)

  return (
    <Flex
      gap={2}
      w={isMobile ? '100%' : 'auto'}
      height={small ? 'auto' : '100px'}
      justify="center"
      direction={small ? 'row' : 'column'}>
      <Tooltip label="View File" placement="top" hasArrow bg="gray.50" color="gray.600">
        <FileViewButton
          size={small ? 'sm' : 'md'}
          aria-label="View"
          onClick={e => {
            e.stopPropagation()
            setViewOpen(true)
          }}>
          <ViewIcon />
          {small ? null : <Text fontSize="sm">View PDF</Text>}
        </FileViewButton>
      </Tooltip>
      <Tooltip label="Download File" placement="top" hasArrow bg="gray.50" color="gray.600">
        <FileViewButton
          size={small ? 'sm' : 'md'}
          aria-label="Download"
          onClick={e => {
            e.stopPropagation()
            downloadBlob(blob, `${header}.${ext}`)
          }}>
          <DownloadIcon />
          {small ? null : <Text fontSize="sm">Download PDF</Text>}
        </FileViewButton>
      </Tooltip>
      {children}
      {viewOpen ? (
        <PdfViewModal
          header={header || 'PDF View'}
          url={dataUrl}
          isOpen={viewOpen}
          onClose={() => setViewOpen(false)}
        />
      ) : null}
    </Flex>
  )
}

export const ImageView: React.FC<ImageViewProps> = ({
  blob,
  header,
  noFullView,
  fileName,
  children,
  withDownload,
}) => {
  const dataUrl = useDataURL(blob)
  const [fullView, setFullView] = useState(false)
  const { isMobile } = useContext(ScreenContext)
  return (
    <Flex
      height="auto"
      p={0}
      px={isMobile ? 'auto' : 0}
      overflow="hidden"
      boxShadow="0 0 3px #555"
      borderRadius={6}
      background="white"
      cursor="pointer"
      aria-label="View Image"
      onClick={noFullView ? undefined : () => setFullView(true)}>
      <Image
        shadow="md"
        fallbackSrc={loadingIcon}
        src={dataUrl ?? loadingIcon}
        style={{ height: '100px', maxHeight: '100%', objectFit: 'cover' }}
      />
      {dataUrl && !noFullView ? (
        <ImageViewModal
          src={dataUrl}
          fileName={fileName}
          withDownload={withDownload}
          header={header}
          onClose={() => setFullView(false)}
          isOpen={fullView}
        />
      ) : null}
      <div style={{ position: 'absolute', top: '0.2rem', right: '0.2rem' }}>{children}</div>
    </Flex>
  )
}

export const BaseFileView: React.FC<FileViewProps> = props => {
  const { blob, loading, error, small } = props
  const { isMobile } = useContext(ScreenContext)
  if (loading) {
    return (
      <Flex
        p={4}
        color="gray"
        shadow="md"
        borderRadius={6}
        align="center"
        background="white"
        gap={3}
        justify="center">
        <Spinner color="gray" size="sm" />
        {small ? null : <Text> Loading...</Text>}
      </Flex>
    )
  }
  if (blob) {
    const b = blob
    switch (blob.type) {
      case 'image/png':
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/gif':
      case 'image/svg':
        return <ImageView {...props} blob={b} />
      case 'application/pdf':
        return <PdfViewButton {...props} blob={b} />
      default:
        return <DownloadFileView {...props} blob={b} />
    }
  } else {
    return error ? (
      <Popover gutter={2} placement="top" trigger="hover" strategy="fixed">
        <PopoverTrigger>
          <Center p={2}>
            <IconButton
              variant="ghost"
              borderRadius="full"
              aria-label="Error"
              icon={<WarningIcon w={8} h={8} color="red.500" />}
            />
          </Center>
        </PopoverTrigger>
        <PopoverContent p={2}>
          <Text
            background="white"
            textAlign="center"
            w={isMobile ? '100%' : 'auto'}
            color={error ? 'red.500' : 'blackAlpha.900'}>
            <i>{error || 'No file chosen'}</i>
          </Text>
          <PopoverArrow />
        </PopoverContent>
      </Popover>
    ) : (
      <Flex
        p={4}
        color="gray"
        shadow="md"
        borderRadius={6}
        align="center"
        background="white"
        gap={3}
        justify="center">
        <Text>No file chosen</Text>
      </Flex>
    )
  }
}

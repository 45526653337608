import { Button, Flex, FlexProps, Text } from '@chakra-ui/react'
import {
  getPlanNextAction,
  getPregnancyPlansArray,
  InsuranceCoverage,
  InsuranceCoverageRequest,
  isInsuranceCoverageRequest,
  objectToArray,
  PopulatedAssessment,
  PopulatedUser,
  User,
  WithId,
} from '@hb/shared'
import { DocumentReference } from 'firebase/firestore'
import React, { useCallback, useMemo, useState } from 'react'
import { useApp } from '../../contexts/AppContext'
import { useMobileLayout } from '../../hooks/useMobileLayout'
import { AddPregnancyPlanModal } from './Coverage/AddPregnancyPlanModal'
import { CoverageRequestView } from './Coverage/CoverageRequestView'
import { CoverageStage } from './Coverage/CoverageStage'
import { NewPlanModal } from './Coverage/NewPlanModal'

const NewPlanButton = ({
  onClick,
  assessmentId,
}: {
  onClick: () => void
  assessmentId: string | null
}) => (
  <Flex w="100%" position="relative">
    <Button onClick={onClick} py={1} variant="link" w="100%" size="sm">
      {assessmentId ? '+ Add Plan to Pregnancy' : '+ Add New Plan'}
    </Button>
  </Flex>
)

export const AdditionalPlansView = ({
  assessmentId,
  assessment,
  noHeader,
  openPlanId,
  onOpenPlan,
  patientRef,
  adminView,
  preview,
  user,
  ...flexProps
}: FlexProps & {
  user: PopulatedUser | null
  assessment: PopulatedAssessment | null
  patientRef: DocumentReference<User>
  assessmentId: string | null
  noHeader?: boolean
  preview?: boolean
  onOpenPlan?: (id: string | null) => void
  openPlanId?: string | null
  adminView?: boolean
}) => {
  const { appName } = useApp()
  const { insurancePlans: plans } = user ?? {}
  const { additional: additionalPlans, secondary: secondaryCoverage, requests } = plans ?? {}

  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null)
  const planId = useMemo(
    () => (openPlanId === undefined ? selectedPlanId : openPlanId),
    [openPlanId, selectedPlanId],
  )
  const onOpen = useCallback(
    (id: string | null) => {
      const openFunc = onOpenPlan ?? setSelectedPlanId
      openFunc(openPlanId === id ? null : id)
    },
    [openPlanId, onOpenPlan],
  )

  const [addPregnancyPlanOpen, setAddPregnancyPlanOpen] = useState(false)
  const [newPlanOpen, setNewPlanOpen] = useState(false)

  const { nextActions } = assessment ?? {}
  const sortedAdditionalPlans = useMemo<
    WithId<InsuranceCoverage | InsuranceCoverageRequest>[]
  >(() => {
    const requestsArr = assessmentId ? [] : objectToArray(requests ?? {})
    const displayedPlans = assessmentId
      ? getPregnancyPlansArray(assessment?.plans).filter(p =>
          assessment?.potentialCoverageIds?.includes(p.id),
        )
      : Object.values(additionalPlans ?? {})
    return [...requestsArr, ...displayedPlans]
  }, [additionalPlans, requests, assessment, assessmentId])

  const showSecondaryCoverage = useMemo(() => {
    if (!secondaryCoverage || assessmentId) return false
    return (
      secondaryCoverage.memberId ||
      secondaryCoverage.noMemberId ||
      secondaryCoverage.insuranceProviderId
    )
  }, [secondaryCoverage, assessmentId])

  const { ref, width } = useMobileLayout(600)

  return (
    <>
      <Flex
        ref={ref}
        w="100%"
        gap={preview ? 1 : 2}
        flexFlow="column"
        px={preview ? 1 : 3}
        py={preview ? 0 : 1}
        {...flexProps}>
        {!adminView && noHeader ? null : (
          <Flex w="100%" align="center">
            {noHeader ? null : (
              <Text
                fontSize={preview ? 'md' : 'lg'}
                lineHeight={1}
                fontWeight={600}
                color="gray.600">
                Potential {assessmentId ? 'Pregnancy' : 'Patient'} Plans
              </Text>
            )}
          </Flex>
        )}
        {showSecondaryCoverage ? (
          <CoverageStage
            width={width}
            patientRef={patientRef}
            id="secondary"
            adminView={adminView}
            assessment={assessment}
            assessmentId={assessmentId}
            nextAction={getPlanNextAction(nextActions ?? {}, 'secondary')}
            coverage={secondaryCoverage}
          />
        ) : null}
        {sortedAdditionalPlans.length || secondaryCoverage ? (
          sortedAdditionalPlans.map(plan =>
            isInsuranceCoverageRequest(plan) ? (
              <CoverageRequestView
                // assessmentId={assessmentId}
                patientRef={patientRef}
                adminView={adminView}
                key={plan.id}
                request={plan}
              />
            ) : (
              <CoverageStage
                width={width}
                key={plan.id}
                id={plan.id}
                assessmentId={assessmentId}
                patientRef={patientRef}
                assessment={assessment}
                nextAction={getPlanNextAction(nextActions ?? {}, plan.id)}
                coverage={plan}
                isOpen={planId === plan.id}
                onOpenToggle={() => onOpen(planId === plan.id ? null : plan.id)}
                adminView={adminView}
              />
            ),
          )
        ) : (
          <Text
            px={3}
            py={1}
            bg="gray.50"
            fontStyle="italic"
            border="1px solid #cdcdcd"
            borderRadius={6}>
            No potential plans
          </Text>
        )}
        {!adminView || appName === 'providers-app' ? null : (
          <NewPlanButton
            assessmentId={assessmentId}
            onClick={() => {
              if (assessmentId) setAddPregnancyPlanOpen(true)
              else setNewPlanOpen(true)
            }}
          />
        )}
      </Flex>
      {adminView && assessmentId && addPregnancyPlanOpen ? (
        <AddPregnancyPlanModal
          onAddNew={() => {
            setAddPregnancyPlanOpen(false)
            setNewPlanOpen(true)
          }}
          assessment={assessment}
          assessmentId={assessmentId}
          onClose={() => setAddPregnancyPlanOpen(false)}
          user={user}
        />
      ) : null}
      {adminView && newPlanOpen ? (
        <NewPlanModal
          user={user}
          assessment={assessment}
          patientRef={patientRef}
          addToAssessmentId={assessmentId}
          onClose={() => setNewPlanOpen(false)}
        />
      ) : null}
    </>
  )
}

import { InvoiceAssessmentPaymentsSnippet, INVOICES, PracticeInvoice, WithId } from '@hb/shared'
import { collection, doc, getDoc } from 'firebase/firestore'
import { db } from '../../backend/db'

const invoicesCollection = collection(db, INVOICES)
const getInvoiceDoc = (invoiceId: string) => doc(invoicesCollection, invoiceId)
export const getInvoice = (invoiceId: string): Promise<PracticeInvoice> =>
  getDoc(getInvoiceDoc(invoiceId)).then(snapshot => snapshot.data() as PracticeInvoice)

export const getDisplayedPregnancies = (
  assessmentSnippets: WithId<InvoiceAssessmentPaymentsSnippet>[],
) => assessmentSnippets.filter(a => Math.abs(a.practiceFee) >= 0.01)

import {
  acceptedPaymentMethodsField,
  alternateEmailField,
  baseRenderingProviderField,
  baseRenderingProvidersField,
  colors,
  FieldMap,
  ListField,
  makeFieldOptional,
  midwiferyNameField,
  moreMidwifeInfo,
  practiceFnameField,
  practiceGroupField,
  practiceIsGroupField,
  practiceLnameField,
  practiceNpiField,
  practiceRedFlaggedReasonField,
  practiceSsnField,
  practiceTaxIdField,
  practiceZipCodeField,
  providerOtherPhonesField,
  providerPhoneField,
  specialtyField,
} from '@hb/shared'
import React from 'react'

export const renderingProviderToName: FieldMap['toName'] = v => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {v?.fname || 'NO FIRST NAME'} {v?.lname || 'NO LAST NAME'}
    <span
      style={{
        background: v?.inactive ? colors.orange.hex : colors.green.hex,
        fontSize: '0.8rem',
        marginLeft: '0.5rem',
        color: 'white',
        borderRadius: '4px',
        padding: '0.3rem',
        paddingBottom: '0.2rem',
      }}>
      {v?.inactive ? 'INACTIVE' : 'ACTIVE'}
    </span>
  </div>
)

export const renderingProviderField: FieldMap = {
  ...baseRenderingProviderField,
  toName: renderingProviderToName,
}

export const renderingProvidersField: ListField = {
  ...baseRenderingProvidersField,
  itemFields: renderingProviderField,
}

export const basicMidwifeInfo: FieldMap = {
  name: 'Midwife',
  children: {
    isGroup: practiceIsGroupField,
    group: practiceGroupField,
    name: midwiferyNameField,
    specialty: specialtyField,
    fname: practiceFnameField,
    lname: practiceLnameField,
    taxId: practiceTaxIdField,
    ssn: practiceSsnField,
    renderingProviders: renderingProvidersField,
    zipCode: practiceZipCodeField,
    npi: practiceNpiField,
    email: alternateEmailField,
    phone: providerPhoneField,
    otherPhones: providerOtherPhonesField,
    acceptedPaymentMethods: acceptedPaymentMethodsField,
    redFlaggedReason: practiceRedFlaggedReasonField,
  },
}

//used for collection
export const midwifeFields: FieldMap = {
  name: 'Midwife',
  children: {
    ...basicMidwifeInfo.children,
    ...moreMidwifeInfo.children,
  },
}

import { Flex, Text } from '@chakra-ui/react'
import { basePracticeSelectField, IdField, PracticeWithAdmin, WithId } from '@hb/shared'

const PracticeSelectPreview = ({ practice }: { practice: WithId<PracticeWithAdmin> }) => {
  const { name, fname, lname } = practice
  return (
    <Flex w="100%" flexFlow="column">
      <Text fontWeight={500} color="gray.600">
        {name}
      </Text>
      <Flex gap={2} align="center">
        <Text lineHeight={1} color="gray.500" fontWeight={600} fontSize="sm">
          Referring provider
        </Text>
        <Text lineHeight={1} color="gray.600" fontSize="md">
          {fname} {lname}
        </Text>
      </Flex>
    </Flex>
  )
}

export const practiceSelectField: IdField<PracticeWithAdmin> = {
  ...basePracticeSelectField,
  renderOption: practice => <PracticeSelectPreview practice={practice} />,
}

export const optionalPracticeSelectField = makeFieldOptional(practiceSelectField)

import {
  Badge,
  Box,
  Flex,
  HStack,
  Stack,
  StackDivider,
  Text,
  TextProps,
  Tooltip,
} from '@chakra-ui/react'
import {
  FieldTypes,
  formatPhoneNumber,
  getDateString,
  getPregnancyPrimaryCoverage,
  getYearsSinceString,
  planDesignOptions,
  TextField,
} from '@hb/shared'

import React, { useContext, useMemo } from 'react'
import { useApp } from '../../../../../contexts'
import { ProfileContext } from '../../../../../contexts/ProfileContext'
import { useFormattedValue } from '../../../../../hooks'
import {
  CoverageTerminationStatus,
  useTerminationStatus,
} from '../../../../Assessments/Coverage/TerminationStatus'
import { CopyTooltip } from '../../../../CopyTooltip'
import { AssessmentClearedSection } from '../../Assessments/AssessmentClearedSection'
import { PregnancyStatusDropdown } from '../../Assessments/PregnancyStatus'
import { AssessmentThreadView } from '../../AssessmentThread'

const HeaderItem = (props: TextProps) => (
  <Flex px={1} whiteSpace="nowrap" fontSize="sm" color="#333" {...props} />
)

const SecondaryCoverageSummary = () => {
  const { user } = useContext(ProfileContext)
  const { insurancePlans } = user ?? {}
  const { secondary } = insurancePlans ?? {}

  const secondaryCoverageTerminationStatus = useTerminationStatus(insurancePlans?.secondary)
  return secondary ? (
    <Box>
      <HeaderItem>
        <span style={{ marginRight: '4px', fontWeight: 600 }}>
          Secondary Coverage{secondary.isMedicaid ? ' (Medicaid)' : ''}
        </span>
        {secondary?.insuranceProvider?.name || 'No insurer'}
        {/* {midwife ? AdminPracticeData?.taxId?.main ?? 'None' : 'N/A'} */}
      </HeaderItem>
      <HeaderItem>
        <span style={{ marginRight: '4px', fontWeight: 600 }}>Secondary Coverage ID</span>
        {secondary.memberId || 'None'}
      </HeaderItem>
      <CoverageTerminationStatus
        wrap
        coverage={secondary}
        terminationStatus={secondaryCoverageTerminationStatus}
      />
    </Box>
  ) : null
}

const midwifeTaxIdField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Midwife Tax ID',
}
const midwifeNPIField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Midwife NPI',
}

const ArchivedByOtherPreview = () => {
  const { selectedAssessment } = useContext(ProfileContext)
  const { practiceData, assessmentData } = selectedAssessment ?? {}
  const { appName } = useApp()
  const archivedByOther = appName === 'app' ? practiceData?.archivedOn : assessmentData?.archivedOn
  if (!archivedByOther) return null
  return (
    <Box>
      <Tooltip
        placement="top"
        bg="red.600"
        textShadow="1px 1px 3px #00000055"
        hasArrow
        label={`Archived on ${appName === 'app' ? 'practice' : 'HB'} ${getDateString(archivedByOther, 'short')}`}>
        <Badge bg="red.600" color="white" textShadow="1px 1px 3px #00000055">
          Archived on {appName === 'app' ? 'practice' : 'HB'}
        </Badge>
      </Tooltip>
    </Box>
  )
}

export const AssessmentHeaderRow = ({ width }: { width: number }) => {
  const {
    selectedAssessment: { populated: selectedAssessment },
    user,
  } = useContext(ProfileContext)
  const { midwife, deliveredOn, edd, signOnCorrections, signOnData, planState, plans, data } =
    selectedAssessment ?? {}
  const insuranceInfo = useMemo(() => data?.['insurance-info'] ?? {}, [data])
  const { dob } = user ?? {}

  const { appName } = useApp()
  const primary = useMemo(() => getPregnancyPrimaryCoverage(plans), [plans])
  const {
    memberId,
    insuranceProviderNumber,
    'plan-design-and-state-mandates': planDesign,
  } = primary ?? {}
  const planDesignText = useMemo(() => {
    if (!planDesign?.planDesign) return ''
    return planDesignOptions.find(p => p.id === planDesign.planDesign)?.text ?? ''
  }, [planDesign])

  const hasSecondaryCoverage = useMemo(() => {
    const fromSignOn = signOnCorrections?.hasSecondaryCoverage ?? signOnData?.hasSecondaryCoverage
    if (fromSignOn) return true
    const option = insuranceInfo?.corrections?.option ?? insuranceInfo?.data?.option
    if (option !== undefined) return option === 'two-plans'
    return false
  }, [signOnCorrections, signOnData, insuranceInfo])
  const weeksIn = useMemo(() => {
    if (deliveredOn === Infinity) return undefined
    if (edd) {
      const delivered = deliveredOn && deliveredOn > 0 ? deliveredOn : Date.now()
      // start date is 40 weeks before edd
      const startDate = edd - 1000 * 60 * 60 * 24 * 7 * 40
      const difference = Math.floor((delivered - startDate) / (1000 * 60 * 60 * 24 * 7))
      return difference > 0 ? difference : undefined
    }
    return undefined
  }, [edd, deliveredOn])

  const { name: insuranceProviderName } = primary?.insuranceProvider ?? {}

  const { value: midwifeTaxId } = useFormattedValue(midwifeTaxIdField, midwife?.taxId)

  const { value: midwifeNPI } = useFormattedValue(midwifeNPIField, midwife?.npi)

  const coveragePrefix = useMemo(() => {
    let text = planDesignText ?? ''
    if (planState) text += `${planDesignText ? ' ' : ''}(${planState})`
    return text
  }, [planDesignText, planState])
  const coverageText = useMemo(
    () => `${memberId || 'None'}${coveragePrefix ? ` - ${coveragePrefix}` : ''}`,
    [memberId, coveragePrefix],
  )

  const isMobile = useMemo(() => width < 1000, [width])

  const primaryCoverageTerminationStatus = useTerminationStatus(primary)

  return (
    <Stack
      borderBottom="1px solid #cdcdcd"
      // align='center'
      direction={isMobile ? 'column' : 'row'}
      bg="gray.100"
      width="100%"
      maxW="100%"
      align="center"
      spacing={0}>
      <Stack
        px={2}
        py={1}
        spacing={1}
        direction={['column', 'column', 'row']}
        w={isMobile ? '100%' : 'auto'}
        divider={<StackDivider borderColor="blackAlpha.500" />}>
        <Flex w={isMobile ? '100%' : 'auto'}>
          <Box flex={isMobile ? 1 : 'unset'} minW="0" w={isMobile ? 'unset' : 'auto'}>
            <HeaderItem>
              <span style={{ marginRight: '4px', fontWeight: 600 }}>DOB</span>
              <CopyTooltip label={dob ? getDateString(dob, 'short') : 'None'}>
                {dob
                  ? `${getDateString(dob, 'short')} (${getYearsSinceString(dob)} years)`
                  : 'None'}
              </CopyTooltip>
            </HeaderItem>
            {deliveredOn ? (
              <HeaderItem>
                <span style={{ fontWeight: 600, marginRight: '4px' }}>Delivered</span>
                <CopyTooltip label={getDateString(deliveredOn, 'short')}>
                  <Text>
                    {deliveredOn === Infinity ? 'Transfer' : getDateString(deliveredOn, 'short')}
                    {weeksIn ? ` - ${weeksIn} weeks` : ''}
                  </Text>
                </CopyTooltip>
              </HeaderItem>
            ) : (
              <HeaderItem>
                <span style={{ fontWeight: 600, marginRight: '4px' }}>EDD</span>
                <CopyTooltip label={edd ? getDateString(edd, 'short') : 'None'}>
                  <Text>{edd ? `${getDateString(edd, 'short')} (${weeksIn} weeks)` : 'None'}</Text>
                </CopyTooltip>
              </HeaderItem>
            )}
          </Box>
        </Flex>
        {appName === 'app' ? (
          <Box>
            <HeaderItem maxW="270px">
              <CopyTooltip prefix={coveragePrefix} label={memberId ?? ''}>
                <Text maxW="270px" isTruncated>
                  <span style={{ marginRight: '4px', fontWeight: 600 }}>
                    {`${insuranceProviderName || 'Member'} ID `}
                  </span>
                  {coverageText}
                </Text>
              </CopyTooltip>
            </HeaderItem>
            <HeaderItem>
              <span style={{ marginRight: '4px', fontWeight: 600 }}>
                {insuranceProviderName || 'Insurance Provider '} Phone
              </span>
              <CopyTooltip
                label={
                  insuranceProviderNumber ? formatPhoneNumber(insuranceProviderNumber) : 'None'
                }>
                <Text>
                  {insuranceProviderNumber ? formatPhoneNumber(insuranceProviderNumber) : 'None'}
                </Text>
              </CopyTooltip>
            </HeaderItem>
          </Box>
        ) : null}
        {appName === 'app' ? (
          <Box>
            <HeaderItem>
              <span style={{ marginRight: '4px', fontWeight: 600 }}>Midwife Tax ID</span>
              <CopyTooltip label={midwifeTaxId}>
                <Text>{midwifeTaxId}</Text>
              </CopyTooltip>
              {/* {midwife ? AdminPracticeData?.taxId?.main ?? 'None' : 'N/A'} */}
            </HeaderItem>
            <HeaderItem>
              <span style={{ marginRight: '4px', fontWeight: 600 }}>Midwife NPI</span>
              <CopyTooltip label={midwifeNPI}>
                <Text>{midwifeNPI}</Text>
              </CopyTooltip>
              {/* {midwife ? AdminPracticeData?.taxId?.main ?? 'None' : 'N/A'} */}
            </HeaderItem>
          </Box>
        ) : null}
        {hasSecondaryCoverage ? <SecondaryCoverageSummary /> : null}
        {primaryCoverageTerminationStatus ? (
          <Box>
            <HeaderItem>
              <Flex gap={1} align="center">
                <CoverageTerminationStatus
                  wrap
                  coverage={primary}
                  terminationStatus={primaryCoverageTerminationStatus}
                />
              </Flex>
            </HeaderItem>
          </Box>
        ) : null}
      </Stack>
      <HStack
        borderTop={isMobile ? '1px solid #cdcdcd' : 'none'}
        justify="flex-end"
        minW="0"
        w={isMobile ? '100%' : 'auto'}
        flex={1}
        px={2}
        py={1}
        spacing={3}>
        {isMobile ? null : <ArchivedByOtherPreview />}
        <Box mr={isMobile ? 'auto' : 0} minW="0">
          <AssessmentThreadView />
        </Box>
        <Box>
          <AssessmentClearedSection />
        </Box>
        {isMobile ? null : <PregnancyStatusDropdown />}
      </HStack>
      {isMobile ? (
        <Flex px={2} w="100%" justify="space-between" align="center" gap={2} pb={1}>
          <ArchivedByOtherPreview />
          <PregnancyStatusDropdown isMobile />
        </Flex>
      ) : null}
    </Stack>
  )
}

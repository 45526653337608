import { CloseIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { colors, FieldMap, getFullName, nameField } from '@hb/shared'
import React, { useState } from 'react'
import { useAuth } from '../../store/auth'
import { useAppModals } from '../../store/modals'
import { SolidActionButton } from '../Buttons'
import { DataView } from '../DataView'
import { useNameEntryForm } from '../Modals/NameEntryModal'
import { EmailEdit } from './EmailEdit'
import { PhoneEdit } from './PhoneEdit'

const nameEditField: FieldMap = {
  ...nameField,
  name: 'Name Details',
}

const OpenTwoFactor = () => {
  const { enrolledFactors } = useAuth()
  return (
    <HStack
      borderBottomRadius={4}
      p={2}
      bg={enrolledFactors?.length ? 'gray.50' : 'yellow.300'}
      w="100%">
      <Text fontSize="xs" fontWeight={500} fontFamily="hero-new">
        TWO-FACTOR AUTHENTICATION
      </Text>
      <SolidActionButton
        ml="auto"
        onClick={() => useAppModals.getState().multiFactorAuth.open()}
        color="white"
        borderRadius="full"
        size="xs">
        OPEN
      </SolidActionButton>
    </HStack>
  )
}

export const AccountModal = ({ onClose }: { onClose: () => void }) => {
  const { onUpdate, value, onUpdateField } = useNameEntryForm()
  const { user } = useAuth()
  return (
    <Modal isCentered isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={0}>
          <VStack spacing={0} divider={<Divider />} w="100%" flexFlow="column">
            <Flex
              bg="gray.50"
              borderTopRadius={4}
              borderBottom="1px solid #cdcdcd"
              px={2}
              py={1.5}
              w="100%"
              align="center">
              <Text fontFamily="Hero-New" color="gray.700">
                Account Details
              </Text>
              <IconButton
                size="xs"
                borderRadius="full"
                aria-label="close"
                ml="auto"
                variant="ghost"
                onClick={onClose}
                icon={<CloseIcon w={2.5} h={2.5} />}
              />
            </Flex>
            <Box px={3} py={1} w="100%">
              <EmailEdit />
            </Box>
            <Box w="100%">
              <DataView
                data={value}
                updateField={onUpdateField}
                onSubmit={onUpdate}
                field={nameEditField}
              />
            </Box>
            <Box w="100%" py={1} px={2}>
              <PhoneEdit user={user} />
            </Box>
            <OpenTwoFactor />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export const AccountButton = () => {
  const [isOpen, setIsOpen] = useState(false)
  const user = useAuth(s => s.user)
  const { pronouns } = user ?? {}

  return (
    <Box bg={`${colors.green.hex}44`} w="100%">
      <HStack py={1} bg={colors.green.hex} px={2} w="100%">
        <Text fontSize="xs" color="white" fontWeight="medium" fontFamily="hero-new">
          ACCOUNT DETAILS
        </Text>
        <Button
          ml="auto"
          onClick={() => setIsOpen(true)}
          color="white"
          borderRadius="full"
          size="xs"
          bg="blackAlpha.200"
          _hover={{ bg: 'blackAlpha.300' }}>
          OPEN
        </Button>
      </HStack>
      <Box p={2}>
        <Flex align="center" gap={2}>
          <Text lineHeight={1} fontWeight={500} opacity={0.9} fontSize="md" w="100%">
            {getFullName(user) || 'No name entered'}
          </Text>
          {pronouns ? (
            <Text
              fontSize="xs"
              color="gray.600"
              bg="gray.100"
              px={1}
              borderRadius={4}
              fontWeight={500}>
              {pronouns}
            </Text>
          ) : null}
        </Flex>
        <Text opacity={0.9} fontSize="sm" w="100%">
          {user?.email || 'No email entered'}
        </Text>
      </Box>
      {isOpen ? <AccountModal onClose={() => setIsOpen(false)} /> : null}
    </Box>
  )
}

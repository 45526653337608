import { Box, Center, Spinner, VStack } from '@chakra-ui/react'
import {
  FileDBValue,
  FullPagePlacement,
  InlineFilePlacement,
  isInlineAttachment,
  TemplateAttachment,
} from '@hb/shared'
import { AnimatePresence } from 'framer-motion'
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { ScreenContext } from '../../contexts'
import { useFile } from '../../hooks'
import { useResizeObserver } from '../../hooks/useResizeObserver'
import { PdfDocumentFormProvider } from '../RichText/DocumentFormProvider'
import { useExternalPdfTemplateEditor } from '../Templates/ExternalPdf/context'
import { AttachmentView } from './Attachments'
import { PdfViewProvider, usePdfView } from './context'
import './styles.css'

import { PdfViewAttachmentPage, PdfViewPage, PdfViewProps } from './types'

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   '/pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url,
// ).toString()
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs'
const PageNumberIndicator = ({
  containerRef,
  scrollRef,
}: {
  containerRef: React.RefObject<HTMLDivElement>
  scrollRef: React.RefObject<HTMLDivElement>
}) => {
  const { onScrollPage: setPage, scrollPageIndex: pageIndex, numPages } = usePdfView()
  const screen = useContext(ScreenContext)
  useEffect(() => {
    const container = containerRef.current
    const scroll = scrollRef.current
    if (numPages === null || !container || !scroll) return () => {}
    const pages = container.querySelectorAll('.pdf-view-page-container')
    const pageHeights = Array.from(pages).map(p => p.scrollHeight)

    const onScroll = () => {
      const { scrollTop } = scroll ?? {}
      if (scrollTop === undefined) return
      let sum = 0
      let scrollPage = 0
      for (let i = 0; i < pageHeights.length; i += 1) {
        sum += pageHeights[i]
        if (sum > scrollTop + pageHeights[i] / 3) {
          scrollPage = i
          break
        }
      }
      setPage(scrollPage)
    }
    scroll.addEventListener('scroll', onScroll, { passive: true })
    scroll.addEventListener('wheel', onScroll, { passive: true })
    return () => {
      scroll.removeEventListener('scroll', onScroll)
      scroll.removeEventListener('wheel', onScroll)
    }
  }, [containerRef, numPages, screen, scrollRef, setPage])
  return (
    <Center w="100%" position="absolute" bottom="10px">
      <Center
        bg="rgba(0,0,0,0.3)"
        color="white"
        fontSize="sm"
        fontWeight={600}
        height="30px"
        p={1}
        px={4}
        borderRadius={4}
        boxShadow="inset 0 0 6px rgba(0,0,0,0.4)"
        textShadow="0 0 3px black">
        {numPages !== null ? `Page ${pageIndex + 1} / ${numPages}` : 'Loading...'}
      </Center>
    </Center>
  )
}

const PdfPage = ({
  page,
  index,
  pageWidth,
  pageHeight,
}: {
  page: PdfViewPage
  index: number
  pageWidth: number
  pageHeight: number
}) => {
  const pageRef = useRef<HTMLDivElement>(null)
  const { width, height } = useResizeObserver(pageRef, 'border')
  return (
    <Box
      ref={pageRef}
      className="pdf-view-page-container"
      key={`page_${index}`}
      position="relative">
      {page.type === 'file' ? (
        <>
          <Page
            className="pdf-view-page"
            loading={
              <Center w={`${pageWidth}px`} h={`${pageHeight}px`}>
                <Spinner />
              </Center>
            }
            width={pageWidth}
            pageNumber={index + 1}
          />
          <AnimatePresence>
            {page.attachments.map(attachment => (
              <AttachmentView
                pageHeight={height}
                pageWidth={width}
                key={attachment.id}
                attachment={attachment}
              />
            ))}
          </AnimatePresence>
        </>
      ) : (
        <AttachmentView attachment={page.attachment} pageWidth={width} pageHeight={height} />
      )}
    </Box>
  )
}

const PdfViewBody = ({
  url,
  children,
  height,
  attachments,
  document,
  onFormSubmit,
  readOnly,
  width,
}: PropsWithChildren<PdfViewProps>) => {
  const { updateNumPages, numPages } = usePdfView()
  useEffect(() => {
    if (!url) updateNumPages(null)
  }, [url, updateNumPages])
  const onDocumentLoadSuccess = useCallback(
    ({ numPages: num }: { numPages: number }) => {
      updateNumPages(num)
    },
    [updateNumPages],
  )

  const { onAttachmentSelect } = useExternalPdfTemplateEditor()
  const containerRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)

  const { inlineAttachments, fullPageAttachments } = useMemo(() => {
    const inline: TemplateAttachment<InlineFilePlacement>[] = []
    const fullPage: TemplateAttachment<FullPagePlacement>[][] = Array.from(
      new Array(numPages ?? 0),
      () => [],
    )
    if (!attachments) return { inlineAttachments: inline, fullPageAttachments: fullPage }
    Object.values(attachments).forEach(a => {
      if (isInlineAttachment(a)) {
        inline.push(a)
      } else {
        const asFullPage = a as TemplateAttachment<FullPagePlacement>
        const placement = asFullPage.placement
        fullPage[placement.afterPageIndex + 1].push(asFullPage)
      }
    })
    return { inlineAttachments: inline, fullPageAttachments: fullPage }
  }, [attachments, numPages])

  const pages = useMemo<PdfViewPage[]>(() => {
    const p: PdfViewPage[] = Array.from(new Array(numPages ?? 0), (_, index) => ({
      type: 'file',
      pageNumber: index + 1,
      attachments: inlineAttachments
        .filter(a =>
          index === (numPages ?? 0) - 1
            ? a.placement.pageIndex >= index
            : a.placement.pageIndex === index,
        )
        .sort((a, b) => {
          const sameLine =
            Math.abs(a.placement.orientation.position[1] - b.placement.orientation.position[1]) < 10
          if (sameLine)
            return a.placement.orientation.position[0] > b.placement.orientation.position[0]
              ? 1
              : -1
          return a.placement.orientation.position[1] > b.placement.orientation.position[1] ? 1 : -1
        }),
    }))

    for (let i = fullPageAttachments.length - 1; i >= 0; i--) {
      const attachments = fullPageAttachments[i]
      const attachmentPages: PdfViewAttachmentPage[] = attachments.map(a => ({
        type: 'attachment',
        attachment: a,
      }))
      if (attachments.length > 0) {
        p.splice(i, 0, ...attachmentPages)
      }
    }

    return p
  }, [numPages, inlineAttachments, fullPageAttachments])

  const contentRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const { height: headerHeight } = useResizeObserver(headerRef, 'scroll')
  const { height: contentHeight } = useResizeObserver(contentRef, 'scroll')

  const docHeight = useMemo(() => {
    if (!children) return contentHeight
    return contentHeight - headerHeight
  }, [children, contentHeight, headerHeight])

  const { pageWidth, pageHeight } = useMemo(
    () => ({
      pageWidth: width - 30,
      pageHeight: height - 30 * 1.4143,
    }),
    [width, height],
  )

  return (
    <PdfDocumentFormProvider
      height={height}
      document={document}
      onFormSubmit={onFormSubmit}
      readOnly={readOnly}>
      <Center w="100%" h="100%" ref={contentRef}>
        {url ? (
          <VStack
            spacing={0}
            width={`${width}px`}
            height={`${contentHeight}px`}
            position="relative"
            align="center">
            {children ? (
              <Box ref={headerRef} w="100%">
                {children}
              </Box>
            ) : null}
            <Center
              p="10px"
              ref={scrollRef}
              alignItems="flex-start"
              overflowY="auto"
              onClick={() => {
                onAttachmentSelect(null)
              }}
              width={`${width}px`}
              height={`${docHeight}px`}>
              <Document
                inputRef={containerRef}
                loading={
                  <Center w={`${pageWidth}px`} h={`${pageHeight}px`}>
                    <Spinner />
                  </Center>
                }
                onLoadSuccess={onDocumentLoadSuccess}
                file={url}>
                {pages.map((page, index) => (
                  <PdfPage
                    key={`page_${index}`}
                    page={page}
                    index={index}
                    pageWidth={pageWidth}
                    pageHeight={pageHeight}
                  />
                ))}
              </Document>
            </Center>
          </VStack>
        ) : (
          <Spinner />
        )}
        {numPages !== null && numPages > 1 ? (
          <PageNumberIndicator containerRef={containerRef} scrollRef={scrollRef} />
        ) : null}
      </Center>
    </PdfDocumentFormProvider>
  )
}

export const PdfView = (props: PdfViewProps) => (
  <PdfViewProvider attachments={props.attachments}>
    <PdfViewBody {...props} />
  </PdfViewProvider>
)

export const FileValuePdfView = ({
  value,
  ...props
}: Omit<PdfViewProps, 'url'> & { value: FileDBValue | null }) => {
  const fileArgs = useMemo(() => ({ value, path: value?.storagePath }), [value])
  const { url } = useFile(fileArgs)

  return <PdfView url={url} {...props} />
}

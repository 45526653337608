import {
  AnyObject,
  AssessmentInterface,
  AssessmentSnippet,
  CollectionFilter,
  CollectionSort,
  colors,
  InsuranceProvider,
  LocalCollectionState,
  PracticeAssessmentData,
  PracticeWithAdmin,
  PresetFilter,
  stateOptions,
} from '@hb/shared'
import { getRowBackground } from '../../DataView/utils'

export const getInsurerFilter = (
  insurers: LocalCollectionState<InsuranceProvider>,
): PresetFilter => {
  const { items } = insurers
  return {
    label: 'Insurer',
    searchable: true,
    filters: items.map(insurer => ({
      label: insurer.name,
      filter: ['insurerId', '==', insurer.id],
    })),
  }
}

export const getProviderFilter = (
  providers: LocalCollectionState<PracticeWithAdmin>,
): PresetFilter => {
  const { items } = providers
  return {
    label: 'Provider',
    searchable: true,
    filters: items.map(provider => ({
      label: provider.name,
      filter: ['midwifeId', '==', provider.id],
    })),
  }
}

// provider assessment tabs
export const getDeliveredBackgroundColor = (index: number) =>
  index % 2 === 0 ? `${colors.pink.hex}22` : `${colors.pink.hex}45`
export const getTransferredBackgroundColor = (index: number) =>
  index % 2 === 0 ? `${colors.green.hex}22` : `${colors.green.hex}34`
export const getAssessmentBackgroundColor = (
  item: AssessmentInterface | PracticeAssessmentData,
  index: number,
) => {
  if (item.deliveredOn && item.deliveredOn !== Infinity) return getDeliveredBackgroundColor(index)
  if (item.delivery?.isTransfer || item.deliveredOn === Infinity)
    return getTransferredBackgroundColor(index)
  return getRowBackground(index)
}

export const getPostpartumBackgroundColor = (
  item: AssessmentInterface | PracticeAssessmentData,
  index: number,
) => {
  if (item.delivery?.isTransfer || item.deliveredOn === Infinity)
    return getTransferredBackgroundColor(index)
  return getRowBackground(index)
}

export const nextActionSort: CollectionSort<AnyObject> = {
  field: 'nextActionDate',
  order: 'asc',
}

export const getPresetAssessmentFilters = (
  insurers: LocalCollectionState<InsuranceProvider>,
  providers: LocalCollectionState<PracticeWithAdmin>,
): PresetFilter[] => [
  {
    label: 'Plan Design',
    filters: [
      {
        label: 'Fully-Insured',
        filter: ['planCoverageType', '==', 'Fully Insured'],
      },
      {
        label: 'Self-Funded',
        filter: ['planCoverageType', '==', 'Self-Funded'],
      },
      {
        label: 'Split-Funded',
        filter: ['planCoverageType', '==', 'Split-Funded'],
      },
    ],
  },
  getInsurerFilter(insurers),
  getProviderFilter(providers),
  {
    label: 'State of Plan',
    searchable: true,
    filters: stateOptions.map(state => ({
      label: state.text,
      filter: ['planState', '==', state.id] as CollectionFilter<AssessmentSnippet>,
    })),
  },
  {
    label: 'Medicaid/Marketplace',
    filters: [
      {
        label: 'Marketplace Plans',
        filter: ['isMarketplacePlan', '==', true],
      },
      {
        label: 'Medicaid Plans',
        filter: ['isMedicaidPlan', '==', true],
      },
    ],
  },
  {
    label: 'Complaints',
    filter: ['hasComplaints', '==', true],
  },
]

import { FieldTypes } from '../../constants'
import { FieldMap, FieldMapValue } from '../../types'
import { formatDollarValue } from '../../utils/data'
import { oonCondition } from './utils'

const getErrorString = (value: any, erorrString = 'ERROR: not found') => {
  if (value === undefined || value === '' || value === null) return erorrString
  return value
}

const labels = (mergedData?: FieldMapValue) => {
  const midwife = mergedData?.delivery?.midwife
  const { zipCode, npi, name, billedCharges } = midwife ?? {}
  const displayedZip =
    zipCode && typeof zipCode === 'string' ? zipCode.substring(0, 5) : 'ERROR: zip code not found'
  const displayedCharge = billedCharges?._59400
  return [
    {
      text: "I need to know what are the 'maximum reimbursable charges' or ‘allowed charges’ for Global Maternity Care (CPT/procedure code 59400)?",
      style: { display: 'block', marginBottom: 10 },
    },
    {
      text: 'The allowed charge should be either a specific dollar amount or dollar range. Please note: the allowed charge is neither your deductible nor coinsurance / out of pocket max (in case the agent reads these figures back to you).',
      style: { display: 'block', marginBottom: 10, color: 'black' },
    },
    {
      text: 'If the agent will not provide you with the allowed charge, we recommend asking if a supervisor can assist.',
      style: { display: 'block', fontWeight: 600, color: 'black' },
    },
    {
      text: `If they need a zip code to look this up, it is ${getErrorString(
        displayedZip,
      )}. If they need an NPI,
      you can give them ${getErrorString(npi)} for ${getErrorString(
        name,
        'ERROR: midwife name not found',
      )}. If they ask for billed charges for CPT code 59400, it is ${
        displayedCharge ? formatDollarValue(displayedCharge) : 'ERROR: Midwife PRM not found'
      }. It is your right to gather details like this in order to assess what costs you will have if you go out of network for services. `,
      style: { display: 'block', marginBottom: 10, color: 'black' },
    },
  ]
}

export const maximumReimbursableChargesField: FieldMap = {
  name: 'Maximum Reimbursable Charges',
  condition: oonCondition,
  validate: v =>
    v?.amount || v?.notes
      ? undefined
      : { notes: 'Please include an amount or explanation from the agent' },
  children: {
    amount: {
      type: FieldTypes.DOLLAR_AMOUNT,
      optional: true,
      placeholder: 'Maximum Reimbursable Charges',
      labels,
      hints: [
        {
          text: 'If the agent provides a dollar range, please enter the lowest dollar amount quoted to you. If the agent can not answer this question, please elaborate in the notes box below',
        },
      ],
    },
    notes: {
      type: FieldTypes.TEXTAREA,
      placeholder: 'Notes...',
      optional: true,
    },
  },
}

import { InfoIcon } from '@chakra-ui/icons'
import { Box, BoxProps, IconButton, IconButtonProps } from '@chakra-ui/react'
import { WithId } from '@hb/shared'
import React, { ReactElement } from 'react'
import { CopyTooltip } from './CopyTooltip'

const CopyIdBody = ({
  item,
  buttonProps,
  icon,
  prefix = 'ID:',
}: {
  item: WithId | null
  buttonProps?: Omit<IconButtonProps, 'aria-label'>
  icon?: ReactElement
  prefix?: string
}) => (
  <CopyTooltip
    placement="left"
    bg="gray.600"
    prefix={prefix}
    label={item ? item.id : 'nothing to copy'}>
    <IconButton
      size="sm"
      w={6}
      h={6}
      minW={0}
      borderRadius="full"
      variant="ghost"
      aria-label="ID"
      icon={icon || <InfoIcon color="gray.500" />}
      {...buttonProps}
    />
  </CopyTooltip>
)

export const CopyId = ({
  item,
  buttonProps,
  icon,
  prefix,
  ...props
}: {
  item: WithId | null
  buttonProps?: Omit<IconButtonProps, 'aria-label'>
  icon?: ReactElement
  prefix?: string
} & BoxProps) => (
  <Box position="absolute" bottom="0.25rem" right="0.25rem" {...props}>
    <CopyIdBody item={item} buttonProps={buttonProps} icon={icon} prefix={prefix} />
  </Box>
)

export const RelativeCopyId = ({
  item,
  buttonProps,
  icon,
  prefix,
  ...props
}: {
  item: WithId | null
  buttonProps?: Omit<IconButtonProps, 'aria-label'>
  icon?: ReactElement
  prefix?: string
} & BoxProps) => (
  <Box position="relative" {...props}>
    <CopyIdBody item={item} buttonProps={buttonProps} icon={icon} prefix={prefix} />
  </Box>
)

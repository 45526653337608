import { Text, VStack } from '@chakra-ui/react'
import { DropdownField, FieldTypes, Hospital } from '@hb/shared'
import React, { useContext, useMemo } from 'react'
import { ProfileContext } from '../../../../../../contexts/ProfileContext'
import { FormElement } from '../../../../../forms/Input'

const formatHospital = (hospital: Hospital | undefined) =>
  `${hospital?.name} | NPI: ${hospital?.npi || 'None'}`
export const AffiliatedHospitals = () => {
  const {
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(ProfileContext)
  const { midwife } = selectedAssessment ?? {}
  const { affiliatedHospitals } = midwife ?? {}
  const affiliatedHospitalField = useMemo<DropdownField>(
    () => ({
      type: FieldTypes.DROPDOWN,
      options: (affiliatedHospitals && Array.isArray(affiliatedHospitals)
        ? affiliatedHospitals
        : []
      )
        .filter(h => !!h.providerHasDeliveryPrivileges)
        .map((hospital, idx) => ({
          id: hospital.npi ?? `${idx}`,
          text: formatHospital(hospital),
        })),
      format: v => (v ? formatHospital(v) : undefined),
      parse: v => affiliatedHospitals?.find(p => p.npi === v),
      placeholder: 'Affiliated hospital (optional)',
      optional: true,
    }),
    [affiliatedHospitals],
  )
  return (
    <VStack align="flex-start" w="100%" spacing={0} pt={2}>
      <Text fontWeight={600} color="gray.500" fontSize="sm">
        Affiliated hospital
      </Text>
      {affiliatedHospitalField.options.length ? (
        <>
          <FormElement field={affiliatedHospitalField} name="affiliatedHospital" />
        </>
      ) : (
        <Text color="gray.500" fontSize="sm">
          {midwife?.name} has no affiliated hospitals where they have delivery privileges
        </Text>
      )}
    </VStack>
  )
}

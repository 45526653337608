import { Collapse, Divider, VStack } from '@chakra-ui/react'
import { DropdownField, Field, InsuranceCoverage } from '@hb/shared'
import React from 'react'
import { FormElement } from '../../../../../forms'
import { customRelateCodePrimary } from '../fields'
import { PayerIdInput } from './PayerIdInput'
import { RelateCodeInput } from './RelateCodeInput'

const CoverageDetailsInput = ({
  coverage,
  authorizationsField,
  authRefFieldId,
  customRelateCodeField,
  customRelateCodeFieldId,
  shouldUseCustomRelateCodeFieldId,
  customPayerIdFieldId,
  shouldUseCustomPayerIdFieldId,
}: {
  coverage: InsuranceCoverage | null | undefined
  authorizationsField: DropdownField
  customRelateCodeField: Field
  authRefFieldId: string
  customRelateCodeFieldId: string
  shouldUseCustomRelateCodeFieldId: string
  customPayerIdFieldId: string
  shouldUseCustomPayerIdFieldId: string
}) => (
  <Collapse unmountOnExit style={{ width: '100%' }} in={!!coverage}>
    <PayerIdInput
      shouldUseCustomField={shouldUseCustomPayerIdFieldId}
      customPayerIdField={customPayerIdFieldId}
      insuranceProvider={coverage?.insuranceProvider}
    />
    <VStack spacing={0} align="flex-start" w="100%">
      <Divider my={1} />
      <FormElement field={authorizationsField} name={authRefFieldId} />
      <Divider my={1} />
      <RelateCodeInput
        coverage={coverage}
        customRelateCodeField={customRelateCodeField}
        customRelateCodeFieldId={customRelateCodeFieldId}
        shouldUseCustomFieldId={shouldUseCustomRelateCodeFieldId}
      />
    </VStack>
  </Collapse>
)

export const MainCoverageDetails = ({
  authorizationsField,
  coverage,
}: {
  coverage: InsuranceCoverage | null | undefined
  authorizationsField: DropdownField
}) => (
  <CoverageDetailsInput
    coverage={coverage}
    authorizationsField={authorizationsField}
    authRefFieldId="primaryCoverageAuthRef"
    customRelateCodeField={customRelateCodePrimary}
    customRelateCodeFieldId="customRelateCodePrimary"
    shouldUseCustomRelateCodeFieldId="shouldUseCustomRelateCodePrimary"
    shouldUseCustomPayerIdFieldId="shouldUseCustomPayerIdPrimary"
    customPayerIdFieldId="customPayerIdPrimary"
  />
)

export const OtherCoverageDetails = ({
  coverage,
  authorizationsField,
}: {
  coverage: InsuranceCoverage | null
  authorizationsField: DropdownField
}) => (
  <CoverageDetailsInput
    coverage={coverage}
    authorizationsField={authorizationsField}
    authRefFieldId="otherCoverageAuthRef"
    customRelateCodeField={customRelateCodePrimary}
    customRelateCodeFieldId="customRelateCodeSecondary"
    shouldUseCustomRelateCodeFieldId="shouldUseCustomRelateCodeSecondary"
    shouldUseCustomPayerIdFieldId="shouldUseCustomPayerIdSecondary"
    customPayerIdFieldId="customPayerIdSecondary"
  />
)

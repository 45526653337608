import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Center, Collapse, Flex, Text } from '@chakra-ui/react'
import {
  colors,
  getInvoiceId,
  getMonthStringFromNumber,
  INVOICES,
  ManuallyCreateInvoiceArgs,
  padZeros,
  parseDateFromInvoiceId,
  PracticeInvoice,
  UseQuery,
} from '@hb/shared'
import { collection, CollectionReference, documentId, query, where } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { db } from '../../backend/db'
import { functions } from '../../backend/functions'
import { practiceSelectField } from '../../collections/fields/practice'
import { PopUpMessageContext } from '../../contexts'
import { useQuery } from '../../hooks/backend/useQuery'
import { ActionButton, SolidActionButton } from '../Buttons'
import { StandaloneInput } from '../forms/Input'
import { Loading } from '../Loading'
import { DefaultModal } from '../Modals/DefaultModal'
import { InvoiceStatusView } from './InvoiceStatusView'
import { useInvoicesView } from './InvoicesViewProvider'

const getPracticeInvoicesQuery = (practiceId: string | null) => {
  const now = new Date()
  const inOneMonth = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000)
  const oneMonthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000)

  const ids = [
    getInvoiceId(practiceId || 'NONE', oneMonthAgo, 0),
    getInvoiceId(practiceId || 'NONE', now, 0),
    getInvoiceId(practiceId || 'NONE', inOneMonth, 0),
  ]

  const months = ids.map(id => parseDateFromInvoiceId(id))
  if (!practiceId)
    return {
      ids,
      months,
      query: null,
    }
  const invoicesRef = collection(db, INVOICES) as CollectionReference<PracticeInvoice>
  return {
    ids,
    months: ids.map(id => parseDateFromInvoiceId(id)),
    query: query(invoicesRef, where(documentId(), 'in', ids)),
  }
}

const PracticeInvoicePreview = ({
  month,
  year,
  data,
  isCreating,
  onCreate,
  id,
}: {
  month: number
  year: number
  data: UseQuery<PracticeInvoice>
  isCreating: boolean
  onCreate: () => void
  id: string
}) => {
  const { selectInvoice } = useInvoicesView()
  const { error, data: invoices, loading } = data
  const invoice = useMemo(() => invoices?.[id] || null, [invoices, id])
  const nextMonthString = useMemo(() => {
    const date = new Date(year, month + 2, 1)
    return {
      month: date.getMonth(),
      year: date.getFullYear(),
    }
  }, [month, year])
  const body = useMemo(() => {
    if (invoice) {
      return (
        <Flex align="center" flexFlow="column" w="100%">
          <InvoiceStatusView invoice={invoice} />
        </Flex>
      )
    }
    if (error) {
      return <Text color="red.600">Error: {error}</Text>
    }

    return loading ? (
      <Loading text="Loading invoice" />
    ) : (
      <Text
        w="100%"
        textAlign="center"
        key={invoice}
        fontStyle="italic"
        color="gray.500"
        fontSize="sm">
        No invoice for this month
      </Text>
    )
  }, [error, loading, invoice])
  return (
    <Flex
      flex={1}
      minW="0"
      align="center"
      bg="white"
      boxShadow="md"
      key={`${month}-${year}`}
      // flexFlow="column"
      p={3}
      w="100%">
      <Flex flexFlow="column">
        <Center>
          <Flex bg={colors.green.hex} px={2.5} borderRadius="full">
            <Text
              whiteSpace="nowrap"
              textShadow="1px 1px 3px #00000044"
              fontWeight={600}
              fontSize="sm"
              color="white">
              {`${getMonthStringFromNumber(month, true)} ${year}`}
            </Text>
          </Flex>
        </Center>
        <Text
          fontSize="sm"
          fontWeight={500}
          color="gray.500"
          whiteSpace="nowrap"
          textAlign="center"
          fontFamily="Open Sans">
          Scheduled {padZeros(nextMonthString.month, 2)}/01/
          {nextMonthString.year.toString().slice(2)}
        </Text>
      </Flex>
      {body}
      {!invoice && loading ? null : (
        <Flex align="center">
          {invoice ? (
            <ActionButton onClick={() => selectInvoice(id)} size="xs" borderRadius="full">
              <Text>View Invoice</Text>
              <ArrowForwardIcon boxSize={3} ml={1} color={colors.green.hex} />
            </ActionButton>
          ) : (
            <SolidActionButton
              isLoading={isCreating}
              onClick={onCreate}
              isDisabled={isCreating}
              size="xs"
              borderRadius="full">
              <Text>+ Create Invoice</Text>
            </SolidActionButton>
          )}
        </Flex>
      )}
    </Flex>
  )
}

const PracticeInvoicesPreview = ({
  practiceId,
  onCreate,
  creatingId,
}: {
  practiceId: string | null
  onCreate: (id: string) => void
  creatingId: string | null
}) => {
  const {
    query: invoicesQuery,
    months,
    ids,
  } = useMemo(() => getPracticeInvoicesQuery(practiceId), [practiceId])

  const data = useQuery<PracticeInvoice>(invoicesQuery)

  return (
    <Flex flexFlow="column" p={1} gap={1.5} w="100%">
      {months.map(({ month, year }, idx) => {
        const id = ids[idx]
        return (
          <PracticeInvoicePreview
            key={`${month}-${year}`}
            month={month}
            year={year}
            data={data}
            isCreating={creatingId === id}
            onCreate={() => {
              if (id) {
                onCreate(id)
              }
            }}
            id={id}
          />
        )
      })}
    </Flex>
  )
}

const CreateInvoice = () => {
  const [practiceId, setPracticeId] = useState<string | null>(null)
  const { selectInvoice } = useInvoicesView()
  const [creatingInvoiceId, setCreatingInvoiceId] = useState<string | null>(null)
  const { showMessage } = useContext(PopUpMessageContext)
  const onCreate = useCallback(
    async (invoiceId: string) => {
      const createFunc = httpsCallable<ManuallyCreateInvoiceArgs>(
        functions,
        'manuallyCreateInvoice',
      )

      setCreatingInvoiceId(invoiceId)
      try {
        await createFunc({ invoiceId })
        selectInvoice(invoiceId)
      } catch (e: any) {
        showMessage({
          type: 'error',
          text: 'Error creating invoice',
          subText: e.message,
        })
      }
      setCreatingInvoiceId(null)
    },
    [selectInvoice, showMessage],
  )

  return (
    <Flex w="100%" flexFlow="column">
      <Flex borderBottom="1px solid #cdcdcd" align="center" py={2.5} px={3}>
        <Text flex={1} minW="0" fontFamily="Hero-New" fontSize="lg" fontWeight={500}>
          Create Invoice
        </Text>
      </Flex>
      <Flex bg="gray.100" p={2} w="100%" flexFlow="column">
        <StandaloneInput
          theme="detailed"
          field={practiceSelectField}
          value={practiceId}
          onChange={setPracticeId}
        />
        <Collapse style={{ width: '100%' }} in={!!practiceId} animateOpacity>
          <PracticeInvoicesPreview
            creatingId={creatingInvoiceId}
            onCreate={onCreate}
            practiceId={practiceId || ''}
          />
        </Collapse>
      </Flex>
    </Flex>
  )
}

export const CreateInvoiceModal = () => {
  const { createInvoiceOpen, setCreateInvoiceOpen } = useInvoicesView()

  return (
    <DefaultModal
      size="xl"
      isOpen={createInvoiceOpen}
      onClose={() => setCreateInvoiceOpen(false)}
      overlayHeader
      render={() => <CreateInvoice />}
    />
  )
}

import { EditIcon, LinkIcon, ViewIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  HStack,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text as Txt,
} from '@chakra-ui/react'
import {
  colors,
  FieldTypes,
  FormattedText,
  IdField,
  midwivesCollection,
  Mode,
  PracticeWithAdmin,
} from '@hb/shared'
import React, { PropsWithChildren, useCallback, useMemo, useRef } from 'react'
import { Editor, Node, NodeMatch, Range, Text, Transforms } from 'slate'
import { ReactEditor, useSlate, useSlateSelection } from 'slate-react'
import { useApp } from '../../contexts'
import { usePatientPlanField } from '../../hooks/insurance/fields'
import { xor } from '../../utils/data'
import { filteredEvents } from '../../utils/events'
import { ColorSelect } from '../ColorSelect'
import { Container } from '../Container'
import { StandaloneInput } from '../forms'
import { CollapseHorizontal } from '../shared/CollapseHorizontal'
import {
  useTemplateData,
  useTemplateInsurancePlans,
  useTemplatePractice,
} from '../Templates/contexts'
import { useEditMode } from '../Templates/contexts/editMode'
import { useTextEditor } from './context'
import { FontSelect } from './fonts/FontSelect'
import { JustifyCenter, JustifyLeft, JustifyRight } from './Icons'

const ToggleButton = ({
  active,
  onClick,
  children,
}: PropsWithChildren<{
  active: boolean
  onClick: () => void
}>) => (
  <Container
    {...filteredEvents(onClick)}
    style={{
      cursor: 'pointer',
      flex: 1,
      height: '100%',
      fontSize: '12px',
      padding: '0 10px',
      background: active ? '#ababab' : 'white',
      color: active ? 'white' : '#ababab',
    }}>
    {children}
  </Container>
)

const ToggleContainer = ({ children }: PropsWithChildren) => (
  <Container
    style={{
      height: 25,
      margin: 5,
      flexFlow: 'row',
      width: 'auto',
      border: '1px solid #ababab',
    }}>
    {children}
  </Container>
)

const HeaderToggle: React.FC<{
  active: boolean
  toggle: (value: boolean) => void
}> = ({ active, toggle }) => (
  <ToggleContainer>
    <ToggleButton
      onClick={() => {
        toggle(!active)
      }}
      active={active}>
      B
    </ToggleButton>
  </ToggleContainer>
)

const LinkToggle: React.FC<{
  active: boolean
  toggle: () => void
}> = ({ active, toggle }) => (
  <Box pl={2}>
    <IconButton
      size="xs"
      aria-label="Link"
      bg={active ? colors.green.hex : 'white'}
      _hover={{ bg: 'green.100' }}
      color={active ? 'white' : colors.green.hex}
      borderColor={colors.green.hex}
      borderWidth="1px"
      onClick={toggle}
      icon={<LinkIcon />}
    />
  </Box>
)

const FontSizeSelect: React.FC<{
  fontSize?: number
  setFontSize: (size: number) => void
}> = ({ fontSize = 1, setFontSize }) => (
  <Box pl={1}>
    <NumberInput
      value={fontSize}
      {...filteredEvents()}
      onChange={(_, n) => setFontSize(n)}
      focusInputOnChange={false}
      size="xs"
      maxW={16}
      step={0.2}>
      <NumberInputField {...filteredEvents()} />
      <NumberInputStepper>
        <NumberIncrementStepper {...filteredEvents()} />
        <NumberDecrementStepper {...filteredEvents()} />
      </NumberInputStepper>
    </NumberInput>
  </Box>
)

const TextAlignSelect: React.FC<{
  justify: FormattedText['justify']
  setJustify: (j?: FormattedText['justify']) => void
}> = ({ justify, setJustify }) => (
  <Stack direction="row" border="1px solid #cdcdcd" ml={2} p={1} borderRadius={4}>
    <JustifyLeft {...filteredEvents(() => setJustify(undefined))} selected={!justify} />
    <JustifyCenter
      {...filteredEvents(() => setJustify('center'))}
      selected={justify === 'center'}
    />
    <JustifyRight {...filteredEvents(() => setJustify('right'))} selected={justify === 'right'} />
  </Stack>
)

const ModeToggle: React.FC<{ mode: Mode; setMode: (mode: Mode) => void }> = ({ mode, setMode }) => {
  const [hovered, setHovered] = React.useState(false)
  const [recentlyChanged, setRecentlyChanged] = React.useState(false)
  return (
    <Flex
      justify="center"
      w="120px"
      h="35px"
      align="center"
      px={2}
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => {
        setHovered(false)
        setRecentlyChanged(false)
      }}
      onClick={() => {
        setMode(mode === 'Edit' ? 'View' : 'Edit')
        setRecentlyChanged(true)
      }}
      cursor="pointer">
      <CollapseHorizontal width={100} in={xor(hovered && !recentlyChanged, mode === 'Edit')}>
        <HStack px={2} py={1} borderRadius={4} bg={colors.green.hex} spacing="0.25rem">
          <EditIcon filter="drop-shadow(1px 1px 3px #00000077)" w={3.5} color="white" />
          <Txt
            whiteSpace="nowrap"
            fontFamily="Hero-New"
            textShadow="1px 1px 3px #00000077"
            fontSize="xs"
            color="white">
            EDIT MODE
          </Txt>
        </HStack>
      </CollapseHorizontal>
      <CollapseHorizontal width={100} in={xor(hovered && !recentlyChanged, mode === 'View')}>
        <HStack px={1} py={1} borderRadius={4} bg="#ababab" spacing="0.25rem">
          <ViewIcon position="relative" color="white" />
          <Txt
            whiteSpace="nowrap"
            fontFamily="Hero-New"
            textShadow="1px 1px 3px #00000077"
            fontSize="xs"
            color="white">
            VIEW MODE
          </Txt>
        </HStack>
      </CollapseHorizontal>
    </Flex>
  )
}

const EditorTextToolbar = () => {
  const selection = useSlateSelection()
  const editor = useSlate()
  const selectTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const { activeColor, headerActive, fontSize, justify, font, linkPath } = useMemo(() => {
    if (!selection) {
      return {
        isImage: false,
        activeColor: undefined,
        headerActive: false,
        fontSize: undefined,
        linkPath: null,
        justify: undefined,
        font: undefined,
      }
    }
    const [imageMatch] = Editor.nodes(editor, {
      match: n => n.field?.type === 'variable' && n.field.type === FieldTypes.FILE,
      at: selection,
    })
    if (imageMatch) {
      return {
        isImage: true,
        activeColor: undefined,
        headerActive: false,
        fontSize: undefined,
        linkPath: null,
        justify: undefined,
        font: undefined,
      }
    }

    const selectedNode = Editor.node(editor, selection)
    const parent =
      selection.anchor.path[0] !== selection.focus.path[0] ? null : Editor.parent(editor, selection)
    const linkMatch =
      selectedNode[0]?.type === 'link' || (parent && parent[0].type === 'link') ? parent : null

    const [match] = Editor.nodes(editor, {
      match: n => Text.isText(n),
      at: selection,
    })
    if (imageMatch) {
      return {
        isImage: true,
        activeColor: undefined,
        headerActive: false,
        fontSize: undefined,
        justify: undefined,
        font: undefined,
      }
    }
    if (match) {
      const [node] = match as FormattedText[]
      return {
        activeColor: node.color,
        headerActive: !!node.header,
        fontSize: node.fontSize,
        justify: node.justify,
        linkPath: linkMatch?.[1] ?? null,
        font: node.font,
      }
    }

    return { headerActive: false, linkPath: null }
  }, [editor, selection])

  const updateFormat = useCallback(
    (props: Partial<{ [K in keyof FormattedText]: FormattedText[K] | undefined }>) => {
      if (selectTimeout.current) {
        clearTimeout(selectTimeout.current)
      }
      const removed: Array<string> = []
      const added: Record<string, any> = {}
      if (!selection) return
      Object.entries(props).forEach(([k, v]) => {
        if (v === undefined) removed.push(k)
        else added[k] = v
      })
      const options = {
        match: (n => Text.isText(n)) as NodeMatch<Node>,
        split: true,
      }
      if (Object.keys(added).length) {
        Transforms.setNodes(editor, added, {
          at: selection,
          match: t => Text.isText(t),
          split: !Range.isCollapsed(selection),
        })
      }
      if (removed.length) {
        Transforms.unsetNodes(editor, removed, options)
      }
      selectTimeout.current = setTimeout(() => {
        const s = editor.selection
        if (!s) return
        editor.deselect()
        selectTimeout.current = setTimeout(() => {
          editor.select(s)
          selectTimeout.current = null
        }, 20)
      }, 20)
    },
    [editor, selection],
  )
  const toggleHeader = useCallback(
    (value: boolean) => {
      updateFormat({ header: value || undefined })
    },
    [updateFormat],
  )

  const setFontSize = useCallback(
    (fs: number) => {
      updateFormat({
        fontSize: fs && fs !== 1 ? fs : undefined,
      })
      ReactEditor.focus(editor)
    },
    [updateFormat, editor],
  )

  const toggleLink = useCallback(() => {
    // split text and add as link element with text node as child
    if (!selection) return
    if (linkPath) {
      Transforms.unwrapNodes(editor, { at: linkPath })
    } else {
      Transforms.wrapNodes(editor, { type: 'link', url: '' }, { at: selection, split: true })
    }
  }, [editor, selection, linkPath])

  return (
    <>
      <FontSelect font={font} setFont={f => updateFormat({ font: f })} />
      <HeaderToggle active={headerActive} toggle={toggleHeader} />
      <Box px={1}>
        <ColorSelect setColor={color => updateFormat({ color })} color={activeColor} />
      </Box>
      <FontSizeSelect fontSize={fontSize} setFontSize={setFontSize} />
      <TextAlignSelect
        setJustify={j => updateFormat({ justify: j === 'left' ? undefined : j })}
        justify={justify}
      />
      <LinkToggle active={!!linkPath} toggle={toggleLink} />
    </>
  )
}

const EditorCoverageSelect = () => {
  const { insurancePlans, selectPlan, selectedPlanId } = useTemplateInsurancePlans()
  const field = usePatientPlanField(insurancePlans)
  return (
    <Flex px={2} align="center" flex={1} minW="200px">
      <Box borderRadius={5} overflow="hidden" pt={1} w="100%">
        <StandaloneInput
          field={field}
          style={{
            padding: '0.25rem 0.4rem',
            border: '1px solid #cdcdcd',
            borderRadius: 5,
          }}
          value={selectedPlanId}
          onChange={id => selectPlan(id)}
        />
      </Box>
    </Flex>
  )
}

const previewPracticeSelectField: IdField<PracticeWithAdmin> = {
  type: FieldTypes.ID,
  placeholder: 'Preview Practice',
  collection: midwivesCollection,
  optional: true,
}
const PreviewPracticeSelect = () => {
  const { selectPractice } = useTemplatePractice()
  const {
    shortcutArgs: { practice },
  } = useTemplateData()

  return (
    <Flex px={2} align="center" flex={1} minW="200px">
      <Flex
        justify="flex-end"
        border="1px solid #cdcdcd"
        borderRadius={5}
        overflow="hidden"
        pt={1}
        w="100%">
        <StandaloneInput
          style={{
            borderRadius: 5,
            padding: 0,
          }}
          field={previewPracticeSelectField}
          onChange={selectPractice}
          value={practice?.id}
        />
      </Flex>
    </Flex>
  )
}

export const EditorToolbar: React.FC = () => {
  const { type } = useTemplateData()
  const { mode, setMode } = useTextEditor()
  const editMode = useEditMode()
  const { appName } = useApp()
  const showCoverageSelect = useMemo(
    () => type !== 'consentForm' && editMode === 'document',
    [editMode, type],
  )
  const showPracticeSelect = useMemo(
    () => appName === 'app' && editMode === 'template',
    [appName, editMode],
  )
  return (
    <Container
      style={{
        height: 'auto',
        justifyContent: 'flex-start',
        flexFlow: 'row wrap',
        borderTop: '1px solid #cdcdcd',
        borderBottom: '1px solid #cdcdcd',
        boxSizing: 'border-box',
        background: 'white',
        zIndex: 3,
      }}>
      <ModeToggle mode={mode} setMode={setMode} />
      {mode === 'Edit' ? <EditorTextToolbar /> : null}
      {showCoverageSelect ? <EditorCoverageSelect /> : null}
      {showPracticeSelect ? <PreviewPracticeSelect /> : null}
    </Container>
  )
}

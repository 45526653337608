import { CollectionFilter } from '../../types/data'
import { UserGroup } from '../../types/shared/db'
import { AnyObject, WithId } from '../../types/utils/common'
import { CollectionId } from './app'

export type BaseCollectionDataTypes = Record<string, AnyObject>

export type BaseCollectionDerivedIds<DataTypes> = {
  [key in keyof DataTypes]: string
}

export type CollectionItem<T extends AnyObject = AnyObject> = T &
  WithId<{
    name?: string
    isInactive?: boolean
    rank?: number
    redFlaggedReason?: string
  }>

export interface BaseCollection<Data extends AnyObject = AnyObject> {
  _type: 'firestoreCollection' | 'derivedCollection' | 'combinedCollection'
  id: CollectionId
  name: string
  access: Array<UserGroup>
  filters?: Array<CollectionFilter<Data>>
}

export type FirestoreCollection<Data extends AnyObject = AnyObject> = BaseCollection<Data> & {
  _type: 'firestoreCollection'
  refPath: string
  noRanks?: boolean
  defaultSort?: keyof CollectionItem<Data>
  shouldNotFetchAll?: true
}

export type DerivedCollection<Data extends AnyObject = AnyObject> = BaseCollection<Data> & {
  _type: 'derivedCollection'
  baseCollection: Collection<Data>
  access: Array<UserGroup>
  transform: (data: Array<CollectionItem<Data>>) => Array<CollectionItem<Data>>
}

export type CombinedCollectionEntry<Data extends AnyObject = AnyObject> = Omit<
  FirestoreCollection,
  'id'
> & { propNames: Array<keyof Data> }

export type CombinedCollection<Data extends AnyObject = AnyObject> = BaseCollection<Data> & {
  _type: 'combinedCollection'
  index: CombinedCollectionEntry<Data>
  access: Array<UserGroup>
  otherCollections: Record<string, CombinedCollectionEntry<Partial<Data>>>
}

export type Collection<DataType extends AnyObject = AnyObject> =
  | FirestoreCollection<DataType>
  | DerivedCollection<DataType>
  | CombinedCollection<DataType>
export type Collections<DataTypes extends BaseCollectionDataTypes> = {
  [key in keyof DataTypes]: Collection<DataTypes[key]>
}

export interface BasePresetFilter<Data extends AnyObject = AnyObject> {
  label: string
  filter: CollectionFilter<Data>
}

export interface DropdownPresetFilter {
  label: string
  filters: BasePresetFilter[]
  searchable?: boolean
  filter?: never
}

export type PresetFilter = BasePresetFilter | DropdownPresetFilter
export const isDropdownPresetFilter = (filter: PresetFilter): filter is DropdownPresetFilter =>
  (filter as DropdownPresetFilter).filter === undefined

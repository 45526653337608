import { CloseIcon, EmailIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  adminRoles,
  colors,
  getDateTimeString,
  getInvoiceSent,
  objectToArray,
  PRACTICE_ACCESS,
  PracticeAccess,
  PracticeInvoice,
  WithId,
} from '@hb/shared'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { sendInvoiceNotificationEmail } from '../../../backend'
import { PopUpMessageContext } from '../../../contexts'
import { useDocument } from '../../../hooks/backend/useDocument'
import { ProfilePicturePopover } from '../../Admins/ProfilePicturePopover'
import { ActionButton } from '../../Buttons'
import { usePracticeUsers } from '../../Practices/hooks'
import { PracticeUserBadge } from '../../Practices/PracticeAccess/PracticeAccessView'
import { SendInvoiceButton } from './SendInvoice'

const SendInvoiceBody = ({
  invoice,
  onSend,
  onClose,
}: {
  invoice: PracticeInvoice
  onSend: (sendTo: string) => Promise<void>
  onClose: () => void
}) => {
  const [sendView, setSendView] = useState(!invoice.emailNotifications?.length)
  const { data: practiceAccess } = useDocument<PracticeAccess>(PRACTICE_ACCESS, invoice.practiceId)

  const { data: practiceUsersRecord } = usePracticeUsers(invoice.practiceId, adminRoles)
  const [sendTo, setSendTo] = useState(practiceAccess?.sendInvoicesTo ?? null)

  const usersArr = useMemo(() => objectToArray(practiceUsersRecord ?? {}), [practiceUsersRecord])
  return (
    <VStack position="relative" bg="gray.50" px={3} py={2} spacing={1}>
      <Text
        textAlign="center"
        w="100%"
        borderBottom="1px solid #cdcdcd"
        fontSize="xs"
        color="gray.600"
        pb={1}
        fontFamily="Hero-new">
        {invoice.emailNotifications?.length || 'No'} EMAIL NOTIFICATIONS SENT
      </Text>
      {sendView ? (
        <Flex w="100%" flexFlow="column">
          <Text color="gray.700" fontSize="sm" fontFamily="Hero-new">
            Send Notification Email To:
          </Text>
          <Flex gap={1} flexFlow="column" w="100%">
            {usersArr.length ? (
              usersArr.map((user, i) => (
                <PracticeUserBadge
                  index={i}
                  selected={sendTo === user.id}
                  select={() => setSendTo(user.id)}
                  key={user.id}
                  user={user}
                />
              ))
            ) : (
              <Text color="gray.600" fontStyle="italic" p={2}>
                No users added to practice access
              </Text>
            )}
          </Flex>
          <HStack ml="auto" pt={2} spacing={3}>
            <Button size="sm" variant="link" onClick={() => setSendView(false)}>
              Cancel
            </Button>
            <ActionButton size="sm" isDisabled={!sendTo} onClick={() => onSend(sendTo ?? '')}>
              Send
            </ActionButton>
          </HStack>
        </Flex>
      ) : (
        <>
          {invoice.emailNotifications?.map(({ sentBy, sentOn, sentToEmail }, i) => (
            <Flex
              borderTop={i ? '1px solid #cdcdcd' : 'none'}
              align="center"
              gap={3}
              key={i}
              color="gray.600"
              fontSize="sm">
              <ProfilePicturePopover userId={sentBy} />
              <Flex flexFlow="column">
                <Text py={1} lineHeight={1} fontWeight={600} fontSize="xs">
                  {getDateTimeString(sentOn, 'short')}
                </Text>
                <Text lineHeight={1.2}>Sent notification email to {sentToEmail}</Text>
              </Flex>
            </Flex>
          ))}
          <Flex pt={2} justify="center" borderTop="1px solid #cdcdcd" w="100%">
            <ActionButton size="sm" onClick={() => setSendView(true)}>
              <EmailIcon mr={2} />
              <Text>Send Notification</Text>
            </ActionButton>
          </Flex>
        </>
      )}
      <IconButton
        onClick={onClose}
        aria-label="Close"
        size="xs"
        position="absolute"
        right={2}
        top={1}
        variant="ghost"
        icon={<CloseIcon w={2.5} />}
      />
    </VStack>
  )
}

const SendNotificationEmailButton = ({ invoice }: { invoice: WithId<PracticeInvoice> }) => {
  const [sending, setSending] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { processResponse } = useContext(PopUpMessageContext)
  const sendNotificationEmail = useCallback(
    async (sendTo: string) => {
      setSending(true)
      setIsOpen(false)
      try {
        await sendInvoiceNotificationEmail({ invoiceId: invoice.id, sendTo })
        processResponse({ success: 'Notification email sent successfully' })
      } catch (err: any) {
        processResponse({
          error: err?.message || 'Error sending notification email',
        })
      }
      setSending(false)
    },
    [invoice, processResponse],
  )
  return (
    <Popover
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="left-start"
      onOpen={() => setIsOpen(true)}
      isLazy>
      <PopoverTrigger>
        <IconButton
          variant="outline"
          size="sm"
          border={`1px solid ${colors.green.hex}`}
          color={colors.green.hex}
          isLoading={sending}
          aria-label="Send Notification Email"
          icon={
            <HStack spacing={1.5} px={2}>
              <Text>{invoice.emailNotifications?.length ?? 0}</Text>
              <EmailIcon />
            </HStack>
          }
        />
      </PopoverTrigger>
      <PopoverContent borderRadius={4}>
        <SendInvoiceBody
          onClose={() => setIsOpen(false)}
          invoice={invoice}
          onSend={sendNotificationEmail}
        />
        <PopoverArrow bg="gray.50" />
      </PopoverContent>
    </Popover>
  )
}

export const InvoiceActions = ({ invoice }: { invoice: WithId<PracticeInvoice> }) => {
  const invoiceSent = useMemo(() => getInvoiceSent(invoice), [invoice])
  return (
    <HStack>
      {invoiceSent ? null : <SendInvoiceButton invoice={invoice} />}
      {invoice.status === 'sent' ? <SendNotificationEmailButton invoice={invoice} /> : null}
    </HStack>
  )
}

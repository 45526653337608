import {
  getAdminTemplatePath,
  getPracticeConsentFormPath,
  ShortcutArgs,
  Template,
  TemplateKey,
  UpdateCallback,
  WithId,
} from '@hb/shared'
import { doc, DocumentReference } from 'firebase/firestore'
import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { db } from '../../../backend/db'
import { useApp } from '../../../contexts'
import { useSelectedPractice } from '../../../hooks'
import { TemplateDataProvider } from './data'
import { EditModeProvider } from './editMode'

interface TemplateEditorContextValue {
  type: TemplateKey
  isLoading: boolean
  onSubmit: (template: Omit<Template, 'rank'> & { id?: string }) => Promise<UpdateCallback>
  onUpdateField: (field: string, value: any) => Promise<UpdateCallback>
  templateRef: DocumentReference<Template> | null
  template: WithId<Template> | null
}

const TemplateEditorContext = createContext<TemplateEditorContextValue>({
  type: 'assessments',
  templateRef: null,
  onUpdateField: () => Promise.resolve({}),
  onSubmit: () => Promise.resolve({}),
  isLoading: false,
  template: null,
})

export const TemplateEditorProvider = ({
  children,
  onSubmit,
  type,
  template,
  isLoading,
  onUpdateField,
  shortcutArgs,
}: PropsWithChildren<{
  shortcutArgs: ShortcutArgs
  type: TemplateKey
  onSubmit: (updated: Omit<Template, 'rank'>) => Promise<UpdateCallback>
  onUpdateField: (field: string, value: any) => Promise<UpdateCallback>
  template: WithId<Template> | null
  isLoading?: boolean
}>) => {
  const { id: selectedPracticeId } = useSelectedPractice()
  const { appName } = useApp()
  const templateRef = useMemo(() => {
    if (!template) return null
    if (appName === 'providers-app') {
      if (type !== 'consentForm') return null
      return selectedPracticeId
        ? (doc(
            db,
            getPracticeConsentFormPath(selectedPracticeId, template.id),
          ) as DocumentReference<Template>)
        : null
    }
    return doc(db, getAdminTemplatePath(type, template.id)) as DocumentReference<Template>
  }, [selectedPracticeId, appName, template, type])
  const contextValue = useMemo<TemplateEditorContextValue>(
    () => ({
      type,
      onSubmit,
      template,
      templateRef,
      onUpdateField,
      isLoading: isLoading ?? false,
    }),
    [type, onSubmit, template, isLoading, onUpdateField, templateRef],
  )

  return (
    <EditModeProvider editMode="template">
      <TemplateDataProvider data={shortcutArgs} type={type}>
        <TemplateEditorContext.Provider value={contextValue}>
          {children}
        </TemplateEditorContext.Provider>
      </TemplateDataProvider>
    </EditModeProvider>
  )
}

export const useTemplateEditor = () => useContext(TemplateEditorContext)

import { FieldHints } from '@hb/shared'
import { useContext, useMemo } from 'react'
import { FormLabelsContext } from '../../../../contexts/FormLabelsContext'

export default function usePopulatedHints(hints?: FieldHints) {
  const value = useContext(FormLabelsContext)
  return useMemo(
    () => hints && (typeof hints === 'function' ? hints(value) : hints),
    [hints, value],
  )
}

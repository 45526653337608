import merge from 'lodash.merge'
import { AssessmentFormAlias, AssessmentFormKey, PopulatedUser } from '../types'
import { PopulatedAssessment } from '../types/app/populated'
import { FieldMap } from '../types/forms/fields'
import { AssessmentForm } from '../types/forms/form'
import { getPregnancyFormData } from '../utils'
import {
  defaultStageValidate,
  getFieldFromPath,
  getNestedFields,
  makeAllFieldsAdmin,
  makeFieldsOptional,
} from '../utils/fields'
import { callIn } from './callIn'
import { introduction } from './introduction'
import { signOnFields } from './signOn'

export const assessmentFields: Record<AssessmentFormKey, AssessmentForm | AssessmentFormAlias> = {
  introduction,
  // 'insurance-info-summary': summarizedInsuranceInfo,
  'call-in': callIn,
  'plan-options': 'planOptions',
  'schedule-call': 'scheduleCall',
  // insuranceDetails
}

export const getAssessmentFields = (
  admin: boolean,
  assessment: Omit<PopulatedAssessment, 'availableForms' | 'stages' | 'plans'> | null,
): Record<string, string | AssessmentForm> => {
  let currKey: AssessmentFormKey | null = 'introduction'
  let res: Record<string, AssessmentForm | string> = {}
  while (currKey) {
    const curr: AssessmentForm | string = assessmentFields[currKey]
    if (curr) {
      res = { ...res, [currKey]: curr }
      if (curr && typeof curr !== 'string' && curr.getNextFormId) {
        currKey = curr.getNextFormId(admin, assessment)
      } else {
        currKey = null
      }
    } else currKey = null
  }
  return res
}
export const nestedAssessmentFields = getNestedFields({
  ...assessmentFields,
})

export const getAssessmentFieldFromPath = (path: string) =>
  getFieldFromPath(
    {
      ...nestedAssessmentFields,
      children: {
        ...nestedAssessmentFields.children,
        signOn: signOnFields,
      },
    },
    path,
  )

export const getNestedUserFields = (
  admin: boolean,
  user: PopulatedUser | null,
  assessment: Omit<PopulatedAssessment, 'availableForms' | 'stages' | 'midwifeId' | 'plans'> | null,
): FieldMap => {
  const nestedFields = getNestedFields(getAssessmentFields(admin, assessment))

  return makeFieldsOptional(
    nestedFields,
    admin
      ? [
          'insurance-info.primaryCoverage.insuranceCard.front',
          'insurance-info.primaryCoverage.insuranceCard.back',
          'insurance-info.medicaidCoverage.insuranceCard.front',
          'insurance-info.medicaidCoverage.insuranceCard.back',
        ]
      : [],
  )
}

export const validateAssessmentAnswers = (admin: boolean, assessment: PopulatedAssessment) => {
  const { formData, formCorrections } = getPregnancyFormData(assessment)
  const validated = {}
  merge(validated, formData)
  if (admin) merge(validated, formCorrections)
  const nestedFields = getNestedFields(getAssessmentFields(admin, assessment))
  return defaultStageValidate(admin ? makeAllFieldsAdmin(nestedFields) : nestedFields)(validated)
}

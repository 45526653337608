import { get as nestedGet } from 'nested-property'
import { FieldTypes } from '../constants'
import {
  callInField,
  getBasicInfoField,
  policyOwnerFieldMap,
  primaryCoverageCondition,
} from '../fields'
import {
  AdditionalCoverageId,
  Assessment,
  AssessmentSnippet,
  BaseInsuranceCoverage,
  ConfirmCoverageStatus,
  CoverageNextActions,
  CoverageStageStatus,
  CoverageWithMetadata,
  DropdownField,
  FieldMapValue,
  InsuranceCoverage,
  InsuranceCoverageHistory,
  InsuranceCoverageRequest,
  InsuranceCoverageType,
  InsuranceProvider,
  isInsuranceCoverageRequest,
  isPopulatedPregnancyPlan,
  NextAction,
  PatientCoverageId,
  PopulatedAssessment,
  PopulatedInsurancePlans,
  PopulatedPregnancyPlan,
  PopulatedPregnancyPlans,
  User,
  UserInsurancePlans,
  WithPatientCoverageId,
} from '../types'
import { getCurrentlyOnMedicaidPlan, mergeAssessmentData } from './assessments'
import { getUniqueRandomId, parseNextActionDate } from './data'
import { defaultStageValidate } from './fields'

export const getAdditionalPlansRequested = (
  user: User | null | undefined,
  // assessment: Assessment | PopulatedAssessment | undefined,
) => {
  const { insurancePlans } = user ?? {}
  const { additional } = insurancePlans ?? {}
  return Object.values(additional ?? {}).some(p => {
    if (isInsuranceCoverageRequest(p)) return true
    if (p.isMedicaid) return false
    const planCallInErrors = defaultStageValidate(callInField, true)(p)
    return !!planCallInErrors && !!Object.keys(p.callInRequests ?? {}).length
  })
}

const getRequestStatus = ({ withCallInForm }: InsuranceCoverageRequest) => {
  const res: CoverageStageStatus = {
    incomplete: ['basic-info'],
    required: ['basic-info'],
  }
  if (withCallInForm) {
    res.incomplete.push('call-in')
    res.required.push('call-in')
  }
  res.incomplete.push('policy-owner')
  res.required.push('policy-owner')
  return res
}
export const getCoverageStatus = (
  type: InsuranceCoverageType,
  coverage: BaseInsuranceCoverage | undefined | null,
  optional?: boolean,
  request?: InsuranceCoverageRequest,
): CoverageStageStatus => {
  if (coverage && isInsuranceCoverageRequest(coverage)) return getRequestStatus(coverage)
  const isMedicaid = !!coverage?.isMedicaid
  const basicInfoField = getBasicInfoField(isMedicaid)
  const basicInfoComplete = !defaultStageValidate(basicInfoField, true)(coverage ?? undefined)

  const hasCallInRequests =
    !!Object.keys(coverage?.callInRequests ?? {}).length || request?.withCallInForm
  const callInRequired = !isMedicaid && ((!optional && type === 'primary') || hasCallInRequests)

  const callInComplete =
    !callInRequired || !defaultStageValidate(callInField, true)(coverage ?? undefined)

  // const policyOwnerRequired = type === 'primaryCoverage' || type === 'medicaidCoverage' || callInRequired

  const policyOwnerComplete = !defaultStageValidate(
    policyOwnerFieldMap,
    true,
  )(coverage ?? undefined)

  const res: CoverageStageStatus = { incomplete: [], required: ['basic-info'] }
  if (!basicInfoComplete) res.incomplete.push('basic-info')
  if (callInRequired) {
    res.required.push('call-in')
    if (!callInComplete) res.incomplete.push('call-in')
  }

  // if (policyOwnerRequired) {
  res.required.push('policy-owner')
  if (!policyOwnerComplete) res.incomplete.push('policy-owner')
  // }

  return res
}

export const getAdditionalPlanStatus = (
  p: BaseInsuranceCoverage | undefined,
  request?: InsuranceCoverageRequest,
) => getCoverageStatus('additional', p, false, request)

export const getNewAdditionalPlanId = (
  additionalPlans: Record<string, BaseInsuranceCoverage> | undefined,
) => getUniqueRandomId(Object.keys(additionalPlans ?? {}))

export const getAdditionalPlansStatus = (
  additionalPlans: Record<string, CoverageWithMetadata> | undefined,
): Record<AdditionalCoverageId, CoverageStageStatus> => {
  if (!additionalPlans) return {}
  return Object.entries(additionalPlans).reduce<Record<AdditionalCoverageId, CoverageStageStatus>>(
    (acc, [id, plan]) => ({
      ...acc,
      [id as AdditionalCoverageId]: getAdditionalPlanStatus(plan),
    }),
    {},
  )
}

export const getRequestsStatus = (
  additionalPlans: Record<string, InsuranceCoverageRequest> | undefined,
): Record<string, CoverageStageStatus> => {
  if (!additionalPlans) return {}
  return Object.entries(additionalPlans).reduce(
    (acc, [id, plan]) => ({
      ...acc,
      [id]: getAdditionalPlanStatus(undefined, plan),
    }),
    {},
  )
}

// export const separateAdditionalPlansAndRequests = (
//   additionalPlans: Assessment['additionalPlans'],
// ) => {
//   const { plans, requests } = Object.entries(additionalPlans ?? {}).reduce(
//     (acc, [id, plan]) => {
//       if (isInsuranceCoverageRequest(plan)) {
//         acc.requests[id] = plan
//         return acc
//       }
//       acc.plans[`additionalPlans.${id}`] = plan
//       return acc
//     },
//     {
//       plans: {} as Record<InsuranceCoverageId, InsuranceCoverage>,
//       requests: {} as Record<string, InsuranceCoverageRequest>,
//     },
//   )

//   return { plans, requests }
// }

export const getCoverageRequiresCallIn = (
  coverage: WithPatientCoverageId<BaseInsuranceCoverage> | undefined | null,
  optional?: boolean,
  request?: InsuranceCoverageRequest,
) => {
  if (!coverage) return false
  const type = getCoverageType(coverage.id)
  if (coverage?.isMedicaid) return false
  if (request?.withCallInForm) return true
  if (type === 'primary' && !optional) return true
  if (type === 'additional') return !!Object.keys(coverage?.callInRequests ?? {}).length
  return false
}

export const getCoverageType = (id: PatientCoverageId): InsuranceCoverageType => {
  if (id === 'primary') return 'primary'
  if (id === 'secondary') return 'secondary'
  return 'additional'
}

const getPregnancyCoverageLabel = (
  id: PatientCoverageId,
  assessment: PopulatedAssessment | null,
) => {
  const isMedicaidPregnancy = !!assessment?.plans?.primaryIsMedicaid
  if (assessment?.nonMedicaidCoverageId === id)
    return `${isMedicaidPregnancy ? 'Secondary' : 'Primary'} (Non-Medicaid)`
  if (assessment?.medicaidCoverageId === id)
    return `${isMedicaidPregnancy ? 'Primary' : 'Secondary'} (Medicaid)`
  return 'Potential'
}
export const getCoverageLabel = (
  id: PatientCoverageId,
  assessment?: PopulatedAssessment | null,
) => {
  if (assessment !== undefined) return getPregnancyCoverageLabel(id, assessment)
  const type = getCoverageType(id)
  if (type === 'primary') return 'Primary'
  if (type === 'secondary') return 'Secondary'
  return 'Potential'
}

export const getConfirmCoverageStatus = (user: User | null | undefined): ConfirmCoverageStatus => {
  // const primaryCoverage = getPrimaryCoverage(assessment?.data)
  // const hasPrimaryCoverage = !getOnlyOnMedicaidPlan(assessment?.data)
  // const { additionalPlans: additionalPlansAndRequests } = assessment ?? {}

  const { insurancePlans } = user ?? {}
  const { secondary, additional, requests } = insurancePlans ?? {}
  const requiresMainCoverage = primaryCoverageCondition(user?.insuranceInfo)

  return {
    primaryCoverage: getCoverageStatus(
      'primary',
      user?.insurancePlans?.primary,
      requiresMainCoverage,
    ),
    secondaryCoverage:
      secondary?.memberId || secondary?.noMemberId || secondary?.insuranceProviderId
        ? getCoverageStatus('secondary', secondary)
        : null,
    additionalPlans: getAdditionalPlansStatus(additional),
    requests: getRequestsStatus(requests),
  }
}

export const getCoverageComplete = (status: ConfirmCoverageStatus) => {
  const mainComplete = !status.primaryCoverage?.incomplete?.length
  const secondaryComplete = !status.secondaryCoverage?.incomplete.length
  // const additionalComplete = !Object.keys(status.additionalPlans ?? {}).find(
  //   p => !!status.additionalPlans[p as AdditionalCoverageId]?.incomplete.length,
  // )
  return mainComplete && secondaryComplete
}

// export const getPlanPropPath = (adminView: boolean, id: InsuranceCoverageId) => {
//   // const dataPath = adminView ? 'corrections' : 'data'
//   if (id === 'primaryCoverage') return `${dataPath}.insurance-info.primaryCoverage`
//   if (id === 'medicaidCoverage') return `${dataPath}.insurance-info.medicaidCoverage`
//   return id
// }
export const getHasMedicaidCoverage = (mergedData?: FieldMapValue) =>
  mergedData?.['insurance-info']?.option === 'medicaid' ||
  (mergedData?.['insurance-info']?.option === 'two-plans' &&
    mergedData?.['insurance-info']?.onePlanIsMedicaid)

export const getEdd = (mergedData?: FieldMapValue) => mergedData?.delivery?.edd

export const getInsurancePlan = <T extends BaseInsuranceCoverage = BaseInsuranceCoverage>(
  insurancePlans: UserInsurancePlans<T>,
  id: PatientCoverageId,
): T | undefined | null => nestedGet(insurancePlans, id) ?? null

export const getCoveragePath = (id: PatientCoverageId) => {
  if (id === 'primary') return 'insurancePlans.primary'
  if (id === 'secondary') return 'insurancePlans.secondary'
  return `insurancePlans.${id}`
}

export const getCoverageNextAction = (
  nextActions: CoverageNextActions,
  id: PatientCoverageId,
): NextAction | null => {
  if (id === 'primary') return nextActions.primaryCoverage ?? null
  if (id === 'secondary') return nextActions.secondaryCoverage ?? null
  return nextActions.additionalPlans?.[id.split('.')[1] ?? ''] ?? null
}

export const getPatientPlansArray = <T extends BaseInsuranceCoverage>(
  plans: UserInsurancePlans<T>,
): (T & { id: PatientCoverageId })[] => {
  const plansArray: (T & { id: PatientCoverageId })[] = []
  if (plans.primary) plansArray.push({ ...plans.primary, id: 'primary' })
  if (plans.secondary) plansArray.push({ ...plans.secondary, id: 'secondary' })
  plansArray.push(
    ...Object.entries(plans.additional ?? {})
      .filter(([, p]) => !isInsuranceCoverageRequest(p))
      .map(([id, plan]) => ({
        ...plan,
        id: `additional.${id}` as PatientCoverageId,
      })),
  )
  return plansArray
}

export const getPregnancyPlansArray = (plans: PopulatedPregnancyPlans | undefined) => {
  if (!plans) return []
  const plansArray = []
  if (plans.medicaid) plansArray.push(plans.medicaid)
  if (plans.nonMedicaid) plansArray.push(plans.nonMedicaid)
  if (plans.potential) plansArray.push(...Object.values(plans.potential))
  return plansArray
}

export const getPlansInsurerIds = <T extends BaseInsuranceCoverage>(
  plans: UserInsurancePlans<T>,
): string[] => {
  const insurerIds: string[] = []
  const plansArray = getPatientPlansArray(plans)
  plansArray.forEach(p => {
    if (p.insuranceProviderId && !insurerIds.includes(p.insuranceProviderId)) {
      insurerIds.push(p.insuranceProviderId)
    }
  })
  return insurerIds
}

export const getPlanNextActionPath = (id: PatientCoverageId) => {
  if (id === 'primary') return 'nextActions.primaryCoverage'
  if (id === 'secondary') return 'nextActions.secondaryCoverage'
  return `nextActions.additionalPlans.${id.split('.')[1] ?? ''}`
}

export const getBackupPlanNextActionPath = (id: PatientCoverageId) => {
  if (id === 'primary') return 'backupNextActions.primaryCoverage'
  if (id === 'secondary') return 'backupNextActions.secondaryCoverage'
  return `backupNextActions.additionalPlans.${id.split('.')[1] ?? ''}`
}

export const getPlanNextAction = (nextActions: CoverageNextActions, id: PatientCoverageId) => {
  if (id.startsWith('additional.')) {
    const parsedId = id.split('.')[1]
    if (!parsedId) return undefined
    return nextActions?.additionalPlans?.[parsedId]
  }
  if (id === 'primary') return nextActions.primaryCoverage
  if (id === 'secondary') return nextActions.secondaryCoverage
  return undefined
}

interface PregnancyCoverageNextAction {
  coverage: PopulatedPregnancyPlan | null | undefined
  id: PatientCoverageId
  date: number
  nextAction: NextAction | null | undefined
  backupNextAction: NextAction | null | undefined
}
export const getCoverageNextActions = (
  plans: PopulatedPregnancyPlans | null | undefined,
  nextActions: CoverageNextActions | null | undefined,
  backupNextActions?: CoverageNextActions | null,
): Array<PregnancyCoverageNextAction> => {
  const { primaryIsMedicaid, medicaid, nonMedicaid, potential } = plans ?? {}

  const nextActionsMap: Partial<
    Record<
      PatientCoverageId,
      {
        coverage: PopulatedPregnancyPlan | undefined | null
        id: PatientCoverageId
        date: number
        nextAction: NextAction | null | undefined
        backupNextAction: NextAction | null | undefined
      }
    >
  > = {}
  // const nonMedicaidId = nonMedicaid ? nonMedicaid.id : primaryIsMedicaid ? 'secondary' : 'primary'
  // const medicaidId = medicaid ? medicaid.id : primaryIsMedicaid ? 'primary' : 'secondary'
  // const nonMedicaidNextAction = getPlanNextAction(nextActions ?? {}, nonMedicaidId) ?? null
  // const medicaidNextAction = getPlanNextAction(nextActions ?? {}, medicaidId) ?? null
  // if (nonMedicaidNextAction || !primaryIsMedicaid) {
  const primaryCoverageId = primaryIsMedicaid
    ? (medicaid?.id ?? 'primary')
    : (nonMedicaid?.id ?? 'primary')
  const primaryCoverage = primaryIsMedicaid ? medicaid : nonMedicaid
  const secondaryCoverageId = primaryIsMedicaid ? (nonMedicaid?.id ?? null) : (medicaid?.id ?? null)
  nextActionsMap[primaryCoverageId] = {
    id: primaryCoverageId,
    coverage: primaryCoverage,
    date: parseNextActionDate(getPlanNextAction(nextActions ?? {}, primaryCoverageId)?.text),
    nextAction: getPlanNextAction(nextActions ?? {}, primaryCoverageId) ?? null,
    backupNextAction: getPlanNextAction(backupNextActions ?? {}, primaryCoverageId) ?? null,
  }
  const secondaryNextAction = secondaryCoverageId
    ? (getPlanNextAction(nextActions ?? {}, secondaryCoverageId) ?? null)
    : null

  if (secondaryCoverageId) {
    nextActionsMap[secondaryCoverageId] = {
      id: secondaryCoverageId,
      coverage: primaryIsMedicaid ? nonMedicaid : medicaid,
      date: parseNextActionDate(secondaryNextAction?.text),
      nextAction: secondaryNextAction,
      backupNextAction: getPlanNextAction(backupNextActions ?? {}, secondaryCoverageId) ?? null,
    }
  }
  // }
  if (potential) {
    potential.forEach(coverage => {
      if (!coverage?.id) return
      const nextAction = getPlanNextAction(nextActions ?? {}, coverage.id) ?? null
      nextActionsMap[coverage.id] = {
        id: coverage.id,
        coverage,
        date: parseNextActionDate(nextAction?.text),
        nextAction,
        backupNextAction: getPlanNextAction(backupNextActions ?? {}, coverage.id) ?? null,
      }
    })
  }
  return Object.values(nextActionsMap).filter(p => !!p) as PregnancyCoverageNextAction[]
}

export const getCoverageNextActionsCount = (assessment: AssessmentSnippet) => {
  let count = 1
  const { plans } = assessment ?? {}
  const { primaryIsMedicaid, medicaid, nonMedicaid, potential } = plans ?? {}
  const secondaryCoverageId = primaryIsMedicaid ? nonMedicaid?.id : medicaid?.id
  if (secondaryCoverageId) count += 1
  if (potential) {
    count += Object.keys(potential).length
  }
  return count
}

export const getCoverageText = (
  coverage: BaseInsuranceCoverage | null | undefined,
  insurer: InsuranceProvider | undefined | null,
) => {
  const insuranceProviderName: string = insurer?.name || '(No Insurer)'

  const { isMedicaid } = coverage ?? {}
  // const isMedicaid = getCurrentlyOnMedicaidPlan(assessment.mergedData)

  const planName = coverage?.planName === "Other / I don't know" ? 'Other' : coverage?.planName
  if (isMedicaid) {
    if (planName && insurer?.plans?.find(p => p.name === planName)) {
      return `${insuranceProviderName} Medicaid - ${planName}`
    }
    return `${insuranceProviderName} Medicaid`
  }
  if (planName && insurer?.plans?.find(p => p.name === planName)) {
    return `${insuranceProviderName} - ${planName}`
  }
  return insuranceProviderName
}

const getPlanLabel = (id: PatientCoverageId) => {
  if (id === 'primary') return 'Primary'
  if (id === 'secondary') return 'Secondary'
  return 'Potential'
}

export const getPlanText = (
  planId: PatientCoverageId,
  coverage: BaseInsuranceCoverage | null | undefined,
  insurer: InsuranceProvider | undefined | null,
) => {
  const coverageText = getCoverageText(coverage, insurer)
  const planLabel =
    coverage && isPopulatedPregnancyPlan(coverage) ? coverage.pregnancyLabel : getPlanLabel(planId)
  return `${planLabel} - ${coverageText}`
}

export const getPatientPlansField = (
  plans: PopulatedInsurancePlans | undefined,
  optional?: boolean,
): DropdownField => {
  const plansArray = getPatientPlansArray(plans ?? {}).filter(p => !!p.insuranceProviderId)
  return {
    type: FieldTypes.DROPDOWN,
    options: plansArray.map(p => ({
      id: p.id,
      text: p ? getPlanText(p.id, p, p.insuranceProvider) : 'No coverage',
    })),
    optional: !!optional,
    placeholder: 'Select Coverage',
  }
}

export const getPregnancyPlan = (
  plans: PopulatedPregnancyPlans | undefined,
  id: PatientCoverageId,
): InsuranceCoverage | null => {
  if (!plans) return null
  if (plans.nonMedicaid && plans.nonMedicaid.id === id) return plans.nonMedicaid
  if (plans.medicaid && plans.medicaid.id === id) return plans.medicaid
  return plans.potential?.find(p => p.id === id) ?? null
}

export const getPregnancyPlansField = (
  plans: PopulatedPregnancyPlans | undefined,
  optional?: boolean,
): DropdownField => {
  const plansArray = getPregnancyPlansArray(plans).filter(p => !!p.insuranceProviderId)
  return {
    type: FieldTypes.DROPDOWN,
    options: plansArray.map(p => ({
      id: p.id,
      text: p ? getCoverageText(p, p.insuranceProvider) : 'No coverage',
    })),
    optional: !!optional,
    placeholder: 'Select Coverage',
  }
}

export const getHasMultiplePlans = (plans: UserInsurancePlans) => {
  let numPlans = 1
  const { secondary, additional } = plans
  if (secondary) numPlans += 1
  numPlans += Object.keys(additional ?? {}).length
  return numPlans > 1
}

// removes populated properties from coverage (eg insuranceProvider, id)
export const sanitizeInsuranceCoverage = ({
  insuranceProvider: _ins,
  id: _id,
  ...rest
}: InsuranceCoverage): CoverageWithMetadata => ({
  ...rest,
  memberId: rest.noMemberId ? '' : (rest.memberId ?? ''),
})

export const addCoverageHistory = <T extends BaseInsuranceCoverage>(
  coverage: T,
  history: InsuranceCoverageHistory[],
): T => {
  const newHistory = [
    ...(coverage.history && Array.isArray(coverage.history) ? coverage.history : []),
    ...history,
  ]
  return { ...coverage, history: newHistory }
}

export const populatePregnancyPlans = (
  pregnancy: Assessment | undefined | null,
  patientPlans: PopulatedInsurancePlans | undefined | null,
): PopulatedPregnancyPlans => {
  if (!pregnancy)
    return { medicaid: null, nonMedicaid: null, potential: [], primaryIsMedicaid: false }
  const plansArray = getPatientPlansArray(patientPlans ?? {})
  const primaryIsMedicaid = getCurrentlyOnMedicaidPlan(mergeAssessmentData(pregnancy))
  const { nonMedicaidCoverageId, medicaidCoverageId, potentialCoverageIds } = pregnancy
  const baseMedicaidPlan = plansArray.find(p => p.id === medicaidCoverageId) ?? null
  const baseNonMedicaidPlan = plansArray.find(p => p.id === nonMedicaidCoverageId) ?? null
  const medicaidPlan: PopulatedPregnancyPlan | null = baseMedicaidPlan
    ? { ...baseMedicaidPlan, pregnancyLabel: 'medicaid' }
    : null
  const nonMedicaidPlan: PopulatedPregnancyPlan | null = baseNonMedicaidPlan
    ? { ...baseNonMedicaidPlan, pregnancyLabel: 'nonMedicaid' }
    : null
  const potentialPlans =
    potentialCoverageIds?.reduce<Array<PopulatedPregnancyPlan>>((acc, id) => {
      const plan = plansArray.find(p => p.id === id)
      if (plan) acc.push({ ...plan, pregnancyLabel: 'potential' })
      return acc
    }, []) ?? []

  const plans: PopulatedPregnancyPlans = {
    nonMedicaid: nonMedicaidPlan ?? null,
    medicaid: medicaidPlan ?? null,
    potential: potentialPlans,
    primaryIsMedicaid,
  }
  return plans
}

export const pregnancyHasPlan = (
  pregnancy: Assessment | undefined | null,
  id: PatientCoverageId,
) => {
  if (!pregnancy) return false
  if (pregnancy.nonMedicaidCoverageId === id || pregnancy.medicaidCoverageId === id) return true
  return !!pregnancy.potentialCoverageIds?.includes(id)
}

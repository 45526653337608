import {
  ALPHABET,
  CheckboxField,
  DollarAmountField,
  DropdownField,
  DropdownOption,
  DropdownOptionItem,
  Field,
  FieldMap,
  FieldMapValue,
  FieldTypes,
  fnameField,
  ListField,
  lnameField,
  NumberField,
  providerNpiField,
  SubmitNewClaimArgs,
  TextField,
} from '@hb/shared'

export const serviceTypeField: DropdownField = {
  placeholder: 'Service Type',
  type: FieldTypes.DROPDOWN,
  options: [
    { id: 'global', text: 'Global' },
    { id: 'prenatal', text: 'Prenatal' },
    { id: 'delivery', text: 'Delivery' },
    { id: 'postpartum', text: 'Postpartum' },
    { id: 'gyn', text: 'GYN' },
    { id: 'newborn', text: 'Newborn' },
    { id: 'labor', text: 'Labor' },
    { id: 'other', text: 'Other' },
  ],
}
export const serviceTypeOtherField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Please specify',
  condition: v => v?.serviceType === 'other',
}

const patientControlNumberField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Patient Ctrl No.',
}

// const exampleClaim = {
//   Insurances: [
//     {
//       // PAYER ADDRESS INFO NEEDED
//       AssignBenefits: 'Y',
//       PatientRelateCode: '18',
//       PayerAddr1: '1234 TECHNOLOGY PARKWAY 123',
//       PayerCity: 'MECHANICSBURG',
//       PayerID: {
//         Local: '110011',
//         ID: '10011',
//       },
//       PayerName: 'MEDICARE',
//       PayerState: 'PA',
//       PayerZip: '12345',
//       ReleaseInfoCode: 'Y',

//       // SUBSCRIBER ADDRESS INFO NEEDED - PLAN OWNER?
//       SubscriberAddr1: '123 BROOKLAND DRIVEWAY',
//       SubscriberCity: 'CHICKASHA',
//       SubscriberDOB: '1901-01-01',
//       SubscriberFirst: 'FirstName',
//       SubscriberGender: 'M',
//       SubscriberID: {
//         MemberID: '411111111A',
//       },
//       SubscriberLast: 'LastName',
//       SubscriberMiddle: 'C',
//       SubscriberState: 'OK',
//       SubscriberZip: '12345',
//       PayerSeqNo: '1',
//       PayerSeqCode: 'A',
//       ClaimIndCode: 'CI',
//       GroupNumber: '',
//       GroupName: '',
//       PriorAuthNo: '',
//       SubscriberCountry: '',
//       PayerCountry: '',
//       SubscriberAddr2: '',
//     },
//   ],
//   Services: [
//     // SERVICES
//     {
//       RevenueCode: '0300',
//       Charge: '10.00',
//       Description: 'LABORATORY',
//       HCPC: '36415',
//       LineNo: 1,
//       Modifier1: 'L1',
//       ServiceStart: '2016-01-01',
//       Units: '1',
//       RenderingProvID: {
//         NPI: '',
//       },
//       Rate: '',
//       Modifier2: 'GA',
//     },
//   ],
//   TransType: 'INST_CLAIM',
//   DiagnosisVersion: '10',
//   ProcedureVersion: '10',
//   DiagnosisCodes10: {
//     Principal: {
//       Code: 'E11.49',
//     },
//     Other1: {
//       Code: 'I10',
//     },
//     VisitReason1: {
//       Code: 'E11.49',
//     },
//     VisitReason2: {
//       Code: 'I10',
//     },
//     Admitting: {
//       Code: '',
//     },
//   },
//   AdmissionHour: '',
//   AdmissionSource: '2',
//   AdmissionType: '3',
//   AttendingFirst: 'FirstName',
//   AttendingLast: 'LastName',
//   AttendingProvID: {
//     NPI: '11111111111',
//   },
//   BillingAddr1: '1234 MAIN AVE',
//   BillingCity: 'CHICKASHA',
//   BillingName: 'GRADY MEMORIAL HOSPITAL',
//   BillingProvID: {
//     NPI: '1111111111',
//     LocationNo: '',
//     EIN: '111111111',
//   },
//   BillType: '131',
//   ClaimCharge: '258.00',
//   ClaimID: {
//     MedicalRecNo: 'M11111111',
//     ClaimNo: 'V11PRF111C11111',
//   },
//   DestInsNo: 1,
//   OccurrenceCodes: {
//     Code1: {
//       Code: '32',
//       Date: '2016-03-07',
//     },
//   },
//   OperatingFirst: '',
//   PatientAddr1: '123 MAIN',
//   PatientCity: 'CHICKASHA',
//   PatientCtlNo: 'GAB11111111111',
//   PatientDOB: '1911-11-11',
//   PatientFirst: 'FIRSTNAME',
//   PatientGender: 'M',
//   PatientID: {
//     SSN: '111111111',
//   },
//   PatientLast: 'LASTNAME',
//   PatientMiddle: 'C',
//   PatientState: 'OK',
//   AssessmentStage: '01',
//   PatientZip: '11111',
//   PayToAddr1: '1111 MAIN AVE',
//   PayToCity: 'CHICKASHA',
//   PayToName: 'GRADY MEMORIAL HOSPITAL',
//   PayToState: 'OK',
//   PayToZip: '73018',
//   RenderingFirst: '',
//   RespAddr1: '111 MAIN',
//   RespCity: 'CHICKASHA',
//   RespFirst: 'FIRSTNAME',
//   RespLast: 'LASTNAME',
//   RespMiddle: 'C',
//   RespState: 'OK',
//   RespZip: '73018',
//   StatementEnd: '2016-03-07',
//   StatementStart: '2016-03-07',
//   MediaCode: 'E',
//   BillingAddr2: '',
//   BillingState: 'OK',
//   BillingZip: '',
//   BillingCountry: '',
//   PayToAddr2: '73018',
//   PayToCountry: '',
//   PayToProvID: {
//     NPI: '1111111111',
//   },
//   AttendingMiddle: '',
//   AttendingSuffix: '',
//   Notes: [
//     {
//       Note: 'DATES OVERLAP WITH 1 CLAIM(S): 1111111111FM',
//       ReferenceCode: 'HOLD',
//       Time: '1601995726',
//       User: 'Autofix',
//     },
//   ],
//   DrgCode: '',
//   DiagnosisCodes: {
//     Admitting: {
//       Code: '',
//     },
//   },
//   EobIndicator: 'Y',
//   RenderingProvID: {
//     NPI: '',
//   },
//   AcceptAssignment: 'A',
//   SignatureOnFile: 'Y',
//   PatientCountry: '',
//   OperatingProvID: {
//     NPI: '',
//   },
//   MedicareStatus: 'RX9997',
//   PaymentDate: '2016-04-25',
//   ClaimPayment: '37.08',
//   ClaimPaidInd: 'Y',
//   ImportBatchID: '1656201006393137',
//   PatientAddr2: '',
// }

// index is 1 based in this case
const modifierField: Field = {
  type: FieldTypes.TEXT,
  placeholder: 'Modifier code',
  optional: true,
}
const modifiersField: ListField = {
  optional: true,
  name: 'Modifiers',
  itemName: 'Modifier',
  itemFields: modifierField,
}

const unitsField: NumberField = {
  type: FieldTypes.NUMBER,
  withStepper: true,
  min: 1,
  defaultValue: 1,
  placeholder: 'Units',
}
// const cptCodeOptions = Array.from(
//   new Set(
//     cptCodes
//       .map((group) => group.codes.map((c) => ({ id: c.code, text: c.code })))
//       .flat(),
//   ),
// )

export const shouldUseCustomRenderingProviderField: CheckboxField = {
  placeholder: 'Should use custom rendering provider',
  type: FieldTypes.CHECKBOX,
  yesText: 'Assign custom rendering provider',
  noText: 'Assign billing provider as rendering provider',
  inputType: 'switch',
}

export const shouldIncludeSecondaryCoverage: CheckboxField = {
  placeholder: 'Should include secondary coverage',
  type: FieldTypes.CHECKBOX,
  yesText: 'Include secondary coverage',
  noText: 'Do not include secondary coverage',
  inputType: 'switch',
}

export const shouldUseCustomPayerId: CheckboxField = {
  placeholder: 'Use custom payer ID',
  type: FieldTypes.CHECKBOX,
  yesText: 'Using custom payer ID',
  noText: 'Using default payer ID',
  inputType: 'switch',
}

export const shouldUseCustomCharge: CheckboxField = {
  placeholder: 'Use custom service charge',
  type: FieldTypes.CHECKBOX,
  yesText: 'Using custom service charge',
  noText: 'Using default provider service charge',
  inputType: 'switch',
}

export const customChargeField: DollarAmountField = {
  type: FieldTypes.DOLLAR_AMOUNT,
  placeholder: 'Custom service charge',
}

/*
01 - Pharmacy

02 - Telehealth Services Other than in Patient’s Home

03 - School

04 - Homeless Shelter

05 - Indian Health Service Freestanding

06 - Indian Health Service Provider Based

07 - Tribal 638 Freestanding

08 - Tribal 638 Provider Based

09 - Prison/Correctional Facility

10 - Telehealth Provided in Patient’s Home

11 - Office

12 - Home

13 - Assisted Living

14 - Group Home

15 - Mobile Unit

16 - A short term accommodation such as a hotel, camp ground, hostel, cruise ship
or resort where the patient receives care, and which is not identified by any other POS code.

17 - A walk-in health clinic, other than an office, urgent care facility, pharmacy
or independent clinic and not described by any other Place of Service code,
that is located within a retail operation and provides, on an ambulatory basis,
preventive and primary care services

18 - Place Of Employment/Worksite

19 - Off-Campus Outpatient Hospital

20 - Urgent Care

21 - Inpatient

22 - Outpatient

23 - Emergency Room Hospital

24 - Ambulatory Surgical Center

25 - Birthing Center

26 - Military Treatment

31 - Skilled Nursing

32 - Nursing Facility

33 - Custodial Care

34 - Hospice

41 - Ambulance Land

42 - Ambulance Air or Water

49 - Independent Clinic

50 - FQHC

51 - Inpatient Psychiatric

52 - Psychiatric Facility Partial Hospital

53 - Community Mental Health

54 - Intermediate Care

55 - Residential Substance Abuse

56 - Psychiatric Residential Treatment

57 - Non Residential Substance Abuse

58 - Non-residential Opioid Treatment Facility

60 - Mass Immunization Center

61 - Comprehensive Inpatient Rehab

62 - Comprehensive Outpatient Rehab

65 - End State Renal

71 - Public Health

72 - Rural Health

81 - Independent Lab

99 - Other Place of Service
*/

const placeServiceOptions: DropdownOption[] = [
  { id: '01', text: '01 - Pharmacy' },
  { id: '02', text: '02 - Telehealth Services Other than in Patient’s Home' },
  { id: '03', text: '03 - School' },
  { id: '04', text: '04 - Homeless Shelter' },
  { id: '05', text: '05 - Indian Health Service Freestanding' },
  { id: '06', text: '06 - Indian Health Service Provider Based' },
  { id: '07', text: '07 - Tribal 638 Freestanding' },
  { id: '08', text: '08 - Tribal 638 Provider Based' },
  { id: '09', text: '09 - Prison/Correctional Facility' },
  { id: '10', text: '10 - Telehealth Provided in Patient’s Home' },
  { id: '11', text: '11 - Office' },
  { id: '12', text: '12 - Home' },
  { id: '13', text: '13 - Assisted Living' },
  { id: '14', text: '14 - Group Home' },
  { id: '15', text: '15 - Mobile Unit' },
  {
    id: '16',
    text: '16 - A short term accommodation such as a hotel, camp ground, hostel, cruise ship or resort where the patient receives care, and which is not identified by any other POS code.',
  },
  {
    id: '17',
    text: '17 - A walk-in health clinic, other than an office, urgent care facility, pharmacy or independent clinic and not described by any other Place of Service code, that is located within a retail operation and provides, on an ambulatory basis, preventive and primary care services',
  },
  { id: '18', text: '18 - Place Of Employment/Worksite' },
  { id: '19', text: '19 - Off-Campus Outpatient Hospital' },
  { id: '20', text: '20 - Urgent Care' },
  { id: '21', text: '21 - Inpatient' },
  { id: '22', text: '22 - Outpatient' },
  { id: '23', text: '23 - Emergency Room Hospital' },
  { id: '24', text: '24 - Ambulatory Surgical Center' },
  { id: '25', text: '25 - Birthing Center' },
  { id: '26', text: '26 - Military Treatment' },
  { id: '31', text: '31 - Skilled Nursing' },
  { id: '32', text: '32 - Nursing Facility' },
  { id: '33', text: '33 - Custodial Care' },
  { id: '34', text: '34 - Hospice' },
  { id: '41', text: '41 - Ambulance Land' },
  { id: '42', text: '42 - Ambulance Air or Water' },
  { id: '49', text: '49 - Independent Clinic' },
  { id: '50', text: '50 - FQHC' },
  { id: '51', text: '51 - Inpatient Psychiatric' },
  { id: '52', text: '52 - Psychiatric Facility Partial Hospital' },
  { id: '53', text: '53 - Community Mental Health' },
  { id: '54', text: '54 - Intermediate Care' },
  { id: '55', text: '55 - Residential Substance Abuse' },
  { id: '56', text: '56 - Psychiatric Residential Treatment' },
  { id: '57', text: '57 - Non Residential Substance Abuse' },
  { id: '58', text: '58 - Non-residential Opioid Treatment Facility' },
  { id: '60', text: '60 - Mass Immunization Center' },
  { id: '61', text: '61 - Comprehensive Inpatient Rehab' },
  { id: '62', text: '62 - Comprehensive Outpatient Rehab' },
  { id: '65', text: '65 - End State Renal' },
  { id: '71', text: '71 - Public Health' },
  { id: '72', text: '72 - Rural Health' },
  { id: '81', text: '81 - Independent Lab' },
  { id: '99', text: '99 - Other Place of Service' },
]

const placeServiceField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Place of service code',
  options: placeServiceOptions,
  searchable: true,
}

const isEmergencyField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  placeholder: 'Is emergency',
}

export const serviceField: FieldMap = {
  initExpanded: true,
  name: '',
  children: {
    FromDate: {
      type: FieldTypes.DATE,
      placeholder: 'From Date',
      isoFormat: true,
    },
    ThroughDate: {
      type: FieldTypes.DATE,
      placeholder: 'To Date',
      isoFormat: true,
    },
    PlaceService: placeServiceField,
    Emergency: isEmergencyField,
    // HCPC: {
    //   type: FieldTypes.DROPDOWN,
    //   placeholder: 'CPT Code',
    //   options: cptCodeOptions,
    // },
    Modifiers: modifiersField,
    // DiagnosisPointer: {
    //   type: FieldTypes.TEXT,
    //   placeholder: 'Diagnosis Pointer',
    // },
    Units: unitsField,
  },
}

const diagnosisPointerValidationField: ListField = {
  name: 'Diagnosis Pointers',
  itemName: 'Diagnosis Pointer',
  itemFields: {
    type: FieldTypes.TEXT,
    placeholder: 'Diagnosis Pointer',
  },
}

const servicesField: ListField = {
  name: 'Services',
  itemName: 'Service',
  itemFields: serviceField,
}
export const serviceValidationField: FieldMap = {
  ...serviceField,
  children: {
    ...serviceField.children,
    DiagnosisPointer: diagnosisPointerValidationField,
  },
}

export const servicesValidationField: ListField = {
  ...servicesField,
  itemFields: serviceValidationField,
}

export const diagnosisCodeOptions: DropdownOptionItem[] = [
  { id: 'z34.80', text: 'z34.80' },
  { id: 'z33.1', text: 'z33.1' },
  { id: 'o80', text: 'o80' },
  { id: 'z39.2', text: 'z39.2' },
  { id: 'z39.1', text: 'z39.1' },
  { id: 'other', text: 'Other' },
]

export const diagnosisCodeSelectField: Field = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Diagnosis code',
  options: diagnosisCodeOptions,
}

// index is 1 based in this case
export const customDiagnosisCodeField: Field = {
  type: FieldTypes.TEXT,
  placeholder: 'Diagnosis code (custom)',
  condition: v => v?.diagnosisCodeSelect === 'other',
}

export const diagnosisCodeField: FieldMap = {
  name: 'Diagnosis code',
  initExpanded: true,
  children: {
    diagnosisCodeSelect: diagnosisCodeSelectField,
    customDiagnosisCode: customDiagnosisCodeField,
  },
}

export const diagnosisCodesField: ListField = {
  name: 'Diagnosis codes',
  itemFields: diagnosisCodeField,
  displayedField: 'alpha',
  itemName: 'Diagnosis code',
}

export const hospitalizedField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  placeholder: 'Hospital birth',
}

export const abilityClaimField: FieldMap = {
  name: 'Claim details',
  children: {
    serviceType: serviceTypeField,
    serviceTypeOther: serviceTypeOtherField,
    PatientCtlNo: { type: FieldTypes.TEXT, placeholder: 'Patient Ctl #' },
    // DiagnosisCodes: diagnosisCodesField,
    // Services: servicesField,
    hospitalized: hospitalizedField,
  },
}

export const shouldUseCustomRelateCodeField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  yesText: 'Use custom relate code',
  noText: 'Use default relate code',
  inputType: 'switch',
  placeholder: 'Use custom relate code',
}

export const customRelateCodePrimary: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Primary coverage: relate code',
  condition: v => !!v?.shouldUseCustomRelateCodePrimary,
}

export const customPayerIdPrimary: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Custom payer ID',
  condition: v => !!v?.shouldUseCustomPayerIdPrimary,
}

export const customPayerIdSecondary: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Custom payer ID',
  condition: v => !!v?.shouldUseCustomPayerIdSecondary,
}

export const customRelateCodeSecondary: Field = {
  type: FieldTypes.TEXT,
  placeholder: 'Secondary coverage: relate code',
  condition: v => !!v?.shouldUseCustomRelateCodeSecondary,
}

export const testSubmitClaimArgs: SubmitNewClaimArgs = {
  PatientCtlNo: 'TEST',
  assessment: '',
  DiagnosisCodes: ['z34.80'],
  mainCoverageId: 'primary',
  Services: [
    {
      // customCharge: 50.0,
      FromDate: new Date().toISOString(),
      ThroughDate: new Date(Date.now() + 1000 * 60 * 60 * 25).toISOString(),
      HCPC: '59426',
      PlaceService: '21',
      Units: 1,
      DiagnosisPointer: ['1'],
    },
  ],
}

const getSubmittedServiceField = (formData: FieldMapValue): FieldMap => ({
  name: 'Service',
  children: {
    HCPC: {
      type: FieldTypes.TEXT,
      placeholder: 'HCPC',
    },
    FromDate: {
      type: FieldTypes.DATE,
      placeholder: 'From Date',
    },
    ThroughDate: {
      type: FieldTypes.DATE,
      placeholder: 'To Date',
    },
    PlaceService: placeServiceField,
    Emergency: isEmergencyField,
    Modifiers: modifiersField,
    Units: unitsField,
    // DiagnosisPointer: diagnosisPointerValidationField,
    DiagnosisPointer: {
      itemFields: {
        type: FieldTypes.DROPDOWN,
        placeholder: 'Diagnosis POinter',
        options: formData.DiagnosisCodes.map((val: FieldMapValue | undefined, index: number) => {
          const code =
            val?.diagnosisCodeSelect === 'other'
              ? val.customDiagnosisCode
              : val?.diagnosisCodeSelect
          return {
            id: `${index + 1}`,
            text: `${ALPHABET[index]} - ${code}`,
          }
        }),
      },
      itemName: 'Diagnosis Code',
      name: 'DiagnosisPointer',
    } as ListField,
  },
})

export const getSubmittedAbilitySubmissionField = (formData: FieldMapValue): FieldMap => ({
  name: 'Ability Submission',
  children: {
    serviceType: serviceTypeField,
    serviceTypeOther: serviceTypeOtherField,
    PatientCtlNo: patientControlNumberField,
    DiagnosisCodes: diagnosisCodesField,
    hospitalized: {
      type: FieldTypes.CHECKBOX,
      placeholder: 'Hospitalized',
    },
    Services: {
      itemName: 'Service',
      name: 'Services',
      itemFields: getSubmittedServiceField(formData),
    },
    primaryCoverageAuthRef: {
      type: FieldTypes.TEXT,
      placeholder: 'Primary coverage: Auth Ref #',
    },
    // shouldUseCustomPayerIdPrimary: {
    //   type: FieldTypes.CHECKBOX,
    //   placeholder: 'Use custom payer ID',
    // },
    customPayerIdPrimary: {
      type: FieldTypes.TEXT,
      placeholder: 'Custom payer ID',
      condition: (v: any) => v?.shouldUseCustomPayerIdPrimary,
    },
    // shouldUseCustomRelateCodePrimary: {
    //   type: FieldTypes.CHECKBOX,
    //   placeholder: 'Use custom relate code',
    // },
    customRelateCodePrimary: {
      type: FieldTypes.TEXT,
      placeholder: 'Custom relate code',
      condition: (v: any) => v?.shouldUseCustomRelateCodePrimary,
    },
    // shouldUseCustomRelateCodeSecondary: {
    //   type: FieldTypes.CHECKBOX,
    //   placeholder: 'Use custom relate code',
    // },
    otherCoverageAuthRef: {
      type: FieldTypes.TEXT,
      placeholder: 'Secondary coverage: Auth Ref #',
    },
    customRelateCodeSecondary: {
      type: FieldTypes.TEXT,
      placeholder: 'Custom relate code',
      condition: (v: any) => v?.shouldUseCustomRelateCodeSecondary,
    },
    // shouldUseCustomPayerIdSecondary: {
    //   type: FieldTypes.CHECKBOX,
    //   placeholder: 'Use custom payer ID',
    // },
    customPayerIdSecondary: {
      type: FieldTypes.TEXT,
      placeholder: 'Custom payer ID',
      condition: v => v?.shouldUseCustomPayerIdSecondary,
    },
    // shouldUseCustomRenderingProvider: {
    //   type: FieldTypes.CHECKBOX,
    //   placeholder: 'Use custom rendering provider',
    // },
    renderingProvider: {
      name: 'Rendering Provider',
      children: {
        fname: fnameField,
        lname: lnameField,
        npi: providerNpiField,
      },
    },
    // shouldIncludeSecondaryCoverage: {
    //   type: FieldTypes.CHECKBOX,
    //   placeholder: 'Include secondary coverage',
    // },
    affiliatedHospital: {
      type: FieldTypes.TEXT,
      placeholder: 'Affiliated hospital',
    },
  },
})

import { messageThreadCollections } from '../constants'
import { User, UserGroup, UserRoleItem, WithId } from '../types'
import { MessageThread, ThreadSubscriberHistoryItem, ThreadType } from '../types/messaging'
import { toSearchString } from './data'

export const getThreadMessageGroupCollectionPath = (threadType: ThreadType, threadId: string) => {
  const collectionId = messageThreadCollections[threadType]
  return `${collectionId}/${threadId}/messages`
}

export const getThreadPath = (threadType: ThreadType, threadId: string) => {
  const collectionId = messageThreadCollections[threadType]
  return `${collectionId}/${threadId}`
}

export const getThreadMessageGroupPath = (
  threadType: ThreadType,
  threadId: string,
  dateString: string,
) => {
  const collectionId = messageThreadCollections[threadType]
  return `${collectionId}/${threadId}/messages/${dateString}`
}

export const getThreadMemberSearchString = (m: WithId<UserRoleItem>) =>
  `${toSearchString(m.fname ?? '')}|${toSearchString(m.lname ?? '')}|${m.nickname ? `${toSearchString(m.nickname)}|` : ''}${toSearchString(m.email ?? '')}`
export const getThreadSearchString = (title: string, members: Array<WithId<UserRoleItem>>) =>
  `${toSearchString(title)}|${members.map(getThreadMemberSearchString).join('|')}`

export const getNewMessageThread = (
  type: ThreadType,
  accessLevel: UserGroup,
  title: string,
  user: User,
  uid: string,
  members: Array<WithId<UserRoleItem>>,
): MessageThread => {
  const subscriberHistoryItem: ThreadSubscriberHistoryItem = {
    addedUsers: members.map(({ id, fname = '', lname = '' }) => ({
      fname: fname ?? '',
      lname: lname ?? '',
      id,
    })),
    removedUsers: [],
    by: uid,
    byFname: user.fname ?? '',
    byLname: user.lname ?? '',
    on: new Date().toISOString(),
  }
  return {
    title,
    subscribers: members.map(m => m.id),
    accessLevel,
    mostRecentMessages: [],
    mostRecentMessageBy: '',
    searchString: getThreadSearchString(title, members),
    subscriberHistory: [subscriberHistoryItem],
    mostRecentMessageOn: 0,
    readBy: [],
    unreadBy: [],
    type,
  }
}

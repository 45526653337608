import { CopyIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { isGroupMessageThread, MessageThread, ThreadType } from '@hb/shared'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { addUsersToThread, removeUsersFromThread } from '../../../backend'
import { PopUpMessageContext, useApp } from '../../../contexts'
import { useMe } from '../../../hooks/backend/useMe'
import { getBadgeColor } from '../../Users/UserBadge'
import { useThreadsList } from '../contexts'
import { DeleteThreadButton } from './DeleteThreadButton'
import { ThreadMembersPreview } from './ThreadMembersPreview'
import { ThreadMemberView } from './ThreadMemberView'

export const ThreadMembersPopover = <T extends ThreadType = ThreadType>({
  thread,
  threadId,
  threadType,
  onEditClick: onEditClick,
  isAdding,
}: {
  thread: MessageThread<T> | null
  threadId?: string
  threadType: T
  onEditClick: (() => void) | (() => Promise<void>)
  isAdding: boolean
}) => {
  const [addLoading, setAddLoading] = useState(false)
  const { closeThread } = useThreadsList()
  const { subscribers = [] } = thread ?? {}
  const { appName } = useApp()
  const me = useMe()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const canEdit = useMemo(() => threadType !== ThreadType.DIRECT, [threadType])
  const memberTypeName = useMemo(
    () => (threadType === ThreadType.ASSESSMENT ? 'subscriber' : 'member'),
    [threadType],
  )

  const { showSuccess } = useContext(PopUpMessageContext)
  const userIsOwner = useMemo(
    () => thread && isGroupMessageThread(thread) && thread.createdBy === me?.uid,
    [thread, me],
  )
  const membershipButtonText = useMemo(() => {
    if (userIsOwner) return 'EDIT MEMBERS'
    switch (threadType) {
      case ThreadType.ASSESSMENT:
        if (me && subscribers.includes(me.uid)) return 'UNSUBSCRIBE'
        return 'SUBSCRIBE TO THREAD'
      default:
        if (me && subscribers.includes(me.uid)) return 'LEAVE THREAD'
        return 'JOIN THREAD'
    }
  }, [threadType, me, subscribers, userIsOwner])

  const editMembership = useCallback(async () => {
    if (!me || !threadId) return
    setAddLoading(true)
    try {
      const userIsOwner = thread && isGroupMessageThread(thread) && thread.createdBy === me.uid
      if (!userIsOwner) {
        if (subscribers.includes(me.uid)) {
          closeThread()
          await removeUsersFromThread({
            threadId,
            type: threadType,
            userIds: [me.uid],
          })
        } else {
          await addUsersToThread({
            threadId,
            type: threadType,
            userIds: [me.uid],
            title: '',
          })
        }
      } else {
        await onEditClick()
      }
    } catch (e) {
      console.error(e)
    }
    setAddLoading(false)
    onClose()
  }, [me, onEditClick, onClose, subscribers, threadId, threadType, thread, closeThread])

  useEffect(() => {
    if (!isAdding || !canEdit) onClose()
  }, [isAdding, canEdit, onClose])
  return (
    <Popover
      isOpen={!isAdding && isOpen && thread?.type !== ThreadType.DIRECT}
      onClose={onClose}
      onOpen={canEdit ? onOpen : () => {}}
      placement="bottom-end"
      trigger="hover">
      <PopoverTrigger>
        <Box cursor="pointer">
          <ThreadMembersPreview noTooltips={!isAdding} thread={thread} />
        </Box>
      </PopoverTrigger>
      <PopoverContent minW="250px" maxH="500px" overflowY="auto" bg="gray.100" w="auto">
        <PopoverArrow bg="gray.100" />
        <VStack w="100%" align="flex-start" borderRadius={6} px={2} py={2} spacing={2}>
          <HStack w="100%">
            <Text fontFamily="Open Sans" fontSize="sm" color="gray.600" fontWeight={600}>
              {memberTypeName.toUpperCase()}S
            </Text>
            <Badge
              ml="auto"
              bg={
                thread?.type === ThreadType.DIRECT
                  ? '#ababab'
                  : getBadgeColor(appName, thread?.accessLevel)
              }
              color="white"
              textShadow="1px 1px 3px #00000077">
              {threadType === ThreadType.DIRECT || threadType === ThreadType.ASSESSMENT
                ? threadType.toUpperCase()
                : thread?.accessLevel?.toUpperCase()}{' '}
              CHAT
            </Badge>
          </HStack>
          <VStack w="100%" spacing={1} align="flex-start">
            {subscribers.length ? (
              subscribers.map(sub => <ThreadMemberView key={sub} userId={sub} />)
            ) : (
              <Text fontFamily="Open Sans" fontSize="sm" color="gray.500">
                No {memberTypeName}s
              </Text>
            )}
          </VStack>
          {!isAdding ? (
            <Button
              isLoading={addLoading}
              w="100%"
              size="xs"
              _hover={{
                bg: 'green.300',
              }}
              colorScheme="green"
              fontFamily="Open Sans"
              onClick={editMembership}>
              {membershipButtonText}
            </Button>
          ) : null}
          {userIsOwner ? <DeleteThreadButton /> : null}
          <Flex align="center" w="100%">
            <Text flex={1} minW="0" isTruncated fontSize="xs">
              Thread ID: {threadId}
            </Text>
            <IconButton
              variant="ghost"
              aria-label="Copy thread ID"
              size="xs"
              icon={<CopyIcon />}
              onClick={() => {
                navigator.clipboard.writeText(threadId ?? '')
                showSuccess('Thread ID copied to clipboard')
              }}
            />
          </Flex>
        </VStack>
      </PopoverContent>
    </Popover>
  )
}

import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import { INVOICES, INVOICES_DELETED, PracticeInvoice } from '@hb/shared'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useApp } from '../../contexts/AppContext'
import { useDocument } from '../../hooks/backend'
import { LoadingView } from '../LoadingOverlay'
import { PatientProfile } from '../Users/Profile/PatientProfile'
import { useInvoicesView } from './InvoicesViewProvider'
import { InvoiceView, PracticeInvoiceView } from './InvoiceView'

// const ReceivedPaymentsFooter = ({
//   assessment,
// }: {
//   assessment: InvoiceAssessmentPaymentsSnippet
// }) => {
//   const { receivedAmount, balanceTowardsPrm, listedFeePercent } = assessment
//   const adjustedTotal = useMemo(
//     () => (balanceTowardsPrm < 0
//       ? receivedAmount + balanceTowardsPrm
//       : receivedAmount),
//     [balanceTowardsPrm, receivedAmount],
//   )
//   return (
//     <VStack bg='gray.50' borderTop='1px solid #cdcdcd' w='100%'>
//       <HStack spacing={2} px={3} w='100%'>
//         <HStack spacing={0} flex={1}>
//           <Text
//             borderRight='1px solid #888'
//             pr={2}
//             fontSize='sm'
//             color='gray.500'
//             fontWeight={600}
//           >
//             Fee: {listedFeePercent}%
//           </Text>
//           <Text
//             textAlign='center'
//             pl={2}
//             flex={1}
//             fontSize='sm'
//             color='gray.500'
//             fontWeight={600}
//           >
//             Balance towards PRM:{' '}
//             {balanceTowardsPrm !== undefined
//               ? formatDollarValue(balanceTowardsPrm)
//               : 'None'}
//           </Text>
//           <Text
//             fontSize='sm'
//             borderLeft='1px solid #777'
//             pl={2}
//             color='gray.500'
//             fontWeight={600}
//           >
//             Adjusted total:
//           </Text>
//         </HStack>
//         <HStack width='100px' spacing={1}>
//           <Text color='gray.500' fontWeight={600}>
//             {formatDollarValue(adjustedTotal)}
//           </Text>
//         </HStack>
//         <HStack justify='center' position='relative' spacing={1}>
//           <IncrementBadge
//             amount={(adjustedTotal - receivedAmount) * listedFeePercent * 0.01}
//           />
//           <TotalAmountBadge
//             amount={adjustedTotal * listedFeePercent * 0.01}
//             bg={colors.green.hex}
//             color='white'
//             textShadow='1px 1px 2px rgba(0,0,0,0.2)'
//           />
//         </HStack>
//         <Box w='20px' />
//       </HStack>
//     </VStack>
//   )
// }

export const InvoiceViewModal = () => {
  const { selectedInvoiceId, selectInvoice, invoicesTab, basePath } = useInvoicesView()

  const { baseEntityId } = useParams<{
    baseEntityId?: string
  }>()
  const collection = useMemo(
    () => (invoicesTab === 'deleted' ? INVOICES_DELETED : INVOICES),
    [invoicesTab],
  )
  const { data: invoice, loading } = useDocument<PracticeInvoice>(
    collection,
    selectedInvoiceId ?? undefined,
  )
  const { appName } = useApp()

  let body = null
  if (loading) {
    body = (
      <Center w="100%" h="200px">
        <LoadingView text="Loading invoice..." />
      </Center>
    )
  } else if (selectedInvoiceId && invoice) {
    body =
      appName === 'app' ? (
        <InvoiceView
          onClose={() => selectInvoice(null)}
          invoice={{ ...invoice, id: selectedInvoiceId }}
        />
      ) : (
        <PracticeInvoiceView invoice={{ ...invoice, id: selectedInvoiceId }} />
      )
  } else {
    body = (
      <Center w="100%" h="100%">
        Invoice not found
      </Center>
    )
  }

  const profileBasePath = useMemo(() => {
    if (!selectedInvoiceId) return null
    return `${basePath}/${invoicesTab}/${selectedInvoiceId}`
  }, [selectedInvoiceId, invoicesTab, basePath])

  return (
    <>
      <Modal
        isOpen={!!selectedInvoiceId && !baseEntityId}
        onClose={() => selectInvoice(null)}
        size="5xl"
        scrollBehavior="inside"
        blockScrollOnMount={false}
        trapFocus={false}
        // size='4xl'
        portalProps={{ appendToParentPortal: true }}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody borderRadius={4} overflowX="hidden" overflowY="auto" p={0}>
            {body}
          </ModalBody>
          <ModalCloseButton
            color="gray.500"
            borderRadius="full"
            bg="blackAlpha.100"
            top={1}
            right={2}
          />
        </ModalContent>
      </Modal>

      {profileBasePath ? <PatientProfile basePath={profileBasePath} /> : null}
    </>
  )
}

import { ArrowUpIcon, DeleteIcon, MinusIcon, WarningTwoIcon } from '@chakra-ui/icons'
import {
  Box,
  Checkbox,
  Collapse,
  Flex,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import {
  Assessment,
  ASSESSMENTS,
  colors,
  getCoveragePath,
  getCoverageStatus,
  getPlanNextAction,
  getPlanNextActionPath,
  notesField,
  pregnancyHasPlan,
  TextAreaField,
  USERS_ADMIN,
  WithId,
  xor,
} from '@hb/shared'
import { arrayRemove, deleteField, doc, writeBatch } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { db } from '../../../backend/db'
import { makeCoveragePrimary, makePregnancyCoveragePrimary } from '../../../backend/functions'
import { useApp, useProfile } from '../../../contexts'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { ActionConfirm } from '../../Alerts/ActionConfirm'
import { ActionButton, DeleteButton, SolidActionButton } from '../../Buttons'
import { StandaloneInput } from '../../forms/Input'
import { NextActionPreview } from '../../Users/Profile/NextAction'
import { BasicPregnancyPreview } from '../AssessmentPreview'
import { useCoverageView } from './context'

const messageField: TextAreaField = {
  ...notesField,
  optional: false,
  placeholder: 'Message to patient',
}

const ConfirmSetPrimaryAlert = ({
  // assessmentId,
  onBack,
}: {
  onBack: () => void
  // assessmentId: string | null
}) => {
  const { coverage, coverageId, patientRef } = useCoverageView()
  const { appName } = useApp()
  const { showMessage } = useContext(PopUpMessageContext)
  const callInComplete = useMemo(() => {
    const newStatus = getCoverageStatus('primary', coverage)
    return !newStatus?.incomplete.includes('call-in')
  }, [coverage])
  const [sendEmail, setSendEmail] = useState(!callInComplete)
  const [message, setMessage] = useState('')

  const handleSetPrimary = useCallback(async () => {
    try {
      const {
        data: { success },
      } = await makeCoveragePrimary({
        appName,
        coverageId,
        patientId: patientRef.id,
        label: 'primary',
        sendEmail,
        message,
      })
      showMessage({
        type: 'success',
        text: 'Coverage updated',
        subText: success,
      })
    } catch (err: any) {
      showMessage({
        type: 'error',
        text: 'Error setting coverage as primary',
        subText: err.message,
      })
    }
  }, [coverageId, showMessage, sendEmail, message, patientRef, appName])
  return (
    <ActionConfirm
      body={
        <Flex gap={2} pt={2} w="100%" flexFlow="column">
          <Text fontWeight={600} fontSize="lg" color="gray.600">
            Update primary coverage?
          </Text>
          <Text lineHeight={1}>Are you sure you want to set this coverage as primary?</Text>

          {callInComplete ? null : (
            <Flex
              border="1px solid #cdcdcd"
              px={3}
              mt={2}
              bg="gray.50"
              pb={3}
              borderRadius={4}
              flexFlow="column"
              w="100%">
              <Text fontSize="sm" mt={2}>
                This coverage will require a call-in request to be completed. Should we send an
                email to the patient with a link to the call-in request?
              </Text>
              <Flex
                mt={2}
                bg="white"
                p={3}
                align="center"
                borderRadius={4}
                boxShadow="1px 1px 4px #00000044">
                <Checkbox
                  isChecked={sendEmail || false}
                  value=""
                  onChange={e => setSendEmail(e.target.checked)}
                  colorScheme="green">
                  <Text
                    color={sendEmail ? colors.green.hex : 'gray.500'}
                    fontSize="sm"
                    fontFamily="Hero-New"
                    lineHeight={1}>
                    Send call-in request email
                  </Text>
                </Checkbox>
              </Flex>
            </Flex>
          )}
          <Collapse style={{ width: '100%' }} in={!!sendEmail}>
            <StandaloneInput
              value={message}
              theme="detailed"
              onChange={e => setMessage(e.target ? e.target.value : e)}
              field={messageField}
            />
          </Collapse>
        </Flex>
      }
      confirmText="Set as primary"
      isOpen
      onClose={onBack}
      onConfirm={handleSetPrimary}
    />
  )
}

const DeleteFromPatientAlertBody = ({
  linkedPregnancies,
}: {
  linkedPregnancies: Array<WithId<Assessment>>
}) => (
  <Flex flexFlow="column" w="100%" gap={1}>
    {linkedPregnancies.length ? (
      <Text mb={0.5} fontWeight={600} lineHeight={1} color="red.600">
        Coverage cannot be deleted while linked to pregnancies
      </Text>
    ) : null}
    <Text mb={1} lineHeight={1} fontWeight={600} color="gray.600">
      Linked pregnancies
    </Text>
    {linkedPregnancies.length ? (
      linkedPregnancies.map(p => <BasicPregnancyPreview key={p.id} pregnancy={p} />)
    ) : (
      <Text lineHeight={1} color="gray.500">
        No linked pregnancies
      </Text>
    )}
  </Flex>
)

const UnlinkFromPregnancyAlertBody = () => {
  const { assessment, coverageId } = useCoverageView()
  const nextAction = useMemo(
    () => getPlanNextAction(assessment?.nextActions ?? {}, coverageId),
    [assessment, coverageId],
  )

  return (
    <Flex flexFlow="column" w="100%" gap={1}>
      <Text mb={0.5} lineHeight={1}>
        This will keep the coverage on the patient profile but unlink it from the pregnancy
      </Text>
      {nextAction ? (
        <Flex
          border="1px solid"
          borderColor="gray.200"
          borderRadius={4}
          bg="whiteAlpha.500"
          py={2}
          gap={1}
          px={3}
          flexFlow="column">
          <Flex gap={2} w="100%" align="center">
            <WarningTwoIcon w={5} h={5} color="yellow.500" />
            <Text>This coverage has a next action for this pregnancy</Text>
          </Flex>
          <NextActionPreview nextAction={nextAction} />
        </Flex>
      ) : null}
    </Flex>
  )
}

const PatientCoverageSetAs = () => {
  const { coverageId, patientRef } = useCoverageView()
  const { appName } = useApp()
  const { showMessage } = useContext(PopUpMessageContext)
  const [loading, setLoading] = useState(false)
  const isSecondary = useMemo(() => coverageId === 'secondary', [coverageId])
  const handleSetSecondary = useCallback(async () => {
    try {
      setLoading(true)
      const {
        data: { success },
      } = await makeCoveragePrimary({
        // assessmentId,
        appName,
        patientId: patientRef.id,
        coverageId,
        label: isSecondary ? null : 'secondary',
        message: '',
        sendEmail: false,
      })
      showMessage({
        type: 'success',
        text: 'Coverage updated',
        subText: success,
      })
    } catch (err: any) {
      showMessage({
        type: 'error',
        text: `Error ${isSecondary ? 'unsetting' : 'setting'} coverage as secondary`,
        subText: err.message,
      })
    }
    setLoading(false)
  }, [coverageId, showMessage, patientRef, isSecondary, appName])

  const [confirmSettingPrimary, setConfirmSettingPrimary] = useState(false)
  return (
    <>
      <Popover isLazy strategy="fixed" placement="right">
        <PopoverTrigger>
          <ActionButton pl={3} gap={1} bg="white" size="xs">
            <ArrowUpIcon w={4} h={4} />
            <Text>SET AS...</Text>
          </ActionButton>
        </PopoverTrigger>
        <PopoverContent py={2} px={3} w="260px">
          <Text lineHeight={1} fontWeight={600} mb={2} color="gray.600">
            Set coverage as...
          </Text>
          <Flex gap={2} w="100%" flexFlow="column">
            <SolidActionButton size="sm" onClick={() => setConfirmSettingPrimary(true)}>
              Primary coverage
            </SolidActionButton>
            <SolidActionButton
              size="sm"
              bg={isSecondary ? 'red.500' : 'green.500'}
              onClick={handleSetSecondary}
              isLoading={loading}>
              {isSecondary ? 'Unset as secondary' : 'Secondary'}
            </SolidActionButton>
          </Flex>
          <PopoverArrow />
        </PopoverContent>
      </Popover>
      {confirmSettingPrimary ? (
        <ConfirmSetPrimaryAlert onBack={() => setConfirmSettingPrimary(false)} />
      ) : null}
    </>
  )
}

const PregnancyCoverageSetAs = () => {
  const { coverageId, isMedicaid, assessment, assessmentId } = useCoverageView()
  const pregnancyIsMedicaid = assessment?.plans?.primaryIsMedicaid
  const { showMessage } = useContext(PopUpMessageContext)
  const [loading, setLoading] = useState(false)
  const isSecondary = useMemo(() => coverageId === 'secondary', [coverageId])
  const handleSetPregnancyPrimary = useCallback(async () => {
    if (!assessmentId) return
    try {
      setLoading(true)
      const {
        data: { success },
      } = await makePregnancyCoveragePrimary({
        assessmentId,
        coverageId,
      })
      showMessage({
        type: 'success',
        text: 'Coverage updated',
        subText: success,
      })
    } catch (err: any) {
      showMessage({
        type: 'error',
        text: `Error ${isSecondary ? 'unsetting' : 'setting'} coverage as secondary`,
        subText: err.message,
      })
    }
    setLoading(false)
  }, [coverageId, showMessage, isSecondary, assessmentId])

  return (
    <Popover isLazy strategy="fixed" placement="right">
      <PopoverTrigger>
        <ActionButton pl={3} gap={1} bg="white" size="xs">
          <ArrowUpIcon w={4} h={4} />
          <Text>SET AS...</Text>
        </ActionButton>
      </PopoverTrigger>
      <PopoverContent py={2} px={3} w="300px">
        <Text w="100%" textAlign="center" lineHeight={1} fontWeight={600} mb={2} color="gray.600">
          Set coverage as...
        </Text>
        <Flex gap={2} w="100%" flexFlow="column">
          <SolidActionButton
            isLoading={loading}
            size="sm"
            onClick={() => handleSetPregnancyPrimary()}>
            {!xor(!pregnancyIsMedicaid, isMedicaid) ? 'Secondary' : 'Primary'} (
            {isMedicaid ? 'Medicaid' : 'Non-medicaid'}) coverage
          </SolidActionButton>
        </Flex>
        <PopoverArrow />
      </PopoverContent>
    </Popover>
  )
}

const CoverageSetAs = () => {
  const { assessmentId } = useCoverageView()

  if (assessmentId) return <PregnancyCoverageSetAs />
  return <PatientCoverageSetAs />
}

export const CoverageActions = ({ adminView }: { adminView: boolean | undefined }) => {
  const { assessments } = useProfile()
  const { patientRef, coverageId, coverage, assessmentId, assessment } = useCoverageView()

  const shouldDisplay = useMemo(() => {
    if (coverageId === 'primary' && !assessmentId) return false
    if (!assessment?.plans?.primaryIsMedicaid && assessment?.nonMedicaidCoverageId === coverageId)
      return false
    if (!!assessment?.plans?.primaryIsMedicaid && assessment?.medicaidCoverageId === coverageId)
      return false
    return true
  }, [coverageId, assessmentId, assessment])

  const handleDelete = useCallback(async () => {
    if (assessmentId) {
      const batch = writeBatch(db)
      const assessmentRef = doc(db, `${ASSESSMENTS}/${assessmentId}`)
      if (assessment?.nonMedicaidCoverageId === coverageId) {
        batch.update(assessmentRef, 'nonMedicaidCoverageId', null)
      }
      if (assessment?.medicaidCoverageId === coverageId) {
        batch.update(assessmentRef, 'medicaidCoverageId', null)
      }
      if (assessment?.potentialCoverageIds?.includes(coverageId)) {
        batch.update(assessmentRef, 'potentialCoverageIds', arrayRemove(coverageId))
      }
      await batch.commit()
    } else {
      const adminRef = doc(db, `${USERS_ADMIN}/${patientRef.id}`)
      const batch = writeBatch(db)
      batch.update(patientRef, getCoveragePath(coverageId), deleteField())
      const nextActionPath = getPlanNextActionPath(coverageId)
      batch.update(adminRef, nextActionPath, deleteField())
      await batch.commit()
    }
  }, [patientRef, coverageId, assessmentId, assessment])

  const linkedPregnancies = useMemo<Array<WithId<Assessment>>>(
    () =>
      coverage
        ? Object.entries(assessments ?? {})
            .filter(([, a]) => pregnancyHasPlan(a, coverage.id))
            .map(([id, a]) => ({ ...a, id }))
        : [],
    [assessments, coverage],
  )

  if (!shouldDisplay) return null
  return (
    <Flex align="center" w="100%" px={2} py={1}>
      {adminView ? <CoverageSetAs /> : null}
      <Box ml="auto">
        <DeleteButton
          text={assessmentId ? 'Unlink Pregnancy' : 'Delete Coverage'}
          size="sm"
          deleteDisabled={!!linkedPregnancies.length && !assessmentId}
          actionName={assessmentId ? 'Unlink' : 'Delete'}
          icon={assessmentId ? <MinusIcon w={4} h={3} /> : <DeleteIcon />}
          alertBody={
            assessmentId ? (
              <UnlinkFromPregnancyAlertBody />
            ) : (
              <DeleteFromPatientAlertBody linkedPregnancies={linkedPregnancies} />
            )
          }
          itemName="coverage"
          onDelete={() => handleDelete()}
        />
      </Box>
    </Flex>
  )
}

import { Badge, Flex, Text } from '@chakra-ui/react'
import {
  DropdownField,
  DropdownOptionItem,
  getPatientPlansField,
  getPregnancyPlan,
  getPregnancyPlansField,
  InsuranceCoverage,
  InsuranceProvider,
  insurersCollection,
  PatientCoverageId,
  PopulatedInsurancePlans,
  PopulatedPregnancyPlans,
  WithId,
} from '@hb/shared'
import { get as nestedGet } from 'nested-property'
import React, { useMemo } from 'react'
import { useCollection } from '../../collections/hooks/useCollection'

const RenderPlanOption = ({
  option,
  plan,
  allInsuranceProviders,
}: {
  option: DropdownOptionItem
  plan: InsuranceCoverage | null
  allInsuranceProviders: WithId<InsuranceProvider>[]
}) => {
  const provider = allInsuranceProviders.find(p => p.id === plan?.insuranceProviderId)
  let badge: string | null = null
  if (option.id === 'primary') {
    badge = 'Primary'
  } else if (option.id === 'secondary') {
    badge = 'Secondary'
  } else {
    badge = 'Potential'
  }
  return (
    <Flex w="100%" gap={2} pr={2} pl={1} align="center">
      <Flex flexFlow="column" flex={1} minW="0">
        {provider && (
          <Text fontWeight={600} color="gray.600">
            {provider.name}
          </Text>
        )}
        <Text lineHeight={1} color="gray.500" fontSize="sm">
          {plan?.memberId || 'Missing member ID'}
        </Text>
      </Flex>
      {badge ? (
        <Badge ml="auto" colorScheme="green" variant="solid" fontSize="0.7em">
          {badge}
        </Badge>
      ) : null}
    </Flex>
  )
}

export const usePatientPlanField = (
  plans: PopulatedInsurancePlans | undefined,
  modifier?: (f: DropdownField) => DropdownField,
) => {
  const { items: insuranceProviders } = useCollection(insurersCollection)
  return useMemo(() => {
    const baseField: DropdownField = {
      ...getPatientPlansField(plans),
      renderOption: option => (
        <RenderPlanOption
          option={option}
          plan={nestedGet(plans, option.id as PatientCoverageId)}
          allInsuranceProviders={insuranceProviders}
        />
      ),
    }
    return modifier ? modifier(baseField) : baseField
  }, [plans, insuranceProviders, modifier])
}

export const usePregnancyPlanField = (
  plans: PopulatedPregnancyPlans | undefined,
  modifier?: (f: DropdownField) => DropdownField,
) => {
  const { items: insuranceProviders } = useCollection(insurersCollection)
  return useMemo(() => {
    const baseField: DropdownField = {
      ...getPregnancyPlansField(plans),
      renderOption: option => (
        <RenderPlanOption
          option={option}
          plan={getPregnancyPlan(plans, option.id as PatientCoverageId)}
          allInsuranceProviders={insuranceProviders}
        />
      ),
    }
    return modifier ? modifier(baseField) : baseField
  }, [plans, insuranceProviders, modifier])
}

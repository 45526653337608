import { CheckIcon } from '@chakra-ui/icons'
import { Badge, HStack, Text } from '@chakra-ui/react'
import { colors, TemplateEditorDraft, TemplateKey } from '@hb/shared'
import React, { useContext } from 'react'
import { Descendant } from 'slate'
import { ProfileContext } from '../../../../contexts/ProfileContext'
import { SolidActionButton } from '../../../Buttons'
import { ProfileTemplateViewModal } from '../../../Templates/ViewedTemplateModal'

const SavedBadge = () => (
  <Badge
    ml={2}
    py={1}
    px={2}
    bg="gray.50"
    color={colors.green.hex}
    textShadow="none"
    boxShadow="1px 1px 3px #00000077">
    <HStack spacing={1}>
      <CheckIcon />
      <Text fontSize="sm">DRAFT SAVED</Text>
    </HStack>
  </Badge>
)

export const DraftView = ({
  templateKey,
  draft,
  name,
}: {
  templateKey: TemplateKey
  draft?: Descendant[] | TemplateEditorDraft
  name: string
}) => {
  const { setViewedTemplateKey, viewedTemplate } = useContext(ProfileContext)
  const selected = viewedTemplate?.type === templateKey
  return (
    <>
      <SolidActionButton
        w="100%"
        justifyContent="space-between"
        onClick={() => setViewedTemplateKey(templateKey)}>
        <Text lineHeight={1}>{name}</Text>
        {draft ? <SavedBadge /> : null}
      </SolidActionButton>
      {selected ? <ProfileTemplateViewModal /> : null}
    </>
  )
}

import { Box, Center, CircularProgress, Collapse, HStack, Text, VStack } from '@chakra-ui/react'
import { colors, MessageThread } from '@hb/shared'
import React, { forwardRef, MutableRefObject, UIEvent, useEffect, useRef, useState } from 'react'
import { useApp } from '../../../contexts'
import { useIsMainWindow } from '../../../contexts/MainWindowContext'
import { useMe } from '../../../hooks/backend/useMe'
import { UseThreadMessages } from '../hooks'
import { reportThreadRead } from '../utils'
import { ThreadMessageView } from './ThreadMessageView'

interface ThreadMessageListProps {
  manuallyMarkedUnread: boolean
  data: UseThreadMessages
  thread?: MessageThread | null
  assessmentThreadUnread?: boolean
  messagesLoading: boolean
  threadId?: string
}
export const ThreadMessagesList = forwardRef<HTMLDivElement, ThreadMessageListProps>(
  (
    { manuallyMarkedUnread, data, thread, threadId, assessmentThreadUnread, messagesLoading },
    ref,
  ) => {
    const { appName } = useApp()
    const me = useMe()
    const { data: messages, loading, hasMoreData, fetchMoreData } = data

    const [unreadMessageId] = useState(
      () => messages.find(m => m.unreadBy?.includes(me?.uid ?? ''))?.createdOn,
    )
    const lastFetchTime = useRef(0)

    const isMainWindow = useIsMainWindow()
    const scrollHandler = useRef<((e: UIEvent<HTMLElement>) => void) | null>()

    const prevScrollTop = useRef(0)
    const prevScrollHeight = useRef(0)
    useEffect(() => {
      const refAsMutable = ref as MutableRefObject<HTMLDivElement | null>
      if (scrollHandler.current) {
        refAsMutable?.current?.removeEventListener('scroll', scrollHandler.current as any)
        scrollHandler.current = null
      }
      if (!hasMoreData) {
        return () => {}
      }
      if (refAsMutable.current && messages.length) {
        // if (!mounted.current) {
        //   // initialize to bottom
        //   refAsMutable.current.scrollTop = refAsMutable.current.scrollHeight
        //   mounted.current = true
        // }

        const correctedScrollTop =
          refAsMutable.current.scrollTop +
          refAsMutable.current.scrollHeight -
          prevScrollHeight.current
        if (correctedScrollTop > 0) {
          refAsMutable.current.scrollTop = correctedScrollTop
        }

        prevScrollTop.current = refAsMutable.current.scrollTop
        prevScrollHeight.current = refAsMutable.current.scrollHeight
        // if no scroll, fetch more data
        if (refAsMutable.current.scrollHeight === refAsMutable.current.clientHeight) {
          fetchMoreData()
        }

        scrollHandler.current = (e: UIEvent<HTMLElement>) => {
          if (e.currentTarget.scrollTop === 0) {
            const now = Date.now()
            if (now - lastFetchTime.current > 500) {
              lastFetchTime.current = now
              fetchMoreData()
            }
          }
        }

        refAsMutable.current.addEventListener('scroll', scrollHandler.current as any)
      }
      return () => {}
    }, [messages, fetchMoreData, hasMoreData, ref])

    const [shouldReportRead] = useState(
      !loading &&
        !manuallyMarkedUnread &&
        me &&
        threadId &&
        thread?.type &&
        (thread.unreadBy.includes(me.uid) || assessmentThreadUnread) &&
        isMainWindow
        ? { appName, type: thread.type, threadId }
        : null,
    )

    useEffect(() => {
      if (shouldReportRead) {
        reportThreadRead(shouldReportRead.appName, shouldReportRead.type, shouldReportRead.threadId)
      }
    }, [shouldReportRead])

    return (
      <VStack
        bg="#f4f4f4"
        height="100%"
        ref={ref}
        spacing={0}
        pb={2}
        width="100%"
        overflowY={messagesLoading ? 'hidden' : 'auto'}>
        <Box w="100%">
          <Collapse unmountOnExit in={loading} style={{ width: '100%' }}>
            <Center w="100%" p={2}>
              <HStack>
                <CircularProgress size={5} color={colors.green.hex} isIndeterminate={loading} />
                <Text size="sm" color="gray.500" fontStyle="italic">
                  Loading older messages...
                </Text>
              </HStack>
            </Center>
          </Collapse>
          <Collapse unmountOnExit in={!hasMoreData && !loading} style={{ width: '100%' }}>
            <Center w="100%">
              <Text size="sm" color="gray.500" py={1} fontStyle="italic">
                Beginning of thread
              </Text>
            </Center>
          </Collapse>
        </Box>
        {messages.map(message => (
          <ThreadMessageView
            withUnreadDivider={message.createdOn === unreadMessageId}
            message={message}
            key={`${message.createdOn}`}
          />
        ))}
      </VStack>
    )
  },
)

import { Button, Flex, Text } from '@chakra-ui/react'
import {
  ASSESSMENTS,
  coverageRequestField,
  FieldMapValue,
  getCoveragePath,
  getInsuranceCoverageField,
  getUniqueRandomId,
  makeAllFieldsOptional,
  OnUploadProgress,
  PatientCoverageId,
  PopulatedAssessment,
  PopulatedUser,
  UpdateCallback,
  User,
} from '@hb/shared'
import { arrayUnion, doc, DocumentReference, writeBatch } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { db, processFieldMapData } from '../../../backend/db'
import { requestCoverage } from '../../../backend/functions'
import { useApp } from '../../../contexts'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { addMetadata } from '../../../utils'
import { ExpandOnMount } from '../../ExpandOnMount'
import { SimpleForm } from '../../forms/FinalForm/SimpleForm'
import { DefaultModal } from '../../Modals/DefaultModal'

const newPlanTypeLabels = {
  medicaid: 'Medicaid',
  'non-medicaid': 'Non-Medicaid',
  request: 'Request from Patient',
}
const NewPlanTypeButton = ({
  type,
  selected,
  onClick,
}: {
  type: 'medicaid' | 'non-medicaid' | 'request'
  selected: boolean
  onClick: () => void
}) => (
  <Button
    onClick={onClick}
    size="sm"
    flex={1}
    boxShadow="1px 1px 3px #00000066"
    bg={selected ? 'green.500' : 'white'}
    border={`1px solid ${selected ? 'green.500' : 'gray.300'}`}
    color={selected ? 'white' : 'gray.600'}
    textShadow={selected ? '1px 1px 3px #00000066' : 'none'}
    variant="unstyled">
    {newPlanTypeLabels[type]}
  </Button>
)

const NewPlanTypeSelect = ({
  type,
  onChange,
}: {
  type: 'medicaid' | 'non-medicaid' | 'request' | null
  onChange: (updated: 'medicaid' | 'non-medicaid' | 'request') => void
}) => (
  <Flex pt={2} gap={1} w="100%">
    <NewPlanTypeButton
      type="non-medicaid"
      selected={type === 'non-medicaid'}
      onClick={() => onChange('non-medicaid')}
    />
    <NewPlanTypeButton
      type="medicaid"
      selected={type === 'medicaid'}
      onClick={() => onChange('medicaid')}
    />
    <NewPlanTypeButton
      type="request"
      selected={type === 'request'}
      onClick={() => onChange('request')}
    />
  </Flex>
)

export const NewPlanModal = ({
  onClose,
  addToAssessmentId,
  patientRef,
  user,
}: {
  onClose: () => void
  addToAssessmentId: string | null
  assessment: PopulatedAssessment | null
  user: PopulatedUser | null
  patientRef: DocumentReference<User>
}) => {
  const { insurancePlans } = user ?? {}
  const { additional: additionalPlans } = insurancePlans ?? {}
  const { appName } = useApp()
  const [type, setType] = useState<'medicaid' | 'non-medicaid' | 'request' | null>(null)
  const field = useMemo(
    () => makeAllFieldsOptional(getInsuranceCoverageField(type === 'medicaid')),
    [type],
  )

  const { processResponse } = useContext(PopUpMessageContext)

  const handleSubmit = useCallback(
    async (data: FieldMapValue, onUploadProgress: OnUploadProgress): Promise<UpdateCallback> => {
      if (!patientRef) return { error: 'No patient selected' }
      const additionalPlanId = getUniqueRandomId(Object.keys(additionalPlans ?? {}))
      const coverageId: PatientCoverageId = `additional.${additionalPlanId}`
      const coveragePath = getCoveragePath(coverageId)
      try {
        const processed = await processFieldMapData(
          `${patientRef.path}/${coveragePath}`.replace(/\./g, '/'),
          field,
          data,
          undefined,
          onUploadProgress,
        )
        const batch = writeBatch(db)
        batch.update(patientRef, coveragePath, addMetadata(processed, appName, true))
        if (addToAssessmentId) {
          const assessmentRef = doc(db, ASSESSMENTS, addToAssessmentId)
          batch.update(assessmentRef, 'potentialCoverageIds', arrayUnion(coverageId))
        }
        await batch.commit()
      } catch (err: any) {
        processResponse({
          error: err?.message || 'Error saving new plan',
        })
        return { error: err?.message || 'Error saving new plan' }
      }
      onClose()
      return { success: 'Plan saved' }
    },
    [field, onClose, processResponse, additionalPlans, appName, patientRef, addToAssessmentId],
  )

  const handleRequestNewPlan = useCallback(
    async (data: FieldMapValue): Promise<UpdateCallback> => {
      if (!patientRef) {
        return processResponse({ error: 'No assessment selected' })
      }

      const { message, withCallInForm, sendEmail } = data
      try {
        await requestCoverage({
          message,
          patientId: patientRef.id,
          // assessmentId,
          withCallInForm,
          appName,
          sendEmail: !!sendEmail,
          requestId: null,
        })
      } catch (err: any) {
        return processResponse({
          error: err?.message || 'Error requesting coverage',
        })
      }
      onClose()
      return processResponse({ success: 'Coverage requested' })
    },
    [processResponse, onClose, appName, patientRef],
  )
  const requestInitData = useMemo(
    () => ({
      sendEmail: true,
    }),
    [],
  )

  return (
    <DefaultModal
      isOpen
      overlayHeader
      isCentered={false}
      onClose={onClose}
      size="xl"
      contentProps={{ bg: 'white' }}
      render={() => (
        <Flex w="100%" flexFlow="column">
          <Flex
            w="100%"
            position="sticky"
            top={0}
            zIndex={1}
            bg="gray.100"
            py={4}
            px={3}
            borderBottom="1px solid #cdcdcd"
            justify="space-between"
            flexFlow="column">
            <Flex justify="center" w="100%">
              <Text
                px={3}
                py={1}
                fontFamily="Hero-New"
                fontSize="lg"
                lineHeight={1}
                color="gray.600">
                New Coverage
              </Text>
            </Flex>
            <Flex px={2} w="100%" align="center">
              <NewPlanTypeSelect type={type} onChange={setType} />
            </Flex>
          </Flex>
          {type === 'medicaid' || type === 'non-medicaid' ? (
            <ExpandOnMount>
              <SimpleForm
                onSubmit={handleSubmit}
                field={field}
                // boxProps={{ }}
              />
            </ExpandOnMount>
          ) : null}
          {type === 'request' ? (
            <ExpandOnMount>
              <SimpleForm
                onSubmit={handleRequestNewPlan}
                field={coverageRequestField}
                value={requestInitData}
                boxProps={{ bg: 'white' }}
              />
            </ExpandOnMount>
          ) : null}
        </Flex>
      )}
    />
  )
}

import {
  BaseQuery,
  CollectionFilter,
  getPracticeUsersCollectionPath,
  newPatientField,
  newPracticePatientField,
  USER_ROLES,
  UserRoleItem,
} from '@hb/shared'
import { DataListTab } from '../../../types/data'
import { userColumns } from '../../DataList/columns/users'

const getPatientsBaseQuery = (midwifeId?: string): BaseQuery<UserRoleItem> => {
  const filters: CollectionFilter<UserRoleItem>[] = [['archived', '==', false]]
  if (!midwifeId) {
    filters.push(['role', '==', 'user'])
  }
  return {
    collection: midwifeId ? getPracticeUsersCollectionPath(midwifeId) : USER_ROLES,
    filters,
  }
}

export const getPatientTab = (
  onCreateClick: () => void,
  midwifeId?: string,
): DataListTab<UserRoleItem> => ({
  searchStringPath: 'stringified',
  rightJustifyTab: true,
  baseQuery: getPatientsBaseQuery(midwifeId),
  creation: {
    onCreateClick,
    field: midwifeId ? newPracticePatientField : newPatientField,
  },
  defaultSort: {
    field: 'createdOn',
    order: 'desc',
  },
  itemName: 'Patient',
  columns: userColumns,
})

import {
  AppName,
  InsuranceProvider,
  insurersCollection,
  LocalCollectionState,
  midwivesCollection,
  PracticeWithAdmin,
} from '@hb/shared'
import React, { useContext, useMemo } from 'react'
import { ScreenContext, SearchBarProvider, useApp } from '../../../contexts'
import { useAppRole, useCollection } from '../../../hooks'
import { useAppModals } from '../../../store'
import { DataListTab } from '../../../types/data'
import { DataList } from '../../DataList'
import { PatientProfile } from '../../Users'
import { getVisitsTab } from '../../Users/Profile/Charting/visitsList'
import { PageContainer } from '../../Views/PageContainer'
import { getAdminAssessmentTab } from './admin'
import { getClaimTab } from './claims'
import { getPatientTab } from './patients'
import { getPracticeAssessmentTab } from './practice'

const getTabs = (
  appName: AppName,
  insurers: LocalCollectionState<InsuranceProvider>,
  providers: LocalCollectionState<PracticeWithAdmin>,
  onCreatePatientClick: () => void,
  midwifeId?: string,
  superAdmin?: boolean,
): Record<string, DataListTab<any>> => {
  const tabs: Record<string, DataListTab<any>> = {}

  if (!midwifeId) {
    tabs['All Assessments'] = getAdminAssessmentTab(null, false, insurers, providers)
    tabs.Inquiry = getAdminAssessmentTab('Inquiry', false, insurers, providers)
    tabs['Auth / Task List'] = getAdminAssessmentTab('Authorization', false, insurers, providers)
    tabs['Office Visits'] = getVisitsTab(appName, null, null, null, true)
    tabs['Send Claim'] = getAdminAssessmentTab('Send Claim', false, insurers, providers)
    tabs.Claims = getClaimTab(midwifeId, insurers, providers)
    tabs.Complete = getAdminAssessmentTab('Complete', false, insurers, providers)
    tabs.Archived = getAdminAssessmentTab(null, true, insurers, providers)
  } else {
    tabs['All Pregnancies'] = getPracticeAssessmentTab(midwifeId, null, false, !!superAdmin)
    tabs.Inquiry = getPracticeAssessmentTab(midwifeId, 'Inquiry', false, !!superAdmin)
    tabs.Current = getPracticeAssessmentTab(midwifeId, 'Current', false, !!superAdmin)
    tabs.Postpartum = getPracticeAssessmentTab(midwifeId, 'Postpartum', false, !!superAdmin)
    tabs.Gyn = getPracticeAssessmentTab(midwifeId, 'Gyn', false, !!superAdmin)
    tabs.Other = getPracticeAssessmentTab(midwifeId, 'Other', false, !!superAdmin)
    tabs['Office Visits'] = getVisitsTab(appName, midwifeId, null, null, true)
    tabs.Discharged = getPracticeAssessmentTab(midwifeId, 'Discharged', false, !!superAdmin)
    if (superAdmin) {
      tabs.Complete = getPracticeAssessmentTab(midwifeId, 'Complete', false, !!superAdmin)
    }
    tabs.Archived = getPracticeAssessmentTab(midwifeId, null, true, !!superAdmin)
  }
  tabs.Patients = getPatientTab(onCreatePatientClick, midwifeId)

  return tabs
}

export const AssessmentsList = ({ midwifeId }: { midwifeId?: string }) => {
  const { appName } = useApp()
  const { open: openInviteModal } = useAppModals(s => s.invitePatient)
  const insurers = useCollection(insurersCollection)
  const providers = useCollection(midwivesCollection)
  // const [profileVersion] = useState<'v1' | 'v2'>('v1')
  const role = useAppRole()
  const tabs = useMemo(
    () =>
      getTabs(
        appName,
        insurers,
        providers,
        () => {
          openInviteModal()
        },
        midwifeId,
        role === 'super-admin',
      ),
    [midwifeId, role, openInviteModal, appName, insurers, providers],
  )

  const basePath = useMemo(() => (midwifeId ? '/' : '/admin'), [midwifeId])

  const { contentHeight } = useContext(ScreenContext)
  return (
    <PageContainer fullWidth>
      <SearchBarProvider>
        <DataList height={contentHeight - 70} rootPath={basePath} tabs={tabs} />
        <PatientProfile inList basePath={basePath} />
      </SearchBarProvider>
    </PageContainer>
  )
}

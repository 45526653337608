import { CheckIcon, EmailIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  CircularProgress,
  Flex,
  HStack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { colors, UpdateCallback } from '@hb/shared'
import { getIdToken, User } from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { auth } from '../../backend/auth'
import { functions } from '../../backend/functions'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { signOut, useAppModals, useAuth } from '../../store'
import { ActionButton, SolidActionButton } from '../Buttons/ActionButton'
import { BoxHeader } from '../Text/BoxHeader'
import { DefaultModal } from './DefaultModal'

const verifyEmail = httpsCallable(functions, 'sendVerificationEmail')

const defaultError = `You may have received a verification email already. 
Please search your inbox for info@hamiltonbilling.com!
If you don't see it, sign in with Google or get in touch with us.
`
export const useIsVerifying = (authUser?: User | null) =>
  useMemo(() => authUser && !authUser.emailVerified, [authUser])
export const EmailVerificationModal = ({ id = 'root' }: { id?: string }) => {
  const { openId, open, close } = useAppModals(s => s.emailVerification)

  const { processResponse } = useContext(PopUpMessageContext)
  const { authUser, user, enrollingUser } = useAuth()
  const { emailVerified: _fromAuthUser, email } = authUser ?? enrollingUser ?? {}
  const fname = user?.fname ?? authUser?.displayName
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (authUser && !authUser.emailVerified) {
      open()
    } else if (enrollingUser && !enrollingUser.emailVerified) {
      open()
    } else if (!authUser && !enrollingUser) {
      close()
    }
  }, [open, authUser, close, enrollingUser])
  const interval = useRef<null | ReturnType<typeof setInterval>>(null)
  const [fetching, setFetching] = useState(false)
  const [sent, setSent] = useState(false)
  const isVerifying = useIsVerifying(authUser)

  const emailVerified = _fromAuthUser

  const fetch = useCallback(async () => {
    if (!auth.currentUser) return
    setFetching(true)
    try {
      await getIdToken(auth.currentUser, true)
      await auth.currentUser.reload()
      const newStatus = auth.currentUser.emailVerified
      if (newStatus) {
        auth.updateCurrentUser(auth.currentUser)
        close()
      }
    } catch (err: any) {
      console.error(err)
      processResponse({
        error: err.message || 'An error occurred checking email verification status',
      })
    }

    setFetching(false)
  }, [processResponse, close])

  const resetInterval = useCallback(() => {
    interval.current = setInterval(fetch, 10000)
  }, [fetch])
  const navigate = useNavigate()

  const check = useCallback(() => {
    if (authUser || enrollingUser) {
      if (interval.current) clearInterval(interval.current)
      fetch()
      resetInterval()
    }
  }, [authUser, enrollingUser, resetInterval, fetch])

  useEffect(() => {
    if (authUser && isVerifying && openId === id) {
      resetInterval()
    } else if (interval.current) {
      clearInterval(interval.current)
    }
    return () => {
      if (interval.current) clearInterval(interval.current)
    }
  }, [isVerifying, authUser, openId, resetInterval, id])

  const onSend = useCallback(async () => {
    setLoading(true)
    try {
      const res = await verifyEmail({ fname: fname ?? '' })
      setSent(true)
      if (res.data) {
        const data = res.data as UpdateCallback
        processResponse(data)
      } else {
        processResponse({ error: defaultError })
      }
    } catch (err) {
      setSent(true)
      console.error(err)
      processResponse({ error: defaultError })
    }
    setLoading(false)
  }, [fname, processResponse])

  return openId === id ? (
    <DefaultModal
      overlayHeader
      onClose={close}
      isOpen
      closeDisabled={!emailVerified}
      size="xl"
      render={() => (
        <Box p={6}>
          <HStack>
            <BoxHeader>Verify your email</BoxHeader>
            <ActionButton
              ml="auto"
              size="sm"
              onClick={() => {
                navigate('/')
                signOut()
              }}>
              Sign Out
            </ActionButton>
          </HStack>
          <Text fontSize="lg" mb={2}>
            For your security, we've got to verify your email!
          </Text>
          <Text color="gray">
            Click the button below to get a verification link sent to your inbox
          </Text>
          <Flex flexFlow="column" w="100%" align="flex-start" mt={4}>
            <VStack w="100%" align="flex-start">
              <SolidActionButton onClick={onSend} isLoading={loading}>
                <EmailIcon filter="drop-shadow(1px 1px 2px #00000077)" color="white" mr={2} /> Send
                Verification Email
              </SolidActionButton>
              <HStack spacing={2} w="100%">
                {sent ? (
                  <Flex align="center">
                    <Tooltip label="Click to check verification status" isDisabled={!isVerifying}>
                      <Button
                        ml={3}
                        size="sm"
                        variant="ghost"
                        aria-label="check-verification"
                        onClick={check}>
                        {fetching ? (
                          <CircularProgress color="gray" size={5} mr={2} />
                        ) : (
                          <CheckIcon mr={2} color={emailVerified ? colors.green.hex : 'gray'} />
                        )}
                        <Text
                          color={emailVerified ? colors.green.hex : 'gray'}
                          fontSize="sm"
                          fontWeight={300}>
                          {emailVerified
                            ? 'Email verified!'
                            : `Verification email sent to ${email}, click to check status`}
                        </Text>
                      </Button>
                    </Tooltip>
                  </Flex>
                ) : (
                  <Box pl={2}>
                    <Button
                      fontSize="sm"
                      fontFamily="Open Sans"
                      variant="link"
                      onClick={() => {
                        setSent(true)
                        check()
                      }}>
                      I have already verified my email
                    </Button>
                  </Box>
                )}
              </HStack>
            </VStack>
          </Flex>
        </Box>
      )}
    />
  ) : null
}

import { CheckIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { colors, getDateString, getDateTimeString, getFullName } from '@hb/shared'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useCachedUser } from '../../../../collections/hooks/cached'
import { AppContext, ProfileContext } from '../../../../contexts'
import { useMe } from '../../../../hooks/backend/useMe'
import { useUpdateDoc } from '../../../../hooks/backend/useUpdateDoc'
import { UserBadge } from '../../UserBadge'

const PracticeAssessmentClearedSection = () => {
  const {
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(ProfileContext)
  const { clearedBy, clearedOn } = selectedAssessment ?? {}
  const { appName } = useContext(AppContext)
  const text = clearedOn ? 'CLEARED FOR COVERAGE' : 'NOT CLEARED FOR COVERAGE'
  const color = clearedOn ? colors.green.hex : '#ababab'
  const { data: clearedByUser } = useCachedUser(clearedBy ?? null)
  return (
    <HStack>
      <Tooltip
        placement="top"
        hasArrow
        label={
          clearedOn
            ? `Cleared by ${
                clearedByUser ? getFullName(clearedByUser) : 'Loading...'
              } on ${getDateTimeString(clearedOn)}`
            : 'Awaiting to be cleared by HB'
        }>
        <HStack
          bg={clearedOn ? 'transparent' : 'whitesmoke'}
          px={2}
          cursor="default"
          py={1}
          spacing={1}
          borderRadius={6}
          border={`1px solid ${clearedOn ? 'transparent' : color}`}>
          {clearedOn ? (
            <Center w="20px" h="20px" borderRadius={6} bg={color}>
              <CheckIcon width="14px" color="white" />
            </Center>
          ) : null}
          {clearedOn && appName === 'app' ? null : (
            <Text
              fontSize="sm"
              fontWeight={600}
              whiteSpace="nowrap"
              fontFamily="Hero-New"
              color={color}>
              {text}
            </Text>
          )}
        </HStack>
      </Tooltip>
    </HStack>
  )
}

const AdminAssessmentClearedSection = () => {
  const {
    selectedAssessment: { populated: selectedAssessment, snippetRef },
  } = useContext(ProfileContext)
  const { clearedOn, clearedBy } = selectedAssessment ?? {}

  const me = useMe()
  const [hovered, setHovered] = useState(false)
  const update = useUpdateDoc('cleared status')
  const [isLoading, setIsLoading] = useState(false)
  const handleClick = useCallback(() => {
    if (!me) return
    setIsLoading(true)
    try {
      if (clearedOn) {
        update(snippetRef, '', { clearedOn: null, clearedBy: null })
      } else {
        update(snippetRef, '', { clearedOn: Date.now(), clearedBy: me.uid })
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [clearedOn, snippetRef, update, me])

  const color = useMemo(
    () => (clearedOn || hovered ? colors.green.hex : '#ababab'),
    [clearedOn, hovered],
  )

  const button = (
    <Button
      onClick={handleClick}
      isLoading={isLoading}
      transition="all 300ms"
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
      bg="whitesmoke"
      w="200px"
      fontFamily="Hero-New"
      overflow="hidden"
      size="sm"
      fontSize="xs"
      gap={1}
      px={0}
      minW="0"
      borderRadius={6}
      border={`1px solid ${color}`}>
      <Flex px={3} justify="center" align="center" gap={1.5} w="100%">
        {clearedOn ? (
          <Center w="20px" h="20px" borderRadius={6} bg={color}>
            <CheckIcon width="14px" color="white" />
          </Center>
        ) : null}
        <Text color={color}>
          {clearedOn ? `CLEARED ${getDateString(clearedOn, 'short')}` : 'NOT CLEARED FOR COVERAGE'}
        </Text>
      </Flex>
    </Button>
  )

  return (
    <HStack>
      {clearedOn ? (
        <Popover trigger="hover">
          <PopoverTrigger>{button}</PopoverTrigger>
          <PopoverContent w="auto">
            <PopoverBody w="250px" py={1} px={3} bg="gray.50">
              <Flex borderRadius={4} gap={1.5} align="center">
                {clearedBy ? <UserBadge userGroup="admin" userId={clearedBy} /> : null}
                <Text fontWeight={600} color="gray.500">
                  Cleared on {getDateString(clearedOn, 'short')}
                </Text>
              </Flex>
              <Button
                w="100%"
                colorScheme="red"
                bg="white"
                variant="outline"
                size="xs"
                mt={1}
                onClick={handleClick}>
                - UNMARK AS CLEARED
              </Button>
            </PopoverBody>
            <PopoverArrow />
          </PopoverContent>
        </Popover>
      ) : (
        button
      )}
    </HStack>
  )
}

export const AssessmentClearedSection = () => {
  const { appName } = useContext(AppContext)
  return appName === 'providers-app' ? (
    <PracticeAssessmentClearedSection />
  ) : (
    <AdminAssessmentClearedSection />
  )
}

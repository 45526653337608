import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  FieldMap,
  FieldMapValue,
  FieldTypes,
  getDateString,
  PracticeInvoiceOmission,
  UpdateCallback,
} from '@hb/shared'

import React, { useCallback, useContext, useState } from 'react'
import { omitPaymentFromInvoice, removeOmissionFromInvoice } from '../../../backend'
import { PopUpMessageContext } from '../../../contexts'
import { SimpleForm } from '../../forms'

const omissionField: FieldMap = {
  name: 'Omission',
  children: {
    reason: {
      placeholder: 'Reason',
      type: FieldTypes.TEXTAREA,
    },
  },
}

export const PaymentOmissionEdit = ({
  omission,
  assessmentId,
  paymentId,
  invoiceId,
}: {
  omission?: PracticeInvoiceOmission
  assessmentId: string
  paymentId: string
  invoiceId: string
}) => {
  const [loading, setLoading] = useState(false)
  const [addingOmission, setAddingOmission] = useState(false)
  const { showError } = useContext(PopUpMessageContext)
  const addOmission = useCallback(
    async (value: FieldMapValue): Promise<UpdateCallback> => {
      const { reason } = value ?? {}
      setLoading(true)
      try {
        await omitPaymentFromInvoice({
          assessmentId,
          paymentId,
          reason: reason ?? '',
          invoiceId,
        })
        setAddingOmission(false)
        setLoading(false)
        return { success: 'Removed omission' }
      } catch (err: any) {
        console.error(err)
        showError(err.message)
        setLoading(false)
        return { error: err.message }
      }
    },
    [assessmentId, paymentId, invoiceId, showError],
  )

  const removeOmission = useCallback(async () => {
    setLoading(true)
    await removeOmissionFromInvoice({
      assessmentId,
      paymentId,
      invoiceId,
    })
    setLoading(false)
  }, [assessmentId, paymentId, invoiceId])

  return omission ? (
    <Popover placement="top" strategy="fixed" trigger="hover">
      <PopoverTrigger>
        <Button
          minH="20px"
          h="20px"
          _hover={{ bg: 'red.400' }}
          py={0}
          size="xs"
          variant="ghost"
          color="gray.100"
          bg="red.500">
          <MinusIcon color="gray.100" />
          <Text ml={1}>OMITTED</Text>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <VStack spacing={0} px={2} py={1} align="flex-start">
              <HStack w="100%">
                <Text fontWeight={600} fontSize="sm" color="red.600">
                  Omitted at {getDateString(omission?.omittedAt, 'short')}
                </Text>
                <Tooltip label="Remove omission">
                  <IconButton
                    ml="auto"
                    size="xs"
                    variant="ghost"
                    icon={<AddIcon color="green.500" />}
                    aria-label="Omission"
                    onClick={removeOmission}
                    isLoading={loading}
                  />
                </Tooltip>
              </HStack>
              <Text fontSize="sm" fontFamily="Hero-New">
                {omission.reason || 'No notes'}
              </Text>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  ) : (
    <Popover placement="right" onClose={() => setAddingOmission(false)} isOpen={addingOmission}>
      <PopoverTrigger>
        <Box>
          <Tooltip placement="top" hasArrow bg="red.600" label="Omit from invoice">
            <IconButton
              size="xs"
              variant="ghost"
              icon={<MinusIcon color={omission ? 'gray.100' : 'red.600'} />}
              aria-label="Omission"
              onClick={() => setAddingOmission(true)}
              isLoading={loading}
            />
          </Tooltip>
        </Box>
      </PopoverTrigger>
      <PopoverContent p={0}>
        <PopoverArrow />
        <SimpleForm field={omissionField} onSubmit={addOmission} />
      </PopoverContent>
    </Popover>
  )
}

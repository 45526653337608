import { Flex, Text } from '@chakra-ui/react'
import { PopulatedUser, User } from '@hb/shared'

import { DocumentReference } from 'firebase/firestore'
import React, { useState } from 'react'
import { ActionButton, SolidActionButton } from '../../Buttons/ActionButton'
import { AdditionalPlansView } from '../AdditionalPlansView'
import { SecondaryCoverageForm } from './SecondaryCoverageForm'

export const FormsViewAdditionalPlans = ({
  assessmentId,
  user,
  patientRef,
  onConfirmNoPlans,
  noPlansConfirmed,
}: {
  assessmentId: string
  patientRef: DocumentReference<User>
  user: PopulatedUser
  onConfirmNoPlans: () => void
  noPlansConfirmed: boolean
}) => {
  // const { plans: insurancePlans } = assessment
  // const { secondaryCoverage: secondary } = insurancePlans

  const [secondaryCoverageType, setSecondaryCoverageType] = useState<
    'non-medicaid' | 'medicaid' | null
  >(null)

  let body = null
  if (!noPlansConfirmed) {
    body = secondaryCoverageType ? (
      <SecondaryCoverageForm
        user={user}
        assessmentId={assessmentId}
        patientRef={patientRef}
        isMedicaid={secondaryCoverageType === 'medicaid'}
        onBack={() => setSecondaryCoverageType(null)}
      />
    ) : (
      <Flex bg="white" flexFlow="column" w="100%" px={3}>
        <Text px={2} py={1} color="gray.600">
          Is there another plan that covers or will cover you during the course of your pregnancy?
        </Text>
        <Flex mt={2} w="100%" gap={2} flexFlow="column">
          <ActionButton w="100%" onClick={onConfirmNoPlans} size="sm">
            I do not have secondary coverage
          </ActionButton>
          <SolidActionButton
            onClick={() => setSecondaryCoverageType('non-medicaid')}
            flex={1}
            size="sm">
            I have secondary coverage (not medicaid)
          </SolidActionButton>
          <SolidActionButton
            onClick={() => setSecondaryCoverageType('medicaid')}
            flex={1}
            size="sm">
            I have secondary coverage through medicaid
          </SolidActionButton>
        </Flex>
      </Flex>
    )
  } else {
    body = (
      <AdditionalPlansView
        noHeader
        // assessment={ass}
        patientRef={patientRef}
        assessment={null}
        assessmentId={null}
        // openPlanId={openPlanId}
        user={user}
        // onOpenPlan={onOpenPlan}
      />
    )
  }

  return (
    <Flex pb={2} flexFlow="column" w="100%">
      <Flex align="center" px={3} w="100%">
        <Text fontSize="md" fontWeight={600}>
          Potential Plans
        </Text>
      </Flex>
      {body}
    </Flex>
  )
}

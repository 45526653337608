import { Box, Flex, Text } from '@chakra-ui/react'
import { getPlanNextAction } from '@hb/shared'
import React, { useContext } from 'react'
import { ProfileContext } from '../../contexts'
import { useMobileLayout } from '../../hooks/useMobileLayout'
import { Expandable } from '../Expandable'
import { AdditionalPlansView } from './AdditionalPlansView'
import { CoverageStage } from './Coverage'

export const PatientPlansView = ({ preview }: { preview?: boolean }) => {
  const { user, patientRef } = useContext(ProfileContext)
  const { insurancePlans: plans } = user ?? {}

  const { ref, width } = useMobileLayout()
  if (!patientRef) {
    return (
      <Flex flexFlow="column" p={3}>
        <Text>Select a patient to see plans</Text>
      </Flex>
    )
  }

  return (
    <Flex ref={ref} w="100%" py={preview ? 0 : 2} flexFlow="column">
      <Text px={3} fontSize="lg" lineHeight={1} fontWeight={600} color="gray.600">
        Patient Coverage
      </Text>
      <Flex w="100%" gap={preview ? 1 : 2} flexFlow="column" px={preview ? 1 : 3} py={2}>
        <CoverageStage
          id="primary"
          assessmentId={null}
          patientRef={patientRef}
          adminView
          width={width}
          coverage={plans?.primary}
        />
      </Flex>
      <AdditionalPlansView
        adminView
        assessment={null}
        assessmentId={null}
        patientRef={patientRef}
        preview={preview}
        user={user}
      />
    </Flex>
  )
}

export const PregnancyPlansView = () => {
  const { user, patientRef, assessmentId, selectedAssessment } = useContext(ProfileContext)
  // const { nextActions } = user ?? {}
  const { plans, nextActions } = selectedAssessment.populated ?? {}
  const { medicaid, nonMedicaid, primaryIsMedicaid } = plans ?? {}
  const { width } = useMobileLayout()
  const primaryPregnancyPlan = primaryIsMedicaid ? medicaid : nonMedicaid
  const secondaryPregnancyPlan = primaryIsMedicaid ? nonMedicaid : medicaid
  const primaryNextAction =
    nextActions && primaryPregnancyPlan
      ? getPlanNextAction(nextActions, primaryPregnancyPlan.id)
      : null

  const secondaryNextAction =
    nextActions && secondaryPregnancyPlan
      ? getPlanNextAction(nextActions, secondaryPregnancyPlan.id)
      : null

  if (!assessmentId || !patientRef) {
    return (
      <Flex flexFlow="column" p={3}>
        <Text>Select a pregnancy to see plans</Text>
      </Flex>
    )
  }

  return (
    <Flex w="100%" py={2} flexFlow="column">
      <Text px={3} fontSize="lg" lineHeight={1} fontWeight={600} color="gray.600">
        Pregnancy Coverage
      </Text>
      <Flex w="100%" gap={2} flexFlow="column" px={3} py={2}>
        {primaryPregnancyPlan ? (
          <CoverageStage
            id={primaryPregnancyPlan.id}
            width={width}
            assessmentId={assessmentId}
            assessment={selectedAssessment.populated}
            patientRef={patientRef}
            nextAction={primaryNextAction}
            adminView
            coverage={primaryPregnancyPlan}
          />
        ) : (
          <Text px={2} lineHeight={1} color="gray.600">
            No primary coverage
          </Text>
        )}
        {secondaryPregnancyPlan ? (
          <CoverageStage
            id={secondaryPregnancyPlan.id}
            assessment={selectedAssessment.populated}
            width={width}
            assessmentId={assessmentId}
            patientRef={patientRef}
            nextAction={secondaryNextAction}
            adminView
            coverage={secondaryPregnancyPlan}
          />
        ) : null}
      </Flex>
      <AdditionalPlansView
        assessmentId={assessmentId}
        assessment={selectedAssessment.populated}
        adminView
        patientRef={patientRef}
        user={user}
      />
    </Flex>
  )
}

export const CollapsiblePatientPlansView = () => (
  <Expandable
    borderRadius={6}
    headerProps={{ bg: 'gray.50', borderRadius: 4 }}
    border="1px solid #cdcdcd"
    header={() => (
      <Flex py={1} px={2} align="center" justify="space-between" w="100%">
        <Text fontSize="md" color="gray.600" fontWeight={500}>
          Insurance Plans
        </Text>
      </Flex>
    )}>
    <Box px={1} w="100%">
      <PatientPlansView preview />
    </Box>
  </Expandable>
)

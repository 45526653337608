import { CheckIcon } from '@chakra-ui/icons'
import { Box, Collapse, Divider, Flex, Text } from '@chakra-ui/react'
import {
  ASSESSMENTS,
  colors,
  ConfirmCoverageStatus,
  getConfirmCoverageStatus,
  getCoverageComplete,
  getCurrentlyOnMedicaidPlan,
  getInsuranceCoverageField,
  getOnlyOnMedicaidPlan,
  InsuranceCoverage,
  isInsuranceCoverageRequest,
  loqRequiredOptions,
  PatientCoverageId,
} from '@hb/shared'
import useResizeObserver from '@react-hook/resize-observer'
import { collection, doc, writeBatch } from 'firebase/firestore'

import React, { PropsWithChildren, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { db } from '../../../backend/db'
import { PopUpMessageContext } from '../../../contexts'
import { FormsViewContext } from '../../../contexts/FormsViewContext'
import { useAuth } from '../../../store'
import { SolidActionButton } from '../../Buttons/ActionButton'
import { DefaultModal } from '../../Modals/DefaultModal'
import { CoverageRequestView } from './CoverageRequestView'
import { CoverageStage } from './CoverageStage'
import { FormsViewAdditionalPlans } from './FormsViewAdditionalPlans'

// const getInitialStage = (status: ConfirmCoverageStatus): ConfirmCoverageStage | null => {
//   if (status.primaryCoverage?.incomplete?.length) return 'primaryCoverage'
//   if (status.secondaryCoverage?.incomplete?.length) return 'secondaryCoverage'
//   const additionalPlan = Object.keys(status.additionalPlans ?? {}).find(
//     p => !!status.additionalPlans[p as AdditionalCoverageId]?.incomplete.length,
//   )
//   if (additionalPlan) return `additionalPlans.${additionalPlan}`
//   return 'confirm'
// }

export const ConfirmCoverageModal = ({ onClose }: { onClose: () => void }) => {
  const {
    assessment: { populated: assessment },
    populatedUser,
    assessmentId,
  } = useContext(FormsViewContext)
  const { user, patientRef } = populatedUser ?? {}
  const { id: userId, insurancePlans, insuranceInfo } = user ?? {}
  // const { insurancePlans } = user ?? {}

  const { requests, primary, secondary } = insurancePlans ?? {}
  const { data } = assessment ?? {}

  const option = useMemo(
    () => insuranceInfo?.corrections?.option ?? insuranceInfo?.data?.option,
    [insuranceInfo],
  )

  const hasPrimaryCoverage = useMemo(
    () => option !== 'no-coverage' && !getOnlyOnMedicaidPlan(data),
    [data, option],
  )

  const isCurrentlyOnMedicaid = useMemo(() => getCurrentlyOnMedicaidPlan(data), [data])

  const primaryCoverageField = useMemo(
    () =>
      hasPrimaryCoverage
        ? getInsuranceCoverageField(
            false,
            option ? loqRequiredOptions.includes(option) : false,
            !isCurrentlyOnMedicaid,
          )
        : null,
    [option, hasPrimaryCoverage, isCurrentlyOnMedicaid],
  )

  // const medicaidCoverageField = useMemo(
  //   () => (hasMedicaidCoverage ? getInsuranceCoverageField(true, false) : null),
  //   [hasMedicaidCoverage],
  // )

  const status = useMemo<ConfirmCoverageStatus>(() => getConfirmCoverageStatus(user), [user])

  const incompletePlans = useMemo(() => {
    const incomplete: {
      propPath:
        | 'insurancePlans.primary'
        | 'insurancePlans.secondary'
        | `insurancePlans.additional.${string}`
      label: 'primary' | 'secondary' | 'potential'
      coverage: InsuranceCoverage | undefined | null
      id: PatientCoverageId
    }[] = []
    if (status.primaryCoverage?.incomplete?.length) {
      incomplete.push({
        id: 'primary',
        label: 'primary',
        propPath: 'insurancePlans.primary',
        coverage: primary,
      })
    }
    if (status.secondaryCoverage?.incomplete?.length) {
      incomplete.push({
        id: 'secondary',
        label: 'secondary',
        propPath: 'insurancePlans.secondary',
        coverage: secondary,
      })
    }
    // Object.keys(status.additionalPlans ?? {})
    //   .filter(p => status.additionalPlans[p as AdditionalCoverageId]?.incomplete.length)
    //   .forEach(p => {
    //     const coverage = additional?.[p as AdditionalCoverageId]
    //     if (coverage) {
    //       incomplete.push({
    //         id: `additional.${p}` as PatientCoverageId,
    //         propPath: `insurancePlans.additional.${p}`,
    //         label: 'potential',
    //         coverage,
    //       })
    //     }
    //   })
    return incomplete
  }, [status, primary, secondary])

  const [noPlansConfirmed, setNoPlansConfirmed] = useState(!!assessment?.additionalPlansConfirmedOn)

  // const onOpenToggle = useCallback((s: ConfirmCoverageStage | null) => {
  //   setStage(prev => (prev === s ? null : s))
  // }, [])

  const { showMessage } = useContext(PopUpMessageContext)

  const [confirmLoading, setConfirmLoading] = useState(false)
  const onConfirm = useCallback(async () => {
    const { authUser } = useAuth.getState()
    if (!authUser) {
      showMessage({
        type: 'error',
        text: 'User not found',
        subText: 'You may have been signed out. Please sign in again.',
      })
      return
    }
    setConfirmLoading(true)
    try {
      const assessmentRef = doc(collection(db, ASSESSMENTS), assessmentId)
      const batch = writeBatch(db)
      // update confirmation status
      batch.update(assessmentRef, 'additionalPlansConfirmedOn', Date.now())
      batch.update(assessmentRef, 'additionalPlansConfirmedBy', authUser.uid)
      await batch.commit()
      onClose()
    } catch (e: any) {
      showMessage({
        type: 'error',
        text: 'Error updating assessment',
        subText: e.message,
      })
      setConfirmLoading(false)
    }
  }, [assessmentId, onClose, showMessage])

  const [contentWidth, setContentWidth] = useState<number>(0)
  const contentRef = useRef<HTMLDivElement>(null)
  const onContentResize = useCallback((e: ResizeObserverEntry) => {
    setContentWidth(e.contentRect.width)
  }, [])
  useResizeObserver(contentRef, onContentResize)

  const incompletePlan = useMemo(() => {
    if (option === 'no-coverage') return null
    const requestIds = Object.keys(requests ?? {})
    if (requestIds.length) {
      const request = requests?.[requestIds[0]]
      if (!request) return null
      return {
        id: requestIds[0],
        label: 'coverage request',
        coverage: { ...request, id: requestIds[0] },
      }
    }
    return incompletePlans[0]
  }, [incompletePlans, requests, option])
  const allComplete = useMemo(() => {
    if (!assessment) return false
    if (!incompletePlan || option === 'no-coverage') return noPlansConfirmed
    return assessment ? getCoverageComplete(status) : false
  }, [status, assessment, noPlansConfirmed, option, incompletePlan])

  let incompletePlanBody: PropsWithChildren['children'] = null
  if (incompletePlan && patientRef) {
    incompletePlanBody =
      incompletePlan.coverage && isInsuranceCoverageRequest(incompletePlan.coverage) ? (
        <CoverageRequestView
          patientRef={patientRef}
          // assessmentId={assessmentId ?? null}
          request={incompletePlan.coverage}
        />
      ) : (
        <CoverageStage
          assessmentId={assessmentId || null}
          id={incompletePlan.id as PatientCoverageId}
          key={incompletePlan.id}
          patientRef={patientRef}
          width={contentWidth}
          alwaysOpen
          coverage={incompletePlan.coverage}
          isOpen
        />
      )
  }

  return (
    <DefaultModal
      overlayHeader
      isOpen
      size="2xl"
      onClose={onClose}
      contentProps={{ bg: 'gray.100' }}
      render={() =>
        assessment && assessmentId && userId && user ? (
          <Flex gap={2} p={3} flexFlow="column" w="100%">
            <Text p={1} fontSize="lg" lineHeight={1} fontWeight={600} color="gray.600">
              Confirm Coverage
            </Text>
            {incompletePlan ? (
              <Flex w="100%" flexFlow="column">
                <Text p={2} fontSize="md" color="gray.600">
                  We need some information about your {incompletePlan.label} coverage.
                </Text>
                {incompletePlanBody}
              </Flex>
            ) : (
              <Flex flexFlow="column" border="1px solid #cdcdcd" bg="white" w="100%">
                {primaryCoverageField && patientRef ? (
                  <Box w="100%" p={2}>
                    <CoverageStage
                      id="primary"
                      assessmentId={assessmentId}
                      width={contentWidth}
                      patientRef={patientRef}
                      coverage={primary}
                      optional={isCurrentlyOnMedicaid}
                      // isOpen={stage === 'primaryCoverage'}
                      // onOpenToggle={() => onOpenToggle('primaryCoverage')}
                    />
                    <Divider mt={2} />
                  </Box>
                ) : null}
                {patientRef ? (
                  <>
                    <FormsViewAdditionalPlans
                      user={user}
                      patientRef={patientRef}
                      assessmentId={assessmentId ?? null}
                      noPlansConfirmed={noPlansConfirmed}
                      onConfirmNoPlans={() => setNoPlansConfirmed(true)}
                    />
                    <Divider />
                  </>
                ) : null}
              </Flex>
            )}
            <Collapse
              in={allComplete && !assessment.additionalPlansConfirmedOn}
              style={{ width: '100%' }}>
              <Flex w="100%">
                <SolidActionButton
                  borderRadius="full"
                  ml="auto"
                  onClick={onConfirm}
                  isLoading={confirmLoading}
                  bg={colors.green.hex}
                  color="white">
                  <CheckIcon filter="drop-shadow(1px 1px 3px #00000066)" w={4} h={4} mr={2} />
                  <Text>Done!</Text>
                </SolidActionButton>
              </Flex>
            </Collapse>
          </Flex>
        ) : (
          <Flex p={3}>
            <Text>No assessment found</Text>
          </Flex>
        )
      }
    />
  )
}

import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Claim, CLAIMS, colors, notesField, TextAreaField, WithId } from '@hb/shared'
import { collection, deleteField, doc, updateDoc } from 'firebase/firestore'

import React, { useCallback, useState } from 'react'
import { db } from '../../../../../backend'
import { SolidActionButton } from '../../../../Buttons'
import { Editable, StandaloneInput } from '../../../../forms'

const nullifiedReasonField: TextAreaField = {
  ...notesField,
  placeholder: 'Reason for nullifying',
}

const NewNullifyContent = ({
  toggleNullify,
  nullifyLoading,
}: {
  toggleNullify: (notes: string) => Promise<void>
  nullifyLoading: boolean
}) => {
  const [message, setMessage] = useState('')

  const handleNullify = useCallback(() => toggleNullify(message), [message, toggleNullify])

  return (
    <Flex gap={2} w="100%" direction="column" p={2}>
      <Text color="gray.700">Nullify claim</Text>
      <Text fontSize="sm" color="gray.500">
        A nullified claim will not be included when calculating the fee
      </Text>
      <StandaloneInput field={nullifiedReasonField} onChange={setMessage} />
      <SolidActionButton onClick={handleNullify} isLoading={nullifyLoading} size="sm">
        Submit
      </SolidActionButton>
    </Flex>
  )
}

const NullifyClaimContent = ({
  toggleNullify,
  nullified,
  nullifyLoading,
}: {
  toggleNullify: (notes: string | null) => Promise<void>
  nullifyLoading: boolean
  nullified?: Claim['nullified']
}) => (
  <VStack w="100%" p={2} align="flex-start">
    {nullified ? (
      <Editable
        inputStyle={{ boxShadow: 'none' }}
        onDelete={() =>
          toggleNullify(null)
            .then(() => ({ success: 'Un-nullified!' }))
            .catch((e: any) => ({ error: e?.message || 'Error un-nullifying' }))
        }
        field={nullifiedReasonField}
        onSubmit={d =>
          toggleNullify(d)
            .then(() => ({ success: 'Updated!' }))
            .catch((e: any) => ({ error: e?.message || 'Error updating' }))
        }
        value={nullified?.notes || 'None'}
      />
    ) : (
      <NewNullifyContent toggleNullify={toggleNullify} nullifyLoading={nullifyLoading} />
    )}
  </VStack>
)

export const NullifyClaimPopover = ({
  systemClaimId,
  systemClaim,
}: {
  systemClaim?: WithId<Claim> | null
  systemClaimId: string
}) => {
  const { nullified } = systemClaim ?? {}
  const [nullifyLoading, setNullifyLoading] = useState(false)
  const toggleNullify = useCallback(
    async (notes: string | null) => {
      const ref = doc(collection(db, CLAIMS), systemClaimId)
      setNullifyLoading(true)
      try {
        await updateDoc(
          ref,
          'nullified',
          notes
            ? {
                on: Date.now(),
                by: 'user',
                notes: notes ?? '',
              }
            : deleteField(),
        )
      } catch (err: any) {
        console.error(err)
      }
    },
    [systemClaimId],
  )
  return (
    <Popover placement="right" isLazy closeOnBlur={false} strategy="fixed">
      <PopoverTrigger>
        <Button textShadow="1px 1px 3px #00000077" size="xs" bg={colors.red.hex} color="white">
          NULLIFY
        </Button>
      </PopoverTrigger>
      <PopoverContent bg="gray.50" w="350px">
        <NullifyClaimContent
          toggleNullify={toggleNullify}
          nullified={nullified}
          nullifyLoading={nullifyLoading}
        />
        <PopoverArrow />
      </PopoverContent>
    </Popover>
  )
}

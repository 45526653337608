import {
  Box,
  BoxProps,
  Button,
  Center,
  Flex,
  FlexProps,
  HStack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { A4_DIMS_PIXELS, colors, FieldMapValue, PopulatedAssessment } from '@hb/shared'
import useResizeObserver from '@react-hook/resize-observer'

import { doc, updateDoc } from 'firebase/firestore'
import * as React from 'react'
import { ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ASSESSMENTS_REF, USERS_REF } from '../../../collections/firestoreCollections'
import { useApp } from '../../../contexts/AppContext'
// import { AssessmentInterfaceContext } from '../../contexts/AssessmentContext'
import { FormLabelsContext } from '../../../contexts/FormLabelsContext'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { ProfileContext, useProfile } from '../../../contexts/ProfileContext'
import { ScreenContext } from '../../../contexts/ScreenContext'
import { updateAssessmentAnswers } from '../../../hooks/backend/assessments'

import { useUpdateDoc } from '../../../hooks/backend/useUpdateDoc'
import { useAuth } from '../../../store/auth'
import { Loading } from '../../Loading'
import { DefaultModal } from '../../Modals/DefaultModal'
import { ReadOnlyEditor, useEditorWidth } from '../../RichText'
import { AssessmentPayments } from '../../Users/Profile/Payments/AssessmentPayments'
import { AssessmentAnswers } from './AssessmentAnswers'
import { AssessmentEditor } from './AssessmentEditor'

const AssessmentModalContainer = ({
  children,
  flexProps,
  ...props
}: BoxProps & { flexProps?: FlexProps }) => (
  <Box p={2} bg="gray.100" position="relative" height="100%" {...props}>
    <Flex
      minH="100%"
      borderRadius={6}
      boxShadow="md"
      w="100%"
      bg="white"
      direction="column"
      alignItems="flex-start"
      h="100%"
      overflowY="auto"
      flexGrow={1}
      flex={1}
      {...flexProps}>
      {children}
    </Flex>
  </Box>
)

const AssessmentModalAssessmentView = ({
  admin,
  width,
  height,
}: {
  admin: boolean
  width: number
  height: number
}) => {
  const { appName } = useApp()
  const { selectedAssessment: assessmentInterface, assessmentId } = useContext(ProfileContext)
  const selectedAssessment = assessmentInterface?.populated
  const assessmentLoading = assessmentInterface?.loading
  const { status } = selectedAssessment ?? {}

  let body: ReactElement | null = null

  const editorWidth = useMemo(() => Math.min(width - 24, A4_DIMS_PIXELS[0]), [width])
  const [headerHeight, setHeaderHeight] = useState(0)
  const headerRef = useRef<HTMLDivElement>(null)

  useResizeObserver(headerRef, entry => {
    setHeaderHeight(entry.contentRect.height)
  })

  const bodyHeight = useMemo(() => height - headerHeight - 20, [height, headerHeight])
  const editorSize = useEditorWidth(editorWidth - 10, bodyHeight)

  const usedVersion = useMemo(() => {
    if (selectedAssessment?.results) {
      return selectedAssessment.editorVersion || 'v1'
    }
    if (selectedAssessment?.draft) {
      return selectedAssessment.draftEditorVersion || 'v1'
    }
    return selectedAssessment?.editorVersion || 'v2'
  }, [selectedAssessment])

  if (appName === 'providers-app') {
    if (!selectedAssessment?.sentOn) {
      body = (
        <Center w="100%">
          <Text p={2} fontStyle="italic" fontFamily="hero-new" fontSize="lg" color="gray.600">
            {selectedAssessment?.submittedOn
              ? 'Assessment not yet sent'
              : 'Questionnaire not yet submitted'}
          </Text>
        </Center>
      )
    } else {
      body = (
        <Box w="100%">
          <ReadOnlyEditor
            {...editorSize}
            version={usedVersion}
            value={
              selectedAssessment?.results && Array.isArray(selectedAssessment.results)
                ? selectedAssessment.results
                : []
            }
          />
        </Box>
      )
    }
  } else if (appName === 'app') {
    if (!selectedAssessment?.submittedOn) {
      body = (
        <Center w="100%">
          <Text p={2} fontStyle="italic" fontFamily="hero-new" fontSize="lg" color="gray.600">
            Questionnaire not yet submitted
          </Text>
        </Center>
      )
    } else if (!selectedAssessment && assessmentLoading) {
      body = (
        <Center w="100%">
          <Loading text="Loading assessment" />
        </Center>
      )
    } else {
      body = (
        <AssessmentEditor
          status={status}
          assessmentId={assessmentId}
          admin={admin}
          width={editorWidth}
          height={bodyHeight}
          assessment={selectedAssessment}
        />
      )
    }
  }

  return (
    <AssessmentModalContainer
      height={height}
      width={width}
      flexProps={{ bg: 'white' }}
      overflowY="hidden">
      <HStack
        ref={headerRef}
        borderTopRadius={6}
        bg="white"
        w="100%"
        height="36px"
        px={3}
        py={1}
        borderBottom="1px solid #cdcdcd">
        <Text fontWeight={600} fontSize="md" color="gray.600" fontFamily="Open Sans">
          Assessment Results
        </Text>
      </HStack>
      {body}
    </AssessmentModalContainer>
  )
}

export const AssessmentModal: React.FC<{
  assessment: PopulatedAssessment | null
  id: string | null
  admin?: boolean
  onClose: () => void
  isOpen: boolean
}> = ({ assessment, onClose, isOpen, id }) => {
  const me = useAuth(s => s.user)
  const { appName } = useApp()
  const { showPayments } = me ?? {}
  const { selectedAssessment, assessmentId } = useProfile()
  const { processResponse } = useContext(PopUpMessageContext)
  const onSaveAnswers = useCallback(
    async (data: FieldMapValue) => {
      if (assessmentId) {
        try {
          await updateAssessmentAnswers({
            data,
            appName,
            id: assessmentId,
          })
          return processResponse({ success: 'Answers updated!' })
        } catch (err: any) {
          return processResponse({ error: err.message })
        }
      }
      return processResponse({ error: 'No assessment' })
    },
    [assessmentId, processResponse, appName],
  )
  const screenDims = useContext(ScreenContext)
  const { isMobile, width: screenWidth } = screenDims
  const expandedPaymentsWidth = useMemo(
    () => (isMobile ? screenWidth - 60 : Math.min(screenWidth / 2.5, 650)),
    [screenWidth, isMobile],
  )

  const [overrideValue, setOverrideValue] = useState(showPayments)
  useEffect(() => {
    setOverrideValue(showPayments)
  }, [showPayments])

  const toggleShowPayments = useCallback(() => {
    if (!me) return { error: 'Not signed in' }
    setOverrideValue(!showPayments)
    return updateDoc(doc(USERS_REF, me.id), { showPayments: !showPayments })
  }, [showPayments, me])

  const update = useUpdateDoc('questionnaire')
  const labelsContextData = useMemo(
    () => ({
      value: assessment?.mergedData,
    }),
    [assessment],
  )

  const { width, height } = useMemo(
    () => ({
      width: screenDims.width - (isMobile ? 16 : 60),
      height: screenDims.height - (isMobile ? 20 : 60),
    }),
    [screenDims, isMobile],
  )

  const editorWidth = useMemo(
    () =>
      appName === 'app'
        ? Math.min((width - (showPayments ? expandedPaymentsWidth : 0)) / 2, A4_DIMS_PIXELS[0])
        : 0,
    [width, showPayments, expandedPaymentsWidth, appName],
  )

  const infoWidth = useMemo(
    () => width - editorWidth - (showPayments ? expandedPaymentsWidth : 0),
    [width, editorWidth, showPayments, expandedPaymentsWidth],
  )

  return (
    <DefaultModal
      contentProps={{
        background: 'transparent',
        maxW: 'unset',
        height: `${height}px`,
        width: `${width}px`,
        maxH: 'unset',
      }}
      closeOnOverlayClick={false}
      overlayHeader
      onClose={onClose}
      bodyProps={{
        display: 'box',
        overflow: 'hidden',
        background: 'white',
        borderRadius: 8,
        width: '100%',
        height: '100%',
      }}
      isOpen={isOpen}
      render={() => (
        <FormLabelsContext.Provider value={labelsContextData}>
          <Flex position="absolute" top={0} left={0} h="100%" w="100%">
            {appName === 'app' ? (
              <AssessmentModalAssessmentView width={editorWidth} height={height} admin />
            ) : null}
            {assessmentId && selectedAssessment.populated ? (
              <AssessmentModalContainer width={`${infoWidth}px`}>
                <AssessmentAnswers
                  width={infoWidth}
                  onSave={onSaveAnswers}
                  onSubmit={async () => {
                    if (!me) return { error: 'Internal error' }
                    if (!id) return { error: 'no assessment id' }
                    return update(doc(ASSESSMENTS_REF, id), '', {
                      submittedOn: Date.now(),
                      submittedBy: me.id,
                      submittedByGroup: appName === 'app' ? 'admin' : 'practice',
                    })
                  }}
                  assessmentId={assessmentId}
                />
              </AssessmentModalContainer>
            ) : null}
            {selectedAssessment ? (
              <AssessmentModalContainer
                zIndex={2}
                position={isMobile ? 'absolute' : 'relative'}
                width={overrideValue ? `${expandedPaymentsWidth}px` : '0px'}
                // transition='width 400ms'
                right={0}>
                <HStack
                  width="100%"
                  bg="white"
                  px={3}
                  py={1}
                  color="gray.600"
                  borderTopRadius={6}
                  borderBottom="1px solid #cdcdcd">
                  <Text fontWeight={600} fontFamily="Open Sans">
                    Payments
                  </Text>
                </HStack>
                <Flex
                  width={`${expandedPaymentsWidth - 16}px`}
                  height="100%"
                  alignItems="flex-start"
                  py={2}
                  px={3}
                  position="relative"
                  bg="gray.50"
                  overflowY="auto">
                  <AssessmentPayments />
                </Flex>
                <Flex
                  align="flex-start"
                  position="absolute"
                  h="100%"
                  alignItems="center"
                  pointerEvents="none"
                  left={showPayments ? '-14px' : '-10px'}
                  w="24px">
                  <Tooltip label="Payments" placement="left">
                    <Button
                      zIndex={2}
                      p={0}
                      pointerEvents="auto"
                      size="sm"
                      // top={isMobile ? 'unset' : 2}
                      color={colors.green.hex}
                      onClick={toggleShowPayments}
                      borderRightRadius={0}
                      borderColor={colors.green.hex}
                      borderWidth="1px"
                      borderRightWidth={showPayments ? '0px' : '1px'}
                      bg="white"
                      fontSize="lg"
                      fontWeight={700}
                      position="absolute"
                      w={isMobile ? '24px' : '20px'}
                      minW="0"
                      height="40px">
                      $
                    </Button>
                  </Tooltip>
                </Flex>
              </AssessmentModalContainer>
            ) : null}
          </Flex>
        </FormLabelsContext.Provider>
      )}
    />
  )
}

import { Center, CircularProgress, HStack, StackProps, Text } from '@chakra-ui/react'
import { colors } from '@hb/shared'
import React, { PropsWithChildren } from 'react'

export const DocumentsHeader = ({
  children,
  numDocs,
  loading,
  stackProps,
}: PropsWithChildren<{
  numDocs: number
  loading: boolean
  stackProps?: StackProps
}>) => (
  <HStack borderTopRadius={6} bg="white" px={2} w="100%" {...stackProps}>
    <Text fontFamily="Hero-New" fontWeight={500} color="gray.600" py={2}>
      {children}
    </Text>
    <Center
      width="22px"
      height="22px"
      position="relative"
      textShadow="1px 1px 2px #00000055"
      borderRadius="full"
      bg={colors.green.hex}>
      {loading ? (
        <CircularProgress color="white" size={5} isIndeterminate />
      ) : (
        <Text
          fontSize="xs"
          textShadow="1px 1px 3px #00000077"
          fontFamily="Hero-New"
          fontWeight={600}
          position="relative"
          // top="2px"
          color="white">
          {numDocs}
        </Text>
      )}
    </Center>
  </HStack>
)

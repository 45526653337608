import {
  Box,
  Popover,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import {
  FieldMap,
  FieldMapValue,
  OnUploadProgress,
  ShareCorrections,
  UpdateCallback,
} from '@hb/shared'

import { FormApi, ValidationErrors } from 'final-form'
import React, { PropsWithChildren, useMemo } from 'react'
import { FormProps } from 'react-final-form'
import { SimpleForm } from '../forms/FinalForm/SimpleForm'
import { DefaultModal, DefaultModalProps } from '../Modals/DefaultModal'
import { DataView } from './DataView'

export type FieldInterface<T, Data extends FieldMapValue = FieldMapValue> = {
  header?: string
  loading?: boolean
  submitText?: string
  cancelText?: string
  data?: Data
  corrections?: Data & { correctionsShared?: ShareCorrections | null }
  readOnly?: boolean
  formProps?: Partial<FormProps<Data>>
  updateField?: (path: string, data?: Data) => Promise<UpdateCallback>
  baseStoragePath?: string | null
  adminView?: boolean
  closeOnSubmit?: boolean
  canSubmitClean?: boolean
  onSubmit: (
    data: Data,
    onUploadProgress: OnUploadProgress,
    form: FormApi<Data>,
  ) => Promise<UpdateCallback>
  validate?: (data: Data) => Promise<ValidationErrors> | ValidationErrors
} & T

export type EditModalProps<Data extends FieldMapValue = FieldMapValue> = PropsWithChildren<
  FieldInterface<Omit<DefaultModalProps, 'render'>, Data>
>

export type FormPopoverProps<Data extends FieldMapValue = FieldMapValue> = FieldInterface<
  PropsWithChildren<Omit<PopoverProps, 'children'>>,
  Data
> & {
  focusLock?: boolean
  field: FieldMap
}

export const FormPopover = <Data extends FieldMapValue>({
  data,
  onSubmit,
  children,
  field,
  ...props
}: FormPopoverProps<Data>) => (
  <Popover strategy="fixed" isLazy closeOnBlur={false} returnFocusOnClose {...props}>
    {({ onClose }) => (
      <>
        <PopoverTrigger>{children}</PopoverTrigger>
        <Portal>
          <PopoverContent width={['100%', 350, 500]}>
            <SimpleForm<Data>
              value={data}
              onSubmit={onSubmit}
              field={field}
              onCancel={onClose}
              closeOnSuccess
              submitText={props.submitText}
              theme="detailed"
            />
          </PopoverContent>
        </Portal>
      </>
    )}
  </Popover>
)

export type GenericEditModalProps<T extends FieldMapValue = FieldMapValue> = Omit<
  EditModalProps<T>,
  'children'
> & { field?: FieldMap }

export const GenericEditModal = <T extends FieldMapValue = FieldMapValue>({
  field,
  data,
  readOnly,
  canSubmitClean,
  adminView,
  submitText,
  corrections,
  onSubmit,
  baseStoragePath,
  closeOnSubmit = true,
  ...props
}: GenericEditModalProps<T>) => {
  const value = useMemo<T>(() => (field && data ? data : ({} as T)), [field, data])
  // close on submit success

  return (
    <DefaultModal
      size="2xl"
      overlayHeader
      closeOnOverlayClick={false}
      bodyProps={{
        bg: 'gray.50',
        borderRadius: 4,
      }}
      {...props}
      render={() => {
        if (!field) return <></>
        if (readOnly)
          return (
            <DataView
              alwaysExpanded
              childrenExpanded
              adminView={!!adminView}
              corrections={corrections}
              baseStoragePath={baseStoragePath}
              field={field}
              data={value}
            />
          )
        return field ? (
          <Box py={2} px={3}>
            <SimpleForm<T>
              readOnly={readOnly}
              onSubmit={onSubmit}
              canSubmitClean={canSubmitClean}
              closeOnSuccess={closeOnSubmit}
              onCancel={props.onClose}
              corrections={corrections}
              submitText={submitText}
              value={value}
              field={field}
            />
          </Box>
        ) : (
          <></>
        )
      }}
    />
  )
}

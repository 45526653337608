import { Center, Collapse, Divider, Flex, Text } from '@chakra-ui/react'
import {
  ASSESSMENTS,
  DropdownField,
  getPregnancyPlansArray,
  isDropdownOptionItem,
  PatientCoverageId,
  PopulatedAssessment,
  PopulatedUser,
} from '@hb/shared'
import { arrayUnion, doc, updateDoc } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { SolidActionButton, StandaloneInput } from '../..'
import { db } from '../../../backend/db'
import { PopUpMessageContext } from '../../../contexts'
import { usePatientPlanField } from '../../../hooks/insurance/fields'
import { DefaultModal } from '../../Modals/DefaultModal'

export const AddPregnancyPlanModal = ({
  onClose,
  onAddNew,
  user,
  assessmentId,
  assessment,
}: {
  onClose: () => void
  onAddNew: () => void
  user: PopulatedUser | null
  assessmentId: string
  assessment: PopulatedAssessment | null
}) => {
  const { insurancePlans } = user ?? {}
  const excludeExistingPlans = useCallback(
    (field: DropdownField): DropdownField => {
      if (!assessment) return field
      const { options: allOptions } = field
      const existingPlans = getPregnancyPlansArray(assessment.plans)
      const options = allOptions.filter(
        o => !existingPlans.some(p => isDropdownOptionItem(o) && p.id === o.id),
      )
      return { ...field, options }
    },
    [assessment],
  )

  const plansField = usePatientPlanField(insurancePlans, excludeExistingPlans)
  const { showError } = useContext(PopUpMessageContext)
  const [selectedPlanId, setSelectedPlanId] = useState<PatientCoverageId | null>(null)
  const [linkPlanLoading, setLinkPlanLoading] = useState(false)
  const confirmAddPlan = useCallback(async () => {
    if (!selectedPlanId || !assessmentId || !assessment) return
    const assessmentRef = doc(db, ASSESSMENTS, assessmentId)
    const currPlansArray = getPregnancyPlansArray(assessment.plans)
    if (currPlansArray.some(p => p.id === selectedPlanId)) {
      showError('Error linking plan', 'Plan already linked')
    }
    setLinkPlanLoading(true)
    try {
      await updateDoc(assessmentRef, 'potentialCoverageIds', arrayUnion(selectedPlanId))
      onClose()
    } catch (err: any) {
      showError('Error linking plan', err.message)
      setLinkPlanLoading(false)
    }
  }, [selectedPlanId, assessmentId, assessment, showError, onClose])
  const label = useMemo(() => {
    if (!selectedPlanId) return ''
    if (selectedPlanId === 'primary') return 'Primary'
    if (selectedPlanId === 'secondary') return 'Secondary'
    return 'Potential'
  }, [selectedPlanId])

  return (
    <DefaultModal
      isOpen
      overlayHeader
      isCentered={false}
      onClose={onClose}
      size="xl"
      contentProps={{ bg: 'gray.100' }}
      render={() => (
        <Flex py={2} px={3} w="100%" flexFlow="column">
          <Text py={1} fontSize="lg" fontWeight={600} color="gray.600">
            Add Plan
          </Text>
          <StandaloneInput
            theme="detailed"
            field={plansField}
            value={selectedPlanId}
            onChange={setSelectedPlanId}
          />
          <Collapse style={{ width: '100%' }} in={!!selectedPlanId}>
            <Flex w="100%" justify="flex-end" py={2}>
              <SolidActionButton
                isLoading={linkPlanLoading}
                onClick={confirmAddPlan}
                size="sm"
                colorScheme="green">
                Confirm Link {label} Plan
              </SolidActionButton>
            </Flex>
          </Collapse>
          <Center py={5} w="100%" position="relative">
            <Divider />
            <Text
              px={2}
              lineHeight={1}
              position="absolute"
              bg="gray.100"
              fontWeight={600}
              color="gray.600">
              OR
            </Text>
          </Center>
          <SolidActionButton onClick={onAddNew} size="sm">
            Add New Plan to Patient
          </SolidActionButton>
        </Flex>
      )}
    />
  )
}

import { DeleteIcon, SettingsIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Image,
  Input,
  Tab,
  TabList,
  Tabs,
  Tooltip,
} from '@chakra-ui/react'
import { colors, midwivesCollection } from '@hb/shared'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useCollection } from '../../collections'
import {
  deletedInvoicesTabs,
  ExtraInvoiceColumnProps,
  getInvoicesTabs,
  InvoiceDataListTab,
} from '../../collections/lists/invoices'
import { ScreenContext, SearchBarProvider } from '../../contexts'
import archiveIcon from '../../icons/archive.svg'
import { DataList } from '../DataList/DataList'
import { PracticeViewModal } from '../Modals/PracticeViewModal'
import { CollapseHorizontal } from '../shared'
import { PageContainer } from '../Views/PageContainer'
import { InvoiceSettingsView } from './InvoiceSettingsView'

import { CreateInvoiceModal } from './CreateInvoiceModal'
import { InvoicesViewProvider, useInvoicesView } from './InvoicesViewProvider'
import { InvoiceViewModal } from './InvoiceViewModal'

// const scheduledInvoiceColumns: Record<string, InvoiceColumn> = {
//   practiceName: practiceNameColumn,
//   // status: statusColumn,
//   scheduledFor: scheduledForColumn,
//   dueDate: dueDateColumn,
//   assessments: assessmentsColumn,
//   amount: amountDueColumn,
// }

// const sentInvoiceColumns: Record<string, InvoiceColumn> = {
//   invoiceNumber: {
//     ...practiceInvoiceNumberColumn,
//     width: 200,
//   },
//   ...scheduledInvoiceColumns,
// }

// const paidInvoiceColumns: Record<string, InvoiceColumn> = {
//   invoiceNumber: {
//     ...practiceInvoiceNumberColumn,
//     width: 200,
//   },
//   practiceName: practiceNameColumn,
//   paymentType: paymentTypeAndNotesColumn,
//   // status: statusColumn,
//   scheduledFor: scheduledForColumn,
//   dueDate: dueDateColumn,
//   assessments: assessmentsColumn,
//   amount: amountDueColumn,
// }

// const notArchivedFilter: CollectionFilter<PracticeInvoice> = ['archivedOn', '==', null]
// const archivedFilter: CollectionFilter<PracticeInvoice> = ['archivedOn', '!=', null]

// const scheduledInvoicesTabs: Record<string, InvoiceDataListTab> = {
//   scheduled: {
//     collection: 'invoices',
//     searchStringPath: 'searchString',
//     columns: scheduledInvoiceColumns,
//     filters: [['status', '==', 'scheduled'], notArchivedFilter],
//     defaultSortKey: 'practiceName',
//     itemName: 'Invoice',
//   },
// }

// const pendingApprovalInvoicesTabs: Record<string, InvoiceDataListTab> = {
//   pendingApproval: {
//     collection: 'invoices',
//     searchStringPath: 'searchString',
//     columns: scheduledInvoiceColumns,
//     defaultSortKey: 'practiceName',
//     filters: [['status', '==', 'pending-approval'], notArchivedFilter],
//     itemName: 'Invoice',
//   },
// }

// const sentInvoicesTabs: Record<string, InvoiceDataListTab> = {
//   sent: {
//     collection: 'invoices',
//     searchStringPath: 'searchString',
//     columns: sentInvoiceColumns,
//     defaultSortKey: 'invoiceNumber',
//     filters: [['status', '==', 'sent'], notArchivedFilter],
//     itemName: 'Invoice',
//   },
// }

// const paidInvoicesTabs: Record<string, InvoiceDataListTab> = {
//   paid: {
//     collection: 'invoices',
//     columns: paidInvoiceColumns,
//     searchStringPath: 'searchString',
//     defaultSortKey: 'invoiceNumber',
//     filters: [['status', '==', 'paid'], notArchivedFilter],
//     itemName: 'Invoice',
//   },
// }

// const archivedInvoicesTabs: Record<string, InvoiceDataListTab> = {
//   archived: {
//     searchStringPath: 'searchString',
//     collection: 'invoices',
//     columns: sentInvoiceColumns,
//     defaultSortKey: 'practiceName',
//     filters: [archivedFilter],
//     itemName: 'Invoice',
//   },
// }

const addCreateClickHandler = (
  tabs: Record<string, InvoiceDataListTab>,
  onCreateClick: () => void,
): Record<string, InvoiceDataListTab> => {
  const newTabs: Record<string, InvoiceDataListTab> = {}
  Object.entries(tabs).forEach(([key, tab]) => {
    newTabs[key] = {
      ...tab,
      creation: {
        field: { children: {}, name: 'New Invoice' },
        onCreateClick,
      },
    }
  })
  return newTabs
}

const InvoicesSelectedProviderView = () => {
  const { selectedPracticeId, selectPractice } = useInvoicesView()
  return selectedPracticeId ? (
    <PracticeViewModal onClose={() => selectPractice(null)} practiceId={selectedPracticeId} />
  ) : null
}

const InvoicesSelectedInvoiceView = () => {
  const { selectedInvoiceId } = useInvoicesView()
  return selectedInvoiceId ? <InvoiceViewModal /> : null
}

const GoToInvoiceByNumber = () => {
  const [invoiceNumber, setInvoiceNumber] = useState('')
  const { goToInvoiceByNumber } = useInvoicesView()
  const [loading, setLoading] = useState(false)
  const [focused, setFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const handleSubmit = useCallback(async () => {
    setLoading(true)
    await goToInvoiceByNumber(inputRef.current?.value ?? '')
    setInvoiceNumber('')
    setLoading(false)
  }, [goToInvoiceByNumber])

  // search on enter if focused
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleSubmit()
      }
    }
    if (focused) {
      window.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [focused, handleSubmit])

  return (
    <HStack>
      <Input
        placeholder="Invoice #"
        size="sm"
        bg="white"
        ref={inputRef}
        borderRadius={6}
        value={invoiceNumber}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        _focus={{
          // boxShadow: '0 0 0 1px #63b3ed',
          boxShadow: 'none',
        }}
        width="100px"
        onChange={e => {
          setInvoiceNumber(e.target.value)
        }}
      />
      <CollapseHorizontal width={50} in={!!invoiceNumber}>
        <Box p={1}>
          <Button
            isLoading={loading}
            onClick={handleSubmit}
            bg={colors.green.hex}
            color="white"
            size="sm">
            Go
          </Button>
        </Box>
      </CollapseHorizontal>
    </HStack>
  )
}

const InvoicesViewTabList = ({ onViewSettings }: { onViewSettings: () => void }) => (
  <TabList bg="gray.50" borderTopRadius={6}>
    <Tab>Pending Approval</Tab>
    <Tab>Current</Tab>
    <Center flex={1}>
      <GoToInvoiceByNumber />
    </Center>
    <HStack spacing={0} ml="auto">
      <Tab>Sent</Tab>
      <Tab>Paid</Tab>
      <Tooltip label="Settings">
        <Center px={2} h="100%">
          <IconButton
            size="sm"
            borderRadius="full"
            aria-label="Invoicing settings"
            onClick={onViewSettings}
            variant="ghost">
            <SettingsIcon width="20px" height="14px" color="gray.500" />
          </IconButton>
        </Center>
      </Tooltip>
      <Tab>
        <Tooltip label="Archived Invoices">
          <Image filter="grayscale(100%)" src={archiveIcon} width="20px" />
        </Tooltip>
      </Tab>
      <Tab h="100%">
        <Tooltip label="Deleted Invoices">
          <Center h="100%">
            <DeleteIcon width="20px" height="14px" color="gray.500" />
          </Center>
        </Tooltip>
      </Tab>
    </HStack>
  </TabList>
)

const InvoicesViewTabs = () => {
  const invoicesView = useInvoicesView()

  const { tabIndex, onTabSelect, setCreateInvoiceOpen } = invoicesView
  const { width: screenWidth, isMobile, contentHeight } = useContext(ScreenContext)
  const width = useMemo(
    () => Math.min(1600, screenWidth - (isMobile ? 20 : 50)),
    [screenWidth, isMobile],
  )
  const [viewingSettings, setViewingSettings] = useState(false)
  const listHeight = useMemo(() => contentHeight - (isMobile ? 20 : 70), [contentHeight, isMobile])

  const { tabs, rootPath } = useMemo(() => {
    switch (tabIndex) {
      case 1:
        return {
          tabs: addCreateClickHandler(getInvoicesTabs('scheduled', false, null), () =>
            setCreateInvoiceOpen(true),
          ),
          rootPath: 'invoices/current',
        }
      case 2:
        return {
          tabs: addCreateClickHandler(getInvoicesTabs('sent', false, null), () =>
            setCreateInvoiceOpen(true),
          ),
          rootPath: 'invoices/sent',
        }
      case 3:
        return {
          tabs: addCreateClickHandler(getInvoicesTabs('paid', false, null), () =>
            setCreateInvoiceOpen(true),
          ),
          rootPath: 'invoices/paid',
        }
      case 4:
        return {
          tabs: addCreateClickHandler(getInvoicesTabs(null, true, null), () =>
            setCreateInvoiceOpen(true),
          ),
          rootPath: 'invoices/archived',
        }
      case 5:
        return {
          tabs: addCreateClickHandler(deletedInvoicesTabs, () => setCreateInvoiceOpen(true)),
          rootPath: 'invoices/deleted',
        }
      default:
        return {
          tabs: addCreateClickHandler(getInvoicesTabs('pending-approval', false, null), () =>
            setCreateInvoiceOpen(true),
          ),
          rootPath: 'invoices/pending-approval',
        }
    }
  }, [tabIndex, setCreateInvoiceOpen])
  const practices = useCollection(midwivesCollection)
  const extraColumnProps = useMemo<ExtraInvoiceColumnProps>(
    () => ({ invoicesView, practices }),
    [invoicesView, practices],
  )

  return (
    <SearchBarProvider>
      <Tabs index={tabIndex} onChange={onTabSelect} colorScheme="green" width={width}>
        <InvoicesViewTabList onViewSettings={() => setViewingSettings(true)} />
        <Box position="relative" w={`${width}px`} height={`${listHeight}px`}>
          <DataList
            extraColumnProps={extraColumnProps}
            height={listHeight}
            width={width}
            tabs={tabs}
            rootPath={rootPath}
          />
          <InvoiceSettingsView isOpen={viewingSettings} onClose={() => setViewingSettings(false)} />
        </Box>
      </Tabs>
    </SearchBarProvider>
  )
}

export const InvoicesView = () => (
  <PageContainer>
    <InvoicesViewProvider>
      <InvoicesViewTabs />
      <InvoicesSelectedProviderView />
      <InvoicesSelectedInvoiceView />
      <CreateInvoiceModal />
    </InvoicesViewProvider>
  </PageContainer>
)

import { AnyObject, FieldMapValue, ShareCorrections, UpdateCallback } from '@hb/shared'
import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react'

export interface DataViewContextData {
  updateField?: (path: string, value: any) => Promise<UpdateCallback>
  rootValue?: FieldMapValue
  inGrid?: boolean
  width?: number
  adminView: boolean
  childrenExpanded?: boolean
}
// test
export const DataViewContext = createContext<DataViewContextData>({ adminView: false })

type OnShareCorrections = (path: string | null, updated: ShareCorrections | null) => Promise<void>
const ShareCorrectionsContext = createContext<{
  share: ShareCorrections | null
  onShare?: OnShareCorrections
}>({ share: null })

export const ShareCorrectionsProvider = ({
  shared,
  children,
  onShare,
}: PropsWithChildren<{
  shared: ShareCorrections | null
  onShare?: (path: string | null, updated: ShareCorrections | null) => Promise<void>
}>) => {
  const contextValue = useMemo(() => ({ share: shared, onShare }), [shared, onShare])
  return (
    <ShareCorrectionsContext.Provider value={contextValue}>
      {children}
    </ShareCorrectionsContext.Provider>
  )
}

export const useShareCorrections = () => useContext(ShareCorrectionsContext)

const CorrectionsContext = createContext<
  (FieldMapValue & { correctionsShared?: ShareCorrections | null }) | null
>(null)

export const CorrectionsProvider = ({
  corrections,
  children,
}: PropsWithChildren<{
  corrections: (AnyObject & { correctionsShared?: ShareCorrections | null }) | null
}>) => <CorrectionsContext.Provider value={corrections}>{children}</CorrectionsContext.Provider>

export const useCorrections = () => useContext(CorrectionsContext)

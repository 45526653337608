import { Box, Flex, Text } from '@chakra-ui/react'
import {
  Field,
  formatField,
  getDefaultPatientRelateCode,
  InsuranceCoverage,
  policyOwnerRelationshipField,
} from '@hb/shared'
import React, { useMemo } from 'react'
import { Condition } from '../../../../../forms/FinalForm/Condition'
import { FormElement } from '../../../../../forms/Input'
import { shouldUseCustomRelateCodeField } from '../fields'

export const RelateCodeInput = ({
  shouldUseCustomFieldId,
  customRelateCodeFieldId,
  coverage,
  customRelateCodeField,
}: {
  shouldUseCustomFieldId: string
  customRelateCodeFieldId: string
  customRelateCodeField: Field
  coverage: InsuranceCoverage | null | undefined
}) => {
  const policyOwner = useMemo(
    () => formatField(policyOwnerRelationshipField, coverage?.policyOwnerRelationship),
    [coverage],
  )
  const defaultRelateCode = useMemo(() => getDefaultPatientRelateCode(policyOwner), [policyOwner])
  return (
    <Flex w="100%" flexFlow="column" pt={2}>
      <FormElement name={shouldUseCustomFieldId} field={shouldUseCustomRelateCodeField} />
      <Condition condition={v => !v?.shouldUseCustomRelateCodePrimary} basePath="">
        <Box px={2}>
          <Text>Policy owner relationship: {policyOwner}</Text>
          <Text>Default relate code: {defaultRelateCode || 'None'}</Text>
        </Box>
      </Condition>
      <FormElement name={customRelateCodeFieldId} field={customRelateCodeField} />
    </Flex>
  )
}

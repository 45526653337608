import {
  CollectionItem,
  colors,
  getFullName,
  LocalCollectionState,
  PracticeUserAccess,
  UserRoleItem,
} from '@hb/shared'
import { useMemo } from 'react'
import { useAuth } from '../../../store/auth'

export const getAdminsColorText = (
  admins?: Array<CollectionItem<UserRoleItem> | CollectionItem<PracticeUserAccess>>,
) => admins?.map(a => getFullName(a)).join(', ') || 'No admins assigned'
export const getAdminsWithColor = (
  admins: LocalCollectionState<UserRoleItem> | undefined,
  color: string,
) => {
  const withColor = admins?.items.filter(a => a.adminColor === color)
  return {
    admins: withColor,
    text: getAdminsColorText(withColor),
    mapped: Object.values(colors).reduce<Record<string, any>>((acc, value) => {
      acc[value.hex] = admins?.items.filter(a => a.adminColor === value.hex) ?? []
      return acc
    }, {}),
  }
}

export const useAdminsWithColor = (
  admins: LocalCollectionState<UserRoleItem> | undefined,
  enabled?: boolean,
  color?: string | null,
) => {
  const admin = useAuth(s => s.admin)
  // const adminsWithColor = useMemo(
  //   () => (admin && color ? admins?.items.filter(a => a.adminColor === color) : []),
  //   [admins, color, admin],
  // )

  // const mapped = useMemo(
  //   () =>
  //     Object.values(colors).reduce(
  //       (acc, value) => {
  //         acc[value.hex] = admins?.items.filter(a => a.adminColor === value.hex) ?? []
  //         return acc
  //       },
  //       {} as Record<string, any>,
  //     ),
  //   [admins],
  // )

  return useMemo(
    () => (admin && color ? getAdminsWithColor(admins, color) : null),
    [admins, color, admin],
  )
}

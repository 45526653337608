import { CheckIcon, CloseIcon, RepeatIcon } from '@chakra-ui/icons'
import { Box, Button, Center, CircularProgress, Flex, HStack, Text } from '@chakra-ui/react'
import {
  colors,
  FieldTypes,
  FileDBValue,
  Template,
  templateKeyToCollection,
  TextField,
  WithId,
} from '@hb/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { useFile } from '../../../hooks'
import { Editable } from '../../forms'
import { PdfView } from '../../PdfView'
import { CollapseHorizontal } from '../../shared/CollapseHorizontal'
import { SmallFileUpload } from '../../shared/SmallFileUpload'
import { useTemplateEditor } from '../contexts/editor'
import { ExternalPdfAddField } from './AddField'
import { ExternalPdfTemplateEditorProvider, useExternalPdfTemplateEditor } from './context'

const templateNameField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Template Name',
}

const ExternalPdfEditHeader = ({
  onClose,
  onUploadingBasePdf,
  fetchFile,
}: {
  onClose?: () => void
  onUploadingBasePdf: (status: boolean) => void
  fetchFile: () => void
}) => {
  const {
    externalPdf,
    externalPdfChanged,
    submitExternalPdfChanges,
    submittingChanges,
    resetExternalPdf,
  } = useExternalPdfTemplateEditor()

  const { template, onUpdateField, type } = useTemplateEditor()
  const { name } = template ?? {}
  const storagePath = useMemo(() => {
    if (!template) return ''
    return (
      externalPdf?.baseFile?.storagePath ??
      `${templateKeyToCollection[type]}/${template.id}/base.pdf`
    )
  }, [externalPdf, template, type])

  const handleUploadPdf = useCallback(
    async (v: FileDBValue | null) => {
      onUploadingBasePdf(true)
      const res = await onUpdateField('externalPdf.baseFile', v)
      fetchFile()
      onUploadingBasePdf(false)
      return res
    },
    [onUpdateField, fetchFile, onUploadingBasePdf],
  )

  return (
    <Flex bg="gray.50" borderBottom={'1px solid #cdcdcd'} height="70px" w="100%">
      <Flex flexFlow="column" h="100%" flex={1} minW="0">
        <Flex w="100%" align="center" h="36px">
          <Flex
            align="center"
            pl={2}
            bg="gray.100"
            h="100%"
            borderRight="1px solid #cdcdcd"
            borderBottom="1px solid #cdcdcd">
            <Editable
              style={{ width: '320px', padding: 0 }}
              field={templateNameField}
              inputStyle={{
                fontSize: '1.2rem',
                position: 'relative',
                top: '3px',
                fontFamily: 'Hero-New',
              }}
              dataCellProps={{
                fontSize: '0.9rem',
                lineHeight: 1,
                height: '36px',
                fontFamily: 'Hero-New',
              }}
              onSubmit={v => onUpdateField('name', v)}
              value={name}
            />
          </Flex>
          <Flex
            align="center"
            h="100%"
            bg="white"
            borderBottom="1px solid #cdcdcd"
            borderRight="1px solid #cdcdcd"
            px={2}>
            <SmallFileUpload
              label="Base PDF"
              accept="application/pdf"
              storagePath={storagePath}
              value={externalPdf.baseFile}
              onUpload={handleUploadPdf}
            />
          </Flex>
          {onClose ? (
            <Box ml="auto" pr={2}>
              <Button
                gap={1}
                variant="outline"
                bg="whiteAlpha.600"
                color="gray.500"
                size="xs"
                onClick={onClose}>
                <CloseIcon w={2} h={2} />
                <Text lineHeight={1}>CLOSE</Text>
              </Button>
            </Box>
          ) : null}
        </Flex>
        <Flex w="100%" px={3} align="center" flex={1}>
          <ExternalPdfAddField />
          <CollapseHorizontal width={180} in={!!externalPdfChanged}>
            <Flex width="160px" align="center" gap={1.5}>
              <Button size="xs" gap={1} variant="outline" onClick={resetExternalPdf}>
                <Text>RESET</Text>
                <RepeatIcon />
              </Button>
              <Button
                size="xs"
                bg="green.500"
                gap={1}
                color="white"
                _hover={{ bg: 'green.400' }}
                onClick={submitExternalPdfChanges}
                isLoading={submittingChanges}>
                <Text>SAVE</Text>
                <CheckIcon />
              </Button>
            </Flex>
          </CollapseHorizontal>
        </Flex>
      </Flex>
    </Flex>
  )
}

const BaseExternalPdfTemplateEditor = ({
  width,
  height,
  header,
  onClose,
}: {
  width: number
  height: number
  header?: React.ReactNode | null
  onClose?: () => void
}) => {
  const { externalPdf } = useExternalPdfTemplateEditor()
  const { url, fetchFile } = useFile({ path: externalPdf?.baseFile.storagePath })
  const [uploadingBasePdf, setUploadingBasePdf] = useState(false)

  if (!externalPdf) {
    return (
      <Center w={`${width}px`} h={`${height}px`}>
        <Box>External PDF not found</Box>
      </Center>
    )
  }
  if (uploadingBasePdf) {
    return (
      <Center w={`${width}px`} h={`${height}px`}>
        <HStack>
          <CircularProgress color={colors.green.hex} size={8} isIndeterminate />
          <Box>Uploading PDF</Box>
        </HStack>
      </Center>
    )
  }
  return (
    <Box overflow="hidden" width={`${width}px`} height={`${height}px`}>
      <PdfView attachments={externalPdf?.attachments} height={height} width={width} url={url}>
        <Box w="100%">
          {header}
          <ExternalPdfEditHeader
            fetchFile={fetchFile}
            onUploadingBasePdf={setUploadingBasePdf}
            onClose={onClose}
          />
        </Box>
      </PdfView>
    </Box>
  )
}

export const ExternalPdfTemplateEditor = ({
  template,
  ...props
}: {
  onClose?: () => void
  width: number
  height: number
  header?: React.ReactNode | null
  template: WithId<Template> | null
}) => {
  const { externalPdf } = template ?? {}
  if (!template || !externalPdf) {
    return <Box>Template base PDF not found</Box>
  }
  return (
    <ExternalPdfTemplateEditorProvider template={template} externalPdf={externalPdf}>
      <BaseExternalPdfTemplateEditor {...props} />
    </ExternalPdfTemplateEditorProvider>
  )
}

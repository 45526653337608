import { AddIcon, CloseIcon } from '@chakra-ui/icons'
import {
  Box,
  CircularProgress,
  Divider,
  Flex,
  HStack,
  IconButton,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { capitalizeFirstLetter, colors, ThreadTab } from '@hb/shared'
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ScreenContext, SearchBarContext, SearchBarContextData, useApp } from '../../contexts'
import { useMe } from '../../hooks'
import groupsIcon from '../../icons/groups.svg'
import { SearchBar } from '../DataList/SearchBar'
import { CollapseHorizontal } from '../shared'
import { getBadgeColor } from '../Users'
import { THREAD_LIST_HEIGHT } from './constants'
import { useThreadsList } from './contexts'
import { CreateThreadView } from './CreateThreadView'
import { DeepSearchThreads } from './DeepSearchThreads'
import { SettingsPopover } from './Settings'
import { ThreadPreview, ThreadView } from './ThreadView/ThreadView'

const ThreadTabButton = ({
  onClick,
  label,
  icon,
  threadTab,
  numUnread,
  selected,
}: {
  onClick: () => void
  label: string
  numUnread: number
  selected: boolean
  threadTab: ThreadTab
  icon: (props: { selected: boolean }) => ReactElement
}) => {
  const { appName } = useApp()
  const threadTypeColor = useMemo(() => getBadgeColor(appName, threadTab), [threadTab, appName])
  const { isMobile } = useContext(ScreenContext)
  return (
    <Tooltip
      textShadow="1px 1px 3px #000000cc"
      placement="right"
      hasArrow
      bg={threadTypeColor}
      label={label}>
      <Box filter="drop-shadow(1px 1px 3px black)" bg="white" borderRadius={6} position="relative">
        <IconButton
          transition="background 300ms, color 300ms"
          onClick={onClick}
          aria-label={label}
          size={isMobile ? 'sm' : 'md'}
          _hover={{ bg: `${threadTypeColor}${selected ? '88' : '44'}` }}
          _active={{ boxShadow: '1px 1px 4px black' }}
          _selected={{ boxShadow: '1px 1px 4px black' }}
          bg={selected ? threadTypeColor : 'gray.100'}
          borderRadius={6}
          color={selected ? 'white' : 'gray.500'}
          icon={icon({ selected })}
        />
        {numUnread ? (
          <Box
            position="absolute"
            top="-5px"
            left="-5px"
            bg="gray.50"
            boxShadow={'0 0 4px #ccbbff'}
            color="green.700"
            borderRadius="50%"
            width="20px"
            height="20px"
            // textShadow="1px 1px 4px black"
            fontWeight={600}
            display="flex"
            lineHeight={1}
            justifyContent="center"
            alignItems="center"
            fontSize="sm">
            {numUnread}
          </Box>
        ) : null}
      </Box>
    </Tooltip>
  )
}

const ImageTabSelectIcon = ({ selected, src }: { selected: boolean; src: string }) => (
  <img
    src={src}
    alt=""
    style={{
      width: '20px',
      height: '20px',
      transition: 'all 0.3s',
      opacity: selected ? 1 : 0.5,
      filter: `invert(${selected ? 1 : 0}) drop-shadow(0 0 3px ${selected ? '#00000099' : '#00000000'})`,
    }}
  />
)

const ThreadsTabSelect = () => {
  const { appName } = useApp()
  const me = useMe()
  const {
    widget: { data, update, tabsVisible: visible },
    threads: { data: threads },
  } = useThreadsList()
  const { tab } = data ?? {}
  const setTab = useCallback((updated: ThreadTab) => update({ tab: updated }), [update])
  const numUnreadAdmin = useMemo(
    () =>
      threads.filter(
        t => t.thread.accessLevel === 'admin' && t.thread.unreadBy.includes(me?.uid ?? ''),
      ).length,
    [threads, me],
  )

  const numUnreadPractice = useMemo(
    () =>
      threads.filter(
        t => t.thread.accessLevel === 'practice' && t.thread.unreadBy.includes(me?.uid ?? ''),
      ).length,
    [threads, me],
  )

  // const numUnreadPatient = useMemo(
  //   () => threads.filter(
  //     (t) => t.thread.accessLevel === 'patient' && t.thread.unreadBy.includes(me?.uid ?? ''),
  //   ).length,
  //   [threads, me],
  // )

  // const numUnreadRecent = useMemo(
  //   () => threads.filter((t) => t.thread.unreadBy.includes(me?.uid ?? '')).length,
  //   [me, threads],
  // )

  return (
    <VStack
      top={8}
      spacing={1}
      pointerEvents={!visible ? 'none' : 'auto'}
      opacity={!visible ? 0 : 1}
      transition="all 300ms"
      zIndex={-1}
      position="absolute"
      right={!visible ? '85%' : '101%'}>
      {/* <ThreadTabButton
        icon={({ selected }) => (
          <ImageTabSelectIcon selected={selected} src={scheduleIcon} />
        )}
        label="Recent"
        selected={tab === 'recent'}
        numUnread={numUnreadRecent}
        onClick={() => setTab('recent')}
      /> */}
      {appName === 'app' ? (
        <ThreadTabButton
          threadTab="admin"
          icon={({ selected }) => (
            <Text textShadow={`1px 1px 3px ${selected ? '#00000077' : '#00000000'}`}>HB</Text>
          )}
          label="Admin Chats"
          numUnread={numUnreadAdmin}
          selected={tab === 'admin'}
          onClick={() => setTab('admin')}
        />
      ) : null}
      <ThreadTabButton
        threadTab="practice"
        icon={({ selected }) => <ImageTabSelectIcon selected={selected} src={groupsIcon} />}
        label="Practice Chats"
        numUnread={numUnreadPractice}
        selected={tab === 'practice'}
        onClick={() => setTab('practice')}
      />
      {/* <ThreadTabButton
        icon={({ selected }) => (
          <ImageTabSelectIcon selected={selected} src={groupIcon} />
        )}
        label="Patient Chats"
        numUnread={numUnreadPatient}
        selected={tab === 'patient'}
        onClick={() => setTab('patient')}
      /> */}
    </VStack>
  )
}

const ThreadsListSelectedThread = () => {
  const {
    widget: { data: widgetState, creatingNewThread },
    isOpen: threadsListOpen,
  } = useThreadsList()
  const { thread: threadSelection } = widgetState ?? {}
  const isOpen = useMemo(
    () => !!(threadsListOpen && threadSelection && !creatingNewThread),
    [threadSelection, creatingNewThread, threadsListOpen],
  )
  const { isMobile } = useContext(ScreenContext)
  const { width: screenWidth } = useContext(ScreenContext)
  const width = useMemo(() => Math.min(screenWidth - 16, 450), [screenWidth])
  return (
    <CollapseHorizontal
      position="relative"
      width={width}
      in={isOpen}
      borderRadius={6}
      withShadow
      style={{
        zIndex: 2,
        marginLeft: isOpen && !isMobile ? '8px' : '0px',
        overflow: 'visible',
        pointerEvents: isOpen ? 'auto' : 'none',
      }}>
      <ThreadsListThreadView width={width} />
    </CollapseHorizontal>
  )
}

const ThreadsListSearchBar = () => {
  const {
    widget: { updateSearchQuery, searchQuery },
    threads: { deepSearchLoading, deepSearchResults, deepSearchThreads },
  } = useThreadsList()

  const inputRef = useRef<HTMLInputElement>(null)
  const [focused, setFocused] = useState(false)
  const contextData = useMemo<SearchBarContextData>(
    () => ({
      query: searchQuery,
      updateQuery: updateSearchQuery,
      searchQuery,
      focused,
      setFocusedState: setFocused,
      inputRef,
    }),
    [searchQuery, updateSearchQuery, focused, inputRef],
  )
  useEffect(() => {
    if (!focused) return () => {}
    const onEnter = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        deepSearchThreads()
      }
    }
    document.addEventListener('keypress', onEnter)
    return () => {
      document.removeEventListener('keypress', onEnter)
    }
  }, [deepSearchThreads, focused])

  const tooltipText = useMemo(() => {
    if (deepSearchResults) {
      return deepSearchResults.length ? '' : 'No threads found'
    }
    return deepSearchLoading ? 'Searching threads...' : 'Press enter to find in chat messages'
  }, [deepSearchLoading, deepSearchResults])
  return (
    <SearchBarContext.Provider value={contextData}>
      <Flex flexFlow="column" w="100%">
        <Tooltip
          isOpen={focused && !!searchQuery}
          color="gray.600"
          placement="top"
          bg="gray.50"
          hasArrow
          label={tooltipText}>
          <HStack w="100%" borderBottom="1px solid #bcbcbc" bg="gray.100">
            <SearchBar
              autoSubmit
              itemName="thread by name or member"
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
          </HStack>
        </Tooltip>
      </Flex>
    </SearchBarContext.Provider>
  )
}

const ThreadsListThreads = ({ onClose }: { onClose?: () => void }) => {
  const {
    goToThread,
    widget: { data: widgetState, loading, creatingNewThread, setCreatingNewThread },
    threads: { selectedTabThreads, deepSearchLoading, deepSearchResults },
  } = useThreadsList()
  const { thread: threadSelection, tab: tabSelection } = widgetState ?? {}
  const { appName } = useApp()
  const threadTypeColor = useMemo(
    () => (tabSelection ? getBadgeColor(appName, tabSelection) : 'gray.300'),
    [appName, tabSelection],
  )

  const { isMobile, width: screenWidth } = useContext(ScreenContext)

  const isOpen = useMemo(
    () => !isMobile || (!threadSelection && !creatingNewThread),
    [isMobile, threadSelection, creatingNewThread],
  )
  const width = useMemo(
    () => Math.min(screenWidth - 16 - (appName === 'app' ? 42 : 0), 400),
    [screenWidth, appName],
  )
  return (
    <>
      <Flex position="relative" height={THREAD_LIST_HEIGHT}>
        <CollapseHorizontal
          withShadow
          in={isOpen}
          width={width}
          height={THREAD_LIST_HEIGHT}
          style={{ zIndex: 2, borderRadius: 6 }}>
          <Flex flexFlow="column" w="100%" h={THREAD_LIST_HEIGHT} borderRadius={6}>
            <HStack
              borderTopRadius={6}
              bg={threadTypeColor}
              color="white"
              px={2}
              userSelect="none"
              borderBottom="1px solid #ababab"
              w="100%">
              <CollapseHorizontal in={loading} height="36px" width={30}>
                <CircularProgress size={5} color={colors.green.hex} isIndeterminate />
              </CollapseHorizontal>
              <Text fontWeight={500} textShadow="1px 1px 3px black" fontFamily="Hero-New">
                {appName === 'app' && tabSelection ? `${capitalizeFirstLetter(tabSelection)} ` : ''}
                Messages
              </Text>
              <HStack ml="auto">
                <SettingsPopover />
                <Tooltip
                  color="white"
                  textShadow="1px 1px 3px #000000cc"
                  placement="top"
                  hasArrow
                  bg={threadTypeColor}
                  label="Create New Thread">
                  <IconButton
                    size="xs"
                    onClick={() => setCreatingNewThread(true)}
                    bg="blackAlpha.300"
                    _hover={{ bg: 'blackAlpha.500' }}
                    color="white"
                    aria-label="Create Thread"
                    icon={<AddIcon />}
                  />
                </Tooltip>
                {onClose ? (
                  <IconButton
                    size="xs"
                    bg="blackAlpha.300"
                    _hover={{ bg: 'blackAlpha.500' }}
                    color="white"
                    aria-label="Close"
                    icon={<CloseIcon />}
                    onClick={onClose}
                  />
                ) : null}
              </HStack>
            </HStack>
            <ThreadsListSearchBar />
            <VStack
              bg="gray.100"
              borderBottomRadius={6}
              minH="300px"
              w="100%"
              flex={1}
              spacing={0}
              divider={<Divider />}
              overflowY="auto">
              <DeepSearchThreads />
              {selectedTabThreads.length ? (
                selectedTabThreads.map(thread => (
                  <ThreadPreview
                    onClick={() => {
                      goToThread({
                        id: thread.threadId,
                        type: thread.threadType,
                        title: thread.thread.title ?? '',
                      })
                    }}
                    key={thread.threadId}
                    thread={{ ...thread.thread, id: thread.threadId }}
                  />
                ))
              ) : (
                <Text userSelect="none" p={2} color="gray.600" fontStyle="italic">
                  {deepSearchLoading || deepSearchResults
                    ? ''
                    : `No ${widgetState?.tab ? `${widgetState?.tab} ` : ''}threads yet`}
                </Text>
              )}
            </VStack>
          </Flex>
        </CollapseHorizontal>
        {appName === 'app' ? <ThreadsTabSelect /> : null}
      </Flex>
      <CreateThreadView isOpen={creatingNewThread} onClose={() => setCreatingNewThread(false)} />
    </>
  )
}

const ThreadsListThreadView = ({ width }: { width: number }) => {
  const {
    widget: { data: widgetData, update: updateWidgetState, searchResult },
  } = useThreadsList()
  const { thread: threadSelection } = widgetData ?? {}

  return threadSelection ? (
    <ThreadView
      fromSearch={!!searchResult}
      key={threadSelection.id}
      isOpen
      width={width}
      threadId={threadSelection.id}
      threadType={threadSelection.type}
      onBack={() => updateWidgetState({ thread: null })}
    />
  ) : null
}

export const MyThreadsList = ({ onClose }: { onClose?: () => void }) => (
  <Flex align="flex-end" h="auto" w="auto">
    <ThreadsListThreads onClose={onClose} />
    <ThreadsListSelectedThread />
  </Flex>
)

import { Flex } from '@chakra-ui/react'
import { Note } from '@hb/shared'
import React, { useCallback, useContext } from 'react'
import { updateSharedNote } from '../../backend'
import { useApp } from '../../contexts/AppContext'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { ProfileContext } from '../../contexts/ProfileContext'
import { useMobileLayout } from '../../hooks/useMobileLayout'
import { NoteForm } from '../forms/NoteForm'

export const AssessmentNote = () => {
  const {
    selectedAssessment: { populated: selectedAssessment },
    assessmentId,
  } = useContext(ProfileContext)
  const { sharedNote } = selectedAssessment ?? {}
  const { appName } = useApp()
  const { processResponse } = useContext(PopUpMessageContext)

  const handleSubmitSharedNote = useCallback(
    async (data: Note) => {
      if (!assessmentId) return processResponse({ error: 'No assessment selected' })
      const { text } = data
      try {
        await updateSharedNote({
          appName,
          assessmentId,
          note: text,
          noteId: 'sharedNote',
        })
        return processResponse({ success: 'Note saved' })
      } catch (err: any) {
        return processResponse({ error: err.message || 'Error saving note' })
      }
    },
    [appName, assessmentId, processResponse],
  )

  const { mobileLayout, ref: contentRef } = useMobileLayout(700)
  return (
    <Flex gap={2} flexFlow={mobileLayout ? 'column' : 'row'} ref={contentRef} w="100%">
      <NoteForm
        onSubmit={handleSubmitSharedNote}
        placeholder="Shared note (visible to HB and practice)"
        note={sharedNote}
      />
    </Flex>
  )
}

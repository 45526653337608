import { ChevronUpIcon } from '@chakra-ui/icons'
import {
  Button,
  Circle,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stat,
  StatArrow,
  Text,
} from '@chakra-ui/react'
import { colors, WithId } from '@hb/shared'
import React, { useContext, useMemo } from 'react'
import { DataListContext } from '../../contexts'
import sortIcon from '../../icons/sort_green.svg'
import { SvgIcon } from '../../icons/SvgIcon'
import { DataColumn } from '../../types/data'

export const RowHeader = <Snippet extends WithId>({
  Header: Component,
  active,
  onClick,
  onDirectionClick,
  flexProps,
  width,
  asc,
}: DataColumn<Snippet> & {
  onClick?: () => void
  asc?: boolean
  active: boolean
  onDirectionClick?: () => void
}) => (
  <Flex
    // bg="rgb(250,250,250)"
    // borderBottom="1px solid #cdcdcd"
    py={1.5}
    align="center"
    {...flexProps}
    width={`${width}px`}
    transition="width 300ms">
    <Flex
      onClick={onClick}
      cursor={onClick ? 'pointer' : 'auto'}
      fontWeight={500}
      fontSize="sm"
      opacity={active ? 1 : 0.8}
      width="100%">
      <Component />
    </Flex>
    {onClick ? (
      <Flex
        justify="flex-end"
        minW={active ? '20px' : 0}
        w={active ? '20px' : 0}
        transition="all 333ms"
        opacity={active ? 1 : 0}>
        <Stat>
          <StatArrow
            onClick={onDirectionClick}
            transition="width 333ms"
            width={3}
            height={3}
            cursor="pointer"
            _hover={{ color: 'blackAlpha.900' }}
            color={active ? 'blackAlpha.800' : 'blackAlpha.600'}
            type={asc ? 'increase' : 'decrease'}
          />
        </Stat>
      </Flex>
    ) : null}
  </Flex>
)

export const DesktopRowHeaders = ({
  keys,
  columns,
  flexColWidth,
}: {
  keys: Array<string>
  columns: Record<string, DataColumn<any>>
  flexColWidth: number
}) => (
  <>
    {keys.map((k, idx) => (
      <RowHeader
        key={k}
        active
        {...columns[k]}
        width={(columns[k].width ?? flexColWidth) + (idx === keys.length - 1 ? 5 : 0)}
      />
    ))}
  </>
)

// headers in a menu for mobile
export const MobileRowHeaders = ({
  keys,
  columns,
}: {
  keys: Array<string>
  columns: Record<string, DataColumn<any>>
}) => {
  const { sortBy, toggleSortDirection, sort } = useContext(DataListContext)
  const sortColumn = useMemo(
    () => Object.values(columns).find(c => c.sortKey === sort.field),
    [columns, sort],
  )

  const withSortKeys = useMemo(
    () =>
      keys
        .filter(k => !!columns[k].sortKey)
        .map(k => columns[k] as DataColumn<any> & { sortKey: string }),
    [columns, keys],
  )

  return (
    <Flex
      align="center"
      bg="gray.50"
      borderBottom="1px solid #cdcdcd"
      px={2}
      py={1}
      gap={2}
      w="100%">
      <Menu>
        <MenuButton
          textAlign="left"
          size="sm"
          gap={2}
          fontSize="md"
          boxShadow="1px 1px 3px #00000055"
          bg="white"
          display="flex"
          justifyContent="flex-start"
          as={Button}
          alignItems="center">
          <Flex gap={2} align="center">
            <SvgIcon src={sortIcon} w="20px" />
            {sortColumn?.title ? (
              <span style={{ color: colors.green.hex, marginLeft: 'auto' }}>
                {sortColumn.title}
              </span>
            ) : null}
          </Flex>
        </MenuButton>
        <MenuList>
          {withSortKeys.map(({ sortKey: k, title, defaultSortDirection }) => (
            <MenuItem
              onClick={() => sortBy(k, defaultSortDirection)}
              fontSize="sm"
              fontFamily="Open Sans"
              fontWeight={600}
              color="gray.600"
              key={k}>
              {title}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <Button
        ml="auto"
        size="sm"
        onClick={toggleSortDirection}
        bg="transparent"
        color={colors.green.hex}
        px={2}
        _hover={{
          bg: 'transparent',
        }}
        _active={{
          bg: 'transparent',
        }}>
        <Text fontSize="xs">{sort.order === 'desc' ? 'DESCENDING' : 'ASCENDING'}</Text>
        <Circle size={5} bg={colors.green.hex} ml={2}>
          <ChevronUpIcon
            transform={`scale(1, ${sort.order === 'desc' ? -1 : 1})`}
            filter="drop-shadow(1px 1px 3px #00000077)"
            color="white"
          />
        </Circle>
      </Button>
    </Flex>
  )
}

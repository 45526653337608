import { AnyObject, BaseQuery } from '@hb/shared'
import {
  collection,
  CollectionReference,
  Firestore,
  orderBy,
  Query,
  query,
  where,
} from 'firebase/firestore'

export const toFirestoreQuery = <T extends AnyObject>(
  db: Firestore,
  baseQuery: BaseQuery<T>,
): Query<T> => {
  const { filters, sort, collection: collectionPath } = baseQuery
  const collectionRef = collection(db, collectionPath) as CollectionReference<T>
  let q = query(collectionRef)
  if (filters) {
    filters.forEach(([fieldPath, opStr, value]) => {
      q = query(q, where(fieldPath, opStr, value))
    })
  }
  if (sort) {
    sort.forEach(({ field, order }) => {
      q = query(q, orderBy(field, order))
    })
  }

  return q
}

import { CollectionFilter } from '../../types/data'
import { UserRoleItem } from '../../types/users/role'
import { adminCollectionAccess } from '../constants'
import { USER_ROLES } from '../names'
import { Collection } from '../types/collection'

export const adminsFilter: CollectionFilter<UserRoleItem> = ['role', 'in', ['admin', 'super-admin']]
export const adminsCollection: Collection<UserRoleItem> = {
  id: 'admins',
  _type: 'firestoreCollection',
  name: 'Admins',
  refPath: USER_ROLES,
  filters: [adminsFilter],
  access: adminCollectionAccess,
  noRanks: true,
  defaultSort: 'lname',
}

import { ChatIcon } from '@chakra-ui/icons'
import { Badge, Button, Center, Flex, Spinner, Stack, Text, Tooltip } from '@chakra-ui/react'
import {
  Claim,
  CLAIMS,
  colors,
  getInovalonClaimStatusColor,
  getOptionFromId,
  getReverseName,
} from '@hb/shared'

import React from 'react'
import { Link } from 'react-router-dom'
import { optionalPracticeSelectField } from '../../../collections/fields/practice'
import { useLogInfo } from '../../../hooks'
import { DataColumn, DataColumns } from '../../../types/data'
import { DisplayButtonBase } from '../../Buttons/DisplayButton'
import { EllipsisText } from '../../EllipsisText'
import { serviceTypeField } from '../../Users/Profile/Claims/Ability/fields'
import { IdFieldSelectHeader } from '../PresetFilters'
import { SortButton } from '../SortButton'
// import { claimsAssignedAdminsColumn } from './users/AssignedAdminsPopover'
import { LogColumnPopover } from './users/LogColumnPopover'
import {
  getAssignedAdminsColumn,
  getDeliveredOnColumn,
  getOverduePaymentColumn,
  getRenderItemNextAction,
  getUnreadMessagesColumn,
  insurerColumn,
} from './users/shared'

type ClaimColumn = DataColumn<Claim>

const nameColumn: ClaimColumn = {
  title: 'Patient Name',
  Header: () => (
    <Flex align="center">
      <SortButton sortKey="lname">PATIENT NAME</SortButton>
    </Flex>
  ),
  sortKey: 'lname',
  width: 200,
  Render: ({ data: claim }) => {
    const { id, abilitySnippet } = claim
    const { TransStatus: status } = abilitySnippet ?? {}
    const statusColor = getInovalonClaimStatusColor(status)
    return (
      <Flex mb={1}>
        <Link style={{ opacity: 1 }} to={`/admin/claims/${id}/claims`}>
          <Tooltip placement="top-start" bg={statusColor} color="white" label={status}>
            <Button fontWeight={500} justifyContent="flex-start" fontSize="md" variant="link">
              <Text
                textAlign="left"
                w="200px"
                color={statusColor}
                textOverflow="ellipsis"
                overflow="hidden">
                {getReverseName(claim)}
              </Text>
            </Button>
          </Tooltip>
        </Link>
      </Flex>
    )
  },
}

const statusColumn: ClaimColumn = {
  title: 'Status',
  Header: () => (
    <Flex align="center">
      <SortButton sortKey="status">STATUS</SortButton>
    </Flex>
  ),
  width: 120,
  sortKey: 'status',
  Render: ({ data: claim }) => {
    const { abilitySnippet } = claim
    const { TransStatus: status } = abilitySnippet ?? {}
    const statusColor = getInovalonClaimStatusColor(status)
    return (
      <Flex mb={1}>
        <Text
          textAlign="left"
          w="200px"
          color={statusColor}
          textOverflow="ellipsis"
          overflow="hidden">
          {status || 'N/A'}
        </Text>
      </Flex>
    )
  },
}

export const claimLogColumn: ClaimColumn = {
  title: 'Log',
  Header: () => <LogColumnPopover />,
  width: 50,
  isLazy: true,
  Render: ({ data: claim, cell, isScrolling, preview: { openPreview } }) => {
    const { log, id } = claim ?? {}
    const { lastUpdated } = useLogInfo(log)
    if (isScrolling) {
      return (
        <Center w="100%">
          <Spinner />
        </Center>
      )
    }

    return (
      <Stack flex={1} direction="row">
        <DisplayButtonBase
          onClick={e =>
            openPreview({
              id,
              item: 'log',
              position: { x: e.clientX, y: e.clientY },
              cell,
            })
          }
          aria-label="log"
          icon={<ChatIcon color={lastUpdated ? 'blue.400' : 'gray'} />}
        />
      </Stack>
    )
  },
}
export const claimLinkedColumn: ClaimColumn = {
  title: 'System',
  Header: () => <Text>SYSTEM</Text>,
  // sortKey: 'abilityId',
  Render: ({ data }) => {
    const { abilityId } = data
    return (
      <Badge color={abilityId ? colors.green.hex : colors.orange.hex}>
        {abilityId ? 'ABILITY' : 'LEGACY'}
      </Badge>
    )
  },
}

export const claimServiceTypeColumn: ClaimColumn = {
  title: 'Service Type',
  Header: () => (
    <Flex align="center">
      <SortButton sortKey="serviceType">SERVICE TYPE</SortButton>
    </Flex>
  ),
  sortKey: 'serviceType',
  Render: ({ data }) => {
    const { serviceType, serviceTypeOther, nullified } = data ?? {}
    let text = ''
    if (serviceType === 'other') {
      text = `Other (${serviceTypeOther || 'Not specified'})`
    } else {
      text = serviceType
        ? (getOptionFromId(serviceTypeField.options, serviceType)?.text ??
          serviceType ??
          'Not specified')
        : 'Not specified'
    }
    return (
      <Flex gap={2} align="center">
        <Text>{text}</Text>
        {nullified ? <Badge colorScheme="red">NULLIFIED</Badge> : null}
      </Flex>
    )
  },
}

const providerColumn: ClaimColumn = {
  title: 'Provider',
  Header: () => (
    <IdFieldSelectHeader
      propName="midwifeId"
      sortPropName="provider"
      defaultDir="asc"
      label="PROVIDER"
      field={optionalPracticeSelectField}
    />
  ),
  sortKey: 'midwife',
  Render: ({ data }) => <EllipsisText text={data?.midwife || 'None'} />,
}

export const claimNextActionColumn: ClaimColumn = {
  title: 'Next Action Date',
  Header: () => (
    <Flex align="center">
      <SortButton sortKey="nextActionDate">NEXT ACTION</SortButton>
    </Flex>
  ),
  isLazy: true,
  width: 280,
  sortKey: 'nextActionDate',
  Render: getRenderItemNextAction<Claim>(CLAIMS, 'Claim'),
}

export const claimsColumns: DataColumns<Claim> = {
  urgent: getAssignedAdminsColumn('claim') as DataColumn<Claim>,
  // unreadMessages: unreadMessagesColumn as any,
  unreadThreadMessages: getUnreadMessagesColumn(),
  deliveredOn: getDeliveredOnColumn(),
  name: nameColumn,
  status: statusColumn,
  // linked: claimLinkedColumn,
  serviceType: claimServiceTypeColumn,
  insurer: insurerColumn as any,
  provider: providerColumn,
  nextAction: claimNextActionColumn,
  log: claimLogColumn,
  overduePayments: getOverduePaymentColumn(),
}

import { AnyObject, DropdownOptionItem, DynamicDropdownField, FieldMapValue } from '@hb/shared'
import { get as nestedGet } from 'nested-property'
import * as React from 'react'
import { useCollectionItem } from '../../../../hooks/backend/useCollectionItem'
import { useAuth } from '../../../../store'

export function useSelectedOption(options?: (DropdownOptionItem | string)[], option?: string) {
  return React.useMemo(() => {
    if (option) {
      return options?.find(o => (typeof o === 'string' ? o === option : o.id === option))
    }
    return undefined
  }, [option, options])
}
export const useDynamicDropdownOptions = ({
  field,
  name = '',
  rootValue,
}: {
  field: DynamicDropdownField<AnyObject>
  name?: string
  rootValue?: FieldMapValue
}) => {
  const { baseId, collection, listId } = field
  const userIsAdmin = useAuth(s => s.admin)
  const itemId = React.useMemo(() => {
    if (!baseId) return undefined
    if (rootValue) {
      if (name.includes('.')) {
        return nestedGet(rootValue, `${name.slice(0, name.lastIndexOf('.'))}.${baseId}`)
      }
      return rootValue[baseId]
    }
    return undefined
  }, [rootValue, name, baseId])

  const { item } = useCollectionItem(collection, itemId)
  const options = React.useMemo<DropdownOptionItem[]>(() => {
    if (item && listId) {
      const indexable = item as Record<string, any>
      if (indexable[listId]) {
        if (indexable[listId] instanceof Array) {
          return indexable[listId].reduce<Array<DropdownOptionItem>>((acc, listItem) => {
            if (!listItem.isInactive || userIsAdmin) {
              return [
                ...acc,
                {
                  text: `${listItem.name}${listItem.isInactive ? ' (Inactive)' : ''}`,
                  id: listItem.name || listItem.id,
                },
              ]
            }
            return acc
          }, [])
        }

        console.error('Dynamic dropdown options is not an array')
      }
    }
    return []
  }, [item, listId, userIsAdmin])

  return options
}

import { midwivesCollection } from '../collections/collections/practice'
import { MIDWIVES } from '../collections/names'
import { adminRoles, FieldTypes } from '../constants'
import {
  AdminPracticeData,
  FieldMapValue,
  IdField,
  PracticeWithAdmin,
  User,
  UserRole,
} from '../types'
import { Practice } from '../types/providers'

// iterates through record where each key is a string
// and each value is a number or a record of the same type,
// and returns a record where each key is a string and each value is a number
export const flattenBilledCharges = (billedCharges?: FieldMapValue): Record<string, number> =>
  Object.entries(billedCharges ?? {}).reduce<Record<string, number>>((acc, [key, curr]) => {
    if (typeof curr === 'number') return { ...acc, [key]: curr }
    return { ...acc, ...flattenBilledCharges(curr) }
  }, {})

export const getMidwifeFullName = (midwife?: PracticeWithAdmin | Practice) => {
  const { fname, lname, name } = midwife ?? {}
  if (fname && lname && name && (`${fname} ${lname}` !== name || `${fname} ${lname}, CNM` !== name))
    return `${name} | ${fname} ${lname}`
  if (name) return name
  if (fname && lname) return `${fname} ${lname}`
  return 'Missing midwife name'
}

export const getPracticeWithAdmin = (
  midwife: Practice,
  adminData?: AdminPracticeData,
): PracticeWithAdmin => {
  const { email: _email, ...midwifeData } = midwife ?? {}
  return { ...midwifeData, ...adminData }
}

export const resolveProviderClaimsAddress = (
  provider?: PracticeWithAdmin | AdminPracticeData | null,
) => {
  const { claimsAddress, corporateAddress, paymentsAddress, servicingAddresses } = provider ?? {}
  if (claimsAddress === 'corporateAddress') {
    return corporateAddress
  }
  if (claimsAddress === 'paymentsAddress') {
    return paymentsAddress
  }
  if (claimsAddress?.startsWith('servicingAddresses')) {
    const idx = Number(claimsAddress.split('.')[1])
    return servicingAddresses?.[idx]
  }
  return undefined
}

export const getSelectedPracticeId = (claims: Record<string, any>, user: User | null) => {
  const { practiceAccess } = claims ?? {}
  const manualId = user?.selectedPracticeId
  if (manualId && practiceAccess?.[manualId] && adminRoles.includes(practiceAccess[manualId]))
    return manualId
  const firstPracticeId = Object.entries(practiceAccess ?? {}).find(([, access]) =>
    adminRoles.includes(access as UserRole),
  )?.[0]
  return firstPracticeId ?? null
}

// const getUploadPath = (practiceId: string) => `${MIDWIVES}/${practiceId}/logo/image`
// const getDownloadPath = (practiceId: string) => `${MIDWIVES}/${practiceId}/logo/resized_image`
export const getPracticeFilePath = (practiceId: string, fileId: string) => {
  switch (fileId) {
    case 'logo':
      return `${MIDWIVES}/${practiceId}/logo/image`
    default:
      throw new Error(`Unknown file id: ${fileId}`)
  }
}

export const basePracticeSelectField: IdField<PracticeWithAdmin> = {
  type: FieldTypes.ID,
  placeholder: 'Select a Practice',
  collection: midwivesCollection,
}

import { FileDBValue } from '../data'
import { Reaction } from '../reactions'
import { UserGroup, WithCreatedMetadata, WithMetadata } from '../shared/db'

export interface ThreadMessageReplyTo {
  messageId: string
  messageGroupId: string
  authorFirstName: string
  authorLastName: string
  message: ThreadMessage
}

export type ThreadMessage = WithMetadata<{
  text: string
  attachedFiles?: Record<string, FileDBValue>
  deletedOn?: number
  deletedBy?: string
  editedOn?: number
  editedBy?: string
  reactions?: Array<Reaction>
  replyTo?: ThreadMessageReplyTo
  readBy?: Array<string>
  unreadBy?: Array<string>
  editedReadBy?: Array<string>
  readOn?: Record<string, number>
}>

export type ThreadMessageWithThreadData = ThreadMessage & {
  threadType: ThreadType
  threadId: string
  messageGroupId: string
}

export type PopulatedThreadMessage<
  T extends ThreadMessageWithThreadData = ThreadMessageWithThreadData,
> = T & {
  sentBySameAsPrevious: boolean
  sentByMe: boolean
  sentBySameAsNext: boolean
  sentAtSameTimeAsPrevious: boolean
  sentAtSameTimeAsNext: boolean
  sentAtSameDateAsPrevious: boolean
  sentAtSameDateAsNext: boolean
  timeString: string
  dateString: string
}

export interface ThreadMessageGroup {
  createdOn: number
  messages: ThreadMessages
}
export type ThreadMessages = Record<string, ThreadMessage>
export type ThreadMessagesWithThreadData = Record<string, ThreadMessageWithThreadData>

export enum ThreadType {
  ASSESSMENT = 'assessment',
  GROUP = 'group',
  DIRECT = 'direct',
}
export type ThreadTab = UserGroup

export type ThreadLogItem = WithCreatedMetadata<{ description: string }>

export interface ThreadHistorySubscriber {
  fname: string
  lname: string
  id: string
}
export interface ThreadSubscriberHistoryItem {
  removedUsers: Array<ThreadHistorySubscriber>
  addedUsers: Array<ThreadHistorySubscriber>
  by: string
  byFname: string
  byLname: string
  on: string
}

export interface MessageThread<T extends ThreadType = ThreadType> {
  title?: string
  type: T
  subscribers: Array<string>
  // store the timestamp of when each user last read the thread
  // store the most recent 5 messages
  subscriberHistory?: Array<ThreadSubscriberHistoryItem>
  mostRecentMessages: Array<ThreadMessage>
  mostRecentMessageOn: number
  accessLevel: UserGroup
  log?: Array<ThreadLogItem>
  mutedBy?: Array<string>
  mostRecentMessageBy: string | null
  searchString: string
  readBy: Array<string>
  unreadBy: Array<string>
}

export interface AssessmentMessageThread extends MessageThread {
  type: ThreadType.ASSESSMENT
  patientId: string
  practiceId: string | null
}

export interface GroupMessageThread extends WithMetadata<MessageThread> {
  type: ThreadType.GROUP
  members: Array<string>
}

export const isGroupMessageThread = (thread: MessageThread): thread is GroupMessageThread =>
  thread.type === ThreadType.GROUP

export interface ThreadSelection {
  type: ThreadType
  title: string
  id: string
}

export interface MessagingWidgetState {
  thread: ThreadSelection | null
  tab: ThreadTab | null
  notificationVolume: number
}

export interface ThreadSearchResult {
  threadId: string
  threadType: ThreadType
  messageGroupId: string
  messageId: string
  message: ThreadMessageWithThreadData
  thread: MessageThread
}

import {
  AssessmentFormKey,
  AssessmentStepKey,
  FieldMapValue,
  getPregnancyFormData,
} from '@hb/shared'
import {
  FormsViewData,
  useAssessmentForm,
  useAssessmentFromId,
  useMyAssessments,
} from '@hb/shared-frontend'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'

export const useFormsView = (): FormsViewData => {
  const { assessments, loading: assessmentsLoading, user: userData } = useMyAssessments()
  const [selected, setSelected] = useState<{ formId: AssessmentFormKey; stageId: string } | null>(
    null,
  )

  const { assessmentId: _assessmentId } = useParams<{ assessmentId: string }>()
  const index = useMemo(() => {
    const keys = Object.keys(assessments ?? {})
    if (_assessmentId) {
      const idx = keys.findIndex(id => _assessmentId === id)
      return idx
    }
    return 0
  }, [assessments, _assessmentId])

  const assessmentId = useMemo(
    () => _assessmentId ?? (assessments && Object.keys(assessments)[0]) ?? undefined,
    [assessments, _assessmentId],
  )

  // const { data: assessment } = useDocument<Assessment>('assessments', assessmentId)
  const assessmentData = useAssessmentFromId(false, assessmentId)
  const { assessmentData: assessment, populated } = assessmentData ?? {}
  const [step, selectStep] = useState<AssessmentStepKey>('questions')

  const { form } = useAssessmentForm(assessment?.data, selected?.formId)

  const mountedAssessment = useRef<number | string | null>(assessment?.createdOn ?? null)
  useEffect(() => {
    if (mountedAssessment.current === assessment?.createdOn) return
    if (assessment?.resultsViewedOn) selectStep('signOn')
    else if (assessment?.results) selectStep('view')
    else {
      selectStep('questions')
    }
    mountedAssessment.current = assessment?.createdOn ?? null
  }, [assessment])

  const { formData, formCorrections } = useMemo<FieldMapValue>(
    () => getPregnancyFormData(populated),
    [populated],
  )

  return {
    step,
    assessmentId,
    assessment: assessmentData,
    assessments,
    selectStep,
    selected,
    populatedUser: userData,
    formData,
    formCorrections,
    assessmentIndex: index,
    open: (formId, stageId) => {
      setSelected({ formId, stageId })
    },
    close: () => {
      setSelected(null)
    },
    assessmentsLoading,
    form,
  }
}

import { Box, Button, Collapse, Divider, HStack, Text, VStack } from '@chakra-ui/react'
import { FieldTypes, MessageThread, TextField, ThreadType } from '@hb/shared'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { addUsersToThread, removeUsersFromThread } from '../../../backend/functions'
import { PopUpMessageContext } from '../../../contexts'
import { StandaloneInput } from '../../forms'
import { SearchUsersList } from '../../Users/SearchUsersList'
import { ThreadMemberView } from '../ThreadMembers/ThreadMemberView'

const requiredThreadTitleField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Thread Title (required for group threads)',
}
export const AddMembersView = ({
  thread,
  threadId,
  height,
  onClose,
  threadType,
  isOpen,
}: {
  thread: MessageThread | null
  threadType: ThreadType
  height: number
  isOpen: boolean
  threadId?: string | null
  onClose: () => void
}) => {
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  // const [accessLevel, setAccessLevel] = useState<UserGroup | null>(null)

  const [title, setTitle] = useState<string>('')
  const titleRequired = useMemo(() => {
    const currThreadSubscribers =
      thread?.subscribers && Array.isArray(thread.subscribers) ? thread.subscribers : []
    const currMembersLength = currThreadSubscribers.length
    const updatedMembersLength = selected.length + currMembersLength
    return currMembersLength < 3 && updatedMembersLength >= 3
  }, [thread, selected])

  const { processResponse } = useContext(PopUpMessageContext)
  const handleAddMembers = useCallback(async () => {
    if (!selected.length) return
    if (!threadId) return
    if (!title && titleRequired) {
      processResponse({ error: 'Title is required for group threads' })
      return
    }
    setLoading(true)
    try {
      await addUsersToThread({
        threadId,
        userIds: selected,
        type: threadType,
        title: title ?? '',
      })
      onClose()
    } catch (e: any) {
      console.error(e)
    }
    setLoading(false)
  }, [onClose, threadId, selected, threadType, titleRequired, title, processResponse])

  useEffect(() => {
    if (!isOpen) setSelected([])
  }, [isOpen])

  // const { appName } = useApp()
  // const accessLevelField = useMemo(
  //   () => (appName === 'providers-app'
  //     ? practiceAccessLevelField
  //     : adminAccessLevelField),
  //   [appName],
  // )

  return (
    <VStack
      bg="gray.100"
      spacing={2}
      py={2}
      px={3}
      height={`${height}px`}
      width="100%"
      overflowY="auto">
      {/* <HStack w="100%">
        <Text
          fontFamily="Open Sans"
          fontSize="md"
          fontWeight={600}
          color="gray.600"
        >
          User Group
        </Text>
        <Box flex={1}>
          <StandaloneInput
            theme="detailed"
            style={{ padding: '0.5rem 0.5rem' }}
            field={accessLevelField}
            value={accessLevel || thread?.accessLevel}
            onChange={setAccessLevel}
          />
        </Box>
      </HStack> */}
      <VStack
        py={2}
        px={3}
        bg="blackAlpha.100"
        borderRadius={6}
        spacing={1}
        w="100%"
        align="flex-start">
        <Text fontFamily="Hero-New" color="gray.500" fontSize="sm">
          Current Members
        </Text>
        <VStack w="100%" spacing={1} align="flex-start">
          {thread?.subscribers?.length ? (
            thread.subscribers.map(sub => (
              <ThreadMemberView
                onDelete={async () => {
                  if (!threadId) return
                  await removeUsersFromThread({
                    threadId,
                    type: threadType,
                    userIds: [sub],
                  })
                }}
                key={sub}
                userId={sub}
              />
            ))
          ) : (
            <Text>No members</Text>
          )}
        </VStack>
      </VStack>
      <SearchUsersList
        selected={selected}
        existing={
          thread?.subscribers && Array.isArray(thread?.subscribers) ? thread?.subscribers : []
        }
        excludeMe
        onChange={setSelected}
        group={thread?.accessLevel}
        label="Add Members"
        type="user"
      />
      <Collapse style={{ width: '100%' }} in={titleRequired}>
        <Box w="100%" p={2}>
          <StandaloneInput
            theme="detailed"
            field={requiredThreadTitleField}
            value={title}
            onChange={setTitle}
          />
        </Box>
      </Collapse>
      <Divider />
      <HStack w="100%">
        <Button bg="white" border="1px solid #cdcdcd" size="sm" flex={1} onClick={onClose}>
          Cancel
        </Button>
        <Button
          size="sm"
          flex={1}
          colorScheme="green"
          opacity={selected.length ? 1 : 0.5}
          onClick={handleAddMembers}
          isLoading={loading}>
          Submit
        </Button>
      </HStack>
    </VStack>
  )
}

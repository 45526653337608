import { CheckIcon, CloseIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Center,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  CheckboxField,
  Claim,
  CLAIMS,
  FieldTypes,
  getFullName,
  getInitials,
  getReverseName,
  LocalCollectionState,
  TextField,
  UserRoleItem,
  WithId,
} from '@hb/shared'
import { doc, DocumentReference, writeBatch } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { db } from '../../../../backend/db'
import { PopUpMessageContext } from '../../../../contexts'
import { DataListContext, useDataListContext } from '../../../../contexts/DataListContext'
import { ThemeContext } from '../../../../contexts/ThemeContext'
import { DeleteButton } from '../../../Buttons/DeleteButton'
import { ColorsCircle, ColorsSelect, ColorsSelectPopover } from '../../../ColorsSelect'
import { StandaloneInput } from '../../../forms'
import Input from '../../../forms/Input'

const urgentField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  placeholder: 'Urgent',
}

const useAssignedAdminColors = (admins: LocalCollectionState<UserRoleItem>) => {
  const { items } = admins
  const options = useMemo(
    () =>
      items
        .filter(a => !!a.adminColor)
        .map(a => {
          const fullName = getFullName(a)
          return {
            name: fullName,
            initials: getInitials(fullName),
            hex: a.adminColor ?? '',
          }
        }),
    [items],
  )

  return options
}

const useAssignedAdminsFilter = (adminsState: LocalCollectionState<UserRoleItem>) => {
  const { userFilters, filterBy, removeFilter } = useContext(DataListContext)
  const options = useAssignedAdminColors(adminsState)
  const { items: admins } = adminsState
  const value = useMemo(() => {
    const filter = userFilters.find(f => f[0] === 'assignedAdmins')
    if (!filter) return []
    return Array.isArray(filter[2])
      ? filter[2].map(adminId => admins.find(a => a.id === adminId)?.adminColor ?? '')
      : []
  }, [admins, userFilters])
  const onChange = useCallback(
    (v: string[]) => {
      const newAdmins = v
        .map(color => admins.find(a => a.adminColor === color)?.id ?? '')
        .filter(a => !!a)
      if (newAdmins.length) filterBy(['assignedAdmins', 'array-contains-any', newAdmins])
      else removeFilter('assignedAdmins')
    },
    [admins, filterBy, removeFilter],
  )

  return { options, value, onChange }
}

const useAssignedAdminsSelect = (
  adminsState: LocalCollectionState<UserRoleItem>,
  item: WithId<{ assignedAdmins?: string[] }> | undefined | null,
  updateRefs: DocumentReference[],
  onSubmitComplete?: () => Promise<void>,
) => {
  const options = useAssignedAdminColors(adminsState)
  const { items: admins } = adminsState
  const { assignedAdmins = [] } = item ?? {}
  // const isUrgent = getIsUrgent(item)
  const colors = assignedAdmins.map(id => admins.find(a => a.id === id)?.adminColor || '#777777')
  // const update = useUpdateDoc('assigned admins')
  const { showError } = useContext(PopUpMessageContext)
  const handleSubmit = useCallback(
    async (updatedColors: string[]) => {
      if (!updateRefs.length) return
      const adminIds = updatedColors
        .map(color => admins.find(a => a.adminColor === color)?.id ?? '')
        .filter(a => !!a)
      // const batch = writeBatch
      try {
        const batch = writeBatch(db)
        updateRefs.forEach(ref => batch.set(ref, { assignedAdmins: adminIds }, { merge: true }))
        await batch.commit()
      } catch (err: any) {
        console.error('Error updating assigned admins:', err)
        showError('Error updating assigned admins', err?.message)
      }
      if (onSubmitComplete) {
        await onSubmitComplete()
      }
    },
    [admins, updateRefs, onSubmitComplete, showError],
  )

  return {
    options,
    handleSubmit,
    colors,
  }
}

const AssignedAdminsFilter = ({
  adminsState,
}: {
  adminsState: LocalCollectionState<UserRoleItem>
}) => {
  const { options, value, onChange } = useAssignedAdminsFilter(adminsState)
  return (
    <Flex flexFlow="row wrap">
      <ColorsSelect onChange={onChange} value={value} allColors={options} />
    </Flex>
  )
}

export const AssignedAdminsHeader = ({ urgentKey }: { urgentKey?: string }) => {
  const { userFilters, filterBy, removeFilter, admins } = useContext(DataListContext)
  // const { urgentColor = colors.red.hex } = getIsUrgent(item) ?? {}

  const { value } = useAssignedAdminsFilter(admins)

  const urgentFilter = useMemo(
    () => (urgentKey ? userFilters.find(f => f[0] === urgentKey) : null),
    [userFilters, urgentKey],
  )

  const handleUrgentToggle = useCallback(
    (urgent: boolean) => {
      if (!urgentKey) return
      if (urgent) {
        filterBy([urgentKey, '==', true])
      } else {
        removeFilter(urgentKey)
      }
    },
    [filterBy, removeFilter, urgentKey],
  )

  return (
    <Center overflow="visible" px={1}>
      <Popover
        placement="right"
        // closeOnBlur={false}
        isLazy
        strategy="fixed">
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <Box cursor="pointer">
                <ColorsCircle
                  size={22}
                  withStrikethrough
                  value={value && Array.isArray(value) ? value : []}
                />
              </Box>
            </PopoverTrigger>
            <PopoverContent p={1} w="148px">
              <PopoverArrow />
              <Flex direction="column" w="100%">
                <Flex w="100%" align="center" px={1}>
                  <Flex flex={1} minW="0">
                    {urgentKey ? (
                      <StandaloneInput
                        field={urgentField}
                        value={!!urgentFilter}
                        onChange={handleUrgentToggle}
                      />
                    ) : null}
                  </Flex>
                  <IconButton
                    variant="ghost"
                    w={6}
                    h={6}
                    minW={0}
                    aria-label="close"
                    size="xs"
                    borderRadius="full"
                    onClick={onClose}
                    icon={<CloseIcon w={2.5} h={2.5} color="gray.500" />}
                  />
                </Flex>
                <AssignedAdminsFilter adminsState={admins} />
                <Flex flex={1} minW="0"></Flex>
              </Flex>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Center>
  )
}

const urgentReasonField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Urgent reason',
}
const themeValue = { theme: 'basic', placeholderAbove: true } as const
export const AssignedAdminsContent = ({
  item,
  collection,
  urgentCollection,
  admins,
  inList,
  type,
}: {
  item: WithId<{ assignedAdmins?: string[] }> | undefined | null
  admins: LocalCollectionState<UserRoleItem>
  collection: string
  urgentCollection: string
  inList?: boolean
  type: 'assessment' | 'claim'
}) => {
  const itemId = useMemo(
    () => (type === 'claim' ? (item as Claim).assessmentId : item?.id),
    [item, type],
  )
  const updateRefs = useMemo(() => {
    const baseRef = itemId ? doc(db, collection, itemId) : null
    const claimRef = type === 'claim' && item?.id ? doc(db, CLAIMS, item.id) : null
    return [baseRef, claimRef].filter(Boolean) as DocumentReference[]
  }, [collection, itemId, type, item])

  const { showError } = useContext(PopUpMessageContext)

  const urgentRef = useMemo(
    () => (itemId ? doc(db, urgentCollection, itemId) : null),
    [itemId, urgentCollection],
  )

  const { refetchItem } = useDataListContext()
  const onSubmitComplete = useCallback(async () => {
    if (inList && item?.id) {
      await refetchItem(item.id)
    }
  }, [item, refetchItem, inList])

  const {
    colors,
    options,
    handleSubmit: submitColor,
  } = useAssignedAdminsSelect(admins, item, updateRefs, onSubmitComplete)
  // const update = useUpdateDoc('urgent reason')

  const handleSubmitReason = useCallback(
    async (reason: string | null) => {
      if (!urgentRef || !updateRefs) return
      const batch = writeBatch(db)
      batch.set(urgentRef, { urgentReason: reason ?? '' }, { merge: true })
      updateRefs.forEach(docRef =>
        batch.set(docRef, { urgentReason: reason ?? '' }, { merge: true }),
      )
      try {
        const res = await batch.commit()
        await onSubmitComplete()
        return res
      } catch (error: any) {
        console.error('Error updating urgent reason:', error)
        showError('Error updating urgent reason', error?.message)
      }
    },
    [urgentRef, onSubmitComplete, updateRefs, showError],
  )
  const matchingOptions = options.filter(o => colors.includes(o.hex))

  const displayedName = getReverseName(item)
  return (
    <ThemeContext.Provider value={themeValue}>
      <PopoverCloseButton />
      <PopoverHeader fontSize="sm" fontWeight={600}>
        <Flex flexFlow="column">
          <Flex align="center">
            <ColorsSelectPopover
              allColors={options}
              withStrikethrough
              onChange={submitColor}
              value={colors}
            />
            <Text color="gray.600" fontFamily="Comfortaa" ml={2}>
              {displayedName}
            </Text>
          </Flex>
          <Flex flexFlow="row wrap">
            {matchingOptions.map(o => (
              <Box key={o.hex} m={1}>
                <Tooltip
                  label={o.name}
                  bg={o.hex}
                  hasArrow
                  color="white"
                  textShadow="1px 1px 3px #00000077">
                  <Badge
                    cursor="pointer"
                    color="white"
                    fontSize="xs"
                    textShadow="1px 1px 3px #00000077"
                    bg={o.hex}
                    variant="solid">
                    {o.initials}
                  </Badge>
                </Tooltip>
              </Box>
            ))}
          </Flex>
        </Flex>
      </PopoverHeader>
      <PopoverBody p={0}>
        {item ? (
          <Form
            onSubmit={({ reason }) => handleSubmitReason(reason)}
            initialValues={{
              reason: 'urgentReason' in item ? item?.urgentReason : '',
            }}>
            {({ handleSubmit, submitting }) => (
              <Flex px={2} align="flex-start">
                <Flex py={2} flexGrow={1}>
                  <Field name="reason">
                    {props => <Input {...props} field={urgentReasonField} />}
                  </Field>
                </Flex>
                <Flex pt={1} ml="auto">
                  <IconButton
                    variant="ghost"
                    size="xs"
                    aria-label="delete"
                    icon={<CheckIcon />}
                    onClick={handleSubmit}
                    isLoading={submitting}
                  />
                  <DeleteButton
                    itemName="Urgent reason"
                    onDelete={() => handleSubmitReason(null)}
                  />
                </Flex>
              </Flex>
            )}
          </Form>
        ) : (
          <Text px={2} py={1}>
            Missing user
          </Text>
        )}

        {/* {isClaim ? null : <OtherReasons item={item} />} */}
      </PopoverBody>
    </ThemeContext.Provider>
  )
}

const AssignedAdminsCircle = ({
  assignedAdmins,
  urgent,
  admins: { items: admins },
}: {
  assignedAdmins?: Array<string>
  urgent?: boolean
  admins: LocalCollectionState<UserRoleItem>
}) => {
  const colors = (assignedAdmins && Array.isArray(assignedAdmins) ? assignedAdmins : []).map(
    id => admins.find(a => a.id === id)?.adminColor || '#777777',
  )
  const text = assignedAdmins?.length
    ? assignedAdmins
        .map(id => {
          const admin = admins.find(a => a.id === id)
          return admin ? getFullName(admin) : 'No admin'
        })
        .join(', ')
    : 'No admins assigned'

  const tooltipBg = useMemo(() => {
    // if multiple colors, return gradient
    if (colors.length > 1) {
      return `linear-gradient(90deg, ${colors.join(', ')})`
    }
    return colors[0] || '#777777'
  }, [colors])

  return (
    // <Box p="3px" bg="#efefef" borderRadius="full" boxShadow="0 0 5px #00000088">
    <Tooltip
      bg={tooltipBg}
      color="white"
      textShadow="1px 1px 3px black"
      placement="right"
      hasArrow
      sx={{
        '--popper-arrow-bg': colors[0] || '#777',
      }}
      label={text}>
      <Center cursor="pointer" pos="relative">
        <ColorsCircle value={colors} />
        {urgent ? (
          <WarningIcon
            pos="absolute"
            pointerEvents="none"
            color="whiteAlpha.900"
            filter="drop-shadow(1px 1px 3px #00000055)"
            w={4}
            h={4}
          />
        ) : null}
      </Center>
    </Tooltip>
    // </Box>
  )
}

export type AssignedAdminsItem = WithId<{
  assignedAdmins?: string[]
  isUrgent?: boolean
  urgentReason?: string | null
}>
export const AssignedAdmins = <T extends AssignedAdminsItem>({
  data,
  admins,
  inList,
  collection,
  urgentCollection,
  type,
}: {
  data: WithId<T> | undefined | null
  admins: LocalCollectionState<UserRoleItem>
  type: 'assessment' | 'claim'
  inList?: boolean
  collection: string
  urgentCollection: string
}) => {
  const { assignedAdmins, urgentReason } = data ?? {}
  return (
    <Popover closeOnBlur={false} placement="right" isLazy strategy="fixed">
      <PopoverTrigger>
        <Box>
          <AssignedAdminsCircle
            assignedAdmins={assignedAdmins}
            admins={admins}
            urgent={!!urgentReason}
          />
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <AssignedAdminsContent
          inList={inList}
          urgentCollection={urgentCollection}
          type={type}
          collection={collection}
          admins={admins}
          item={data}
        />
        <PopoverArrow />
      </PopoverContent>
    </Popover>
  )
}

// export const claimsAssignedAdminsColumn: DataColumn<Claim> = {
//   title: 'Urgent',
//   Header: () => (
//     <Box px={2}>
//       <AssignedAdminsHeader urgentKey="isUrgent" />
//     </Box>
//   ),
//   // sortKey: 'urgentSort',
//   flexProps: { justify: 'center' },
//   width: 40,
//   Render: ({ data, admins }) => (
//     <AssignedAdmins
//       data={data}
//       admins={admins}
//       urgentCollection={ASSESSMENTS_ADMIN}
//       collection={ASSESSMENT_SNIPPETS}
//     />
//   ),
// }

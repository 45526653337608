import {
  AppName,
  AssessmentInvoiceSnippet,
  FieldMapValue,
  PaymentReceived,
  UpdateCallback,
} from '@hb/shared'
import { addReceivedPayment } from '../../../../backend/functions'
import { validatePaymentReceived } from './fields'

export const submitReceivedPayment = async (
  appName: AppName,
  assessmentId: string,
  id: string,
  data: FieldMapValue,
  sendMessage: boolean,
  message: string,
): Promise<UpdateCallback> => {
  if (!id) return { error: 'No ID' }
  const validationErrors = validatePaymentReceived(data)
  if (validationErrors) return validationErrors
  const { amount, paidBy, date, source, eobFile, checkFile, notes, type } = data
  const payment: PaymentReceived = {
    paidBy,
    amount,
    date,
    source,
    type,
  }
  if (eobFile) payment.eobFile = eobFile
  if (checkFile) payment.checkFile = checkFile
  if (notes) payment.notes = notes

  const createdOn = Number.parseInt(id, 10)
  if (Number.isNaN(createdOn)) {
    return { error: 'Invalid ID' }
  }
  return addReceivedPayment({
    appName,
    assessmentId,
    payment,
    createdOn,
    sendMessage: !!sendMessage,
    message,
  })
    .then(() => ({ success: 'Payment added' }))
    .catch((e: any) => ({ error: e?.message || 'Error adding payment' }))
}
export const getPaymentInvoiceSnippets = (
  assessmentInvoiceSnippets: Record<string, AssessmentInvoiceSnippet>,
  paymentId: string,
) =>
  Object.entries(assessmentInvoiceSnippets).reduce<Record<string, AssessmentInvoiceSnippet>>(
    (acc, [invoiceId, invoiceSnippet]) => {
      const paymentData = invoiceSnippet?.chargedPayments?.[paymentId]
      const customFeePercent = invoiceSnippet?.customFeePercents?.[paymentId]
      if (!paymentData && !customFeePercent) return acc
      return {
        ...acc,
        [invoiceId]: invoiceSnippet,
      }
    },
    {},
  )

import { Box, Button, Flex } from '@chakra-ui/react'
import { colors } from '@hb/shared'
import React, { useMemo, useRef } from 'react'
import { useApp, useProfile } from '../../../../../../contexts'
import { Size, useResizeObserver } from '../../../../../../hooks/useResizeObserver'
import { AssessmentModal } from '../../../../../Assessments/AssessmentModal/AssessmentModal'
import { PatientPlansView, PregnancyPlansView } from '../../../../../Assessments/InsurancePlansView'
import { SignOnInfoPanel } from '../../../../../Assessments/SignOnInfo'
import { AssessmentAuthorizationsTab } from '../../../Authorizations/AssessmentAuthorizations'
import { ProfileVisits } from '../../../Charting/Visits'
import { ProfileConsentForms } from '../../../ConsentForms/ProfileConsentForms'
import { AssessmentFiles } from '../../../Files/AssessmentFiles'
import { AssessmentLog } from '../../../Log/AssessmentLog'
import { Resizable } from '../../../Log/Resizable'
import { getV2PregnancyTabs } from '../../../Nav/tabs'
import { AssessmentPaymentsTab } from '../../../Payments'
import { AssessmentHeaderRow } from '../../V1/AssessmentHeader'
import { PatientControlPanel } from '../Patient/PatientControlPanel'
import { PregnancyControlPanel } from '../Pregnancy/PregnancyControlPanel'

const PregnancyContentBody = () => {
  const { tab } = useProfile()
  const tabPath = tab?.path

  switch (tabPath) {
    case 'plans':
      return (
        <Box borderRadius={4} bg="white" w="100%" m={2}>
          <PregnancyPlansView />
        </Box>
      )
    case 'payments':
      return <AssessmentPaymentsTab />
    case 'files':
      return (
        <Box w="100%" p={3}>
          <AssessmentFiles />
        </Box>
      )
    case 'sign-on':
      return (
        <Box w="100%" p={3}>
          <SignOnInfoPanel mobileLayout={false} />
        </Box>
      )
    case 'authorizations':
      return <AssessmentAuthorizationsTab />
    default:
      return <PregnancyControlPanel />
  }
}

const PregnancyContent = ({ size }: { size: Size }) => {
  const { selectedAssessment, user, goTo: onTabSelect, tab } = useProfile()
  const { appName } = useApp()
  const displayedTabs = useMemo(
    () => getV2PregnancyTabs(appName).filter(t => t.path !== 'patient'),
    [appName],
  )
  const bodyRef = useRef<HTMLDivElement>(null)

  const { width, height } = useResizeObserver(bodyRef, 'content')
  return (
    <Flex w="100%" h="100%" flexFlow="column">
      <AssessmentHeaderRow width={size.width} />
      <Flex gap={2} py={1} px={2} border="1px solid #cdcdcd" bg="gray.100" w="100%">
        {displayedTabs.map((t, i) => (
          <Button
            bg={tab?.path === t.path ? colors.pink.hex : 'white'}
            color={tab?.path === t.path ? 'gray.700' : 'gray.500'}
            borderRadius="full"
            size="sm"
            key={i}
            px={3}
            onClick={() =>
              onTabSelect({
                tab: t.path,
                entity: { id: selectedAssessment.id, type: 'pregnancy' },
              })
            }
            cursor="pointer">
            {t.name}
          </Button>
        ))}
      </Flex>
      <Flex ref={bodyRef} flex={1} w="100%" minH="0">
        <Resizable
          width={width}
          height={height}
          localStorageId="user-profile-resize"
          leftChild={() => (
            <Box bg="#efefef" w="100%" h="100%" overflowY="auto">
              <PregnancyContentBody />
            </Box>
          )}
          rightChild={() => <AssessmentLog maxHeight={size.height} />}
        />
      </Flex>
      {user && selectedAssessment.populated && tab?.path === 'assessment' ? (
        <AssessmentModal
          assessment={selectedAssessment.populated}
          id={selectedAssessment.id}
          isOpen
          onClose={() =>
            onTabSelect({ entity: { id: selectedAssessment.id, type: 'pregnancy' }, tab: null })
          }
          // user={user}
        />
      ) : null}
    </Flex>
  )
}

const PatientContent = () => {
  const { subTab } = useProfile()
  // if (!subTab || !isPatientTab(subTab)) return <PatientControlPanel />
  switch (subTab?.path) {
    case 'consent-forms':
      return <ProfileConsentForms />
    case 'visits':
      return <ProfileVisits onlyStandaloneVisits={false} assessmentId={null} />
    case 'insurance':
      return <PatientPlansView />
    default:
      return <PatientControlPanel />
  }
}

export const BodyContent = ({ size }: { size: Size }) => {
  const { tab, assessmentId, selectedItem } = useProfile()
  const selectedItemType = useMemo(() => selectedItem?.type || 'patient', [selectedItem])
  switch (selectedItemType) {
    case 'pregnancy':
      if (assessmentId && tab?.path !== 'patient') return <PregnancyContent size={size} />
      break
    default:
      return <PatientContent />
  }
  return <PatientContent />
}

import { Flex, FlexProps, Text } from '@chakra-ui/react'
import {
  capitalizeFirstLetter,
  DropdownField,
  Field,
  FieldTypes,
  getHasMultiplePlans,
  getInsurancePlan,
  PatientCoverageId,
  PopulatedAssessment,
  PopulatedInsurancePlans,
  PopulatedUser,
} from '@hb/shared'
import React, { useMemo } from 'react'
import { useField } from 'react-final-form'
import { usePatientPlanField } from '../../../../../../hooks/insurance/fields'
import { FormElement } from '../../../../../forms'
import { MainCoverageDetails, OtherCoverageDetails } from './CoverageDetails'

const CoverageBox = (props: FlexProps) => (
  <Flex
    border="1px solid #cdcdcd"
    borderRadius={4}
    align="flex-start"
    bg="white"
    boxShadow="md"
    w="100%"
    px={3}
    my={2}
    flexFlow="column"
    py={2}
    {...props}
  />
)

const makeFieldOptional = <T extends Field>(field: T) => ({
  ...field,
  optional: true,
})
export const SelectInsurer = ({
  assessment,
  user,
}: {
  assessment: PopulatedAssessment | null
  user: PopulatedUser | null
}) => {
  const { authorizations } = assessment ?? {}
  const { insurancePlans: plans } = user ?? {}

  const {
    input: { value: mainCoverageId },
  } = useField<PatientCoverageId>('mainCoverageId', {
    subscription: { value: true },
  })
  const {
    input: { value: otherCoverageId },
  } = useField('otherCoverageId', { subscription: { value: true } })

  const otherCoverage = useMemo(
    () => (plans ? getInsurancePlan(plans, otherCoverageId) : null),
    [otherCoverageId, plans],
  )

  const primaryCoverage = useMemo(
    () => (plans ? getInsurancePlan(plans, mainCoverageId) : null),
    [mainCoverageId, plans],
  )

  // const { item: mainInsurer } = useCollectionItem(
  //   providersCollection,
  //   primaryCoverage?.insuranceProviderId,
  // )

  // const { item: otherInsurer } = useCollectionItem(
  //   otherCoverage?.isMedicaid ? medicaidProvidersCollection : providersCollection,
  //   otherCoverage?.insuranceProviderId,
  // )

  const hasMultiplePlans = useMemo(() => getHasMultiplePlans(plans ?? {}), [plans])

  const field = usePatientPlanField(plans)
  const nonSelectedPlans = useMemo<PopulatedInsurancePlans>(() => {
    const otherPlans: PopulatedInsurancePlans = {
      primary: null,
      secondary: null,
      additional: {},
      requests: {},
    }
    if (!hasMultiplePlans) return otherPlans
    if (!mainCoverageId)
      return {
        additional: plans?.additional ?? {},
        primary: plans?.primary ?? null,
        secondary: plans?.secondary ?? null,
        requests: plans?.requests ?? {},
      }

    const hasPrimaryInsurer = !!plans?.primary?.insuranceProviderId
    const hasSecondaryInsurer = !!plans?.secondary?.insuranceProviderId

    const addPrimaryCoverage = () => {
      if (hasPrimaryInsurer) otherPlans.primary = plans?.primary ?? null
    }
    const addSecondaryCoverage = () => {
      if (hasSecondaryInsurer) otherPlans.secondary = plans?.secondary ?? null
    }

    if (mainCoverageId === 'primary') {
      otherPlans.additional = plans?.additional ?? {}
      addSecondaryCoverage()
    } else if (mainCoverageId === 'secondary') {
      otherPlans.additional = plans?.additional ?? {}
      addPrimaryCoverage()
    } else if (mainCoverageId.startsWith('additional')) {
      const planId = mainCoverageId.split('.')[1] ?? ''
      const { [planId]: _, ...additionalPlans } = plans?.additional ?? {}
      otherPlans.additional = additionalPlans
      addPrimaryCoverage()
      addSecondaryCoverage()
    }

    return otherPlans
  }, [mainCoverageId, plans, hasMultiplePlans])

  const otherPlanField = usePatientPlanField(nonSelectedPlans, makeFieldOptional)

  const authorizationsField = useMemo<DropdownField>(
    () => ({
      placeholder: 'Authorization',
      type: FieldTypes.DROPDOWN,
      optional: true,
      options:
        Object.values(authorizations ?? {}).map(a => ({
          text: `${a.referenceNumber} - ${capitalizeFirstLetter(a.serviceType)}`,
          id: a.referenceNumber,
        })) ?? [],
    }),
    [authorizations],
  )

  return (
    <>
      <CoverageBox>
        <Text fontWeight={600} color="gray.500" fontSize="md">
          Main Coverage
        </Text>
        <FormElement field={field} name="mainCoverageId" />
        <MainCoverageDetails coverage={primaryCoverage} authorizationsField={authorizationsField} />
      </CoverageBox>
      {hasMultiplePlans ? (
        <CoverageBox>
          <Text py={1} fontWeight={600} color="gray.500" fontSize="md">
            Additional Coverage
          </Text>
          <FormElement name="otherCoverageId" field={otherPlanField} />
          <OtherCoverageDetails
            coverage={otherCoverage ?? null}
            authorizationsField={authorizationsField}
          />
        </CoverageBox>
      ) : null}
    </>
  )
}

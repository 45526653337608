import { Box, Button, Flex, HStack, Text } from '@chakra-ui/react'
import { CheckboxField, FieldTypes } from '@hb/shared'
import React from 'react'
import { useAppModals } from '../../store'
import { updateSiteSettings, useSiteSettings } from '../../store/settings'
import { StandaloneInput } from '../forms'
import { DefaultModal } from '../Modals'

const overrideNativeDateInputField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  inputType: 'switch',
  placeholder: 'Override Native Date Input',
  yesText: 'Always use native date input',
  noText: 'Use default date input',
}
export const SiteSettings = () => {
  const { nativeDateInputOverride } = useSiteSettings()

  return (
    <Flex w="100%" flexFlow="column">
      <Flex w="100%" align="center" pt={2} px={3}>
        <Text color="gray.600" fontWeight={500} fontFamily="hero-new">
          SITE SETTINGS
        </Text>
      </Flex>
      <Box w="100%" px={2}>
        <StandaloneInput
          style={{ padding: 0, height: '24px' }}
          field={overrideNativeDateInputField}
          onChange={v => updateSiteSettings('nativeDateInputOverride', v)}
          value={nativeDateInputOverride}
        />
      </Box>
    </Flex>
  )
}

export const SiteSettingsModal = () => {
  const {
    siteSettings: { openId, close },
  } = useAppModals()
  return (
    <DefaultModal
      overlayHeader
      isOpen={openId === 'root'}
      onClose={close}
      render={() => <SiteSettings />}
    />
  )
}

export const SiteSettingsButton = () => (
  <HStack py={1} bg="gray.100" px={2} w="100%">
    <Text fontSize="xs" color="gray.600" fontWeight="medium" fontFamily="hero-new">
      SITE SETTINGS
    </Text>
    <Button
      ml="auto"
      onClick={() => useAppModals.getState().siteSettings.open()}
      color="white"
      borderRadius="full"
      size="xs"
      bg="blackAlpha.400"
      _hover={{ bg: 'blackAlpha.500' }}>
      OPEN
    </Button>
  </HStack>
)

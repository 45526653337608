import { EditIcon } from '@chakra-ui/icons'
import { Flex, Text } from '@chakra-ui/react'
import {
  CheckboxField,
  FieldMap,
  FieldMapValue,
  FieldTypes,
  getCoverageRequiresCallIn,
  notesField,
  TextAreaField,
} from '@hb/shared'
import { FORM_ERROR } from 'final-form'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { requestCallIn } from '../../../backend'
import { PopUpMessageContext } from '../../../contexts'
import { useAuth } from '../../../store'
import { ActionLog } from '../../ActionLog'
import { ActionButton, SolidActionButton } from '../../Buttons'
import { SimpleForm } from '../../forms'
import { DefaultModal } from '../../Modals/DefaultModal'
import { useCoverageView } from './context'

const requiredNotesField: TextAreaField = {
  ...notesField,
  optional: false,
  placeholder: 'Message to patient',
}

const sendEmailField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  placeholder: 'Send email to patient',
}

const field: FieldMap = {
  name: 'Request Call In',
  children: {
    notes: requiredNotesField,
    sendEmail: sendEmailField,
  },
}

export const RequestCallIn = ({
  // assessmentId,
  visible,
}: {
  // assessmentId: string // | null
  visible: boolean
}) => {
  const { coverage, patientRef } = useCoverageView()
  const { callInRequests } = coverage ?? {}
  const authUser = useAuth(s => s.authUser)
  const isAdmin = useAuth(s => s.admin)
  const { showMessage } = useContext(PopUpMessageContext)
  const [isOpen, setIsOpen] = useState(false)
  const callInComplete = useMemo(() => getCoverageRequiresCallIn(coverage), [coverage])

  const existingId = useMemo(() => Object.keys(callInRequests ?? {})[0], [callInRequests])

  const existingRequest = useMemo(
    () => callInRequests?.[existingId] ?? null,
    [existingId, callInRequests],
  )

  const initData = useMemo(
    () => ({
      message: existingRequest?.message ?? '',
      sendEmail: !callInComplete,
    }),
    [existingRequest, callInComplete],
  )

  const handleRequestCallIn = useCallback(
    async (data: FieldMapValue) => {
      if (!authUser) {
        showMessage({ text: 'Not signed in', type: 'error' })
        return { [FORM_ERROR]: 'Not signed in' }
      }
      if (!isAdmin) {
        showMessage({ text: 'Only admins can request call in', type: 'error' })
        return { [FORM_ERROR]: 'Only admins can request call in' }
      }
      if (!coverage) {
        showMessage({ text: 'Coverage not found', type: 'error' })
        return { [FORM_ERROR]: 'Coverage not found' }
      }
      try {
        const {
          data: { success },
        } = await requestCallIn({
          patientId: patientRef.id,
          // assessmentId,
          coverageId: coverage.id,
          message: data.message ?? '',
          sendEmail: data.sendEmail || false,
        })

        showMessage({
          type: 'success',
          text: 'Call in requested',
          subText: success,
        })
      } catch (err: any) {
        showMessage({
          text: 'Error requesting call in',
          subText: err?.message,
          type: 'error',
        })
        return { [FORM_ERROR]: 'Error requesting call in' }
      }
      setIsOpen(false)
      return undefined
    },
    [authUser, isAdmin, showMessage, coverage, patientRef],
  )
  return isAdmin ? (
    <>
      {existingId ? (
        <SolidActionButton
          pointerEvents={visible ? 'auto' : 'none'}
          opacity={visible ? 1 : 0}
          transition="opacity 300ms"
          fontWeight={500}
          borderRadius="full"
          bg="green.400"
          _hover={{
            bg: 'green.300',
          }}
          size="xs"
          gap={1}
          fontFamily="Hero-New"
          onClick={e => {
            e.stopPropagation()
            setIsOpen(true)
          }}>
          <EditIcon filter="drop-shadow(1px 1px 3px #00000066)" />
          <Text>CALL-IN REQUESTED</Text>
        </SolidActionButton>
      ) : (
        <ActionButton
          fontWeight={500}
          borderRadius="full"
          ml="auto"
          bg="whiteAlpha.500"
          _hover={{
            bg: 'whiteAlpha.700',
          }}
          size="xs"
          fontFamily="Hero-New"
          onClick={e => {
            e.stopPropagation()
            setIsOpen(true)
          }}>
          REQUEST CALL-IN
        </ActionButton>
      )}
      {isOpen ? (
        <DefaultModal
          overlayHeader
          isOpen={isOpen}
          contentProps={{ bg: 'gray.100' }}
          onClose={() => setIsOpen(false)}
          render={() => (
            <Flex flexFlow="column" py={3} px={4}>
              <Text fontSize="lg" fontWeight={600} color="gray.600">
                {existingRequest ? 'Call-In Request' : 'Request Call-In'}
              </Text>
              {existingRequest ? (
                <Flex flexFlow="column">
                  <ActionLog action="Requested" on={existingRequest.on} by={existingRequest.by} />
                  <Text color={existingRequest.message ? 'gray.600' : 'gray.500'}>
                    {existingRequest.message || 'No message'}
                  </Text>
                </Flex>
              ) : (
                <SimpleForm
                  canSubmitClean
                  submitText="Send Request"
                  boxProps={{ boxShadow: 'none', bg: 'transparent', p: 0 }}
                  field={field}
                  value={initData}
                  onSubmit={handleRequestCallIn}
                />
              )}
            </Flex>
          )}
        />
      ) : null}
    </>
  ) : null
}

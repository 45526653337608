import { Box, Flex, HStack, Progress, Stack, StackDivider } from '@chakra-ui/react'
import {
  adminsCollection,
  AnyObject,
  CollectionFilter,
  CollectionSort,
  SortKey,
  WithId,
} from '@hb/shared'
import useResizeObserver from '@react-hook/resize-observer'
import { FieldPath } from 'firebase/firestore'
import React, {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate } from 'react-router'
import { VariableSizeGrid, VariableSizeList } from 'react-window'
import { useCollection } from '../../collections/hooks/useCollection'
import {
  DataListContext,
  DataListContextType,
  ScreenContext,
  SearchBarContext,
} from '../../contexts'
import { useApp } from '../../contexts/AppContext'
import { useListQuery } from '../../hooks'
import { useAuth } from '../../store'
import { DataColumns, DataListTab } from '../../types/data'
import { GenericEditModal } from '../DataView'
import { DataListFooter } from './DataListFooter'
import { DataListTabs } from './DataListTabs'
import { DesktopDataList } from './DesktopDataList'
import { MobileDataList } from './MobileDataList'
import { DataListPresetFilters } from './PresetFilters'
import { SearchBar } from './SearchBar'
import { getFlexColumnWidth } from './utils'

const excludeOmittedBooleanFilters = <Data extends AnyObject>(
  filters: CollectionFilter<Data>[],
  userFilters: CollectionFilter<Data>[],
) => filters.filter(f => !userFilters.find(uf => uf[0] === f[0] && typeof uf[2] === 'boolean'))
const defaultItemHeight = 36
const numItems = 100

export const DataList = <Data extends AnyObject, ExtraColumnProps extends AnyObject = AnyObject>({
  tabs,
  rootPath,
  tabIndex: _tabIndex,
  onRowClick,
  onTabChange,
  preview,
  width: propWidth,
  extraColumnProps,
  height,
}: {
  tabs: Record<string, DataListTab<Data, ExtraColumnProps>>
  rootPath: string
  extraColumnProps?: ExtraColumnProps
  tabIndex?: number
  onTabChange?: (index: number) => void
  preview?: boolean
  onRowClick?: (id: string, tabIndex: number) => void
  width?: number
  height: number
}) => {
  const { searchQuery, updateQuery, inputRef } = useContext(SearchBarContext)
  const navigate = useNavigate()
  const selectItem = useCallback(
    (id: string | null) => navigate(id ? `/admin/${rootPath}/${id}` : `/admin/${rootPath}`),
    [navigate, rootPath],
  )

  const [userFilters, setUserFilters] = useState<CollectionFilter<Data>[]>([])
  const [autoTabIndex, setTabIndex] = useState(0)

  const tabChangeLoadTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [tabChangeLoading, setTabChangeLoading] = useState(false)
  useEffect(() => {
    if (tabChangeLoading) {
      tabChangeLoadTimeout.current = setTimeout(() => {
        setTabChangeLoading(false)
      }, 500)
    }
    return () => {
      if (tabChangeLoadTimeout.current) {
        clearTimeout(tabChangeLoadTimeout.current)
        tabChangeLoadTimeout.current = null
      }
    }
  }, [tabChangeLoading])

  const tabIndex = useMemo(() => _tabIndex ?? autoTabIndex, [_tabIndex, autoTabIndex])
  const handleTabChange = useCallback(
    (index: number) => {
      if (index === tabIndex) return
      setTabChangeLoading(true)
      setUserFilters([])
      const newTab = tabs[Object.keys(tabs)[index]]
      if (!newTab) return
      setSort({
        field: newTab.defaultSort.field,
        order: newTab.defaultSort.order,
      })
      if (onTabChange) onTabChange(index)
      else setTabIndex(index)
    },
    [onTabChange, tabIndex, tabs],
  )
  const tabName = useMemo(() => Object.keys(tabs)[tabIndex], [tabIndex, tabs])

  const tab = useMemo(() => tabs[tabName] || tabs[Object.keys(tabs)[0]], [tabName, tabs])
  const { columns, transformData, itemName, creation, itemHeight, baseQuery } = tab
  const [sort, setSort] = useState<CollectionSort<Data>>(tab.defaultSort)

  const sortBy = useCallback(
    (k: SortKey<Data>, direction?: 'asc' | 'desc') => {
      if (!k) return
      // apply current search query
      if (inputRef.current) {
        updateQuery(inputRef.current.value)
      }
      if (k === sort?.field) {
        setSort({ ...sort, order: sort.order === 'asc' ? 'desc' : 'asc' })
      } else {
        setSort({
          field: k,
          order: direction === 'asc' ? 'asc' : 'desc',
        })
      }
    },
    [sort, updateQuery, inputRef],
  )

  const filterBy = useCallback(
    (filter: CollectionFilter<Data>) => {
      // apply current search query
      if (inputRef.current) {
        updateQuery(inputRef.current.value)
      }
      setUserFilters(prev => {
        let newFilters = prev.filter(f => f[0] !== filter[0])
        if (filter[1] === 'array-contains') {
          newFilters = newFilters.filter(f => f[1] !== 'array-contains')
        }
        newFilters.push(filter)
        return newFilters
      })
    },
    [setUserFilters, updateQuery, inputRef],
  )

  const removeFilter = useCallback(
    (filterKey: string | FieldPath) => {
      setUserFilters(prev => prev.filter(f => f[0] !== filterKey))
    },
    [setUserFilters],
  )

  // const filters = useMemo(() => excludeOmittedBooleanFilters(userFilters), [userFilters])

  const appData = useApp()

  const [newItemModalOpen, setNewItemModalOpen] = useState(false)

  useEffect(() => {}, [tab])

  const {
    items,
    loading: dataLoading,
    goNext,
    goPrev,
    canGoNext,
    canGoPrev,
    goToPage,
    pageNum,
    refetchItem,
    queryCount,
    totalPages,
  } = useListQuery<Data>(
    {
      sort,
      sortFunc: sort ? columns[sort.field]?.sortFunc : undefined,
      search: searchQuery,
      secondarySort: tab.defaultSort,
      filters: userFilters,
      ...tab,
    },
    numItems,
  )
  const ref = useRef<VariableSizeList | VariableSizeGrid>(null)

  const hasMultipleTabs = useMemo(() => Object.keys(tabs).length > 1, [tabs])

  const admins = useCollection(adminsCollection)

  const { width: screenWidth } = useContext(ScreenContext)

  const fullWidth = useMemo(
    () => propWidth ?? Math.max(0, screenWidth - screenWidth * 0.0725),
    [screenWidth, propWidth],
  )

  const [headerHeight, setHeaderHeight] = useState(0)
  const [footerHeight, setFooterHeight] = useState(0)
  const headerRef = useRef<HTMLDivElement>(null)

  const onHeaderResize = useCallback(
    (entry: ResizeObserverEntry) => {
      setHeaderHeight(entry.contentRect.height)
    },
    [setHeaderHeight],
  )

  useResizeObserver(headerRef, onHeaderResize)

  const bodyHeight = useMemo(
    () => (preview ? height : height - headerHeight - footerHeight - 50),
    [height, headerHeight, footerHeight, preview],
  )

  const gridView = useMemo(() => fullWidth > 800, [fullWidth])
  const width = useMemo(() => {
    const hasNoFlexCols = Object.values(tab.columns).every(c => !c.width)
    const preScrollbar = hasNoFlexCols
      ? Object.values(tab.columns).reduce((acc, curr) => acc + (curr.width ?? 0), 0)
      : fullWidth
    const gridItemHeight = typeof itemHeight === 'number' ? (itemHeight ?? defaultItemHeight) : null
    const hasScrollbar =
      gridView && gridItemHeight
        ? gridItemHeight * Object.keys(items ?? {}).length > bodyHeight
        : false
    return preScrollbar - (hasScrollbar ? 8 : 0)
  }, [fullWidth, tab, gridView, items, bodyHeight, itemHeight])

  const usedItemHeight = useMemo(() => itemHeight ?? defaultItemHeight, [itemHeight])

  const toggleSortDirection = useCallback(() => {
    setSort(s => ({
      ...s,
      order: s.order === 'asc' ? 'desc' : 'asc',
    }))
  }, [])

  const filters = useMemo(
    () => excludeOmittedBooleanFilters(tab.baseQuery.filters ?? [], userFilters),
    [tab, userFilters],
  )
  const contextData = useMemo<DataListContextType<Data, ExtraColumnProps>>(
    () => ({
      baseQuery,
      sortBy,
      tab,
      filterBy,
      removeFilter,
      toggleSortDirection,
      refetchItem,
      filters,
      admins,
      sort,
      userFilters,
      onRowClick,
      extraColumnProps,
      gridView,
    }),
    [
      sortBy,
      onRowClick,
      sort,
      baseQuery,
      gridView,
      refetchItem,
      userFilters,
      toggleSortDirection,
      extraColumnProps,
      admins,
      filterBy,
      removeFilter,
      tab,
      filters,
    ],
  )

  const initialNewItemData = useMemo(() => {
    const initData = creation?.initialData
    if (!initData || !newItemModalOpen) return null
    return typeof initData === 'function' ? initData() : initData
  }, [creation, newItemModalOpen])

  const loading = useMemo(() => dataLoading || tabChangeLoading, [dataLoading, tabChangeLoading])

  const { onCreate } = creation ?? {}
  useEffect(() => {
    const asGrid = ref.current as VariableSizeGrid | undefined
    const asList = ref.current as VariableSizeList | undefined
    const rowIndexReset = gridView ? asGrid?.resetAfterRowIndex : asList?.resetAfterIndex
    if (!rowIndexReset) return
    rowIndexReset(0)
  }, [usedItemHeight, gridView, items])
  const onCreateClick = useMemo(() => {
    if (!creation) return undefined
    const { onCreateClick } = creation
    if (onCreateClick)
      return () => {
        onCreateClick()
      }
    else
      return () => {
        setNewItemModalOpen(true)
      }
  }, [creation])
  // const [selectedIndex, setSelectedIndex] = useState(0)
  return (
    <DataListContext.Provider value={contextData}>
      <Flex height={`${height}px`} position="relative" direction="column" align="flex-start">
        <Stack
          width={fullWidth}
          divider={<StackDivider />}
          spacing={0}
          height="100%"
          zIndex={1}
          boxShadow="0 0 6px rgba(0,0,0,0.5)"
          bg="white">
          {preview ? null : (
            <Flex zIndex={2} flexFlow="column" w="100%" ref={headerRef}>
              {hasMultipleTabs ? (
                <DataListTabs index={tabIndex} onChange={handleTabChange} tabs={tabs} />
              ) : null}
              <HStack w="100%" px={1} pt={hasMultipleTabs ? 0 : 1}>
                {tab.searchStringPath ? (
                  <Box flex={1}>
                    <SearchBar
                      tooltip={tab.searchTip}
                      onAddClick={onCreateClick}
                      itemName={itemName}
                      creationItemName={creation?.itemName}
                    />
                  </Box>
                ) : null}
                <DataListPresetFilters />
              </HStack>
              <Progress colorScheme="green" height="5px" isIndeterminate={loading} />
            </Flex>
          )}
          {!gridView ? (
            <MobileDataList
              ref={ref as RefObject<VariableSizeList>}
              active
              // sortAsc={sortAsc}
              admins={admins}
              toggleSortDirection={toggleSortDirection}
              loading={loading}
              selectUser={selectItem}
              defaultSort={sort}
              preview={preview}
              width={width}
              fullWidth={fullWidth}
              height={bodyHeight}
              onRowClick={
                onRowClick
                  ? id => {
                      onRowClick(id, tabIndex)
                    }
                  : undefined
              }
              numRows={numItems}
              tabName={tabName}
              extraColumnProps={extraColumnProps}
              columns={columns}
              transformData={transformData}
              sortBy={sortBy}
              tab={tab}
              items={items}
            />
          ) : (
            <DesktopDataList
              ref={ref as RefObject<VariableSizeGrid>}
              active
              admins={admins}
              defaultSort={sort}
              toggleSortDirection={toggleSortDirection}
              loading={loading}
              selectUser={selectItem}
              preview={preview}
              width={width}
              fullWidth={fullWidth}
              height={bodyHeight}
              extraColumnProps={extraColumnProps}
              numRows={numItems}
              tabName={tabName}
              onRowClick={
                onRowClick
                  ? id => {
                      onRowClick(id, tabIndex)
                    }
                  : undefined
              }
              columns={columns}
              transformData={transformData}
              sortBy={sortBy}
              tab={tab}
              itemHeight={usedItemHeight}
              // filtered={users}
              items={items}
            />
          )}
          {preview ? null : (
            <DataListFooter
              canGoNext={canGoNext}
              canGoPrev={canGoPrev}
              onGoNext={goNext}
              onGoPrev={goPrev}
              mobileLayout={!gridView}
              numItems={numItems}
              isGrid={gridView}
              onHeightChange={setFooterHeight}
              pageNum={pageNum}
              totalPages={totalPages}
              goToPage={goToPage}
              items={items}
              loading={loading}
              queryCount={queryCount}
              gridRef={ref}
            />
          )}
        </Stack>
      </Flex>
      {creation && onCreate && newItemModalOpen ? (
        <GenericEditModal
          field={creation.field}
          isOpen={newItemModalOpen}
          data={initialNewItemData}
          onClose={() => {
            setNewItemModalOpen(false)
          }}
          submitText={creation?.submitText}
          onSubmit={d => onCreate(d, appData)}
        />
      ) : null}
    </DataListContext.Provider>
  )
}

export const StandaloneRow = <T extends WithId, ExtraProps extends AnyObject = AnyObject>({
  columns,
  width,
  extraProps,
  data,
}: {
  columns: DataColumns<T, ExtraProps>
  extraProps?: ExtraProps
  width: number
  data: T
}) => {
  const flexColumnWidth = useMemo(() => getFlexColumnWidth({ width, columns }), [columns, width])
  const { searchQuery } = useContext(SearchBarContext)
  const admins = useCollection(adminsCollection)
  const auth = useAuth()
  const app = useApp()
  return (
    <Flex width={width}>
      {Object.values(columns).map((c, i) => (
        <Flex width={c.width ? `${c.width}px` : flexColumnWidth} key={`col_${i}`}>
          {c.Render({
            data,
            app,
            searchQuery,
            admins,
            preview: {
              openPreview: () => {},
              closePreview: () => {},
              preview: null,
            },
            tabName: '',
            auth,
            isScrolling: false,
            cell: { rowIndex: 0, columnIndex: i },
            ...((extraProps ?? {}) as ExtraProps),
          })}
        </Flex>
      ))}
    </Flex>
  )
}

import { type AppName } from '../app'
import {
  BaseInsuranceCoverage,
  CoverageWithMetadata,
  InsuranceCoverage,
  InsuranceCoverageRequest,
  WithPregnancyCoverageLabel,
} from '../assessments'
import { FileDBValue } from '../data'
import { WithMetadata } from '../shared/db'
import { Address, FieldMapValue } from '../utils'

export type AssessmentFormKey = 'introduction' | 'call-in' | 'plan-options' | 'schedule-call'

export type AssessmentFormAlias = 'planOptions' | 'scheduleCall'

export interface UserInsurancePlans<T extends BaseInsuranceCoverage = CoverageWithMetadata> {
  primary?: T | null
  secondary?: T | null
  additional?: Record<string, T>
  requests?: Record<string, InsuranceCoverageRequest>
}

export interface PregnancyInsurancePlans<T extends BaseInsuranceCoverage = CoverageWithMetadata> {
  nonMedicaid: T | null
  medicaid: T | null
  potential: Array<T>
}

// export type PregnancyInsurancePlans = {

// }

export type PopulatedPregnancyPlan = WithPregnancyCoverageLabel<InsuranceCoverage>
export type PopulatedInsurancePlans = Required<UserInsurancePlans<InsuranceCoverage>>
export type PopulatedPregnancyPlans = Required<PregnancyInsurancePlans<PopulatedPregnancyPlan>> & {
  primaryIsMedicaid: boolean
}

export const isPopulatedPregnancyPlan = (
  plan: PopulatedPregnancyPlan | InsuranceCoverage | BaseInsuranceCoverage,
): plan is PopulatedPregnancyPlan => (plan as PopulatedPregnancyPlan).pregnancyLabel !== undefined

export type InsuranceInfoOption =
  | 'one-plan'
  | 'two-plans'
  | 'medicaid'
  | 'no-coverage'
  | 'coverage-will-change'
  | 'multiple-options'
  | 'not-pregnant'
type MultipleCoverageOption =
  | 'partner-individual'
  | 'partner-employer'
  | 'multiple-employer'
  | 'other'

export interface InsuranceInfo {
  option?: InsuranceInfoOption
  onePlanIsMedicaid?: boolean
  twoPlansNote?: string
  noCoverageDescription?: string
  // coverage will change
  coverageWillChangeDescription?: string
  newCoverageStartDate?: number
  employmentWillChangeNote?: string
  employmentWillChangeUnknownNote?: string
  coverageWillDropNote?: string
  addedToAnotherPlanNote?: string
  // multiple options
  multipleOptionsDescription?: MultipleCoverageOption
  partnerIndividualNote?: string
  partnerEmployerNote?: string
  multipleEmployerNote?: string
  confirmPartnerPlan?: boolean
  planToAssess?: 'current-plan' | 'partner-plan'
  planCoverageType?: string
  reimbursementSystem?: string
  reimbursementRate?: number
  employmentOptionsFile?: FileDBValue
  coverageStartDate?: number
  multipleOptionsOtherNotes?: string
  // not pregnant
  notPregnantNote?: string
}

export interface User {
  name?: string | null
  dob?: number | null
  fname?: string | null
  lname?: string | null
  middleName?: string | null
  pronouns?: string | null
  nickname?: string | null
  email?: string | null
  voicemailOk?: boolean
  textOk?: boolean
  phone?: string
  isInvite: boolean

  signOnData?: FieldMapValue
  signOnCorrections?: FieldMapValue
  signedOnDate?: number
  emailVerified?: { on: number; by: string }
  invitedOn?: number
  joinedOn?: number
  createdOn: number
  createdBy?: string
  midwives?: string[]

  // from assessment sign on data
  homeAddress?: Address

  // on assessment modal
  showPayments?: boolean

  // for practice users
  selectedPracticeId?: string

  profilePicture?: WithMetadata<FileDBValue>
  shouldResetPassword?: boolean

  insuranceInfo?: {
    data?: InsuranceInfo
    corrections?: InsuranceInfo
  }
  insurancePlans?: UserInsurancePlans

  forceUpdateOn?: number
}

export interface UserStatus {
  updated: string
  online: boolean
}

export type UserData = Omit<User, 'forceUpdateOn'>

export interface GoogleUserProps {
  email: string | null
  displayName: string | null
  uid: string
}

export interface UserCallback {
  success?: string
  error?: string
}

export type OnUserSnapshot = (authUser: GoogleUserProps, dbUser: User) => void

export type ProfileType = 'pregnancy' | 'office-visit'
export interface UserInviteProps {
  email?: string
  fname: string
  lname: string
  role?: string
  verifyEmail: boolean
  practiceId?: string
  phone?: string
  dob?: number
  messageToAdmins?: string
  appName: AppName
  profileType: ProfileType
}

export interface UserInviteResponse {
  patientId: string
  pregnancyId: string | null
}

export interface UserInvite extends User {
  sendInviteEmail?: boolean
  fname?: string
  lname?: string
  customMessage?: string
  invitedOn?: number
}

import { Button } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

export const ViewMoreButton = ({
  onClick,
  children,
}: PropsWithChildren<{ onClick: () => void }>) => (
  <Button
    ml="auto"
    justifyContent="flex-end"
    size="sm"
    color="gray.600"
    onClick={onClick}
    variant="link">
    {children}
  </Button>
)

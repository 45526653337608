import { Button, Flex, HStack, StackDivider, Text, VStack } from '@chakra-ui/react'
import { colors } from '@hb/shared'

import React, { useContext, useMemo } from 'react'

import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Link } from 'react-router'
import { AppContext } from '../../../../contexts/AppContext'
import { ProfileContext } from '../../../../contexts/ProfileContext'
import { useCreateAssessment } from '../../../../hooks/backend/assessments'
import { useUpdateDoc } from '../../../../hooks/backend/useUpdateDoc'
import { SolidActionButton } from '../../../Buttons'
import { NoteForm } from '../../../forms/NoteForm'
import { Subheader } from '../../Text'
import { AddAssessmentAlert } from '../Assessments/AddAssessmentAlert'
import { PatientContact } from './PatientContact'
import { PatientPregnancySummary } from './PatientPregnancySummary'

const PregnancySummaryBox = () => (
  <VStack
    spacing={0}
    px={2}
    py={3}
    boxShadow="1px 1px 3px #00000077"
    w="100%"
    bg="white"
    align="flex-start"
    borderRadius={6}>
    <PatientPregnancySummary />
  </VStack>
)

export const PatientSummary: React.FC = () => {
  const {
    user,
    adminPatientRef,
    practicePatientRef,
    assessmentId,
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(ProfileContext)
  const { patientNote } = user ?? {}

  const { appName } = useContext(AppContext)
  const update = useUpdateDoc('patient')

  const { addLoading, confirmingNewAssessment, createAssessment, setConfirmingNewAssessment } =
    useCreateAssessment()

  const patientPath = useMemo(
    () => `${appName === 'app' ? '/admin' : ''}/patients/${user?.id}`,
    [appName, user?.id],
  )

  return (
    <VStack spacing={2} px={4} width="100%">
      <Flex
        px={3}
        flexFlow="column"
        borderRadius={6}
        pt={1}
        gap={2}
        w="100%"
        boxShadow="1px 1px 3px #00000077"
        bg="white">
        <HStack pt={1}>
          <Flex gap={2} align="center">
            <Subheader>Patient Info</Subheader>
            {assessmentId ? (
              <Link style={{ display: 'flex', alignItems: 'center' }} to={patientPath}>
                <Button gap={1} size="xs" variant="link">
                  <Text as="span" fontSize="xs">
                    Go to Patient Profile
                  </Text>
                  <ArrowForwardIcon fontSize="xs" />
                </Button>
              </Link>
            ) : null}
          </Flex>
          <SolidActionButton
            onClick={() => setConfirmingNewAssessment(true)}
            isLoading={addLoading}
            bg={colors.green.hex}
            color="white"
            size="xs"
            fontFamily="Comfortaa"
            ml="auto">
            + NEW PREGNANCY
          </SolidActionButton>
        </HStack>
        <VStack
          divider={<StackDivider borderColor="blackAlpha.300" />}
          spacing={1}
          align="flex-start"
          w="100%"
          p={3}
          bg={`${colors.pink.hex}66`}
          boxShadow="inset 0 0 4px #333">
          <PatientContact />
        </VStack>
        <NoteForm
          note={patientNote}
          boxProps={{ background: 'transparent', boxShadow: 'none', p: 0 }}
          placeholder="Patient notes"
          onSubmit={data =>
            update(appName === 'app' ? adminPatientRef : practicePatientRef, 'patientNote', data)
          }
        />
      </Flex>
      {selectedAssessment ? <PregnancySummaryBox /> : null}
      <AddAssessmentAlert
        onConfirm={createAssessment}
        isOpen={confirmingNewAssessment}
        onCancel={() => setConfirmingNewAssessment(false)}
      />
    </VStack>
  )
}

import {
  adminRoles,
  BUG_REPORTS,
  BugReport,
  CollectionFilter,
  CollectionSort,
  getAppRole,
} from '@hb/shared'
import { collection, orderBy, Query, query, where } from 'firebase/firestore'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { db } from '../../../backend'
import { useApp } from '../../../contexts/AppContext'
import { usePracticeAccess } from '../../../contexts/PracticeAccess/PracticeAccessProvider'
import { useQuery } from '../../../hooks'
import { useAuth } from '../../../store'
import { notArchivedFilter, openFilter } from './constants'

export const useBugReports = () => {
  const [filters, setFilters] = useState<CollectionFilter<BugReport>[]>([
    openFilter,
    notArchivedFilter,
  ])
  const { appName } = useApp()
  const authUser = useAuth(s => s.authUser)
  const claims = useAuth(s => s.claims)
  const { selectedPracticeId } = usePracticeAccess()
  const appRole = useMemo(
    () => getAppRole(appName, authUser?.uid ?? null, claims, selectedPracticeId),
    [appName, claims, selectedPracticeId, authUser],
  )

  const [sort, setSort] = useState<CollectionSort<BugReport>>({ field: 'createdOn', order: 'asc' })
  const toggleFilter = useCallback(
    (filter: CollectionFilter<BugReport>) => {
      if (filters.includes(filter)) {
        setFilters(filters.filter(f => f !== filter))
      } else {
        setFilters([
          ...filters.filter(f => filter[0] !== f[0] && filter[1] !== '!=' && f[1] !== '!='),
          filter,
        ])
      }
    },
    [filters],
  )

  const bugReportsQuery = useMemo(() => {
    if (!appRole) return null
    if (!adminRoles.includes(appRole)) {
      return null
    }
    let baseQuery = query(collection(db, BUG_REPORTS))
    filters.forEach(filter => {
      baseQuery = query(baseQuery, where(...filter))
    })
    return query(baseQuery, orderBy(sort.field, sort.order)) as Query<BugReport>
  }, [filters, sort, appRole])

  const bugReports = useQuery<BugReport>(
    appRole && adminRoles.includes(appRole) ? bugReportsQuery : null,
  )
  const { bugReportId: selectedBugReportId } = useParams<{
    bugReportId?: string
  }>()
  const navigate = useNavigate()
  const selectBugReport = useCallback(
    (id: string) => navigate(`/admin/${BUG_REPORTS}/${id}`),
    [navigate],
  )
  const deselectBugReport = useCallback(() => navigate(`/admin/${BUG_REPORTS}`), [navigate])

  return useMemo(
    () => ({
      bugReportsQuery,
      filters,
      sort,
      bugReports,
      selectedBugReportId,
      selectBugReport,
      deselectBugReport,
      toggleFilter,
      setSort,
    }),
    [
      bugReportsQuery,
      filters,
      sort,
      toggleFilter,
      bugReports,
      selectedBugReportId,
      selectBugReport,
      deselectBugReport,
    ],
  )
}

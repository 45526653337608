import { Box, Button, Center, Collapse, Divider, Flex, Text, VStack } from '@chakra-ui/react'
import { colors, objectToArray } from '@hb/shared'
import React, { JSX, useMemo, useState } from 'react'
import { useProfile } from '../../../../../../contexts/ProfileContext'
import { BasicPregnancyPreview } from '../../../../../Assessments'
import { ViewMoreButton } from '../../../../../Buttons/ViewMoreButton'
import { PatientInsuranceSummary } from '../../../Patient/PatientInsuranceSummary'
import { PatientPregnancySummary } from '../../../Patient/PatientPregnancySummary'
import { PatientVisitsSummary } from '../../../Patient/PatientVisitsSummary'
import { PatientAlerts } from './PatientAlerts'
import { PatientName } from './PatientName'
import { PatientNotes } from './PatientNotes'

const GoToConsentForms = () => {
  const { goTo, consentForms } = useProfile()
  const { data } = consentForms
  const { numConsentForms, numSigned, progress } = useMemo(() => {
    const numConsentForms = Object.keys(data ?? {}).length
    const numSigned = Object.values(data ?? {}).filter(c => !!c.signedOn).length
    const progress = numConsentForms ? (numSigned / numConsentForms) * 100 : 0
    return { numConsentForms, numSigned, progress }
  }, [data])
  return (
    <Center py={2} w="100%">
      <Button
        onClick={() => goTo({ entity: { type: 'patient' }, tab: 'consent-forms' })}
        color={numConsentForms ? 'white' : 'gray.500'}
        textShadow={numConsentForms ? '1px 1px 3px #00000077' : 'none'}
        bg={numConsentForms ? colors.green.hex : 'gray.50'}
        size="sm"
        border="1px solid"
        borderColor={numConsentForms ? colors.green.hex : 'gray.400'}
        position="relative">
        <Box
          position="absolute"
          top={0}
          left={0}
          bottom={0}
          w={`${progress}%`}
          bg={colors.green.hex}
        />
        <Text zIndex={1}>
          {numConsentForms
            ? `${numSigned}/${numConsentForms} Consent Form${numConsentForms === 1 ? '' : 's'} Signed`
            : 'No Consent Forms'}
        </Text>
      </Button>
    </Center>
  )
}

const MorePregnanciesSummary = () => {
  const { selectedAssessment, assessments, mostRecentPregnancy, goTo } = useProfile()

  const [viewingMore, setViewingMore] = useState(false)
  const nonSelectedAssessments = useMemo(() => {
    const displayedAssessmentId = selectedAssessment?.id ?? mostRecentPregnancy?.id
    return objectToArray(assessments ?? {}).filter(a => a.id !== displayedAssessmentId)
  }, [assessments, mostRecentPregnancy, selectedAssessment])
  let body: JSX.Element | null = null
  if (nonSelectedAssessments.length) {
    body = (
      <>
        <Flex px={2} w="100%">
          <Text color="gray.600">
            {nonSelectedAssessments.length} other{' '}
            {nonSelectedAssessments.length === 1 ? 'pregnancy' : 'pregnancies'}
          </Text>
          <ViewMoreButton onClick={() => setViewingMore(!viewingMore)}>
            {!viewingMore ? '+ View All' : '- Collapse'}
          </ViewMoreButton>
        </Flex>
        <Collapse in={viewingMore} style={{ width: '100%' }}>
          <Flex flexFlow="column" gap={1.5} p={1} w="100%">
            {nonSelectedAssessments.map(assessment => (
              <BasicPregnancyPreview
                onSelect={() =>
                  goTo({ entity: { id: assessment.id, type: 'pregnancy' }, tab: null })
                }
                key={assessment.id}
                pregnancy={assessment}
              />
            ))}
          </Flex>
        </Collapse>
      </>
    )
  } else {
    body = (
      <Text px={2} color="gray.600" fontSize="md">
        No other pregnancies
      </Text>
    )
  }
  return (
    <Box pt={0.5} pb={1} borderTop="1px solid #00000011">
      {body}
    </Box>
  )
}
const PatientInfoPregnancySummary = () => (
  <Flex flexFlow="column" w="100%">
    <PatientPregnancySummary />
    <MorePregnanciesSummary />
  </Flex>
)

export const PatientInfo = () => (
  <VStack
    divider={<Divider borderColor="#00000033" />}
    p={1}
    spacing={0}
    bg={`${colors.pink.hex}77`}
    flexFlow="column"
    h="100%"
    w="100%">
    <PatientName />
    <PatientAlerts />
    <PatientInfoPregnancySummary />
    <PatientVisitsSummary />
    <PatientInsuranceSummary />
    <GoToConsentForms />
    <PatientNotes />
  </VStack>
)

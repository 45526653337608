import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react'
import { colors } from '@hb/shared'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useApp } from '../../contexts/AppContext'
import { ProfileContext, useProfile } from '../../contexts/ProfileContext'
import { useScreen } from '../../hooks/useScreen'
import { MenuTabList } from '../shared/Nav/MenuTabList'
import { SubTab } from '../shared/Nav/types'
import { AssessmentPaymentsTab } from '../Users/Profile/Payments/AssessmentPayments'
import { AssessmentModal } from './AssessmentModal/AssessmentModal'
import { AssessmentNote } from './AssessmentNote'
import { AssessmentPreview } from './AssessmentPreview'
import { PregnancyPlansView } from './InsurancePlansView'
import { SignOnInfoPanel } from './SignOnInfo'

const ArchivedAssessments = ({ preview }: { preview?: boolean }) => {
  const { assessmentId, goTo, user, assessments } = useContext(ProfileContext)
  const archivedAssessments = useMemo(
    () =>
      Object.entries(assessments ?? {}).filter(
        ([id, val]) => !!val.archivedOn && id !== assessmentId,
      ),
    [assessments, assessmentId],
  )
  return user ? (
    <Accordion allowMultiple defaultIndex={[]} width="100%">
      <AccordionItem>
        <AccordionButton py={1}>
          <Box fontFamily="Hero-New" as="span" flex="1" textAlign="left">
            <Text
              fontWeight={500}
              fontSize="xs">{`ARCHIVED PREGNANCIES (${archivedAssessments.length})`}</Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px={2} py={0}>
          <Flex w="100%" direction="column" gap={2}>
            {archivedAssessments.map(([id, assessment]) => (
              <AssessmentPreview
                id={id}
                key={id}
                preview={preview}
                buttonText={id === assessmentId ? 'Selected' : 'Select'}
                onSelect={() => goTo({ entity: { type: 'pregnancy', id }, tab: null })}
                user={user}
                assessment={assessment}
              />
            ))}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  ) : null
}

export const AssessmentsView = ({ preview }: { preview?: boolean }) => {
  const {
    assessments,
    user,
    assessmentId,
    goTo,
    selectedAssessment: { populated: selectedAssessment, assessmentData: selectedAssessmentData },
    admin,
    profileVersion,
  } = useProfile()
  const notSelected = useMemo(() => {
    const entries = Object.entries(assessments ?? {})
    const filtered = preview ? entries : entries.filter(([id]) => id !== assessmentId)
    return filtered.sort(([, a], [, b]) => b.createdOn - a.createdOn)
  }, [assessments, assessmentId, preview])
  const notArchived = useMemo(() => notSelected.filter(([, val]) => !val.archivedOn), [notSelected])

  const [assessmentModalIsOpen, setAssessmentModalIsOpen] = useState(false)

  const onSelect = useCallback(
    (id: string) => {
      switch (profileVersion) {
        case 'v2':
          goTo({ entity: { type: 'pregnancy', id }, tab: null })
          break
        default:
          if (id === assessmentId) setAssessmentModalIsOpen(true)
          else goTo({ entity: { id, type: 'pregnancy' }, tab: null })
      }
    },
    [assessmentId, profileVersion, goTo],
  )

  const notSelectedButtonText = useMemo(() => {
    switch (profileVersion) {
      case 'v2':
        return 'View'
      default:
        return 'Open'
    }
  }, [profileVersion])

  return user ? (
    <VStack align="flex-start" spacing={1} width="100%">
      {assessmentId && !preview ? (
        <Flex gap={2} flexFlow="column" w="100%">
          <Text fontWeight="semibold" color="gray.500">
            Selected pregnancy
          </Text>
          <AssessmentPreview
            id={assessmentId}
            selected
            buttonText={profileVersion === 'v2' ? 'View' : 'Open'}
            preview={preview}
            onSelect={() => onSelect(assessmentId)}
            user={user}
            assessment={selectedAssessmentData}
          />
          {preview ? null : <AssessmentNote />}
        </Flex>
      ) : null}
      <Flex px={preview ? 2 : 0} flexFlow="column" w="100%">
        {preview ? null : (
          <Text px={2} fontWeight="semibold" color="gray.500">
            {assessmentId ? 'Other assessments' : 'Assessments'}
          </Text>
        )}
        <Flex w="100%" direction="column" pt={preview ? 2 : 0} gap={2}>
          {notArchived.map(([id, assessment]) => (
            <AssessmentPreview
              id={id}
              key={id}
              preview={preview}
              selected={id === assessmentId}
              buttonText={notSelectedButtonText}
              onSelect={() => onSelect(id)}
              user={user}
              assessment={assessment}
            />
          ))}
        </Flex>
      </Flex>
      <ArchivedAssessments preview={preview} />
      {user && assessmentModalIsOpen ? (
        <AssessmentModal
          assessment={selectedAssessment}
          admin={admin}
          // user={user}
          id={assessmentId}
          isOpen={assessmentModalIsOpen}
          onClose={() => setAssessmentModalIsOpen(false)}
        />
      ) : null}
    </VStack>
  ) : (
    <Text color="red.500">Error: no patient</Text>
  )
}

const DesktopTabs = ({ tabs, mobileLayout }: { tabs: Array<SubTab>; mobileLayout: boolean }) => {
  const { subTab, goTo: onTabSelect, selectedAssessment } = useProfile()
  const handleSelect = useCallback(
    (updated: string) => {
      const id = selectedAssessment.id
      if (id) onTabSelect({ entity: { type: 'pregnancy', id }, tab: updated })
    },
    [onTabSelect, selectedAssessment],
  )
  if (mobileLayout) {
    return <MenuTabList onSelect={handleSelect} tabs={tabs} path={subTab?.path} />
  }
  return (
    <TabList>
      {tabs.map((tab, i) => (
        <Tab
          mr={3}
          key={i}
          background="white"
          fontSize="sm"
          _selected={{ background: colors.pink.hex, color: '#333' }}
          shadow="md">
          {tab.name}
        </Tab>
      ))}
    </TabList>
  )
}

export const AssessmentSummary = ({ width }: { width: number }) => {
  const { appName } = useApp()
  const { isMobile } = useScreen()
  const mobileLayout = useMemo(() => width < 500, [width])
  const { goTo: onTabSelect, subTab, selectedAssessment, subTabs } = useProfile()
  // const [tabIndex, setTabIndex] = useState(0)
  const tabIndex = useMemo(() => {
    if (!subTab) return 0
    const idx = subTabs.findIndex(t => t.path === subTab.path)
    return idx >= 0 ? idx : 0
  }, [subTabs, subTab])
  const onTabChange = useCallback(
    (tabIndex: number) => {
      const selectedTab = subTabs[tabIndex]
      if (selectedTab) {
        onTabSelect({
          entity: { type: 'pregnancy', id: selectedAssessment.id },
          tab: `pregnancy/${selectedTab.path}`,
        })
      }
    },
    [onTabSelect, selectedAssessment, subTabs],
  )

  return (
    <Box w="100%">
      <Tabs onChange={onTabChange} index={tabIndex} size="sm" isLazy variant="soft-rounded">
        {!isMobile ? (
          <Flex
            borderBottom={mobileLayout ? '1px solid #cdcdcd' : 'none'}
            bg={mobileLayout ? 'white' : 'transparent'}
            gap={0}
            px={mobileLayout ? 0 : 4}
            pt={mobileLayout ? 0 : 2}
            w="100%">
            <DesktopTabs mobileLayout={mobileLayout} tabs={subTabs} />
          </Flex>
        ) : null}
        <TabPanels>
          {appName === 'app' ? (
            <TabPanel py={isMobile ? 1 : 2} px={isMobile ? 1 : 4}>
              <AssessmentsView />
            </TabPanel>
          ) : (
            <TabPanel py={isMobile ? 1 : 2} px={isMobile ? 1 : 4}>
              <SignOnInfoPanel mobileLayout={mobileLayout} />
            </TabPanel>
          )}
          {appName === 'app' ? (
            <TabPanel>
              <SignOnInfoPanel mobileLayout={mobileLayout} />
            </TabPanel>
          ) : (
            <TabPanel>
              <AssessmentsView />
            </TabPanel>
          )}
          {appName === 'providers-app' ? (
            <TabPanel py={0} px={2}>
              <AssessmentPaymentsTab />
            </TabPanel>
          ) : (
            <TabPanel p={3}>
              <Box borderRadius={4} boxShadow="md" bg="white" w="100%">
                <PregnancyPlansView />
              </Box>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Box>
  )
}

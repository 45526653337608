import { type Descendant, type EditorVersion } from '../../editor/types'
import {
  ChargedPaymentReceived,
  CustomFeePercent,
  Invoice,
  PracticeInvoiceOmission,
} from '../../invoicing/types/invoice'
import { Appointment } from '../appointments'
import { PatientAuthorization } from '../authorizations'
import { EligibilityResponse, RealtimeEligibilityRequest, type SubmitNewClaimArgs } from '../claims'
import { Claim, type ClaimSnippet } from '../claims/claim'
import { FileDBValue } from '../data'
import { InsurancePlan, InsuranceProvider } from '../insurance'
import { AssessmentPaymentsData, PaymentDue, PaymentReceived } from '../payments'
import { Alert } from '../shared/alert'
import { DBMetadata, UserGroup, WithMetadata, WithPartialMetadata } from '../shared/db'
import { TemplateEditorDraft, TemplateExternalPdf, TemplateKey } from '../templates'
import { AdminUserData, PatientNameItem, PopulatedPregnancyPlans, UserRoleItem } from '../users'
import {
  Address,
  AssessmentStatus,
  FieldMapValue,
  Log,
  NextAction,
  Note,
  RedFlagRecord,
  WithId,
} from '../utils'
import { AssessmentStages } from './shared'

export type PracticeAssessmentStage =
  | 'Inquiry'
  | 'Current'
  | 'Postpartum'
  | 'Gyn'
  | 'Complete'
  | 'Other'
  | 'Discharged'
export type PracticeAssessmentStages = PracticeAssessmentStage[]

export interface FaxRecord {
  sentOn: number
  sentBy: string
  text: any[]
}

export interface ReferenceNumberData {
  referenceNumber?: string
  notes?: string
  updatedOn?: number
  updatedBy?: string
}

export interface CoverageNextActions {
  additionalPlans?: Record<string, NextAction>
  primaryCoverage?: NextAction | null
  secondaryCoverage?: NextAction | null
}

export interface AdminAssessmentData {
  // useManualPayments?: boolean
  // manualPayments?: LegacyPaymentsData<any>
  // patientPrm?: number
  authorizations?: Record<string, PatientAuthorization>
  // claims?: Record<string, Claim>
  log?: Log | null
  alerts?: Record<number, string | Alert>
  urgentReason?: string | null
  urgentColor?: string | null
  claimsDrafts?: Record<string, { name: string; data: Partial<SubmitNewClaimArgs> }>
  financialAdj?: number
  balanceTowardFee?: number
  // nextAction?: NextAction | null
  nextActions?: CoverageNextActions | null
  backupNextActions?: CoverageNextActions | null
  gynNextAction?: NextAction | null

  files?: Record<string, FileDBValue>
  miscarried?: boolean
  miscarriedUpdatedOn?: number
  miscarriedUpdatedBy?: string
  miscarriedUpdatedByGroup?: 'patient' | 'practice' | 'admin'
  patientPrmNotes?: string | null
  authReferenceNumbers?: ReferenceNumberData[]
  claimsReferenceNumbers?: ReferenceNumberData[]
  assessmentNote?: Note
  authNote?: Note | null
  claimsNote?: Note
  paymentsNote?: Note | null
  redFlags?: RedFlagRecord
  payments?: AssessmentPaymentsData
  faxRecords?: Record<string, FaxRecord[]>
  hasOverduePayment?: boolean
  draft?: Descendant[]
  draftEditorVersion?: EditorVersion
  stages: AssessmentStages
  // other drafts (clinicals, receipts, etc)
  drafts?: Record<string, Descendant[] | TemplateEditorDraft>
  draftSavedOn?: number
  draftSavedBy?: string
  forceUpdatedOn?: number
}

export type PracticeAssessmentData = Pick<
  AdminAssessmentData,
  | 'balanceTowardFee'
  | 'files'
  | 'alerts'
  | 'miscarried'
  | 'assessmentNote'
  | 'miscarriedUpdatedBy'
  | 'miscarriedUpdatedByGroup'
  | 'miscarriedUpdatedOn'
  | 'financialAdj'
  | 'hasOverduePayment'
> &
  Pick<
    AssessmentSnippet,
    | keyof PatientNameItem
    | 'patientId'
    | 'assessmentName'
    | 'balanceTowardPrm'
    | 'dob'
    | 'dobString'
    | 'nickname'
    | 'markedCompleteOn'
    | 'isGynProfile'
    | 'signedOnDate'
    | 'email'
    | 'hasOverduePayment'
    | 'sharedNote'
    | 'sharedPaymentNote'
    | 'phone'
    | 'partnerName'
    | 'unreadThreadMessages'
    | 'hasComplaints'
    | 'planCoverageType'
    | 'planState'
    | 'isMarketplacePlan'
    | 'isMedicaidPlan'
    | 'clearedOn'
    | 'clearedBy'
    | 'partnerEmail'
    | 'nextActionDate'
    | 'insuranceCoverage'
    | 'nextActionText'
    | 'authRefNumbers'
    | 'claimRefNumbers'
    | 'eftCheckRefNumbers'
    | 'stringified'
    | 'archived'
    | 'edd'
    | 'prm'
    | 'deliveredOn'
    | 'status'
    | 'midwifeName'
    | 'createdOn'
  > & {
    midwifeId: string
    sortColor: string
    stages: PracticeAssessmentStages
    nextPatientAppointment: WithId<Appointment> | null
    nextPatientAppointmentDate: number | null
    archivedOn: number | null
    archivedBy: string | null
    nextAction?: NextAction | null
    payments: AssessmentPaymentsData
    delivery: AssessmentSnippet['delivery']
  }

export interface AssessmentInvoiceSnippet {
  manuallyAddedBy: string | null
  invoiceId: string
  practiceId: string
  invoiceNumber: number | null
  sentOn: string | null
  sentBy: string | null
  chargedPayments: Record<string, ChargedPaymentReceived>
  invoiceStatus: Invoice['status']
  manualPaymentIds: string[] | null
  omissions: Record<string, PracticeInvoiceOmission>
  customFeePercents: Record<string, CustomFeePercent>
  practiceName: string
  scheduledFor: string | null
  payment: {
    amount: number
    paidOn: string | null
    markedPaidBy: string | null
    paymentType: string | null
  } | null
}

export type AssessmentInvoiceSnippets = Record<string, AssessmentInvoiceSnippet>

export type DeliveryType = 'nsvd' | 'cSection' | 'breech' | 'vacuum' | 'forceps'

export interface Delivery {
  location: string
  locationOther?: string
  newbornSex: string
  deliveryTypes?: DeliveryType[]
  newbornFname: string | null
  newbornLname: string | null
  newbornWeight: number | null
  summary?: string
  isTransfer: boolean
}
export interface AssessmentSnippet
  extends Pick<UserRoleItem, keyof PatientNameItem | 'email' | 'phone' | 'dob' | 'dobString'> {
  stringified: string
  hasOverduePayment: boolean
  isGynProfile: boolean

  // derived from assessment
  patientId: string
  assessmentName: string
  nickname: string | null
  createdOn: number
  midwifeName: string
  midwifeId: string | null
  planCoverageType: string | null
  planState: string | null
  isMarketplacePlan: boolean
  isMedicaidPlan: boolean
  hasComplaints: boolean
  insurerId: string | null
  insuranceCoverage: string
  status: AssessmentStatus
  edd: number
  signedOnDate: number | null
  additionalAuthRefNumbers: ReferenceNumberData[]
  additionalClaimsRefNumbers: ReferenceNumberData[]
  claimRefNumbers: string[]
  authRefNumbers: string[]
  eftCheckRefNumbers: string[]
  claims: Record<string, ClaimSnippet>
  plans?: PopulatedPregnancyPlans
  // TODO: remove this after moving plans are linked
  // patientPlans?: PopulatedInsurancePlans
  signOnReminders: { sentOn: number }[]

  // directly copied from admin data
  stages: AdminAssessmentData['stages']
  onInquiryList: boolean
  onAuthList: boolean
  onSendClaimList: boolean
  onClaimsList: boolean
  onCompleteList: boolean

  deliveredOn: number | null
  delivery: WithMetadata<Partial<Delivery>> | null
  assessmentSentOn: number | null
  urgentColor: AdminAssessmentData['urgentColor'] | null
  urgentReason: AdminAssessmentData['urgentReason'] | null
  nextActions?: CoverageNextActions | null
  nextActionsString: string
  gynNextAction?: AdminUserData['gynNextAction'] | null
  // nextActions: AdminAssessmentData['nextActions'] | null
  // patientNextActions: AdminUserData['nextActions'] | null
  // patientGynNextAction: AdminUserData['gynNextAction'] | null
  isUrgent: boolean

  assignedAdmins: string[]
  // derived from admin data
  markedCompleteOn: number | null
  logSnippet: Log | null
  archived: boolean
  nextActionText: string
  nextActionDate: number
  partnerName: string
  partnerEmail: string
  // urgent color if set, else red if urgent, else zzz
  urgentSort: string
  redFlagText: string
  inquiryRank: number
  // invoiceIds: Array<string>
  duePayments: WithId<PaymentDue>[]
  receivedPayments: WithId<PaymentReceived>[]
  prm: number | null
  balanceTowardPrm: number | null
  unreadThreadMessages: number

  // cleared for coverage
  clearedOn: number | null
  clearedBy: string | null

  signedOnByPractice: number | null

  sharedNote: Note | null
  sharedPaymentNote: Note | null

  forceUpdateOn: number
  searchEmbedding?: number[]
  miscarried: boolean

  // TODO: remove
  // clearedOnInitializedOn?: number
}
export type AssessmentInterface = {
  [K in keyof AssessmentSnippet & keyof PracticeAssessmentData]:
    | AssessmentSnippet[K]
    | PracticeAssessmentData[K]
}

export type ClaimInterface = Pick<
  {
    [K in keyof Claim & keyof AssessmentSnippet]: Claim[K] | AssessmentSnippet[K]
  },
  | keyof PatientNameItem
  | 'authRefNumbers'
  | 'claimRefNumbers'
  | 'eftCheckRefNumbers'
  | 'nextActionsString'
  | 'dobString'
  | 'email'
  | 'phone'
  | 'names'
>

export interface AssessmentDocumentVersion {
  text: Descendant[]
  editorVersion?: EditorVersion
  sentOn: number
  sentBy: string
  viewedOn?: number | null
}

export interface ConsentFormVersion extends AssessmentDocumentVersion {
  formData: FieldMapValue | null
  toBeSignedByUser: string
  signedOn: number | null
  signedBy: string | null
  signedStoragePath: string | null
}

export type ReimbursementOption = 'medicare-rates' | 'r-and-c' | 'unknown'
export type PlanDesignOption = 'fully-insured' | 'split-funded' | 'self-funded'
export interface PolicyOwner {
  policyOwnerName?: {
    fname: string
    lname: string
  }
  policyOwnerSex?: string
  policyOwnerDob?: number
  policyOwnerSameAddress?: boolean
  policyOwnerAddress?: Address
  policyOwnerPhone?: string
  policyOwnerEmail?: string
}

export interface SentEmailData {
  on: number
  by: string
  message: string
}
export interface CallInRequest {
  on: number
  by: string
  message: string
  emailsSent: SentEmailData[]
}

export interface InsuranceCoverageRequest {
  type: 'request'
  withCallInForm: boolean
  requestedOn: number
  requestedBy: string
  requestedByGroup: UserGroup
  message: string
  emailsSent: SentEmailData[]
}

// type BaseInsuranceCoverageId = 'primaryCoverage' | 'medicaidCoverage'

export type InsuranceCoverageType = 'primary' | 'secondary' | 'additional'
export type AdditionalCoverageId = `additional.${string}`
// export type PregnancyCoverageId = BaseInsuranceCoverageId | AdditionalCoverageId
export type PatientCoverageId = 'primary' | 'secondary' | `additional.${string}`

export type PregnancyCoverageId = 'nonMedicaid' | 'medicaid' | `potential.${string}`
export type PregnancyCoverageLabel = 'nonMedicaid' | 'medicaid' | 'potential'
export type WithPatientCoverageId<T> = T & { id: PatientCoverageId }
export type WithPregnancyCoverageLabel<T> = T & {
  pregnancyLabel: PregnancyCoverageLabel
}

export type InsuranceCoverageUpdateType =
  | 'basicInfo'
  | 'callIn'
  | 'policyOwner'
  | 'unsetAsPrimary'
  | 'setAsPrimary'
  | 'setAsSecondary'
  | 'unsetAsSecondary'
export interface InsuranceCoverageHistory {
  type: InsuranceCoverageUpdateType
  on: number
  by: string
  group: UserGroup
  notes?: string
}

export interface CoverageEligibilityRequest {
  response: EligibilityResponse
  request: RealtimeEligibilityRequest & {
    practiceId: string
    practiceName: string
    insurerId: string
    insurerName: string
  }
  // plan: Omit<BaseInsuranceCoverage, 'eligibilityRequests'>
}

export interface BaseInsuranceCoverage {
  type?: never
  isMedicaid: boolean
  insuranceProviderId?: string
  currentlyOnMedicaidPlan?: boolean
  medicaidStartDate?: number | null
  initialServiceDate?: number | null
  planName?: string
  noMemberId?: boolean
  callInRequests?: Record<string, CallInRequest>
  fromRequest?: InsuranceCoverageRequest | null
  memberId?: string
  policyOwnerRelationship?: string
  policyOwnerInfo?: PolicyOwner
  terminationDate?: number
  insuranceProviderNumber?: string
  insuranceCard?: {
    front?: FileDBValue
    frontImageIncludesBack?: boolean
    back?: FileDBValue
  }
  'in-network'?: {
    coinsurance: number
    deductible: number
    deductibleBasedOnCalendarYear: boolean
    deductibleCountsTowardOOPM: boolean
    outOfPocketMax: number
    insuranceCard: {
      front?: FileDBValue
      frontImageIncludesBack: boolean
      back?: FileDBValue
    }
    notes?: string
  }
  'out-of-network'?: {
    coinsurance: number
    deductible: number
    deductibleCountsTowardOOPM: boolean
    outOfPocketMax: number
    maximumReimbursableCharges: {
      amount?: number
      notes?: string
    }
    noBenefits?: boolean
    reimbursementOption: {
      optionText: ReimbursementOption
      medicareRate?: number
      reasonableAndCustomaryRate?: number
      notes?: string
    }
  }
  'plan-design-and-state-mandates'?: {
    planDesign: PlanDesignOption
    followsStateMandates: boolean
    confirmFollowsStateMandates?: boolean
    confirmDoesNotFollowStateMandates?: boolean
    // state plan is based in - legacy prop name, should be changed to planState
    followsStateMandatesNote?: string
    notes?: string
  }
  'call-details'?: {
    agentName: string
    callReferenceNumber: string
    notes?: string
  }
  eligibilityRequests?: Record<string, WithMetadata<CoverageEligibilityRequest>>
  history?: InsuranceCoverageHistory[]
}

export type CoverageWithMetadata = WithMetadata<BaseInsuranceCoverage> & {}

export interface InsuranceCoverage
  extends WithMetadata<Omit<BaseInsuranceCoverage, 'insuranceProvider' | 'id'>> {
  insuranceProvider: InsuranceProvider | null
  plan: InsurancePlan | null
  id: PatientCoverageId
}

export const isInsuranceCoverageRequest = (
  c: BaseInsuranceCoverage | InsuranceCoverageRequest,
): c is InsuranceCoverageRequest => c.type === 'request'

// specific to assessments (pregnancy)
export interface AssessmentDocument {
  type: TemplateKey
  viewedOn?: number | null
  expiresOn: number
  expired: boolean
  assessmentId: string
  patientId: string
  editorVersion?: EditorVersion
  name?: string
  sentOn: number
  sentBy: string
  text: Descendant[]
  previousVersions?: AssessmentDocumentVersion[]
  assessmentArchived: boolean
}

// specific to patient
export interface ConsentForm
  extends Omit<
    AssessmentDocument,
    'previousVersions' | 'expiresOn' | 'expired' | 'assessmentArchived' | 'assessmentId' | 'text'
  > {
  type: 'consentForm'
  formData: FieldMapValue | null
  previousVersions?: ConsentFormVersion[]
  signedOn: number | null
  signedBy: string | null
  signedStoragePath: string | null
  isExternalPdf?: boolean
  externalPdf: TemplateExternalPdf
  toBeSignedByUser: string
  text: Descendant[] | null
}

export const isConsentForm = (doc: AssessmentDocument | ConsentForm): doc is ConsentForm =>
  doc.type === 'consentForm'

export type AssessmentDocumentArgs = Omit<
  AssessmentDocument,
  | 'assessmentArchived'
  | 'previousVersions'
  | 'expiresOn'
  | 'expired'
  | 'sentOn'
  | 'sentBy'
  | 'assessmentId'
> & {
  templateId: string | null
  assessmentId: string | null
}

export interface Assessment extends Partial<DBMetadata> {
  name?: string

  data?: WithPartialMetadata<FieldMapValue>
  corrections?: WithPartialMetadata<FieldMapValue>
  nonMedicaidCoverageId: PatientCoverageId | null
  medicaidCoverageId: PatientCoverageId | null
  potentialCoverageIds?: PatientCoverageId[]
  // TODO: remove
  // additionalPlans?: Record<string, InsuranceCoverage | InsuranceCoverageRequest>

  additionalPlansConfirmedOn?: number
  additionalPlansConfirmedBy?: string
  additionalPlansRequestedOn?: number
  additionalPlansRequestedBy?: string

  skippedQuestionnaire?: boolean
  questionnaireSkippedOn?: number | null
  questionnaireSkippedBy?: string | null

  createdOn: number
  createdBy?: string
  submittedOn?: number
  submittedBy?: string
  submittedByGroup?: 'patient' | 'practice' | 'admin'
  sentOn?: number
  sentBy?: string
  answersUpdatedOn?: number
  answersUpdatedBy?: string
  answersUpdatedByGroup?: UserGroup
  correctionsUpdatedOn?: number
  // TODO: delete (moved to snippet)
  status?: AssessmentStatus

  signOnData?: WithPartialMetadata<FieldMapValue>
  signOnCorrections?: WithPartialMetadata<FieldMapValue>
  signedOnDate?: number
  signOnSkippedBy?: string | null
  signOnSkippedOn?: number | null
  signOnReminders?: { sentOn: number }[]
  disclaimers?: {
    disclaimer1Accepted?: boolean
    disclaimer2Accepted?: boolean
  }

  correctionsUpdatedBy?: string
  correctionsUpdatedByGroup?: UserGroup
  resultsViewedOn?: number
  patientId: string
  results?: Descendant[]
  resultsInsurerId?: string | null

  // if no version specified, assume v1
  editorVersion?: EditorVersion
  previousResults?: {
    insuranceProviderId?: string | null
    editorVersion?: EditorVersion
    results: Descendant[]
    sentOn: number | null
    sentBy: string | null
  }[]

  authAppeals?: Record<string, AssessmentDocument>
  authInstructions?: Record<string, AssessmentDocument>

  archivedOn: number | null
  archivedBy?: string | null
  midwifeId?: string | null

  files?: Record<string, FileDBValue>

  inviteSentOn?: number
  inviteSentBy?: string

  forceUpdatedOn?: number
}

export type NewAssessment = Partial<Assessment>
export const isNewAssessment = (a: Assessment | NewAssessment): a is NewAssessment => !a.submittedOn

export type AssessmentSortKey =
  | keyof Pick<
      AssessmentSnippet,
      | 'email'
      | 'lname'
      | 'urgentSort'
      | 'nextActionDate'
      | 'insuranceCoverage'
      | 'deliveredOn'
      | 'signedOnDate'
      | 'midwifeName'
      | 'inquiryRank'
      | 'hasOverduePayment'
      | 'redFlagText'
      | 'edd'
      | 'unreadThreadMessages'
    >
  | 'claimNextAction'
  | keyof Pick<PracticeAssessmentData, 'sortColor' | 'nextPatientAppointmentDate'>

export type ConfirmCoverageStage = 'primaryCoverage' | 'secondaryCoverage' | string | 'confirm'
export type CoverageSnippet = 'basic-info' | 'call-in' | 'policy-owner'
type CoverageStageIncomplete = CoverageSnippet[]
type CoverageStageRequired = CoverageSnippet[]
export type CoverageStageStatus = {
  incomplete: CoverageStageIncomplete
  required: CoverageStageRequired
} | null

export interface ConfirmCoverageStatus {
  primaryCoverage: CoverageStageStatus
  secondaryCoverage: CoverageStageStatus
  additionalPlans: Record<AdditionalCoverageId, CoverageStageStatus>
  requests: Record<string, CoverageStageStatus>
}

export type ListNextActionEntity =
  | { type: 'gyn' }
  | { type: 'coverage'; id: PatientCoverageId; coverage: InsuranceCoverage | undefined | null }

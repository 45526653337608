import { CloseIcon, SearchIcon } from '@chakra-ui/icons'
import { Box, Flex, FlexProps, IconButton, Input, Portal, Tooltip } from '@chakra-ui/react'
import { capitalizeFirstLetter, makePlural } from '@hb/shared'
import React, {
  FC,
  forwardRef,
  PropsWithChildren,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { SearchBarContext, useSearch } from '../../contexts'
import { InputRef } from '../forms/Input/types'
import { AddItemButton } from './AddItemButton'

const SearchBarTooltip = ({
  children,
  inputRef,
  rawQuery,
}: PropsWithChildren<{ inputRef: RefObject<HTMLInputElement>; rawQuery: string }>) => {
  const { focused } = useSearch()

  const [{ top, left, width }, setPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  })
  useEffect(() => {
    if (focused && inputRef.current) {
      const { top, left, width } = inputRef.current.getBoundingClientRect()
      setPosition({ top: top + 40, left, width: Math.min(width, 500) })
    }
  }, [inputRef, focused])

  const [visible, setVisible] = useState(false)
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null
    const shouldShow = focused && !rawQuery
    timeout = setTimeout(() => {
      setVisible(shouldShow)
    }, 50)

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [focused, rawQuery])

  if (!focused && !visible) return null
  return (
    <Portal>
      <Box
        opacity={visible ? 1 : 0}
        pointerEvents="none"
        zIndex={2}
        bg="gray.50"
        borderRadius={3}
        boxShadow="1px 1px 4px rgba(0, 0, 0, 0.2)"
        py={1}
        px={2}
        transition="opacity 300ms"
        top={`${top}px`}
        left={`${left}px`}
        width={`${width}px`}
        position="absolute">
        {children}
      </Box>
    </Portal>
  )
}

export const SearchBar = forwardRef<
  InputRef,
  {
    onAddClick?: () => void
    itemName: string
    flexProps?: FlexProps
    onClear?: () => void
    onFocus?: () => void
    onBlur?: () => void
    tooltip?: FC
    creationItemName?: string
    autoSubmit?: boolean
  }
>(
  (
    {
      onAddClick,
      itemName,
      onClear,
      flexProps,
      autoSubmit,
      onFocus,
      onBlur,
      creationItemName,
      tooltip: SearchTip,
    },
    ref,
  ) => {
    const [rawQuery, setRawQuery] = useState('')

    const { query, updateQuery, setFocusedState, inputRef } = useContext(SearchBarContext)
    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      },
      blur: () => {
        if (inputRef.current) {
          inputRef.current.blur()
        }
      },
    }))

    useEffect(() => {
      const onSearch = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          if (inputRef.current && inputRef.current === document.activeElement) {
            updateQuery(inputRef.current.value.trim())
          }
        }
      }
      document.addEventListener('keypress', onSearch)
      return () => {
        document.removeEventListener('keypress', onSearch)
      }
    }, [updateQuery, autoSubmit, inputRef])

    const clear = useCallback(() => {
      updateQuery('')
      if (inputRef.current) {
        inputRef.current.value = ''
      }
    }, [updateQuery, inputRef])

    useEffect(() => {
      if (!inputRef.current) return
      inputRef.current.value = query
    }, [query, inputRef])

    const onSearch = useCallback(() => {
      if (inputRef.current) {
        updateQuery(inputRef.current.value.trim())
      }
    }, [updateQuery, inputRef])

    const handleBlur = useCallback(() => {
      setFocusedState(false)
      if (onBlur) {
        onBlur()
      }
    }, [setFocusedState, onBlur])

    const handleFocus = useCallback(() => {
      setFocusedState(true)
      if (onFocus) {
        onFocus()
      }
    }, [setFocusedState, onFocus])

    return (
      <Flex pos="relative" align="center" width="100%" opacity={0.7} px={1} py={1} {...flexProps}>
        <Flex align="center" flex={1} pos="relative">
          <IconButton
            aria-label="Search"
            onClick={onSearch}
            variant="ghost"
            size="sm"
            borderRadius="full"
            icon={<SearchIcon width={4} height={4} />}
          />
          <Box position="relative" flex={1} minW="0">
            <Input
              ref={inputRef}
              value={autoSubmit ? query : undefined}
              _focus={{
                boxShadow: 'none',
                border: '1px solid #999999',
              }}
              onChange={({ target }) => {
                setRawQuery(target.value)
                if (autoSubmit) updateQuery(target.value)
              }}
              background="white"
              onFocus={handleFocus}
              onBlur={handleBlur}
              ml={2}
              placeholder={`Search ${makePlural(itemName.toLowerCase())}...`}
              size="sm"
            />
            <IconButton
              zIndex={2}
              right={0}
              top={1}
              position="absolute"
              aria-label="clear"
              onClick={() => {
                clear()
                if (onClear) {
                  onClear()
                }
              }}
              size="xs"
              borderRadius="full"
              variant="ghost"
              opacity={0.7}
              icon={<CloseIcon width={2} height={2} />}
            />
            {SearchTip ? (
              <SearchBarTooltip rawQuery={rawQuery} inputRef={inputRef}>
                <SearchTip />
              </SearchBarTooltip>
            ) : null}
          </Box>
        </Flex>
        {onAddClick ? (
          <Tooltip
            placement="top"
            label={`+ New ${capitalizeFirstLetter(creationItemName ?? itemName)}`}>
            <AddItemButton itemName={creationItemName ?? itemName} onClick={onAddClick} />
          </Tooltip>
        ) : null}
      </Flex>
    )
  },
)

import { AnyObject, BaseQuery, getUniqueRandomId, UserRoleItem, WithId } from '@hb/shared'

import {
  doc,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  getDocs,
  limit as getLimit,
  query as getQuery,
  onSnapshot,
  Query,
  startAfter,
} from 'firebase/firestore'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { db } from '../../backend/db'
import {
  USER_INVITES_REF,
  USERS_ADMIN_REF,
  USERS_REF,
} from '../../collections/firestoreCollections'
import { PopUpMessageContext } from '../../contexts'
import { UseCollectionArgs } from '../../types/populated'
import { UserRowItem } from '../../types/views'

import { httpsCallable } from 'firebase/functions'
import { functions } from '../../backend/functions'
import { toFirestoreQuery } from '../../utils/db'
import { getQueryCount } from './data'
import { useMe } from './useMe'
import { useUpdateDoc } from './useUpdateDoc'

export const useAdminRef = (id: string) => useMemo(() => doc(USERS_ADMIN_REF, id), [id])
export const useUserRef = (id: string) => useMemo(() => doc(USERS_REF, id), [id])
export const useInviteRef = (id: string) => useMemo(() => doc(USER_INVITES_REF, id), [id])

export const useUserRowItem = (user: UserRoleItem): UserRowItem => {
  const { isInvite, id } = user
  const adminRef = useAdminRef(id)
  const inviteRef = useInviteRef(id)
  const userRef = useUserRef(id)
  const update = useUpdateDoc()

  return {
    user,
    ref: isInvite ? userRef : inviteRef,
    update,
    adminRef,
  }
}

function chunkMaxLength<T>(arr: Array<T>, chunkSize: number, maxLength: number) {
  return Array.from({ length: maxLength }, () => arr.splice(0, chunkSize))
}

const fetchQueryFromServer = async <T extends AnyObject>(
  baseQuery: BaseQuery<T>,
): Promise<{ totalCount: number; data: Array<WithId<T>> }> => {
  const fetchFunc = httpsCallable<BaseQuery<T>, { data: Array<WithId<T>>; totalCount: number }>(
    functions,
    'fetchQuery',
  )
  return fetchFunc(baseQuery).then(r => r.data)
}

export const useListQuery = <T extends AnyObject>(
  args: UseCollectionArgs<T>,
  limit = 100,
): {
  items: Record<string, T> | null
  goNext: () => void
  loading: boolean
  goPrev: () => void
  goToPage: (index: number) => Promise<void>
  canGoNext: boolean
  queryCount: number | null
  totalPages: number
  refetchItem: (id: string) => Promise<void>
  searchStringPath?: keyof T
  pageNum: number
  canGoPrev: boolean
} => {
  const [fetched, setFetched] = useState<Array<
    WithId<
      T & {
        doc?: DocumentSnapshot
      }
    >
  > | null>(null)

  const me = useMe()
  const {
    baseQuery,
    sortFunc,
    sort,
    pause,
    transformData,
    search,
    filters,
    secondarySort,
    toSearchQuery,
  } = args

  const [cursors, setCursors] = useState<Array<DocumentSnapshot<T> | null>>([])
  const [listItemCount, setListItemCount] = useState<number | null>(null)

  const unpaginatedQuery = useMemo<BaseQuery<T>>(() => {
    const base: BaseQuery<T> = { ...baseQuery }
    if (filters) {
      base.filters = [...(base.filters ?? []), ...filters]
    }
    if (sort) {
      base.sort = [...(base.sort ?? []), sort]
    }
    if (secondarySort && secondarySort.field !== sort?.field) {
      base.sort = [...(base.sort ?? []), secondarySort]
    }
    return base
  }, [baseQuery, filters, sort, secondarySort])

  // returns null if search is not handled (ie does not return a search query)
  const snapshotQuery = useMemo(() => {
    if (!search) return unpaginatedQuery
    return toSearchQuery ? toSearchQuery(unpaginatedQuery, search) : null
  }, [unpaginatedQuery, search, toSearchQuery])

  useEffect(() => {
    if (!snapshotQuery) return
    setListItemCount(null)
    getQueryCount(snapshotQuery)
      .then(res => {
        setListItemCount(res.data ?? null)
      })
      .catch(err => {
        console.error(err)
      })
    setCursors([])
  }, [snapshotQuery, search])
  // const [lastFetched, setLastFetched] = useState<Array<QueryDocumentSnapshot>>([])
  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)

  const { processResponse } = useContext(PopUpMessageContext)
  const snapshotQueryRef = useMemo(() => {
    if (!snapshotQuery || limit < 1) return null
    return toFirestoreQuery(db, snapshotQuery)
  }, [limit, snapshotQuery])

  const addPagination = useCallback(
    (query: Query<T>): Query<T> => {
      let q = getQuery(query)
      const lastCursor = cursors[cursors.length - 1]
      // if (!query) {
      if (lastCursor) q = getQuery(q, startAfter(lastCursor), getLimit(limit))
      else q = getQuery(q, getLimit(limit))
      // }

      return q
    },
    [cursors, limit],
  )
  const subscribeId = useRef<string | null>(null)

  const refetchItem = useCallback(
    async (id: string) => {
      // only necessary if item was fetched from server

      if (!search) return
      const docRef = doc(db, baseQuery.collection, id) as DocumentReference<T>
      return getDoc(docRef).then(doc => {
        const data = doc.data()
        if (data) {
          setFetched(f =>
            f
              ? f.map(item => {
                  if (item.id === id) {
                    return {
                      ...item,
                      ...data,
                      doc,
                    }
                  }
                  return item
                })
              : null,
          )
        }
      })
    },
    [baseQuery, search],
  )

  const subscribe = useCallback(() => {
    const handleError = (e: { message: string }) => {
      const containsUrl = e?.message?.includes('http')
      const parsedUrl = containsUrl ? e?.message?.split(' ').find(s => s.includes('http')) : ''
      if (
        me?.uid === 'sBCNDAsQGyU31Ie84WlTJopz9HS2' &&
        parsedUrl &&
        e.message?.startsWith('The query requires an index. You can create it here:')
      ) {
        window.open(parsedUrl, '_blank')
        window.focus()
      } else {
        processResponse({ error: e?.message || 'Error fetching data' })
      }
      console.error({ unpaginatedQuery })
      console.trace(e)
    }

    const onSnapshotSubscribe = (fullQuery: Query<T>) =>
      onSnapshot(
        fullQuery,
        s => {
          if (subId !== subscribeId.current) return
          setLoading(false)
          // setLastFetched(s.docs)
          setFetched(
            s.docs.map(fetchedDoc => ({
              ...fetchedDoc.data(),
              id: fetchedDoc.id,
              doc: fetchedDoc,
            })),
          )
        },
        handleError,
      )

    const subId = getUniqueRandomId(subscribeId.current ? [subscribeId.current] : [])
    subscribeId.current = subId
    setLoading(true)
    setFetched(null)

    if (snapshotQueryRef) return onSnapshotSubscribe(addPagination(snapshotQueryRef))
    const startingAfter = cursors[cursors.length - 1]?.id ?? null
    if (!startingAfter) setListItemCount(null)
    fetchQueryFromServer({
      ...unpaginatedQuery,
      search,
      limit,
      startAfterId: startingAfter,
    })
      .then(({ data, totalCount }) => {
        if (subId !== subscribeId.current) return
        setLoading(false)
        if (!startingAfter) setListItemCount(totalCount)
        setFetched(data)
      })
      .catch(handleError)

    return () => {}
  }, [
    addPagination,
    processResponse,
    snapshotQueryRef,
    unpaginatedQuery,
    search,
    cursors,
    limit,
    me,
  ])

  useEffect(() => {
    // set(null)
    if (pause) {
      return () => {}
    }
    return subscribe()
  }, [pause, subscribe])

  const goNext = useCallback(async () => {
    if (!fetched) return
    const lastFetched = fetched[fetched.length - 1]
    if (!lastFetched) return
    const lastRef =
      (lastFetched?.doc as DocumentSnapshot<T>) ??
      (await getDoc(doc(db, baseQuery.collection, lastFetched.id) as DocumentReference<T>))
    if (lastRef) setCursors(s => [...s, lastRef])
  }, [fetched, baseQuery])

  const goPrev = useCallback(() => setCursors(c => c.slice(0, c.length - 1)), [])

  // const searchSet = useMemo(() => (query ? toSearchSet(query) : null), [query])
  const canGoPrev = useMemo(() => !!cursors.length, [cursors])
  const pageNum = useMemo(() => cursors.length + 1, [cursors])
  const { items, queryCount } = useMemo(() => {
    if (!fetched) {
      return { items: null, queryCount: null }
    }
    // let qCount = listItemCount
    let sorted = transformData ? transformData(fetched) : [...fetched]
    if (sortFunc) {
      // sorted = sorted.sort((a, b) => sortFunc(sortDesc ? b : a, sortDesc ? a : b))
      sorted = sorted.sort((a, b) => sortFunc(a, b))
    }
    // if (searchSet && searchStringPath) {
    //   sorted = sorted.filter(item => matchesQuery<T>(item, searchStringPath, query, searchSet))
    // }
    // if (query) {
    //   qCount = sorted.length - 1
    //   sorted = sorted.slice(cursors.length * limit, (cursors.length + 1) * limit)
    // }
    return {
      items: sorted.reduce(
        (acc, currItem) => ({
          ...acc,
          [currItem.id]: currItem,
        }),
        {},
      ),
      queryCount: listItemCount,
    }
  }, [
    fetched,
    // searchSet,
    // searchStringPath,
    sortFunc,
    // query,
    transformData,
    // sortDesc,
    // cursors,
    // limit,
    listItemCount,
  ])

  const totalPages = useMemo(
    () => Math.max(1, Math.ceil((queryCount ?? 0) / limit)),
    [limit, queryCount],
  )
  const canGoNext = useMemo(() => pageNum < totalPages, [pageNum, totalPages])
  const goToPage = useCallback(
    async (index: number): Promise<void> => {
      if (!snapshotQueryRef || index < 0 || index >= totalPages || index === cursors.length) {
        return
      }
      if (index === 0) setCursors([])
      else if (index > cursors.length) {
        // if (query) {
        // setCursors(c => [...c, null])
        // } else {
        // get new cursors
        const lastFetched = fetched?.[fetched.length - 1]
        let lastFetchedDoc: DocumentSnapshot<T> | null = null
        if (lastFetched)
          lastFetchedDoc =
            (lastFetched.doc as DocumentSnapshot<T>) ??
            (await getDoc(doc(db, baseQuery.collection, lastFetched.id) as DocumentReference<T>))

        const limitTo = limit * (index - cursors.length + (lastFetched ? -1 : 0))
        if (limitTo < 1) {
          if (lastFetched) {
            setCursors(c => [...c, lastFetchedDoc])
          }
          return
        }
        let q = getQuery(snapshotQueryRef)
        if (lastFetched) {
          q = getQuery(q, startAfter(lastFetchedDoc), getLimit(limitTo))
        }
        setPageLoading(true)
        await getDocs(q)
          .then(res => {
            const grouped = chunkMaxLength(res.docs, limit, Math.ceil(res.docs.length / limit))
            const lastOfEachGroup = grouped.map(g => g[g.length - 1]).filter(g => !!g)
            setCursors(c => [...c, lastFetchedDoc, ...lastOfEachGroup])
          })
          .catch(e => {
            console.error(e)
            processResponse({ error: e?.message || 'Error fetching data' })
          })
          .finally(() => {
            setPageLoading(false)
          })
        // }
      } else {
        setCursors(c => c.slice(0, index))
      }
    },
    [totalPages, cursors, snapshotQueryRef, limit, fetched, baseQuery, processResponse],
  )
  return useMemo(
    () => ({
      items,
      loading: loading || pageLoading,
      goPrev,
      queryCount,
      totalPages,
      goNext,
      canGoNext,
      refetchItem,
      canGoPrev,
      goToPage,
      pageNum,
    }),
    [
      items,
      loading,
      goPrev,
      queryCount,
      totalPages,
      goNext,
      canGoNext,
      pageLoading,
      refetchItem,
      canGoPrev,
      goToPage,
      pageNum,
    ],
  )
}

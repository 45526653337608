import { Box, Collapse, Flex, HStack, Input, Text, VStack } from '@chakra-ui/react'
import {
  colors,
  padZeros,
  Signature,
  SignatureBody,
  SignatureType,
  SignatureTypedBody,
  WithoutHash,
} from '@hb/shared'
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTodayDate } from '../../../../hooks/useTodayDate'

import { useAuth } from '../../../../store'
import { CollapseError } from '../../../CollapseError'
import { getTypedSignatureError } from './utils'

// const isValidYear = (year: string) => {
//   const yearNum = Number.parseInt(year, 10)
//   if (Number.isNaN(yearNum)) return false
//   return yearNum >= 1900 && yearNum <= 2999
// }

// const isValidMonth = (month: string) => {
//   const monthNum = Number.parseInt(month, 10)
//   if (Number.isNaN(monthNum)) return false
//   return monthNum >= 1 && monthNum <= 12
// }

// const daysInMonth = (year: number, month: number) => new Date(year, month, 0).getDate()

// const isValidDay = (day: string, month: string, year: string) => {
//   const dayNum = Number.parseInt(day, 10)
//   const monthNum = Number.parseInt(month, 10)
//   const yearNum = Number.parseInt(year, 10)
//   if (Number.isNaN(dayNum) || Number.isNaN(monthNum) || Number.isNaN(yearNum)) return false
//   const daysInThisMonth = daysInMonth(yearNum, monthNum)
//   return dayNum >= 1 && dayNum <= daysInThisMonth
// }

const DateSegmentInput = ({
  value,
  onChange,
  disabled,
  readOnly,
  maxLength,
  width,
  placeholder,
  onBlur,
  onFocus,
}: {
  value: string
  onChange: (updated: string) => void
  disabled?: boolean
  readOnly?: boolean
  width: number
  placeholder: string
  maxLength: number
  onBlur?: () => void
  onFocus?: () => void
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  let borderColor = 'gray.200'
  if (isFocused) borderColor = 'green.500'
  else if (isHovered) borderColor = 'gray.300'

  return (
    <Flex
      alignItems="center"
      borderRadius="md"
      borderBottomWidth={1}
      borderColor={borderColor}
      width={`${width}px`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <Input
        type="text"
        value={value}
        onChange={e => onChange(e.target.value)}
        onFocus={() => {
          if (onFocus) onFocus()
          setIsFocused(true)
        }}
        _focus={{
          borderColor: colors.green.hex,
          boxShadow: 'none',
        }}
        size="xs"
        colorScheme="green"
        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
        onBlur={() => {
          if (onBlur) onBlur()
          setIsFocused(false)
        }}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        readOnly={readOnly}
        style={{
          border: 'none',
          fontSize: '0.9rem',
          textAlign: 'center',
          padding: '0.08rem 0',
          outline: 'none',
          width: '100%',
          background: 'none',
        }}
      />
    </Flex>
  )
}

// split date into three inputs, value formatted as 'MM/DD/YYYY'
const SignatureDateInput = ({
  value,
  onChange,
  onError,
  disabled,
  readOnly,
}: {
  value: string
  onChange: (updated: string) => void
  onError: (error: string) => void
  disabled?: boolean
  readOnly?: boolean
}) => {
  const { todayString } = useTodayDate()

  const [month, setMonth] = useState(value?.split('/')[0] ?? '')
  const [day, setDay] = useState(value?.split('/')[1] ?? '')
  const [year, setYear] = useState(value?.split('/')[2] ?? '')
  const monthRef = useRef(month)
  const dayRef = useRef(day)
  const yearRef = useRef(year)

  const updateValue = useCallback(() => {
    // if (
    //   isValidYear(yearRef.current)
    //   && isValidMonth(monthRef.current)
    //   && isValidDay(dayRef.current, monthRef.current, yearRef.current)
    // ) {
    onChange(`${monthRef.current}/${dayRef.current}/${yearRef.current}`)
    // }
  }, [onChange])

  // const error = useMemo(
  //   () => (value && value !== todaysDate ? "Should be today's date" : ''),
  //   [value, todaysDate],
  // )

  useEffect(() => {
    const newError = value && value !== todayString && !readOnly ? "Should be today's date" : ''
    onError(newError)
  }, [value, todayString, onError, readOnly])

  const updateMonth = useCallback(
    (newMonth: string) => {
      monthRef.current = newMonth
      setMonth(newMonth)
      updateValue()
    },
    [updateValue],
  )

  const updateDay = useCallback(
    (newDay: string) => {
      dayRef.current = newDay
      setDay(newDay)
      updateValue()
    },
    [updateValue],
  )

  const updateYear = useCallback(
    (newYear: string) => {
      yearRef.current = newYear
      setYear(newYear)
      updateValue()
    },
    [updateValue],
  )

  // if month, clamp to 1-12 and add leading zero if needed
  const normalizeMonth = useCallback(() => {
    if (!month) return
    const monthNum = Number.parseInt(month, 10)
    if (Number.isNaN(monthNum)) return
    let newMonth = padZeros(monthNum, 2)
    if (monthNum < 1) newMonth = '01'
    else if (monthNum > 12) newMonth = '12'
    setMonth(newMonth)
    monthRef.current = newMonth
    updateValue()
  }, [month, updateValue])

  // if day, clamp to 1-31 and add leading zero if needed
  const normalizeDay = useCallback(() => {
    if (!day) return
    const dayNum = Number.parseInt(day, 10)
    if (Number.isNaN(dayNum)) return
    let newDay = padZeros(dayNum, 2)
    if (dayNum < 1) newDay = '01'
    else if (dayNum > 31) newDay = '31'
    setDay(newDay)
    dayRef.current = newDay
    updateValue()
  }, [day, updateValue])

  // if year, clamp to 1900-2999
  const normalizeYear = useCallback(() => {
    if (!year) return
    const yearNum = Number.parseInt(year, 10)
    if (Number.isNaN(yearNum)) return
    let newYear = yearNum.toString()
    if (yearNum < 1900) newYear = '1900'
    else if (yearNum > 2999) newYear = '2999'
    setYear(newYear)
    yearRef.current = newYear
    updateValue()
  }, [year, updateValue])

  return (
    <Flex
      border={readOnly ? 'none' : '1px solid #cdcdcd'}
      px={2}
      py={1}
      borderRadius={4}
      justify="center"
      flexFlow="column"
      align="center">
      <Text fontSize="sm" color="gray.500" textAlign="center" style={{ userSelect: 'none' }}>
        Date
      </Text>
      <HStack justify="center" spacing={1} w="100%">
        <DateSegmentInput
          value={month}
          onChange={updateMonth}
          width={40}
          onBlur={normalizeMonth}
          disabled={disabled}
          readOnly={readOnly}
          placeholder="MM"
          maxLength={2}
        />
        <DateSegmentInput
          value={day}
          onChange={updateDay}
          disabled={disabled}
          onBlur={normalizeDay}
          width={40}
          readOnly={readOnly}
          placeholder="DD"
          maxLength={2}
        />
        <DateSegmentInput
          value={year}
          onChange={updateYear}
          onBlur={normalizeYear}
          disabled={disabled}
          readOnly={readOnly}
          width={60}
          placeholder="YYYY"
          maxLength={4}
        />
      </HStack>
      {/* <CollapseError error={error} /> */}
    </Flex>
  )
}

const SignaturePrintBodyInput = ({
  value,
  onChange,
  onError,
  readOnly,
}: {
  value: SignatureTypedBody | null
  onChange: (updated: SignatureTypedBody | null) => void
  onError: (error: string) => void
  readOnly?: boolean
}) => {
  const me = useAuth(s => s.user)
  const [firstName, setFirstName] = useState(value?.firstName ?? '')
  const [lastName, setLastName] = useState(value?.lastName ?? '')
  const [middleInitial, setMiddleInitial] = useState(value?.middleInitial ?? '')

  const firstNameRef = useRef(value?.firstName ?? '')
  const lastNameRef = useRef(value?.lastName ?? '')
  const middleInitialRef = useRef(value?.middleInitial ?? '')

  const updateValue = useCallback(() => {
    if (firstNameRef.current && lastNameRef.current) {
      onChange({
        type: 'typed',
        firstName: firstNameRef.current,
        lastName: lastNameRef.current,
        middleInitial: middleInitialRef.current,
      })
    } else {
      onChange(null)
    }
  }, [onChange])

  const updateFirstName = useCallback(
    (newFirstName: string) => {
      setFirstName(newFirstName)
      firstNameRef.current = newFirstName
      updateValue()
    },
    [updateValue],
  )

  const updateLastName = useCallback(
    (newLastName: string) => {
      setLastName(newLastName)
      lastNameRef.current = newLastName
      updateValue()
    },
    [updateValue],
  )

  const updateMiddleInitial = useCallback(
    (newMiddleInitial: string) => {
      setMiddleInitial(newMiddleInitial)
      middleInitialRef.current = newMiddleInitial
      updateValue()
    },
    [updateValue],
  )

  // const error = useMemo(() => {
  //   if (!firstName && !lastName && !middleInitial) return ''
  //   if (!firstName) return 'First name required'
  //   if (!lastName) return 'Last name required'
  //   if (firstName !== me?.fname) return 'First name does not match your name on file'
  //   if (lastName !== me?.lname) return 'Last name does not match your name on file'
  //   if (middleInitial && middleInitial.length !== 1) return 'Middle initial must be one character'
  //   return ''
  // }, [firstName, lastName, middleInitial, me])

  // const [error, setError] = useState<string | null>(null)
  useEffect(() => {
    if (readOnly) return
    const newError = getTypedSignatureError(firstName, middleInitial, lastName, me)
    // setError(newError)
    onError(newError)
  }, [firstName, lastName, middleInitial, me, onError, readOnly])

  const showMiddleInitial = readOnly ? middleInitial : !!me?.middleName
  return (
    <Flex w="100%" flexFlow="column">
      <HStack pb={1} spacing={1} w="100%">
        <Input
          type="text"
          size="sm"
          placeholder="First Name"
          borderColor="#cdcdcd"
          borderWidth="1px"
          borderTopWidth={readOnly ? 0 : '1px'}
          borderRightWidth={readOnly ? 0 : '1px'}
          borderLeftWidth={readOnly ? 0 : '1px'}
          // border={readOnly ? 'none' : '1px solid #cdcdcd'}
          _focus={{
            borderColor: colors.green.hex,
            boxShadow: 'none',
          }}
          readOnly={readOnly}
          value={firstName}
          onChange={e => updateFirstName(e.target.value)}
        />

        {showMiddleInitial ? (
          <Input
            type="text"
            size="sm"
            width="60px"
            placeholder="M"
            textAlign="center"
            readOnly={readOnly}
            maxLength={1}
            borderWidth="1px"
            borderTopWidth={readOnly ? 0 : '1px'}
            borderRightWidth={readOnly ? 0 : '1px'}
            borderLeftWidth={readOnly ? 0 : '1px'}
            _focus={{
              borderColor: colors.green.hex,
              boxShadow: 'none',
            }}
            value={middleInitial}
            onChange={e => updateMiddleInitial(e.target.value)}
          />
        ) : null}
        <Input
          size="sm"
          type="text"
          placeholder="Last Name"
          readOnly={readOnly}
          borderWidth="1px"
          borderTopWidth={readOnly ? 0 : '1px'}
          borderRightWidth={readOnly ? 0 : '1px'}
          borderLeftWidth={readOnly ? 0 : '1px'}
          _focus={{
            borderColor: colors.green.hex,
            boxShadow: 'none',
          }}
          value={lastName}
          onChange={e => updateLastName(e.target.value)}
        />
      </HStack>
      {/* <CollapseError error={error} /> */}
    </Flex>
  )
}

const SignatureBodyInput = ({
  value,
  onChange,
  onError,
  method,
  readOnly,
}: {
  value: SignatureBody | null
  onChange: (updated: SignatureBody | null) => void
  onError: (error: string) => void
  readOnly?: boolean
  method: SignatureType
}) => (
  <VStack>
    <Collapse in={method === 'typed'} style={{ width: '100%' }}>
      <SignaturePrintBodyInput
        value={value as SignatureTypedBody}
        onChange={onChange}
        readOnly={readOnly}
        onError={onError}
      />
    </Collapse>
    <Collapse in={method === 'drawn'} style={{ width: '100%' }}></Collapse>
  </VStack>
)

export const SignatureInputBody = ({
  value,
  onChange,
  disabled,
  readOnly,
  onFocus,
  onBlur,
  style,
}: {
  value: Signature | WithoutHash<Signature> | undefined | null
  onChange: (updated: WithoutHash<Signature> | undefined | null) => void
  disabled?: boolean
  readOnly?: boolean
  onFocus?: () => void
  onBlur?: () => void
  style?: CSSProperties
}) => {
  const [method] = useState<SignatureType>(value?.body?.type || 'typed')
  const [dateString, setDateString] = useState<string | null>(value?.date ?? null)

  const [signatureError, setSignatureError] = useState<string | null>(null)
  const [dateError, setDateError] = useState<string | null>(null)
  const dateStringRef = useRef(dateString)

  const [signatureBody, setSignatureBody] = useState<SignatureBody | null>(value?.body ?? null)
  const signatureBodyRef = useRef<SignatureBody | null>(signatureBody)

  const onDateChange = useCallback(
    (newDate: string | null) => {
      setDateString(newDate)
      dateStringRef.current = newDate
      if (newDate && signatureBodyRef.current) {
        onChange({ body: signatureBodyRef.current, date: newDate })
      } else {
        onChange(undefined)
      }
    },
    [onChange],
  )

  const onSignatureBodyChange = useCallback(
    (newSignatureBody: SignatureBody | null) => {
      setSignatureBody(newSignatureBody)
      signatureBodyRef.current = newSignatureBody
      if (newSignatureBody && dateStringRef.current) {
        onChange({ body: newSignatureBody, date: dateStringRef.current })
      } else {
        onChange(undefined)
      }
    },
    [onChange],
  )

  const error = useMemo(() => {
    if (!signatureBody && !dateString) return ''
    if (!signatureBody) return 'Signature required'
    if (signatureError) return signatureError
    if (!dateString) return 'Date required'
    if (dateError) return dateError
    return ''
  }, [dateString, dateError, signatureBody, signatureError])

  return (
    <Flex
      onFocus={onFocus}
      onBlur={onBlur}
      minW="300px"
      flexFlow="column"
      w="100%"
      px={2}
      py={1}
      style={style}>
      {/* {readOnly ? null : (
        <HStack w="100%" p={1} spacing={1}>
          <ActionButton
            bg={method === 'typed' ? colors.green.hex : 'white'}
            color={method === 'typed' ? 'white' : colors.green.hex}
            size="sm"
            gap={1}
            flex={1}
            onClick={() => setMethod('typed')}
          >
            <SvgIcon
              src={method === 'typed' ? keyboardIcon : keyboardIconGreen}
              filter={method === 'typed' ? 'brightness(3)' : 'invert(0)'}
            />
            Typed
          </ActionButton>
          <ActionButton
            bg={method === 'drawn' ? colors.green.hex : 'white'}
            color={method === 'drawn' ? 'white' : colors.green.hex}
            size="sm"
            gap={1}
            flex={1}
            onClick={() => setMethod('drawn')}
          >
            <SvgIcon
              src={method === 'drawn' ? signatureIcon : signatureIconGreen}
              filter={method === 'drawn' ? 'brightness(3)' : 'invert(0)'}
            />
            Drawn
          </ActionButton>
        </HStack>
      )} */}
      <Flex align="center" gap={2} w="100%">
        <Box flex={1}>
          <SignatureBodyInput
            method={method}
            readOnly={readOnly}
            onChange={onSignatureBodyChange}
            onError={err => setSignatureError(err)}
            value={signatureBody}
          />
        </Box>
      </Flex>
      <SignatureDateInput
        value={value?.date ?? ''}
        onChange={onDateChange}
        onError={err => setDateError(err)}
        disabled={disabled}
        readOnly={readOnly}
      />
      <CollapseError error={error} />
    </Flex>
  )
}

import {
  insurersCollection,
  medicaidInsurersCollection,
} from '../../collections/collections/insurer'
import { FieldTypes } from '../../constants'
import { IdField, InsuranceProvider } from '../../types'
import { makeFieldOptional } from '../../utils/fields'

export const insuranceProviderField: IdField<InsuranceProvider> = {
  type: FieldTypes.ID,
  collection: insurersCollection,
  placeholder: 'Select an Insurer',
  allowOther: true,
}
export const optionalInsuranceProviderField = makeFieldOptional(insuranceProviderField)

export const medicaidInsuranceProviderField: IdField<InsuranceProvider> = {
  type: FieldTypes.ID,
  collection: medicaidInsurersCollection,
  placeholder: 'Select a Medicaid Insurer',
  allowOther: true,
}

import { DownloadIcon } from '@chakra-ui/icons'
import { IconButton, Image, ModalBodyProps, TextProps } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { ScreenContext } from '../../contexts/ScreenContext'
import loadingIcon from '../../icons/loading.svg'
import { ImageViewModalProps } from '../../types/modals'
import { DefaultModal } from './DefaultModal'

const downloadFromSrc = (src: string, fileName: string) => {
  const link = document.createElement('a')
  link.href = src
  link.download = fileName || 'image.png'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const modalBodyProps: ModalBodyProps = {
  width: 'auto',
  height: 'auto',
  background: '#00000066',
  position: 'relative',
}

const modalHeaderTextProps: TextProps = {
  top: 1.5,
  left: 2,
  color: 'white',
  textShadow: '1px 1px 4px black',
  fontSize: '0.9rem',
  filter: 'drop-shadow(0 0 4px #00000077)',
}

export const ImageViewModal: React.FC<ImageViewModalProps> = ({
  src,
  withDownload,
  fileName,
  ...props
}) => {
  const screen = useContext(ScreenContext)
  const [width, setWidth] = useState<number | null>(null)
  const [height, setHeight] = useState<number | null>(null)
  return (
    <DefaultModal
      overlayHeader
      isCentered
      // header={header}
      bodyProps={modalBodyProps}
      headerTextProps={modalHeaderTextProps}
      noCloseButton
      contentProps={{
        p: 0,
        width: 'auto',
        background: 'transparent',
        overflow: 'hidden',
        maxH: height ?? '100%',
        maxW: width ?? '100%',
      }}
      render={() => (
        <>
          <TransformWrapper>
            <TransformComponent>
              <div
                style={{
                  height: height ?? '120px',
                  width: width ?? '120px',
                  display: 'flex',
                  justifyContent: 'center',
                  transition: 'height 300ms ease, width 300ms ease',
                  alignItems: 'center',
                }}>
                <Image
                  w="100%"
                  h="100%"
                  objectFit="contain"
                  opacity={height ? 1 : 0}
                  transition={`opacity 300ms ease ${height ? 0 : 180}ms`}
                  onLoad={e => {
                    const { naturalHeight, naturalWidth } = e.target as HTMLImageElement
                    const aspectRatio = naturalWidth / naturalHeight
                    const minWidth = Math.max(naturalWidth, 240)
                    const minHeight = minWidth / aspectRatio
                    setWidth(Math.min(minWidth, screen.width))
                    setHeight(Math.min(minHeight, screen.height))
                  }}
                  src={src}
                />
                <Image
                  w="100px"
                  h="100px"
                  objectFit={'contain'}
                  position="absolute"
                  pointerEvents="none"
                  opacity={height ? 0 : 1}
                  src={loadingIcon}
                />
              </div>
            </TransformComponent>
          </TransformWrapper>
          {withDownload && src && fileName ? (
            <IconButton
              aria-label="Download"
              position="absolute"
              top={1}
              right={1}
              onClick={() => {
                downloadFromSrc(src, fileName)
              }}
              color="white"
              variant="ghost"
              borderRadius="full"
              _hover={{
                bg: 'whiteAlpha.500',
              }}
              size="sm"
              icon={<DownloadIcon filter="drop-shadow(0 0 3px #00000066)" />}
            />
          ) : null}
          {/* <IconButton
            aria-label="Close"
            variant="ghost"
            size="xs"
            position="absolute"
            onClick={props.onClose}
            _hover={{
              bg: 'blackAlpha.400',
            }}
            borderRadius="full"
            top={1}
            right={1}
            icon={
              <CloseIcon w={3.5} h={3.5} color="white" filter="drop-shadow(1px 1px 3px #000000)" />
            }
          /> */}
        </>
      )}
      {...props}
    />
  )
}

import { WarningTwoIcon } from '@chakra-ui/icons'
import {
  Button,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { midwivesCollection, PracticeWithAdmin, providerRedFlaggedField, WithId } from '@hb/shared'
import React from 'react'
import { useUpdateCollection } from '../../hooks/useAppCollections'
import { SimpleForm } from '../forms/FinalForm/SimpleForm'

export const ProviderAlertPopover = ({
  midwife,
}: {
  midwife?: WithId<PracticeWithAdmin> | null
}) => {
  const { updateItem } = useUpdateCollection(midwivesCollection)
  return (
    <Popover isLazy placement="bottom">
      <PopoverTrigger>
        <Button variant="ghost" bg="gray.50" borderRadius="full" size="sm" aria-label="Red flags">
          <HStack spacing={1.5}>
            {midwife?.redFlagged ? (
              <Text
                isTruncated
                maxW="250px"
                fontSize="sm"
                color={midwife?.redFlagged ? 'yellow.600' : 'gray.400'}>
                {midwife?.redFlaggedReason || 'Red Flagged'}
              </Text>
            ) : null}
            <WarningTwoIcon
              width={5}
              height={5}
              color={midwife?.redFlagged ? 'yellow.600' : 'gray.400'}
            />
          </HStack>
        </Button>
      </PopoverTrigger>
      <PopoverContent height="auto" p={0}>
        <PopoverBody p={0}>
          <SimpleForm<{ redFlagged?: boolean; redFlaggedReason?: string }>
            value={{
              redFlagged: midwife?.redFlagged,
              redFlaggedReason: midwife?.redFlaggedReason,
            }}
            theme="detailed"
            field={providerRedFlaggedField}
            boxProps={{ px: 0, py: 0 }}
            onSubmit={({ redFlagged, redFlaggedReason }) =>
              updateItem(midwife?.id ?? '', '', {
                redFlagged,
                redFlaggedReason: redFlagged ? redFlaggedReason : '',
              })
            }
          />
        </PopoverBody>
        <PopoverArrow />
      </PopoverContent>
    </Popover>
  )
}

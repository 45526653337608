import { ChevronDownIcon, LinkIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Image,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  AbilityClaim,
  AbilityClaimError,
  AbilityService,
  Claim,
  CLAIMS_ABILITY_DETAIL,
  colors,
  FieldMapValue,
  formatDollarValue,
  getDateString,
  getDateTimeString,
  getInovalonClaimStatusColor,
  WithId,
} from '@hb/shared'

import React, { useContext, useMemo, useState } from 'react'
import { useDocument } from '../../../../../hooks/backend/useDocument'
import { useFormattedValue } from '../../../../../hooks/useFormattedValue'
import { Expandable } from '../../../../Expandable'
import { ClaimInfo } from '../ClaimInfo'
import { serviceTypeField } from './fields'
import { LinkAbilityClaim } from './LinkAbilityClaim'
import { NullifyClaimPopover } from './NullifyClaim'

interface ClaimsViewData {
  selectedId: string | null
  select: (id: string) => void
  updatingAbilityId: string | null
  setUpdatingAbilityId: (id: string | null) => void
  creatingNewLegacy: boolean
  setCreatingNewLegacy: (val: boolean) => void
  creatingNewAbilityClaim: boolean
  setCreatingNewAbilityClaim: (val: boolean) => void
}

const ClaimsViewContext = React.createContext<ClaimsViewData>({
  selectedId: null,
  select: () => {},
  updatingAbilityId: null,
  setUpdatingAbilityId: () => {},
  creatingNewLegacy: false,
  setCreatingNewLegacy: () => {},
  creatingNewAbilityClaim: false,
  setCreatingNewAbilityClaim: () => {},
})

export const toClaimName = (data: FieldMapValue) =>
  `${data?.serviceType?.toString().toUpperCase() || 'NO SERVICE TYPE'}${
    data.serviceType === 'other' && data.serviceTypeOther ? ` (${data.serviceTypeOther})` : ''
  }: ${data.status?.toString().toUpperCase()} - ${
    data.serviceDates || 'No dates of service'
  } - ${data.cptCodes || 'No cpt codes'}`

const AbilityClaimErrorView = ({ error }: { error: AbilityClaimError }) => {
  const { messsage, name, related_fields } = error
  const fieldName = useMemo(() => {
    const [field] = related_fields && Array.isArray(related_fields) ? related_fields : []
    return field?.name ?? ''
  }, [related_fields])
  const [expanded, setExpanded] = useState(false)
  const statusColor = error.override_enabled === 'Y' ? 'yellow.500' : 'red.500'
  return (
    <VStack spacing={0} borderRadius={4} w="100%" borderWidth="2px" borderColor={statusColor}>
      <HStack w="100%" p={2} bg={statusColor} justify="space-between">
        <VStack align="flex-start" spacing={1}>
          {fieldName ? (
            <Text fontWeight={500} lineHeight={1} fontSize="sm" color="white">
              {fieldName.toUpperCase()}
            </Text>
          ) : null}
          <Text lineHeight={1} color="white">
            {name}
          </Text>
        </VStack>
        <IconButton
          minH="0"
          minW="0"
          _hover={{ bg: 'whiteAlpha.400' }}
          h="auto"
          p={2}
          bg="transparent"
          onClick={() => setExpanded(!expanded)}
          aria-label={expanded ? 'collapse' : 'expand'}
          color="white"
          icon={<ChevronDownIcon />}
        />
      </HStack>
      <Collapse in={expanded} style={{ width: '100%' }}>
        <Text px={2} py={1} whiteSpace="pre-wrap">
          {decodeURI(messsage)}
        </Text>
      </Collapse>
    </VStack>
  )
}
const AbilityClaimErrors = ({ errors }: { errors?: Array<AbilityClaimError> }) => {
  const { overridden, notOverridden } = useMemo(() => {
    const over: Array<AbilityClaimError> = []
    const notOver: Array<AbilityClaimError> = []
    errors?.forEach(e => {
      if (e.override_enabled === 'Y') over.push(e)
      else notOver.push(e)
    })
    return { overridden: over, notOverridden: notOver }
  }, [errors])
  if (!errors?.length) return null
  return (
    <VStack w="100%" align="flex-start">
      <Text color="red.600" decoration="underline">
        ERRORS
      </Text>
      {notOverridden.map((e, i) => (
        <AbilityClaimErrorView key={`error_${i}`} error={e} />
      ))}
      <Expandable
        header={() => (
          <Text py={1} textDecor="underline" color="yellow.600">
            OVERRIDDEN ERRORS
          </Text>
        )}>
        <VStack w="100%">
          {overridden.map((e, i) => (
            <AbilityClaimErrorView key={`error_${i}`} error={e} />
          ))}
        </VStack>
      </Expandable>
    </VStack>
  )
}

const ServiceView = ({ service }: { service: AbilityService }) => {
  const { FromDate, ThroughDate, HCPC, DiagnosisPointer, Charge, RenderingFirst, RenderingLast } =
    service
  return (
    <HStack spacing={1} px={1} align="flex-start">
      <ClaimInfo header="HCPC">
        <Text>{HCPC}</Text>
      </ClaimInfo>
      <ClaimInfo header="SERVICE DATE">
        <Text whiteSpace="nowrap">
          {FromDate ? getDateString(FromDate, 'short') : 'NONE'} -{' '}
          {ThroughDate ? getDateString(ThroughDate, 'short') : 'NONE'}
        </Text>
      </ClaimInfo>
      <ClaimInfo header="DIAGNOSIS POINTERS">
        <Flex gap={1} align="center">
          {DiagnosisPointer?.split(',').map((pointer, idx) => (
            <Badge fontWeight={500} fontSize="sm" key={idx} colorScheme="green">
              {pointer}
            </Badge>
          ))}
        </Flex>
      </ClaimInfo>
      <ClaimInfo w="120px" header="CHARGE">
        <Text>{formatDollarValue(Charge)}</Text>
      </ClaimInfo>
      <ClaimInfo header="RENDERING PROVIDER">
        <Text>{`${RenderingFirst} ${RenderingLast}`}</Text>
      </ClaimInfo>
    </HStack>
  )
}

const ServicesView = ({ claim, preview }: { claim: AbilityClaim | null; preview?: boolean }) => {
  const services = useMemo(() => Object.values(claim?.Services ?? {}), [claim])
  const [firstService] = services
  if (preview)
    return (
      <ClaimInfo header="INITIAL SERVICE DATE">
        <Text>
          {firstService?.FromDate ? getDateString(firstService.FromDate, 'short') : 'NONE'}
        </Text>
      </ClaimInfo>
    )

  return (
    <VStack spacing={0} flexFlow="column" w="100%" align="flex-start">
      <Flex align="center" px={1} py={0.5} w="100%">
        <Text isTruncated color="gray.500" fontSize="xs" fontWeight={600}>
          {services.length} SERVICE{services.length === 1 ? '' : 'S'}
        </Text>
      </Flex>
      <VStack spacing={1} align="flex-start">
        {services.map((service, idx) => (
          <ServiceView key={idx} service={service} />
        ))}
      </VStack>
    </VStack>
  )
}

const InsuranceView = ({
  insurance,
}: {
  insurance: Required<AbilityClaim>['Insurances'][number]
}) => {
  const { PayerName, SubscriberID, GroupNumber, PriorAuthNo } = insurance
  return (
    <HStack spacing={1} px={1} align="flex-start">
      <ClaimInfo header="PAYER NAME">
        <Text>{PayerName}</Text>
      </ClaimInfo>
      <ClaimInfo header="SUBSCRIBER ID">
        <Text>{SubscriberID?.MemberID || 'NONE'}</Text>
      </ClaimInfo>
      <ClaimInfo header="GROUP NUMBER">
        <Text>{GroupNumber || 'NONE'}</Text>
      </ClaimInfo>
      <ClaimInfo header="PRIOR AUTH NO.">
        <Text>{PriorAuthNo || 'NONE'}</Text>
      </ClaimInfo>
    </HStack>
  )
}

const InsurancesView = ({ claim, preview }: { claim: AbilityClaim | null; preview?: boolean }) => {
  const insurances = useMemo(() => Object.values(claim?.Insurances ?? {}), [claim])
  const [firstInsurance] = insurances
  if (preview)
    return (
      <ClaimInfo header="INSURANCE">
        <Text>
          {firstInsurance
            ? `${firstInsurance.PayerName} - ${firstInsurance.SubscriberID?.MemberID || 'No Member ID'}`
            : 'NONE'}
        </Text>
      </ClaimInfo>
    )

  return (
    <VStack spacing={0} flexFlow="column" w="100%" align="flex-start">
      <Flex align="center" px={1} py={0.5} w="100%">
        <Text isTruncated color="gray.500" fontSize="xs" fontWeight={600}>
          {insurances.length} INSURANCES
        </Text>
      </Flex>
      <VStack spacing={1} align="flex-start">
        {insurances.map((insurance, idx) => (
          <InsuranceView key={idx} insurance={insurance} />
        ))}
      </VStack>
    </VStack>
  )
}

const OpenClaimInInovalon = ({ systemClaim }: { systemClaim?: WithId<Claim> | null }) => (
  <Tooltip
    bg="#d6cf38"
    color="#251f21"
    hasArrow
    placement="top"
    label="Open in Inovalon (Log into Ease All-Payer first)">
    <IconButton
      variant="ghost"
      borderRadius="full"
      aria-label="Open in Inovalon"
      onClick={e => {
        e.stopPropagation()
        window.open(
          `https://portal.focusedi.com/claim/view/${systemClaim?.abilityId}/prof`,
          '_blank',
        )
      }}
      icon={<Image height="20px" src="/images/Inovalon-Logo.svg" alt="Ability Logo" />}
    />
  </Tooltip>
)

export const AbilityClaimContent = ({
  claim,
  error,
  systemClaimId,
  systemClaim,
  preview,
  withErrors,
}: {
  claim: AbilityClaim | null
  systemClaim?: WithId<Claim> | null
  systemClaimId: string
  error: string | null
  withErrors?: boolean
  preview?: boolean
}) => {
  const { setUpdatingAbilityId } = useContext(ClaimsViewContext)
  const { value: serviceType } = useFormattedValue(serviceTypeField, systemClaim?.serviceType)
  const statusColor = useMemo(() => getInovalonClaimStatusColor(claim?.TransStatus), [claim])

  const canResubmit = useMemo(
    () => claim && ['ERROR', 'VALIDATED', 'ACK_PENDING'].includes(claim?.TransStatus ?? ''),
    [claim],
  )
  if (error) {
    return <Text color="red">{error}</Text>
  }
  if (claim) {
    return (
      <>
        {withErrors ? <AbilityClaimErrors errors={claim?.errors} /> : null}
        <ClaimInfo header="SERVICE TYPE">
          <Flex align="center">
            <Text isTruncated>{serviceType || 'NONE'}</Text>
            {systemClaim?.nullified ? (
              <Badge ml={2} colorScheme="red">
                NULLIFIED
              </Badge>
            ) : null}
          </Flex>
        </ClaimInfo>
        <ClaimInfo header="PATIENT CONTROL NO.">
          <Text isTruncated>{claim.PatientCtlNo || 'NONE'}</Text>
        </ClaimInfo>
        {/* <ClaimInfo header='ID'>
          <Text>{claim.TransID ?? 'NONE'}</Text>
        </ClaimInfo> */}
        <ClaimInfo header="STATUS">
          <HStack>
            <Text color={statusColor}>{claim.TransStatus || 'NONE'}</Text>
            {canResubmit ? (
              <Button
                bg={colors.green.hex}
                color="white"
                onClick={() => setUpdatingAbilityId(systemClaim?.abilityId ?? null)}
                size="xs">
                RESUBMIT
              </Button>
            ) : null}
          </HStack>
        </ClaimInfo>
        {/* <ServicesView claim={claim} preview={preview} /> */}
        <ClaimInfo header="CHARGE">
          <Flex gap={3} align="center">
            <Text>${claim.ClaimCharge}</Text>
            {preview ? null : (
              <NullifyClaimPopover systemClaimId={systemClaimId} systemClaim={systemClaim} />
            )}
          </Flex>
        </ClaimInfo>
        <ClaimInfo header="CREATED ON">
          <Text isTruncated>
            {claim.CreateDate ? getDateTimeString(new Date(claim.CreateDate), 'short') : 'NONE'}
          </Text>
        </ClaimInfo>
      </>
    )
  }
  return <Text color="red">Cannot find ability claim</Text>
}

const AbilityClaimMoreContent = ({
  abilityClaim,
  loading,
}: {
  abilityClaim: AbilityClaim | null
  loading: boolean
}) => {
  if (abilityClaim) {
    return (
      <>
        {/* <ClaimInfo header="AUTH REF">
        <Text isTruncated>{claim?.submittedArgs?.} </Text>
      </ClaimInfo> */}
        <ClaimInfo header="CLAIM NO.">
          <Text isTruncated>{abilityClaim?.ClaimID?.ClaimNo || 'NONE'}</Text>
        </ClaimInfo>
        <ClaimInfo header="CREATE MODE">
          <Text isTruncated>{abilityClaim.CreateMode || 'NONE'}</Text>
        </ClaimInfo>
      </>
    )
  }
  if (loading) {
    return (
      <HStack>
        <CircularProgress isIndeterminate size="24px" color={colors.green.hex} />
        <Text>Loading...</Text>
      </HStack>
    )
  }
  return <Text color="red">Cannot find ability claim</Text>
}
export const AbilityClaimView = ({
  claim,
  withMore,
  claimId,
}: {
  claim?: Claim
  withMore?: boolean
  claimId: string | null
}) => {
  const { abilityId } = claim ?? {}
  const {
    data: abilityClaim,
    loading,
    error,
  } = useDocument<AbilityClaim>(CLAIMS_ABILITY_DETAIL, abilityId)
  const [isRelinking, setIsRelinking] = useState(false)
  if (!claimId) return null
  return (
    <VStack spacing={0} width="100%" align="flex-start">
      {loading ? (
        <HStack justify="center" w="100%" p={2} align="center">
          <Spinner color="gray.400" />
          <Text color="gray.600">Loading claim from ability...</Text>
        </HStack>
      ) : (
        <Flex gap={1} flexFlow="column" w="100%">
          <Flex w="100%" position="relative" flexFlow="row wrap" gap={1} align="flex-start">
            <OpenClaimInInovalon />
            {abilityId && !isRelinking ? (
              <>
                <Tooltip bg="#afbd20" label="Re-link ability claim">
                  <Box pos="absolute" right={3} top={0}>
                    <Button
                      disabled={loading}
                      _hover={{ bg: '#cedf40' }}
                      onClick={() => setIsRelinking(true)}
                      bg="#afbd20"
                      size="xs">
                      <LinkIcon color="white" />
                    </Button>
                  </Box>
                </Tooltip>
                <AbilityClaimContent
                  withErrors
                  systemClaim={claim}
                  preview={!withMore}
                  systemClaimId={claimId}
                  error={error}
                  claim={abilityClaim}
                />
              </>
            ) : (
              <LinkAbilityClaim
                onCancel={() => setIsRelinking(false)}
                isRelink={isRelinking}
                onComplete={() => setIsRelinking(false)}
                claim={claim}
                claimId={claimId}
                assessmentId={null}
              />
            )}
            {abilityClaim && withMore && !isRelinking ? (
              <AbilityClaimMoreContent loading={loading} abilityClaim={abilityClaim} />
            ) : null}
          </Flex>
          <ServicesView claim={abilityClaim} preview={!withMore} />
          <InsurancesView claim={abilityClaim} preview={!withMore} />
        </Flex>
      )}
    </VStack>
  )
}

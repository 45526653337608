import { CheckIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from '@chakra-ui/react'
import { colors, LocalCollectionState, UpdateCallback, UserRoleItem } from '@hb/shared'
import React, { CSSProperties, FC, useState } from 'react'
import { getAdminsColorText, useAdminsWithColor } from '../hooks/backend/user/useAdminsWithColor'
import { filteredEvents } from '../utils/events'
import { Container } from './Container'
import { LoadingOverlay } from './LoadingOverlay'

export const Strikethrough: FC<{ style?: CSSProperties }> = ({ style }) => (
  <svg viewBox="0 0 100 100" style={{ height: '100%', width: '100%', ...style }}>
    <path d="M 0 0 L 100 100" strokeWidth={9} stroke={colors.red.hex} />
  </svg>
)

type SetColor = ((color?: string) => void) | ((color?: string) => Promise<UpdateCallback>)
export const ColorSelect: React.FC<{
  color?: string | null
  setColor: SetColor
  style?: CSSProperties
  admins?: LocalCollectionState<UserRoleItem>
  withAdmins?: boolean
}> = ({ color, setColor, style, withAdmins, admins }) => {
  const [loading, setLoading] = useState(false)
  const handleSubmit = async (c?: string) => {
    setLoading(true)
    await setColor(c)
    setLoading(false)
  }
  const withColor = useAdminsWithColor(admins, withAdmins, color)
  const { mapped } = withColor ?? {}
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Flex cursor="pointer" height="24px" width="24px" position="relative" style={style}>
          <Container
            borderRadius="full"
            style={{
              height: '24px',
              border: '3px solid #f5f5f5',
              boxShadow: '0 0 5px #00000088',
              width: '24px',
              background: color || '#efefef',
            }}>
            {color ? null : <Strikethrough style={{ width: '15px', height: '15px' }} />}
          </Container>
        </Flex>
      </PopoverTrigger>
      <PopoverContent width="150px">
        <Flex
          bg="white"
          overflow="hidden"
          boxShadow="0 0 4px #333"
          borderRadius={4}
          w="150px"
          flexFlow="row wrap">
          {color ? (
            <Container
              {...filteredEvents(handleSubmit)}
              title="Clear"
              style={{
                cursor: 'pointer',
                background: 'white',
                overflow: 'hidden',
                boxShadow: 'inset 0 0 4px #333',
                width: 25,
                height: 25,
                margin: '2.5px',
                borderRadius: 4,
              }}>
              <Strikethrough />
            </Container>
          ) : null}
          {Object.values(colors)
            .filter(c => c.hex !== color)
            .map(c => (
              <Box key={c.hex}>
                <Tooltip
                  placement="top"
                  hasArrow
                  label={`${c.name}${withAdmins ? ` - ${getAdminsColorText(mapped?.[c.hex])}` : ''}`}>
                  <IconButton
                    aria-label={c.name}
                    variant="unstyled"
                    margin="2px"
                    cursor="pointer"
                    background={c.hex}
                    width="26px"
                    minW="0"
                    height="26px"
                    boxShadow="inset 0 0 4px #333"
                    borderRadius={4}
                    color="transparent"
                    _hover={{ color: 'white' }}
                    icon={<CheckIcon filter="drop-shadow(0 0 3px #000000)" />}
                    {...filteredEvents(() => handleSubmit(c.hex))}
                  />
                </Tooltip>
              </Box>
            ))}
        </Flex>
        <LoadingOverlay loading={loading} />
      </PopoverContent>
    </Popover>
  )
}

// uses FaxesViewContext to prompt the user to select a

import { ArrowBackIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  CircularProgress,
  Collapse,
  HStack,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  Assessment,
  colors,
  DropdownField,
  FieldTypes,
  getAssessmentName,
  getDateString,
  getPracticeAssessmentCollectionPath,
} from '@hb/shared'
import { collection, Query, query, where } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { db } from '../../backend/db'
import { ASSESSMENTS_REF } from '../../collections/firestoreCollections'
import { PopUpMessageContext } from '../../contexts'
import { AppContext } from '../../contexts/AppContext'
import { usePracticeAccess } from '../../contexts/PracticeAccess/PracticeAccessProvider'
import { useQuery } from '../../hooks/backend/useQuery'
import { ActionLog } from '../ActionLog'
import { StandaloneInput } from '../forms/Input'
import { CollapseHorizontal } from '../shared/CollapseHorizontal'
import { SearchUsers } from '../Users/SearchUsers'

const SelectPatientAssessment = ({
  patientId,
  setPatientId,
  assessmentId,
  onSubmit,
  onBack,
  submitting,
}: {
  patientId: string | null
  setPatientId: (pId: string | null) => void
  assessmentId?: string | null
  onBack?: () => void
  onSubmit: (submitted: string) => void
  submitting: boolean
}) => {
  // const {
  //   faxAssignment: {
  //     onSubmit,
  //     submitting,
  //     assigningToPatientId,
  //     assignToPatient,
  //   },
  // } = useContext(FaxesViewContext)

  const { appName } = useContext(AppContext)
  const { selectedPracticeId } = usePracticeAccess()
  const queryRef = useMemo(() => {
    if (!patientId) return null
    if (appName === 'providers-app' && !selectedPracticeId) return null
    if (appName === 'app') {
      return query(ASSESSMENTS_REF, where('patientId', '==', patientId)) as Query<Assessment>
    }
    return selectedPracticeId
      ? (query(
          collection(db, getPracticeAssessmentCollectionPath(selectedPracticeId)),
          where('patientId', '==', patientId),
        ) as Query<Assessment>)
      : null
  }, [patientId, appName, selectedPracticeId])
  const { data: assessments, loading: assessmentsLoading } = useQuery<Assessment>(queryRef)

  const field = useMemo<DropdownField>(
    () => ({
      type: FieldTypes.DROPDOWN,
      placeholder: 'Select an assessment',
      options: Object.entries(assessments ?? {}).map(([id, assessment]) => ({
        text: getAssessmentName(assessment),
        id,
      })),
      renderOption: ({ id }) => {
        const assessment = assessments?.[id]
        return (
          <VStack align="flex-start" spacing={1}>
            {assessment ? (
              <>
                <HStack>
                  <ActionLog
                    on={assessment.createdOn}
                    by={assessment.createdBy}
                    action="Created"
                    group={assessment.createdByGroup}
                  />
                </HStack>
                <HStack>
                  <Text fontFamily="Open Sans">{getAssessmentName(assessment)}</Text>
                  {assessment.archivedOn ? (
                    <Badge colorScheme="red">
                      Archived on {getDateString(assessment.archivedOn, 'short')}
                    </Badge>
                  ) : null}
                </HStack>
              </>
            ) : (
              <Text> No assessment with id {id}</Text>
            )}
          </VStack>
        )
      },
    }),
    [assessments],
  )
  let body = null
  if (submitting) {
    body = (
      <HStack p={2} w="100%">
        <CircularProgress size={5} isIndeterminate color={colors.green.hex} />
        <Text>Submitting changes...</Text>
      </HStack>
    )
  } else if (assessmentsLoading) {
    body = (
      <HStack p={2} w="100%">
        <CircularProgress size={5} isIndeterminate color={colors.green.hex} />
        <Text>Getting assessments...</Text>
      </HStack>
    )
  } else {
    body = (
      <StandaloneInput
        field={field}
        theme="detailed"
        onChange={id => onSubmit(id)}
        value={assessmentId}
      />
    )
  }
  return (
    <Box p={2} bg="gray.50" borderRadius={4} boxShadow="md">
      <HStack w="100%">
        <Box flex={1} minW="0">
          {body}
        </Box>
        <CollapseHorizontal width={36} in={!submitting}>
          <IconButton
            size="sm"
            ml="auto"
            aria-label="Back"
            variant="ghost"
            icon={<ArrowBackIcon />}
            onClick={() => {
              setPatientId(null)
              if (onBack) {
                onBack()
              }
            }}
          />
        </CollapseHorizontal>
      </HStack>
    </Box>
  )
}

// patient and then an assessment belonging to that patient
export const SearchAssessments = ({
  onSubmit,
  onBack,
  assessmentId,
  patientId: initPatientId,
}: {
  onSubmit: (submitted: string) => Promise<void>
  onBack?: () => void
  assessmentId?: string | null
  patientId?: string | null
}) => {
  // const {
  //   faxAssignment: {
  //     assigningToFaxId,
  //     assigningToPatientId,
  //     assignToFax,
  //     assignToPatient,
  //   },
  // } = useContext(FaxesViewContext)

  const [patientId, setPatientId] = useState<string | null>(initPatientId ?? null)
  const [submitting, setSubmitting] = useState(false)
  const { processResponse } = useContext(PopUpMessageContext)
  const handleSubmit = useCallback(
    async (id: string) => {
      setSubmitting(true)
      try {
        await onSubmit(id)
      } catch (err: any) {
        processResponse({ error: err?.message || 'An error occurred' })
      }
      setSubmitting(false)
    },
    [onSubmit, processResponse],
  )

  return (
    <Box>
      <Collapse in={!!patientId}>
        <SelectPatientAssessment
          onSubmit={handleSubmit}
          submitting={submitting}
          onBack={onBack}
          patientId={patientId}
          assessmentId={assessmentId}
          setPatientId={setPatientId}
        />
      </Collapse>
      <Collapse in={!patientId}>
        <SearchUsers isOpen={!patientId} userTypeName="patient" onSelect={id => setPatientId(id)} />
      </Collapse>
    </Box>
  )
}

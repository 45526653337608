import {
  AssessmentPaymentsData,
  PaymentDue,
  PaymentRecord,
  PaymentType,
  PopulatedAssessment,
  PopulatedUser,
  ShortcutArgs,
} from '../../types'
import { formatDollarValue } from '../data'
import { addNDaysTo, getDateString, getNDaysFromNow } from '../dates'
import { notUndefinedOrNull } from '../fields'
import { getTotalFromInsurer, getTotalFromPatient } from '../payments'
import { getCorrectedValue } from '../populated'
import { flattenBilledCharges } from '../providers'
import { getShortcutArgs } from './shortcutArgs'

const getTypeAndIndex = (path: string): [PaymentType, number] => {
  const [shortcutType] = path.split('.')
  switch (shortcutType) {
    case 'initialRetainer':
      return ['retainer', 0]
    case 'initialHomeBirthRetainer':
      return ['home-birth-retainer', 0]
    default:
      break
  }
  const [type, indexStr] = shortcutType === 'initialRetainer' ? ['retainer', '0'] : path.split('.')
  if (!indexStr) throw new Error('Invalid payment path at utils/templates/cost.ts')
  const index = parseInt(indexStr, 10)
  return [type as PaymentType, index]
}

export const getCostDueValue = ({
  path,
  shortcutArgs,
}: {
  path: string
  shortcutArgs: ShortcutArgs
}) => {
  // ex: retainer.0
  const { assessment, practice } = shortcutArgs ?? {}
  const pAsAdmin = practice ?? undefined
  const [type, index] = getTypeAndIndex(path)

  const { payments } = assessment ?? {}
  const sourcePayments = payments?.due ?? {}
  const typePayments = Object.entries(sourcePayments)
    .filter(([, val]) => val.type === type)
    .map(([key]) => key)
  const paymentKey = typePayments[index]
  const payment = paymentKey ? sourcePayments[typePayments[index]] : null
  if (!payment) {
    if (type === 'retainer' && index === 0 && pAsAdmin?.retainer) {
      return formatDollarValue(pAsAdmin.retainer)
    }

    return undefined
  }

  return payment.amount ? formatDollarValue(payment.amount) : undefined
}

const getCostReceivedValue = ({
  path,
  assessment,
}: {
  path: string
  assessment?: PopulatedAssessment | null
}) => {
  // ex: retainer.0
  const [shortcutType] = path.split('.')

  const totalFromPatient = getTotalFromPatient(assessment)
  const totalFromInsurer = getTotalFromInsurer(assessment)
  switch (shortcutType) {
    case 'totalFromPatient':
      return totalFromPatient ? formatDollarValue(totalFromPatient) : undefined
    case 'totalFromInsurer':
      return totalFromInsurer ? formatDollarValue(totalFromInsurer) : undefined
    default:
      break
  }

  const [type, index] = getTypeAndIndex(path)

  const { payments } = assessment ?? {}
  if (!payments) return undefined
  const sourcePayments = payments.received
  if (!sourcePayments) return undefined
  const typePayments = Object.entries(sourcePayments)
    .filter(([, val]) => val.type === type)
    .map(([key]) => key)
  if (!typePayments[index]) return undefined
  const payment = sourcePayments[typePayments[index]]
  if (!payment) return undefined

  return payment.amount ? formatDollarValue(payment.amount) : undefined
}

export const getAllowedCharges = ({ plan }: ShortcutArgs) => {
  const { 'out-of-network': oonData } = plan ?? {}
  const { maximumReimbursableCharges, reimbursementOption: reimbursement } = oonData ?? {}
  const { medicareRate, optionText: reimbursementOption } = reimbursement ?? {}
  if (maximumReimbursableCharges?.amount) return maximumReimbursableCharges.amount
  if (reimbursementOption) {
    switch (reimbursementOption) {
      case 'medicare-rates':
        if (medicareRate && !Number.isNaN(medicareRate)) {
          return medicareRate * 0.01 * 3000
        }
        break
      case 'r-and-c':
        return 10000
      default:
        break
    }
  }
  return undefined
  // return 'ALLOWED CHARGES'
}

export const getAllowedChargesString = (args: ShortcutArgs) => {
  const allowed = getAllowedCharges(args)
  if (notUndefinedOrNull(allowed)) {
    return formatDollarValue(allowed.toString())
  }
  return undefined
}

export const getReimbursement = (args: ShortcutArgs) => {
  const allowedCharges = getAllowedCharges(args)

  if (allowedCharges) {
    const { plan } = args
    const { 'out-of-network': oonData } = plan ?? {}
    const { deductible, coinsurance } = oonData ?? {}
    if (deductible && coinsurance) {
      return Math.max(0, (allowedCharges - deductible) * coinsurance * 0.01)
    }
  }
  return undefined
}

export const getReimbursementString = (args: ShortcutArgs) => {
  const reimbursement = getReimbursement(args)
  if (notUndefinedOrNull(reimbursement)) {
    return formatDollarValue(reimbursement.toString())
  }
  return undefined
}

export const getAutomaticDepositAmount = (args: ShortcutArgs): number | undefined => {
  const reimbursement = getReimbursement(args)
  const { prm, retainer } = args
  if (!reimbursement) {
    // console.error('Error calculating deposit - cant calculate reimbursement')
    return undefined
  }

  if (!prm) {
    // console.error('Error populating deposit - no midwife PRM')
    return undefined
  }
  if (!retainer) {
    // console.error('Error populating deposit - no midwife retainer')
    return undefined
  }

  return Math.max(0, (prm - reimbursement - retainer) / 2)
}

export const generateAutoPaymentsDue = (args: ShortcutArgs) => {
  const due: PaymentRecord<PaymentDue> = {}
  const signedOnDate = args.user?.signedOnDate ?? Date.now()
  due[`${signedOnDate}`] = {
    type: 'retainer',
    dueDate: getNDaysFromNow(14),
    amount: args.retainer ?? 1500,
  }
  const depositAmt = getAutomaticDepositAmount(args)
  const edd = getCorrectedValue(args.assessment, 'delivery.edd')
  if (depositAmt) {
    due[`${signedOnDate + 1}`] = {
      type: 'deposit',
      amount: depositAmt,
      dueDate: addNDaysTo(edd, -7 * 12),
    }
    due[`${signedOnDate + 2}`] = {
      type: 'deposit',
      amount: depositAmt,
      dueDate: addNDaysTo(edd, -7 * 4),
    }
  }

  return due
}

export const generatePaymentSchedule = (
  user: PopulatedUser,
  assessmentId: string,
  assessment: PopulatedAssessment,
): AssessmentPaymentsData => {
  const args = getShortcutArgs(user, assessmentId, assessment, user.insurancePlans?.primary)
  const ret: AssessmentPaymentsData = {
    createdOn: Date.now(),
    updatedOn: Date.now(),
  }
  if (args.prm) ret.prm = args.prm
  else ret.prm = 10000

  ret.due = generateAutoPaymentsDue(args)
  ret.prm = args.prm ?? 10000
  return ret
}

export const getDepositsString = (args: ShortcutArgs): string | undefined => {
  const { deposits, useManualPayments } = args
  if (useManualPayments) {
    const enteredDeposits = deposits?.filter(d => !!d.amount)
    if (enteredDeposits?.length) {
      return enteredDeposits
        .map(
          d =>
            `${formatDollarValue(d.amount)} - deposit due ${
              d.dueDate ? `by ${getDateString(d.dueDate)}` : '(No due date entered)'
            }`,
        )
        .join('\n')
    }
    return undefined
  }

  const depositAmount = getAutomaticDepositAmount(args)
  if (!depositAmount) return 'Error getting deposit automatic amount'
  if (!Number.isNaN(depositAmount)) {
    return `${formatDollarValue(depositAmount)} - deposit due 28 weeks into pregnancy
${formatDollarValue(depositAmount)} - deposit due 36 weeks into pregnancy`
  }

  return undefined
}

const getBilledChargeValue = (path: string, shortcutArgs: ShortcutArgs): string | undefined => {
  const { assessment } = shortcutArgs
  const { midwife } = assessment ?? {}
  const [chargeCode] = path.split('.')
  const midwifeBilledChargeAmount = flattenBilledCharges(midwife?.billedCharges)[`_${chargeCode}`]

  return midwifeBilledChargeAmount ? formatDollarValue(midwifeBilledChargeAmount) : undefined
}

const getPrmValue = (shortcutArgs: ShortcutArgs): string | undefined => {
  const assessmentPrm = shortcutArgs.assessment?.payments?.prm
  if (assessmentPrm) return formatDollarValue(assessmentPrm)
  const practicePrm = shortcutArgs.practice?.prm
  if (practicePrm) return formatDollarValue(practicePrm)
  return undefined
}

export const getCostValue = (path: string, shortcutArgs: ShortcutArgs): string | undefined => {
  const { assessment } = shortcutArgs
  // ex: payments.received.0
  const [, source, ...rest] = path.split('.')

  switch (source) {
    case 'prm':
      return getPrmValue(shortcutArgs)
    case 'allowedCharges':
      return getAllowedChargesString(shortcutArgs)
    case 'reimbursement':
      return getReimbursementString(shortcutArgs)
    case 'due':
      return getCostDueValue({ path: rest.join('.'), shortcutArgs })
    case 'received':
      return getCostReceivedValue({ path: rest.join('.'), assessment })
    case 'billedCharges':
      return getBilledChargeValue(rest.join('.'), shortcutArgs)
    default:
      return undefined
  }
}

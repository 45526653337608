import { Stack, StackDivider } from '@chakra-ui/react'
import {
  FieldMap,
  getCoveragePath,
  PatientCoverageId,
  primaryCoverageField,
  USER_INVITES,
  USERS,
} from '@hb/shared'

import { set } from 'nested-property'
import React, { useCallback, useContext, useMemo } from 'react'
import { ThemeContext } from '../../contexts'
import { ProfileContext } from '../../contexts/ProfileContext'
import { useUpdateDoc } from '../../hooks/backend/useUpdateDoc'

import { DataView } from '../DataView'

export const InsuranceCardView = ({
  insuranceCard,
  updateField,
  baseStoragePath,
  field,
}: {
  insuranceCard: any
  updateField: (path: string, data: any) => Promise<any>
  baseStoragePath: string
  field: FieldMap
}) => (
  <DataView
    initExpanded={!!insuranceCard}
    updateField={updateField}
    baseStoragePath={baseStoragePath}
    field={field}
    data={insuranceCard}
  />
)

export const InsuranceCardsView = () => {
  const {
    user,
    patientRef,
    isInvite,
    userId,
    // selectedAssessment: { ref, populated: selectedAssessment },
    // assessmentId,
  } = useContext(ProfileContext)

  // const { mergedData } = selectedAssessment ?? {}
  // const { primaryCoverage, medicaidCoverage } = mergedData?.['insurance-info'] ?? {}
  const { insurancePlans } = user ?? {}
  const { primary, secondary } = insurancePlans ?? {}
  const update = useUpdateDoc()
  const handleInsuranceCardUpload = useCallback(
    async (coverageId: PatientCoverageId, key: string, data: any) => {
      if (patientRef) {
        const coveragePath = getCoveragePath(coverageId)
        const m = {}
        set(m, key, data)
        return update(patientRef, `${coveragePath}.insuranceCard.${key}`, data)
      }
      return { error: 'Unknown error' }
    },
    [update, patientRef],
  )
  const patientStoragePath = useMemo(
    () => `${isInvite ? USER_INVITES : USERS}/${userId}`,
    [isInvite, userId],
  )

  const primaryCoverageInsuranceCardField = useMemo<FieldMap>(
    () => ({
      ...(primaryCoverageField.children.insuranceCard as FieldMap),
      name: 'Primary Coverage',
      initExpanded: !!primary?.insuranceCard,
    }),
    [primary],
  )

  const secondaryCoverageInsuranceCardField = useMemo<FieldMap>(
    () => ({
      ...(primaryCoverageField.children.insuranceCard as FieldMap),
      name: 'Secondary Coverage',
      initExpanded: !!secondary?.insuranceCard,
    }),
    [secondary],
  )

  return (
    <ThemeContext.Provider value={{ placeholderAbove: false }}>
      <Stack
        w="100%"
        boxShadow="md"
        bg="white"
        spacing={0}
        borderRadius={6}
        divider={<StackDivider />}>
        <InsuranceCardView
          insuranceCard={primary?.insuranceCard}
          updateField={(path, data) => handleInsuranceCardUpload('primary', path, data)}
          baseStoragePath={`${patientStoragePath}/insurancePlans/primary/insuranceCard`}
          field={primaryCoverageInsuranceCardField}
        />
        <InsuranceCardView
          insuranceCard={secondary?.insuranceCard}
          updateField={(path, data) => handleInsuranceCardUpload('secondary', path, data)}
          baseStoragePath={`${patientStoragePath}/insurancePlans/secondary/insuranceCard`}
          field={secondaryCoverageInsuranceCardField}
        />
      </Stack>
    </ThemeContext.Provider>
  )
}

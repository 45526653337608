import { EditIcon } from '@chakra-ui/icons'
import { Box, Flex, HStack, IconButton, Text, Tooltip } from '@chakra-ui/react'
import {
  BaseInsuranceCoverage,
  callIn,
  colors,
  CoverageSnippet,
  CoverageStageStatus,
  defaultStageValidate,
  FieldMapValue,
  Form,
  getCoverageLabel,
  getCoverageNextActionEntity,
  getCoveragePath,
  getCoverageRequiresCallIn,
  getCoverageStatus,
  getCoverageText,
  getCoverageType,
  getDateString,
  InsuranceCoverage,
  InsuranceCoverageUpdateType,
  insurersCollection,
  makeAllFieldsOptional,
  OnUploadProgress,
  PatientCoverageId,
  UpdateCallback,
} from '@hb/shared'
import { set as nestedSet } from 'nested-property'
import React, { JSX, useCallback, useContext, useMemo, useState } from 'react'
import { processFieldMapData } from '../../../backend'
import { useApp } from '../../../contexts'
import { useCollectionItem } from '../../../hooks/backend/useCollectionItem'
import { useAppGroup } from '../../../hooks/backend/user/useAppGroup'
import { useAuth } from '../../../store/auth'
import { xor } from '../../../utils'
import { AddItem } from '../../AddItem'
import { DataView, GenericEditModal } from '../../DataView'
import { Expandable } from '../../Expandable'
import { FormWizard } from '../../forms'
import { SimpleForm } from '../../forms/FinalForm/SimpleForm'
import { DefaultModal } from '../../Modals/DefaultModal'
import {
  EntityNextActionContent,
  useSubmitCoverageNextAction,
} from '../../Users/Profile/NextAction'
import { EligibilityRequestPopover } from '../../Users/Profile/Patient/EligibilityRequest'
import { CoverageViewContext, CoverageViewProvider, useCoverageView } from './context'
import { CoverageActions } from './CoverageActions'
import { CoverageHistory } from './CoverageHistory'
import { CoverageStatusBadge } from './CoverageStatusBadge'
import { RequestCallIn } from './RequestCallIn'
import { CoverageStageProps } from './types'
import { coverageSnippetStage } from './utils'

const callInForm: Form = {
  ...callIn,
  path: '',
}

const coverageSnippetUpdateType: Record<CoverageSnippet, InsuranceCoverageUpdateType> = {
  'basic-info': 'basicInfo',
  'call-in': 'callIn',
  'policy-owner': 'policyOwner',
}

const CoverageSnippetView = ({
  id,
  isMedicaid,
  coverageId,
  disabled,
  inModal,
}: {
  id: CoverageSnippet
  coverageId: PatientCoverageId
  isMedicaid: boolean
  disabled?: boolean
  inModal?: boolean
}) => {
  const authUser = useAuth(s => s.authUser)
  const { coverage, handleSubmit, adminView, baseStoragePath, patientRef } =
    useContext(CoverageViewContext)
  const [isEditing, setIsEditing] = useState(false)

  const field = useMemo(() => {
    const f = coverageSnippetStage[id](isMedicaid)
    return adminView ? makeAllFieldsOptional(f) : f
  }, [id, isMedicaid, adminView])

  const group = useAppGroup()
  const isComplete = useMemo(() => !defaultStageValidate(field, true)(coverage), [field, coverage])

  const updateField = useCallback(
    async (path: string, data: FieldMapValue): Promise<UpdateCallback> => {
      const uid = authUser?.uid
      if (!uid) return { error: 'Not signed in' }
      if (!coverage && coverageId.startsWith('.additionalPlans'))
        return { error: 'No coverage to update' }
      let submitted: Partial<InsuranceCoverage> = {}
      if (path) {
        submitted = { ...coverage }
        nestedSet(submitted, path, data)
      } else submitted = { ...coverage, ...data }
      submitted.history = [
        ...(coverage?.history && Array.isArray(coverage?.history) ? coverage.history : []),
        { by: uid, on: Date.now(), type: coverageSnippetUpdateType[id], group },
      ]
      return handleSubmit(submitted).then(errors => {
        if (errors) return { error: 'An error occurred' }
        return { success: 'Updated!' }
      })
    },
    [coverage, handleSubmit, authUser, id, coverageId, group],
  )

  const getStoragePath = useCallback(
    () => `${patientRef.path}/${getCoveragePath(coverageId)}`.replace(/\./g, '/'),
    [coverageId, patientRef],
  )

  const onSubmit = useCallback(
    async (data: FieldMapValue, onUploadProgress: OnUploadProgress) => {
      const processed = await processFieldMapData(
        baseStoragePath,
        field,
        data,
        coverage,
        onUploadProgress,
      )
      return handleSubmit(processed)
    },
    [handleSubmit, baseStoragePath, field, coverage],
  )

  let body: JSX.Element | null = null
  if (id === 'call-in') {
    if (!adminView) {
      body = (
        <FormWizard
          withPreview
          data={coverage}
          onSubmit={handleSubmit}
          getStoragePath={getStoragePath}
          // baseStoragePath={baseStoragePath}
          form={callInForm}
        />
      )
    } else {
      body = (
        <DataView
          noHeader
          boxProps={{
            p: 0,
            boxShadow: 'none',
            borderBottom: '1px solid #cdcdcd',
          }}
          adminView={adminView}
          updateField={updateField}
          baseStoragePath={baseStoragePath}
          onSubmit={onSubmit}
          data={coverage}
          field={field}
        />
      )
    }
  } else if (adminView || isComplete) {
    body = (
      <DataView
        boxProps={{
          p: 0,
          boxShadow: 'none',
          borderBottom: '1px solid #cdcdcd',
        }}
        adminView={!!adminView}
        onSubmit={onSubmit}
        noHeader
        baseStoragePath={baseStoragePath}
        updateField={updateField}
        data={coverage}
        field={field}
      />
    )
  } else {
    body = (
      <SimpleForm
        boxProps={{
          p: 0,
          boxShadow: 'none',
        }}
        onSubmit={onSubmit}
        value={coverage}
        field={field}
      />
    )
  }
  return (
    <>
      <Expandable
        borderBottom="1px solid #cdcdcd"
        initExpanded={!isComplete}
        headerProps={{ bg: '#e4f6ef', _hover: { bg: 'green.100' } }}
        bg="white"
        header={({ isOpen }) => (
          <HStack flex={1} pl={2} py={1} minW="0" spacing={0}>
            <Flex color="#565656" position="relative" fontWeight={600} fontFamily="Open Sans">
              {field.name}
            </Flex>
            <Flex gap={2} ml="auto">
              {adminView && id === 'call-in' && coverageId.startsWith('additional.') ? (
                <RequestCallIn visible={isOpen} />
              ) : null}
              {adminView || id !== 'call-in' ? (
                <Tooltip label={disabled ? 'Please complete previous steps' : ''}>
                  <IconButton
                    aria-label="edit"
                    pointerEvents={disabled ? 'none' : 'auto'}
                    color={disabled ? 'gray.400' : 'gray.600'}
                    icon={<EditIcon />}
                    _hover={{ bg: 'whiteAlpha.500' }}
                    h={7}
                    w={7}
                    minW={0}
                    borderRadius="full"
                    size="sm"
                    variant="ghost"
                    onClick={e => {
                      e.stopPropagation()
                      if (disabled) return
                      setIsEditing(true)
                    }}
                  />
                </Tooltip>
              ) : null}
            </Flex>
          </HStack>
        )}>
        <Flex bg="white" px={inModal ? 2 : 0} w="100%">
          {body}
        </Flex>
      </Expandable>
      {isEditing ? (
        <GenericEditModal
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          onSubmit={data => updateField('', data)}
          field={field}
          data={coverage}
        />
      ) : null}
    </>
  )
}
const CoverageTimeline = ({
  coverage,
}: {
  coverage: InsuranceCoverage | BaseInsuranceCoverage | undefined | null
}) => {
  const {
    terminationDate,
    initialServiceDate: nonMedicaidStartDate,
    isMedicaid,
    medicaidStartDate,
  } = coverage ?? {}
  const initialServiceDate = useMemo(() => {
    if (isMedicaid) return medicaidStartDate
    return nonMedicaidStartDate
  }, [isMedicaid, medicaidStartDate, nonMedicaidStartDate])
  const hasTerminated = useMemo(() => {
    if (!terminationDate) return false
    const now = Date.now()
    return terminationDate < now
  }, [terminationDate])
  const statusColor = useMemo(() => {
    const now = Date.now()
    if (hasTerminated) return 'red'
    if (initialServiceDate && initialServiceDate > now) return 'gray'
    return 'green'
  }, [initialServiceDate, hasTerminated])

  const formattedCoverageStartDate = useMemo(
    () => (initialServiceDate ? getDateString(initialServiceDate, 'short') : ''),
    [initialServiceDate],
  )

  const formattedTerminationDate = useMemo(
    () => (terminationDate ? getDateString(terminationDate, 'short') : ''),
    [terminationDate],
  )

  const text = useMemo(() => {
    if (formattedCoverageStartDate && formattedTerminationDate) {
      return `${formattedCoverageStartDate} - ${formattedTerminationDate}`
    }
    if (formattedCoverageStartDate) {
      const hasStarted = initialServiceDate && initialServiceDate < Date.now()
      return `${hasStarted ? 'Started' : 'Starts'} ${formattedCoverageStartDate}`
    }
    if (formattedTerminationDate) {
      return `${hasTerminated ? 'Terminated' : 'Terminates'} ${formattedTerminationDate}`
    }
    return 'No start/end date'
  }, [hasTerminated, formattedCoverageStartDate, formattedTerminationDate, initialServiceDate])

  return (
    <Flex>
      <Flex gap={3} borderRadius={4} px={2} align="center" bg={`${statusColor}.100`}>
        <Text whiteSpace="nowrap" fontWeight={600} fontSize="xs" color={`${statusColor}.700`}>
          {text.toUpperCase()}
        </Text>
      </Flex>
    </Flex>
  )
}

const CoverageNextAction = () => {
  const { coverageId: id, coverage, assessmentId, nextAction } = useCoverageView()
  const nextActionEntity = useMemo(() => getCoverageNextActionEntity(id, coverage), [id, coverage])
  if (!assessmentId)
    return (
      <Text fontSize="sm" color="gray.500" fontWeight={600} px={2} py={1}>
        Select a pregnancy to view/edit next actions
      </Text>
    )
  return (
    <EntityNextActionContent
      inList={false}
      entity={nextActionEntity}
      assessmentId={assessmentId}
      nextAction={nextAction}
    />
  )
}

const CoveragePreview = ({
  mobileLayout,
  adminView,
  status,
}: {
  status: CoverageStageStatus
  mobileLayout: boolean
  adminView?: boolean
}) => {
  const { coverageId: id, coverage, nextAction, assessment, assessmentId } = useCoverageView()
  const coverageName = useMemo(
    () => getCoverageLabel(id, assessmentId ? assessment : undefined),
    [id, assessment, assessmentId],
  )
  const { item: insuranceProvider } = useCollectionItem(
    insurersCollection,
    coverage?.insuranceProviderId,
  )

  const coverageText = useMemo(
    () => (coverage ? getCoverageText(coverage, insuranceProvider) : ''),
    [coverage, insuranceProvider],
  )
  const hasEligibilityRequest = useMemo(
    () => Object.keys(coverage?.eligibilityRequests ?? {}).length,
    [coverage],
  )
  return (
    <Flex flexFlow="column" w="100%">
      <Flex
        flexFlow={mobileLayout ? 'column' : 'row'}
        py={1}
        gap={mobileLayout ? 0 : 2}
        align={mobileLayout ? 'center' : 'flex-start'}
        w="100%">
        <Flex gap={1} px={2} w="100%" align="center" flexFlow="row">
          <Text
            flex={1}
            minW="0"
            color="white"
            isTruncated
            textShadow="1px 1px 3px #00000066"
            fontWeight={600}>
            {coverageName} Coverage - {coverageText}
          </Text>
          <Flex gap={1.5} align="center" ml="auto" flexFlow="row">
            {mobileLayout ? null : (
              <>
                <CoverageStatusBadge status={status} />
                <CoverageTimeline coverage={coverage} />
              </>
            )}
            {coverage ? <CoverageHistory coverage={coverage} /> : null}
          </Flex>
        </Flex>
        {mobileLayout ? (
          <Flex
            pt={1}
            px={2}
            borderTop="1px solid #00000022"
            w="100%"
            justify="space-between"
            align="center">
            <CoverageStatusBadge status={status} />
            <CoverageTimeline coverage={coverage} />
          </Flex>
        ) : null}
      </Flex>
      {adminView && nextAction ? <CoverageNextAction /> : null}
      {adminView && hasEligibilityRequest ? <SubmitCoverageEligibilityRequest /> : null}
    </Flex>
  )
}

const AddNextAction = ({
  assessmentId,
  id,
}: {
  id: PatientCoverageId
  assessmentId: string | null
}) => {
  const onSubmit = useSubmitCoverageNextAction(assessmentId, id, false)
  return (
    <Flex borderBottom="1px solid #cdcdcd" align="center" w="100%" bg="gray.100" pl={3}>
      <Text opacity={0.8} fontWeight={600}>
        Next Action
      </Text>
      <Flex ml="auto">
        {assessmentId ? (
          <AddItem
            placeholder="Next action"
            itemName="Next Action"
            onAdd={text => onSubmit(text)}
          />
        ) : (
          <Flex px={2} py={1.5}>
            <Text fontSize="sm" opacity={0.7}>
              Select a pregnancy to view/edit next action
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

const SubmitCoverageEligibilityRequest = () => {
  const { coverageId } = useCoverageView()
  return (
    <Flex bg="gray.100" borderBottom="1px solid #cdcdcd" w="100%" py={1} px={2}>
      <Flex flex={1} minW="0">
        <Text isTruncated opacity={0.8} fontWeight={600}>
          Eligibility Requests
        </Text>
      </Flex>
      <EligibilityRequestPopover coverageId={coverageId} />
    </Flex>
  )
}

const CoverageStageBody = <T extends BaseInsuranceCoverage>({
  coverage,
  status,
  optional,
  request,
  nextAction,
  adminView,
  isMedicaid,
  inModal,
}: CoverageStageProps<T> & {
  status: CoverageStageStatus
  isMedicaid: boolean | undefined
}) => {
  const { coverageId: id, assessmentId } = useCoverageView()
  const { appName } = useApp()
  const requiresCallIn = useMemo(
    () => getCoverageRequiresCallIn(coverage ? { ...coverage, id } : null, optional, request),
    [coverage, optional, request, id],
  )
  const basicInfoComplete = useMemo(() => !status?.incomplete.includes('basic-info'), [status])

  const callInComplete = useMemo(() => !status?.incomplete.includes('call-in'), [status])

  const showRequestCallIn = useMemo(() => !!adminView && !isMedicaid, [adminView, isMedicaid])
  const hasEligibilityRequest = useMemo(
    () => Object.keys(coverage?.eligibilityRequests ?? {}).length,
    [coverage],
  )

  return (
    <>
      {adminView && !nextAction ? <AddNextAction assessmentId={assessmentId} id={id} /> : null}
      {adminView && !hasEligibilityRequest ? <SubmitCoverageEligibilityRequest /> : null}
      <Flex w="100%" bg="gray.50">
        <CoverageSnippetView
          coverageId={id}
          id="basic-info"
          isMedicaid={!!isMedicaid}
          inModal={inModal}
        />
      </Flex>
      {(requiresCallIn || showRequestCallIn) && !request ? (
        <CoverageSnippetView
          coverageId={id}
          disabled={!adminView && !basicInfoComplete}
          id="call-in"
          isMedicaid={!!isMedicaid}
        />
      ) : null}
      {request ? null : (
        <CoverageSnippetView
          coverageId={id}
          id="policy-owner"
          disabled={!adminView && (!basicInfoComplete || !callInComplete)}
          isMedicaid={!!isMedicaid}
        />
      )}
      {adminView && coverage && appName === 'app' ? (
        <CoverageActions adminView={adminView} />
      ) : null}
    </>
  )
}

export const CoverageStage = <T extends BaseInsuranceCoverage>(props: CoverageStageProps<T>) => {
  const {
    isOpen,
    onOpenToggle,
    adminView,
    coverage,
    id,
    width,
    optional,
    inModal,
    alwaysOpen,
    request,
  } = props

  const type = useMemo(() => getCoverageType(id), [id])
  const status = useMemo(
    () => getCoverageStatus(type, coverage, optional, request),
    [type, coverage, optional, request],
  )
  const { isMedicaid } = coverage ?? {}

  const complete = useMemo(() => !status?.incomplete?.length, [status])

  const initExpanded = useMemo(() => xor(!complete, adminView), [complete, adminView])

  const mobileLayout = useMemo(() => width < 769, [width])
  return (
    <CoverageViewProvider {...props}>
      <Expandable
        isOpen={isOpen}
        alwaysExpanded={inModal || alwaysOpen}
        onClose={onOpenToggle}
        onOpen={onOpenToggle}
        bg={inModal ? 'transparent' : 'gray.50'}
        initExpanded={initExpanded}
        overflow="hidden"
        border="1px solid #cdcdcd"
        borderRadius={inModal ? 0 : 6}
        iconColor="whiteAlpha.900"
        headerProps={{
          bg: colors.green.hex,
          pl: 0,
          pr: 2,
          py: 0,
        }}
        header={() => (
          <Box
            w="100%"
            mr={alwaysOpen ? 0 : 2}
            borderRight={alwaysOpen ? 'none' : '1px solid #00000033'}>
            <CoveragePreview mobileLayout={mobileLayout} adminView={adminView} status={status} />
          </Box>
        )}>
        <CoverageStageBody {...props} status={status} isMedicaid={isMedicaid} />
      </Expandable>
    </CoverageViewProvider>
  )
}

export const CoverageStageModal = <T extends BaseInsuranceCoverage>({
  isOpen,
  onClose,
  ...props
}: CoverageStageProps<T> & { isOpen: boolean; onClose: () => void }) => (
  <DefaultModal
    size="xl"
    isOpen={isOpen}
    onClose={onClose}
    overlayHeader
    render={() => <CoverageStage inModal isOpen {...props} />}
  />
)

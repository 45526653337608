import { Box, Flex, Text } from '@chakra-ui/react'
import { InsuranceProvider } from '@hb/shared'
import React from 'react'
import { Condition } from '../../../../../forms/FinalForm/Condition'
import { FormElement } from '../../../../../forms/Input'
import { customPayerIdPrimary, shouldUseCustomPayerId } from '../fields'

export const PayerIdInput = ({
  insuranceProvider,
  shouldUseCustomField,
  customPayerIdField,
}: {
  shouldUseCustomField: string
  customPayerIdField: string
  insuranceProvider: InsuranceProvider | undefined | null
}) => (
  <Flex w="100%" flexFlow="column" pt={2}>
    <FormElement name={shouldUseCustomField} field={shouldUseCustomPayerId} />
    <Condition basePath="" condition={v => !v?.[shouldUseCustomField]}>
      <Box w="100%" px={2}>
        {!insuranceProvider?.abilityId ? (
          <Text color="red.600" fontSize="md">
            Insurance provider is missing Payer ID (Insurers Page)
          </Text>
        ) : (
          <Text>
            {insuranceProvider?.name || 'Insurer'} payer ID:{' '}
            {insuranceProvider?.abilityId || 'None'}
          </Text>
        )}
      </Box>
    </Condition>
    <FormElement name={customPayerIdField} field={customPayerIdPrimary} />
  </Flex>
)

import { insurersCollection, UserGroup } from '@hb/shared'
import React, { useMemo } from 'react'
import { useCollectionItem } from '../../hooks/backend/useCollectionItem'
import { ActionLog } from '../ActionLog'

export const AssessmentResultsLogItem = ({
  sentBy,
  sentOn,
  group,
  insurerId,
}: {
  sentOn?: number | null
  sentBy?: string | null
  group?: UserGroup
  insurerId?: string | null
}) => {
  const { item: insurer } = useCollectionItem(insurersCollection, insurerId)

  const text = useMemo(() => {
    const insurerName = insurer?.name
    return insurerName ? `${insurerName} assessment sent` : 'Assessment sent'
  }, [insurer])

  return <ActionLog on={sentOn} by={sentBy} group={group} action={text} />
}

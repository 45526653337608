import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { Box, Flex, IconButton, Text, VStack } from '@chakra-ui/react'
import {
  ALPHABET,
  colors,
  DropdownField,
  FieldTypes,
  flattenedCptCodes,
  getNewServiceDiagnosisCode,
  MultipleSelectField,
} from '@hb/shared'
import React, { useMemo, useRef, useState } from 'react'
import { useField } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'
import { SolidActionButton } from '../../../../../Buttons'
import { FormElement, StandaloneInput } from '../../../../../forms/Input'
import { InputRef } from '../../../../../forms/Input/types'
import { CollapseHorizontal } from '../../../../../shared'
import { customDiagnosisCodeField } from '../fields'

const AddDiagnosisCode = ({
  index,
  hcpcCode,
  name,
}: {
  index: number
  hcpcCode: string | undefined
  name: string
}) => {
  const { fields } = useFieldArray('DiagnosisCodes', { subscription: {} })
  const { input: pointersInput } = useField(`${name}.DiagnosisPointer`, {
    subscription: { value: true },
  })

  const options = useMemo(() => {
    if (!hcpcCode) return []
    const codeData = flattenedCptCodes[hcpcCode]
    if (!codeData) return []
    return [
      ...codeData.icd10.map(({ code, prefix, description }) => ({
        id: code,
        text: `${prefix ? `${prefix} | ` : ''}${code} - ${description}`,
      })),
      { id: 'other', text: 'Other' },
    ]
  }, [hcpcCode])

  const selectField = useMemo<DropdownField>(
    () => ({
      type: FieldTypes.DROPDOWN,
      options,
      placeholder: 'Diagnosis Code',
    }),
    [options],
  )

  const customCodeField = useMemo(
    () => ({
      ...customDiagnosisCodeField,
      placeholder: `Diagnosis Code ${ALPHABET[index]}`,
      condition: null,
    }),
    [index],
  )

  const [isAdding, setIsAdding] = useState(false)
  const [selectedOption, setSelectedOption] = useState<string | null>(null)
  const [customCode, setCustomCode] = useState('')
  const canSubmit = useMemo(() => {
    if (options.length && !selectedOption) return false
    if (selectedOption === 'other') return !!customCode
    return true
  }, [selectedOption, customCode, options])

  const selectFieldRef = useRef<InputRef>(null)
  const customCodeFieldRef = useRef<InputRef>(null)
  return (
    <Flex borderRadius={6} px={1} w="100%" align="center" justify="space-between">
      <CollapseHorizontal width={170} in={!isAdding}>
        <SolidActionButton
          onClick={() => {
            setIsAdding(true)
          }}
          size="sm">
          + Add Diagnosis Code
        </SolidActionButton>
      </CollapseHorizontal>
      <Flex
        align="center"
        gap={2}
        pointerEvents={isAdding ? 'auto' : 'none'}
        opacity={isAdding ? 1 : 0}
        transition="opacity 300ms"
        flex={1}
        w={options.length ? '100%' : 'auto'}>
        <Flex
          h="32px"
          align="center"
          border="1px solid #cdcdcd"
          borderRadius={4}
          minW="0"
          flex={options.length ? 1 : 'unset'}>
          {options.length ? (
            <Flex flex={1} minW="0" align="center">
              <StandaloneInput
                ref={selectFieldRef}
                field={selectField}
                value={selectedOption}
                style={{
                  pointerEvents: isAdding ? 'auto' : 'none',
                  width: '100%',
                  // this is the order for padding: top right bottom left
                  padding: '0.4rem 0.4rem 0.4rem 0.4rem',
                }}
                onChange={e => {
                  setSelectedOption(e)
                  if (e === 'other') {
                    customCodeFieldRef.current?.focus()
                  }
                }}
              />
            </Flex>
          ) : null}
          <CollapseHorizontal width={230} in={!options.length || selectedOption === 'other'}>
            <Box borderLeft={options.length ? '1px solid #cdcdcd' : 'none'} h="100%">
              <StandaloneInput
                ref={customCodeFieldRef}
                field={customCodeField}
                style={{
                  width: '228px',
                  pointerEvents: isAdding ? 'auto' : 'none',
                }}
                value={customCode}
                onChange={e => setCustomCode(e)}
              />
            </Box>
          </CollapseHorizontal>
        </Flex>
        <IconButton
          aria-label="Cancel"
          size="xs"
          onClick={() => {
            setIsAdding(false)
            setSelectedOption(null)
            selectFieldRef.current?.blur()
            setCustomCode('')
          }}
          icon={<CloseIcon />}
        />
        <IconButton
          aria-label="Submit"
          size="xs"
          bg={colors.green.hex}
          pointerEvents={canSubmit ? 'auto' : 'none'}
          opacity={canSubmit ? 1 : 0.5}
          color="white"
          onClick={() => {
            if (!canSubmit) return
            setIsAdding(false)
            setSelectedOption(null)
            setCustomCode('')
            fields.push({
              diagnosisCodeSelect: selectedOption || 'other',
              customDiagnosisCode: customCode,
            })
            pointersInput.onChange([
              ...(pointersInput.value && Array.isArray(pointersInput.value)
                ? pointersInput.value
                : []),
              `${index + 1}`,
            ])
          }}
          icon={<CheckIcon />}
        />
      </Flex>
    </Flex>
  )
}

export const DiagnosisPointersInput = ({ name }: { name: string }) => {
  const {
    input: { value: diagnosisCodes },
  } = useField<Array<string>>('DiagnosisCodes', {
    subscription: { value: true },
  })
  const { input } = useField(`${name}.HCPC`, { subscription: { value: true } })
  const { value: hcpcCode } = input ?? {}

  const options = useMemo(
    () =>
      (diagnosisCodes && Array.isArray(diagnosisCodes) ? diagnosisCodes : []).map((code, idx) => ({
        id: `${idx + 1}`,
        text: `${ALPHABET[idx]?.toUpperCase() ?? ''} | ${getNewServiceDiagnosisCode(code as any)}`,
      })),
    [diagnosisCodes],
  )
  // const field = useMemo(
  //   () => ({
  //     name: 'Diagnosis Pointers',
  //     itemName: 'Diagnosis Pointer',
  //     itemFields: {
  //       type: FieldTypes.DROPDOWN,
  //       options,
  //       placeholder: options?.length
  //         ? 'Diagnosis pointer'
  //         : 'No diagnosis codes to point to',
  //     } as DropdownField,
  //   } as ListField),
  //   [options],
  // )

  const field = useMemo<MultipleSelectField>(
    () => ({
      type: FieldTypes.MULTIPLE_SELECT,
      options,
      placeholder: 'Diagnosis pointers',
    }),
    [options],
  )
  if (!hcpcCode) {
    return (
      <VStack px={2} align="flex-start" w="100%" spacing={0}>
        <Text fontWeight={600} color="gray.500" fontSize="sm">
          Diagnosis Pointer
        </Text>
        <Text px={1} color="gray.600" fontStyle="italic">
          Please select a CPT code first
        </Text>
      </VStack>
    )
  }

  return (
    <VStack px={2} align="flex-start" w="100%" spacing={1}>
      {options.length ? (
        <FormElement field={field} name={`${name}.DiagnosisPointer`} />
      ) : (
        <>
          <Text fontWeight={600} color="gray.500" fontSize="sm">
            Diagnosis Pointer
          </Text>
          <Text px={1} pb={1} color="gray.600" fontStyle="italic">
            No diagnosis codes to point to
          </Text>
        </>
      )}
      <AddDiagnosisCode name={name} hcpcCode={hcpcCode} index={options.length} />
    </VStack>
  )
}

import {
  Box,
  Button,
  Flex,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { kebabCaseToTitleCase, NotificationSoundId, NotificationTopic } from '@hb/shared'
import { setDoc } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import useSound from 'use-sound'
import { PopUpMessageContext } from '../../../contexts'
import playIcon from '../../../icons/play_arrow_fill.svg'
import { SvgIcon } from '../../../icons/SvgIcon'
import { LoadingOverlay } from '../../LoadingOverlay'
import {
  defaultNotificationSound,
  notificationSoundGradients,
  notificationSounds,
} from '../constants'
import { useThreadsList } from '../contexts'

const PreviewPlayButton = ({ sound }: { sound: NotificationSoundId }) => {
  const { widget } = useThreadsList()
  const { data: widgetData, localNotificationVolume } = widget ?? {}
  const { notificationVolume: volume } = widgetData ?? {}

  const [play] = useSound(notificationSounds[sound], {
    volume: localNotificationVolume ?? volume ?? 0.25,
  })

  return (
    <IconButton
      borderRadius="full"
      size="xs"
      variant="ghost"
      _hover={{ bg: 'whiteAlpha.300' }}
      aria-label="Play sound"
      icon={
        <SvgIcon
          filter="invert(100%) brightness(200%) drop-shadow(0 0 3px #00000066)"
          src={playIcon}
        />
      }
      onClick={e => {
        e.stopPropagation()
        play()
      }}
    />
  )
}

const SoundPreview = ({ sound, onClick }: { sound: NotificationSoundId; onClick?: () => void }) => (
  <Box>
    <Flex
      py={0.5}
      boxShadow="1px 1px 5px #00000033"
      transition="all 200ms"
      _hover={{
        boxShadow: onClick ? '1px 1px 8px #00000066' : '1px 1px 5px #00000033',
      }}
      px={2}
      borderRadius={4}
      onClick={onClick}
      cursor={onClick ? 'pointer' : 'default'}
      justify="space-between"
      bg={notificationSoundGradients[sound]}
      overflow="hidden"
      align="center"
      gap={1.5}>
      <Text fontWeight={600} color="white" textShadow="1px 1px 3px #00000066" fontSize="sm">
        {kebabCaseToTitleCase(sound)}
      </Text>
      <PreviewPlayButton sound={sound} />
    </Flex>
  </Box>
)

export const NotificationSoundSelect = ({ topic }: { topic: NotificationTopic }) => {
  const { settings } = useThreadsList()
  const { showMessage } = useContext(PopUpMessageContext)
  const { data: settingsData, loading: settingsLoading, ref } = settings ?? {}

  const [selectLoading, setSelectLoading] = useState(false)
  const onSelect = useCallback(
    async (id: NotificationSoundId) => {
      const isValidKey = !!notificationSounds[id]
      if (!isValidKey || !ref) {
        return
      }
      setSelectLoading(true)
      try {
        await setDoc(ref, { notifications: { sounds: { [topic]: id } } }, { merge: true })
      } catch (err: any) {
        showMessage({
          text: 'Failed to update notification sound',
          subText: err.message,
          type: 'error',
        })
      }
      setSelectLoading(false)
    },
    [ref, topic, showMessage],
  )

  const customValue = useMemo(
    () => settingsData?.notifications?.sounds?.[topic],
    [settingsData, topic],
  )

  const value = useMemo(() => customValue ?? defaultNotificationSound, [customValue])

  return (
    <Box position="relative">
      <Popover isLazy strategy="fixed">
        <PopoverTrigger>
          <Button
            pr={0}
            as={Flex}
            cursor="pointer"
            py={2}
            isLoading={settingsLoading || selectLoading}
            justifyContent="space-between"
            w="100%"
            size="sm"
            variant="ghost"
            gap={1}>
            <Text fontWeight={600} fontSize="sm" opacity={0.8}>
              {customValue !== value ? 'Using custom sound' : 'Using default sound'}
            </Text>
            <SoundPreview sound={value} />
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody p={2}>
            <Flex flexFlow="column" gap={2}>
              {Object.keys(notificationSounds).map(option => (
                <SoundPreview
                  onClick={() => onSelect(option as NotificationSoundId)}
                  sound={option as NotificationSoundId}
                  key={option}
                />
              ))}
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <LoadingOverlay loading={settingsLoading} />
    </Box>
  )
}

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Alert, FieldTypes, reactionIcons, WithId } from '@hb/shared'
import { deleteField } from 'firebase/firestore'
import React, { useContext, useMemo } from 'react'
import { updateAssessmentMiscarriedStatus } from '../../../backend'
import { ProfileContext, ScreenContext, useApp } from '../../../contexts'
import { useUpdateDoc } from '../../../hooks/backend/useUpdateDoc'
import { addMetadata } from '../../../utils'
import { ActionLog } from '../../ActionLog'
import { AddAlertPopover } from '../../Alerts/AddAlertPopover'
import { AlertsView } from '../../Alerts/AlertsView'
import { defaultAlertIcon } from '../../Alerts/fields'
import { Editable } from '../../forms/Input'
const AlertIcon = ({ icon }: { icon?: string | null }) => (
  <Center>
    {icon ? (
      <Box fontSize="md">{reactionIcons[icon as keyof typeof reactionIcons]}</Box>
    ) : (
      defaultAlertIcon
    )}
  </Center>
)

const MiscarriedBadge = () => (
  <Text
    lineHeight={1.4}
    px={1}
    mx={1}
    borderRadius={3}
    fontWeight={600}
    fontSize="sm"
    bg="red.500"
    color="white">
    MISCARRIED
  </Text>
)

const MiscarriedContent = () => {
  const {
    selectedAssessment: { populated: selectedAssessment },
    assessmentId,
  } = useContext(ProfileContext)
  const { miscarried, miscarriedUpdatedBy, miscarriedUpdatedByGroup, miscarriedUpdatedOn } =
    selectedAssessment ?? {}
  return (
    <VStack w="100%" spacing={0}>
      <Editable
        onSubmit={async v => {
          if (!assessmentId) return { error: 'No pregnancy ID' }
          return updateAssessmentMiscarriedStatus({
            assessmentId,
            miscarried: v,
          })
            .then(() => ({ success: 'Updated miscarried status' }))
            .catch((e: any) => ({
              error: e.message || 'Error updating miscarried status',
            }))
        }}
        value={miscarried}
        field={{
          type: FieldTypes.CHECKBOX,
          placeholder: 'Patient has miscarried',
        }}
      />
      {miscarriedUpdatedBy ? (
        <ActionLog
          action="Updated"
          on={miscarriedUpdatedOn}
          by={miscarriedUpdatedBy}
          group={miscarriedUpdatedByGroup}
        />
      ) : null}
    </VStack>
  )
}

const MiscarriedPopover = () => (
  <Popover closeOnBlur={false} strategy="fixed" isLazy placement="bottom">
    <PopoverTrigger>
      <HStack>
        <Button variant="unstyled">
          <MiscarriedBadge />
        </Button>
      </HStack>
    </PopoverTrigger>
    <Portal>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody borderRadius={6} overflow="hidden" p={0}>
          <HStack bg="red.600" borderBottom="1px solid #cdcdcd" px={2} py={1}>
            <Text color="red.100" fontWeight={600}>
              MISCARRIED
            </Text>
            <PopoverCloseButton color="gray.50" />
          </HStack>
          <Stack bg="gray.50" spacing={0}>
            <VStack spacing={1} p={2}>
              <Text fontSize="xs" color="gray.500" fontWeight="bold">
                MISCARRIED STATUS
              </Text>
              <MiscarriedContent />
            </VStack>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Portal>
  </Popover>
)

const PatientAlertPopover = ({ access }: { access: 'practice' | 'admin' }) => {
  const {
    user,
    adminPatientRef,
    selectedAssessment: {
      adminRef: adminAssessmentRef,
      practiceRef: practiceAssessmentRef,
      populated: selectedAssessment,
    },
    practicePatientRef,
  } = useContext(ProfileContext)
  const { adminAlerts, practiceAlerts } = selectedAssessment ?? {}
  const assessmentAlertsRef = useMemo(
    () => (access === 'admin' ? adminAssessmentRef : practiceAssessmentRef),
    [access, adminAssessmentRef, practiceAssessmentRef],
  )
  const patientAlertsRef = useMemo(
    () => (access === 'admin' ? adminPatientRef : practicePatientRef),
    [access, adminPatientRef, practicePatientRef],
  )
  const { adminAlerts: adminPatientAlerts, practiceAlerts: practicePatientAlerts } = user ?? {}
  const patientAlerts = useMemo(
    () => (access === 'admin' ? adminPatientAlerts : practicePatientAlerts),
    [access, adminPatientAlerts, practicePatientAlerts],
  )

  const assessmentAlerts = useMemo(
    () => (access === 'admin' ? adminAlerts : practiceAlerts),
    [access, adminAlerts, practiceAlerts],
  )

  const update = useUpdateDoc('alert')
  const { appName } = useApp()
  const allAlerts = useMemo<WithId<Partial<Alert>>[]>(() => {
    const all = {
      ...patientAlerts,
      ...assessmentAlerts,
    }
    return Object.values(all).reduce<WithId<Partial<Alert>>[]>((acc, curr, idx) => {
      if (typeof curr === 'string') {
        return [...acc, { text: curr, id: `text-alert-${idx}` }]
      }
      return [...acc, { ...curr, id: `alert-${idx}` }]
    }, [])
  }, [patientAlerts, assessmentAlerts])

  return (
    <Popover closeOnBlur={false} strategy="fixed" trigger="hover" isLazy placement="bottom">
      <Flex cursor="pointer" align="center">
        <PopoverTrigger>
          <VStack spacing={0}>
            <Text
              fontFamily="Comfortaa"
              fontSize="xs"
              fontWeight={600}
              lineHeight={1.1}
              color="gray.600">
              {access === 'admin' ? 'ADMIN' : 'PRACTICE'} ALERTS
            </Text>
            <HStack
              // w='100%'
              py={1}
              px="0.4rem"
              borderRadius={4}
              maxW="140px"
              justify="center"
              bg="whiteAlpha.800"
              overflow="hidden"
              minW="120px"
              spacing={2}>
              <HStack divider={<StackDivider />} w="100%" spacing={1}>
                {allAlerts.length > 0 ? (
                  allAlerts.map(a => (
                    <HStack key={a.id} spacing={1}>
                      <AlertIcon icon={a.customIcon} />
                      <Text fontSize="sm" color="gray.600" fontWeight={600} whiteSpace="nowrap">
                        {a.text}
                      </Text>
                    </HStack>
                  ))
                ) : (
                  <Text fontSize="xs" color="gray.500" fontWeight={600}>
                    No alerts
                  </Text>
                )}
              </HStack>
            </HStack>
          </VStack>
        </PopoverTrigger>
      </Flex>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody borderRadius={6} overflow="hidden" p={0}>
            <HStack bg="red.600" px={2} py={1}>
              <Text color="red.100" fontWeight={600}>
                {access === 'admin' ? 'ADMIN' : 'PRACTICE'} ALERTS
              </Text>
              <PopoverCloseButton color="gray.50" />
            </HStack>
            <Stack bg="gray.50" spacing={0}>
              <Box borderTop="1px solid #cdcdcd" py={2} px={3}>
                <Text fontSize="xs" color="gray.500" fontWeight="bold">
                  PREGNANCY ALERTS
                </Text>
                <AlertsView
                  type="assessment"
                  alerts={assessmentAlerts}
                  accessLevel={access}
                  onDelete={key => update(assessmentAlertsRef, `alerts.${key}`, deleteField())}
                  onSubmit={(key, data) =>
                    update(assessmentAlertsRef, `alerts.${key}`, addMetadata(data, appName, false))
                  }
                />
              </Box>
              <Box borderTop="1px solid #cdcdcd" py={2} px={3}>
                <Text fontSize="xs" color="gray.500" fontWeight="bold">
                  PATIENT ALERTS
                </Text>
                <AlertsView
                  type="patient"
                  accessLevel={access}
                  alerts={patientAlerts}
                  onDelete={key => update(patientAlertsRef, `alerts.${key}`, deleteField())}
                  onSubmit={(key, data) => update(patientAlertsRef, `alerts.${key}`, data)}
                />
              </Box>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
export const ProfileHeaderAlerts = () => {
  const { appName } = useApp()
  const {
    selectedAssessment: { populated: selectedAssessment },
    user,
  } = useContext(ProfileContext)

  const { adminAlerts: adminUserAlerts, practiceAlerts: practiceUserAlerts } = user ?? {}
  const { adminAlerts, practiceAlerts, miscarried } = selectedAssessment ?? {}

  const { isMobile } = useContext(ScreenContext)

  const hasNoAdminAlerts = useMemo(
    () => !(Object.keys(adminAlerts ?? {}).length + Object.keys(adminUserAlerts ?? {}).length),
    [adminAlerts, adminUserAlerts],
  )
  const hasNoPracticeAlerts = useMemo(
    () =>
      !(Object.keys(practiceAlerts ?? {}).length + Object.keys(practiceUserAlerts ?? {}).length),
    [practiceAlerts, practiceUserAlerts],
  )
  const hasNoAlerts = useMemo(
    () => hasNoAdminAlerts && hasNoPracticeAlerts,
    [hasNoAdminAlerts, hasNoPracticeAlerts],
  )
  let body = <AddAlertPopover />
  if (appName === 'providers-app') {
    if (hasNoPracticeAlerts) {
      body = <AddAlertPopover accessLevel="practice" />
    } else {
      body = <PatientAlertPopover access="practice" />
    }
  } else if (!hasNoAlerts) {
    body = (
      <>
        {hasNoAdminAlerts ? (
          <AddAlertPopover accessLevel="admin" />
        ) : (
          <PatientAlertPopover access="admin" />
        )}
        {hasNoPracticeAlerts ? <AddAlertPopover /> : <PatientAlertPopover access="practice" />}
      </>
    )
  }

  return (
    <HStack py={1} justify="center" w={isMobile ? '100%' : 'auto'} spacing={2}>
      {miscarried ? <MiscarriedPopover /> : null}
      {body}
    </HStack>
  )
}

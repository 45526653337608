import {
  ASSESSMENT_SNIPPETS,
  AssessmentInterface,
  AssessmentSnippet,
  AssessmentStage,
  BaseQuery,
  CollectionFilter,
  InsuranceProvider,
  LocalCollectionState,
  PracticeWithAdmin,
  SortKey,
  getCoverageNextActionsCount,
} from '@hb/shared'
import { DataListTab } from '../../../types'
import { toAdminAssessmentSearchQuery } from '../../../utils/search/assessments'
import { assessmentColumns } from '../../DataList'
import { getAssessmentBackgroundColor, getPresetAssessmentFilters, nextActionSort } from './common'
import { AssessmentSearchTip } from './search'

const getAdminAssessmentFilters = (stage: AssessmentStage | null, archived: boolean) => {
  const filters: CollectionFilter<AssessmentInterface>[] = []
  if (stage) {
    filters.push([stageFilterProps[stage] as SortKey<AssessmentInterface>, '==', true])
  }
  filters.push(['archived', '==', archived])
  return filters
}

const getAssessmentSnippetBaseQuery = (
  stage: AssessmentStage | null,
  archived: boolean,
): BaseQuery<AssessmentInterface> => ({
  collection: ASSESSMENT_SNIPPETS,
  filters: getAdminAssessmentFilters(stage, archived),
})

const getAdminRowHeight = (data: AssessmentInterface) => {
  const baseHeight = data.assessmentName && !data.assessmentName.startsWith('EDD:') ? 45 : 40
  if (data.isGynProfile) return baseHeight
  return Math.max(baseHeight, getCoverageNextActionsCount(data as AssessmentSnippet) * 40)
}

const stageFilterProps: Record<AssessmentStage, keyof AssessmentSnippet> = {
  Inquiry: 'onInquiryList',
  Authorization: 'onAuthList',
  'Send Claim': 'onSendClaimList',
  Claims: 'onClaimsList',
  Complete: 'onCompleteList',
}

export const getAdminAssessmentTab = (
  // baseTab: DataListTab<AssessmentInterface>,
  stage: AssessmentStage | null,
  archived: boolean,
  insurers: LocalCollectionState<InsuranceProvider>,
  providers: LocalCollectionState<PracticeWithAdmin>,
  // superAdmin?: boolean,
): DataListTab<AssessmentInterface> => ({
  // ...baseTab,
  baseQuery: getAssessmentSnippetBaseQuery(stage, archived),
  itemName: 'Pregnancy',
  searchStringPath: 'stringified',
  toSearchQuery: toAdminAssessmentSearchQuery,
  searchTip: AssessmentSearchTip,
  // itemHeight: midwifeId ? 30 : 36,
  // secondarySort: secondaryNextActionSort,
  // collection: midwifeId ? getPracticeAssessmentCollectionPath(midwifeId) : baseTab.collection,
  defaultSort: nextActionSort,
  itemHeight: getAdminRowHeight,
  presetFilters: getPresetAssessmentFilters(insurers, providers),
  getItemBackgroundColor: getAssessmentBackgroundColor,
  columns: assessmentColumns,
})

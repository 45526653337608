import { StackProps, Text, VStack } from '@chakra-ui/react'

import React from 'react'

export const ClaimInfo = ({
  header,
  children,
  ...props
}: { header: string; children: React.ReactNode } & Omit<StackProps, 'children'>) => (
  <VStack bg="white" boxShadow="md" flex={1} p={1} pr={3} align="flex-start" spacing={0} {...props}>
    <Text isTruncated color="gray.500" fontSize="xs" fontWeight={600}>
      {header}
    </Text>
    {children}
  </VStack>
)

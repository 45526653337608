import { type WithMetadata } from '../../types'
import { type ClaimSnippet } from '../../types/claims/claim'
import { PaymentDue, PaymentReceived, PaymentRecord } from '../../types/payments'
import { Note } from '../../types/utils/common'
import { InvoicingSettingsFees } from './invoicingSettings'
import { BaseDBItem, RemoveBase } from './shared'

export type InvoiceStatus = 'draft' | 'scheduled' | 'pending-approval' | 'sent' | 'paid'

export interface InvoiceLineItem extends BaseDBItem {
  // starts 1001
  description: string
  amount: number
  createdAt: string
  updatedAt: string
  createdBy: string
  updatedBy: string
}

export interface BaseInvoicePaymentInstallment {
  isRefund?: boolean
  amount: number
  paidOn: string
  pendingAdminConfirmation?: boolean
  type: string
}

export type InvoicePaymentInstallment = WithMetadata<BaseInvoicePaymentInstallment>

export interface Invoice extends BaseDBItem {
  invoiceNumber: number | null
  status: InvoiceStatus
  lineItems: Array<InvoiceLineItem>
  paidOn: string | null
  markedPaidBy: string | null
  paidAmount: number | null
  pendingPaidAmount: number | null
  paymentType: string | null
  paymentNotes: string | null
  fees: InvoicingSettingsFees
  dueDate: string
  amount: number
  sentOn: string | null
  sentBy: string | null
  searchString: string
  installments?: Record<string, InvoicePaymentInstallment>
  scheduledFor: string | null
  forceUpdatedOn?: number
  archivedOn: number | null
  deletedOn?: number
}

export interface InvoiceAssessmentClaimsSnippet {
  patientId: string
  assessmentId: string
  assessmentName: string
  receivedAmount: number
  dueAmount: number
  claims: Record<string, ClaimSnippet>
  numClaims: number
  balanceTowardsPrm: number
  numPendingClaims: number
  numErrorClaims: number
  numPaidClaims: number
  practiceFee: number
  numDeniedClaims: number
  fname: string
  lname: string
  nickname?: string
  deliveredOn?: number | null
}

export type ChargedPaymentReceived = PaymentReceived & {
  invoicedAmount: number
  previousFeeAmount?: number
  usedFeePercent?: number
  feeAmount: number
}

export interface InvoiceAssessmentPaymentsSnippet {
  patientId: string
  assessmentId: string
  assessmentName: string
  dueAmount: number
  receivedAmount: number
  chargedAmount: number
  practiceFee: number
  duePayments: PaymentRecord<PaymentDue>
  listedFeePercent: number
  receivedPayments: PaymentRecord<PaymentReceived>
  chargedPayments: PaymentRecord<ChargedPaymentReceived>
  manuallyAddedBy: string | null
  paymentIds: Array<string>
  manualPaymentIds: Array<string>
  balanceTowardsPrm: number
  numDuePayments: number
  numReceivedPayments: number
  fname: string
  lname: string
  nickname?: string
  deliveredOn: number | null
}

export const isInvoiceAssessmentPaymentsSnippet = (
  snippet: InvoiceAssessmentPaymentsSnippet | InvoiceAssessmentClaimsSnippet,
): snippet is InvoiceAssessmentPaymentsSnippet =>
  (snippet as InvoiceAssessmentPaymentsSnippet).receivedPayments !== undefined

// key is [assessmentId-patientId]
export interface PracticeInvoiceOmission {
  omittedBy: string
  omittedAt: string
  reason: string
}

export interface PracticeInvoiceLineItem {
  description: string
  receivedAmount?: number
  amount: number
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

export interface PracticeInvoiceAdditionalPatient {
  fname: string
  lname: string
  deliveredOn: number | null
  edd: number | null
  nickname?: string
  lineItems: Record<string, PracticeInvoiceLineItem>
}

export const lineItemGroupIsAdditionalPatient = (
  lineItemGroup: PracticeInvoiceAdditionalPatient | InvoiceAssessmentPaymentsSnippet,
): lineItemGroup is PracticeInvoiceAdditionalPatient =>
  (lineItemGroup as PracticeInvoiceAdditionalPatient).lineItems !== undefined

export type AssessmentID = string
export type PaymentID = string
export interface CustomFeePercent {
  percent: number
  updatedAt: string
  updatedBy: string
  fromInvoice?: string
}

export interface EmailNotification {
  sentOn: number
  sentToId: string
  sentToEmail: string
  sentBy: string
}
export type AssessmentCustomFeePercents = Record<PaymentID, CustomFeePercent>
export interface PracticeInvoice extends Invoice {
  assessments: Record<string, InvoiceAssessmentPaymentsSnippet>
  assessmentIds: Array<string>
  omissions: Record<string, PracticeInvoiceOmission>
  additionalPatients?: Record<string, PracticeInvoiceAdditionalPatient>
  additionalLineItems?: Record<AssessmentID, Record<string, PracticeInvoiceLineItem>>
  customFeePercents?: Record<AssessmentID, AssessmentCustomFeePercents>
  numPendingAssessments: number
  numErrorAssessments: number
  numPaidAssessments: number
  emailNotifications: Array<EmailNotification>
  practiceName: string
  numDeniedAssessments: number
  practiceId: string
  clearingHouseFee: number
  scheduledFor: string | null
  adminNote?: Note
}

export type UpdatedInvoiceInterface = Omit<
  PracticeInvoice,
  | 'assessments'
  | 'assessmentIds'
  | 'lineItems'
  | 'amount'
  | 'clearingHouseFee'
  | 'numPendingAssessments'
  | 'numPaidAssessments'
  | 'numErrorAssessments'
  | 'numDeniedAssessments'
>

export interface SentPracticeInvoice extends Invoice {
  invoiceNumber: number
  status: 'sent' | 'paid'
  sentOn: string
}

export type InvoiceSortKey =
  | 'invoiceNumber'
  | 'status'
  | 'dueDate'
  | 'amount'
  | 'amountPaid'
  | 'practiceName'
  | 'scheduledFor'
  | 'paymentType'

export type InvoiceLineItemArgs = RemoveBase<InvoiceLineItem>

export type CreateInvoiceArgs = RemoveBase<Invoice> &
  Omit<Invoice, 'modifications' | 'status' | 'lineItems'> & {
    lineItems: Array<InvoiceLineItemArgs>
  }

export interface AddLineItemToAssessmentInvoiceArgs {
  invoiceId: string
  assessmentId: string | null
  additionalPatientId: string | null
  amount: number
  receivedAmount?: number
  description: string
  lineItemId: string | null
}

export interface RemoveLineItemFromAssessmentInvoiceArgs {
  invoiceId: string
  assessmentId: string | null
  additionalPatientId: string | null
  lineItemId: string
}

export interface AddAdditionalPatientToInvoiceArgs {
  invoiceId: string
  additionalPatientId: string | null
  fname: string | null
  lname: string | null
  nickname?: string | null
  edd: number | null
  deliveredOn: number | null
}

export interface ManuallyCreateInvoiceArgs {
  invoiceId: string
}

export interface RemoveAdditionalPatientFromInvoiceArgs {
  invoiceId: string
  additionalPatientId: string
}

export interface SetCustomFeePercentageArgs {
  assessmentId: string
  paymentId: string
  invoiceId: string
  customFeePercent: number | null
}

export interface MovePaymentToInvoiceArgs {
  assessmentId: string
  paymentId: string
  toInvoiceId: string
  fromInvoiceId: string
}

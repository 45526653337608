import { Flex } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

export const SettingsContainer = ({ children }: PropsWithChildren) => (
  <Flex
    bg="white"
    boxShadow="1px 1px 3px #00000077"
    borderRadius={4}
    userSelect="none"
    py={2}
    px={3}
    gap={1}
    flexFlow="column">
    {children}
  </Flex>
)

import { Divider, Flex, Text, VStack } from '@chakra-ui/react'
import React from 'react'

const SearchTipSection = ({
  title,
  examples,
  prefix,
}: {
  title: string
  examples: string[]
  prefix?: string
}) => (
  <Flex w="100%" py={0.5} align="center" gap={1.5}>
    {prefix ? (
      <Text
        bg="blue.100"
        color="blue.600"
        fontWeight={600}
        fontSize="sm"
        px={1}
        boxShadow="1px 1px 3px rgba(0, 0, 0, 0.3)"
        borderRadius={3}>
        {prefix}:
      </Text>
    ) : null}
    <Text>{title}</Text>
    <Text ml="auto" fontSize="sm" color="gray.600">
      {examples.join(', ')}
    </Text>
  </Flex>
)

export const AssessmentSearchTip = () => (
  <VStack align="flex-start" spacing={0} divider={<Divider />} flexFlow="column" w="100%">
    <Flex w="100%" justify="space-between" align="center">
      <Text py={0.5} fontSize="sm" color="gray.600" fontWeight={600}>
        Search for:
      </Text>
      <Text py={0.5} fontSize="sm" color="gray.600" fontWeight={600}>
        Examples
      </Text>
    </Flex>
    <SearchTipSection
      title="Patient Name"
      examples={['Katie', 'Baker', 'Katie Baker', 'Baker Katie']}
    />
    <SearchTipSection title="DOB" examples={['MM/DD/YYYY']} />
    <SearchTipSection
      title="Phone Number"
      examples={['1234567890', '123-456-7890', '(123) 456-7890']}
    />
    <SearchTipSection title="Email" examples={['info@hamiltonbilling.com']} />
    <SearchTipSection title="Auth Ref #" prefix="auth" examples={['auth:12345678']} />
    <SearchTipSection title="Claim Ref #" prefix="claim" examples={['claim:12345678']} />
    <SearchTipSection title="Check/EFT Ref #" prefix="$" examples={['$:12345678']} />
    <SearchTipSection title="Next Actions" prefix="na" examples={['na:01/01/2025']} />
    <SearchTipSection
      title="Deep search (legacy)"
      prefix="legacy"
      examples={['legacy:katie baker']}
    />
  </VStack>
)

import { createContext } from 'react'
import { useBugReports } from './hooks'

export const BugReportsContext = createContext<ReturnType<typeof useBugReports>>({
  bugReportsQuery: null,
  filters: [],
  sort: {
    field: 'createdOn',
    order: 'asc',
  },
  deselectBugReport: () => {},
  selectBugReport: () => {},
  selectedBugReportId: undefined,
  bugReports: {
    data: null,
    loading: false,
    error: null,
  },
  toggleFilter: () => {},
  setSort: () => {},
})

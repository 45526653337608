import {
  capitalizeFirstLetterOfEachWord,
  CollectionItem,
  DropdownField,
  DropdownOption,
  FieldTypes,
  insurersCollection,
  Template,
  templateCollections,
  TemplateKey,
  templateTypeNames,
  WithId,
} from '@hb/shared'
import React, { useCallback, useMemo } from 'react'
import { useCollection } from '../../collections/hooks/useCollection'
import { useCollectionItem } from '../../hooks/backend/useCollectionItem'
import { StandaloneInput } from '../forms/Input'

type TemplateCollectionItems = Array<CollectionItem<Template>>
export const TemplateMenu: React.FC<{
  onChange: (template: WithId<Template>) => void
  templateType: TemplateKey
  insuranceProviderId?: string
  template?: WithId<Template> | null
}> = ({ insuranceProviderId, onChange, template, templateType }) => {
  const { items: templates } = useCollection(templateCollections[templateType])
  const { item: insuranceProvider } = useCollectionItem(insurersCollection, insuranceProviderId)
  const { insuranceProviderTemplates, otherTemplates } = useMemo(() => {
    if (!templates) {
      return {
        otherTemplates: [] as TemplateCollectionItems,
        insuranceProviderTemplates: [] as TemplateCollectionItems,
      }
    }
    if (!insuranceProvider) {
      return {
        otherTemplates: templates,
        insuranceProviderTemplates: [] as TemplateCollectionItems,
      }
    }
    return templates.reduce(
      (acc, curr) => {
        switch (curr.id) {
          case insuranceProvider.defaultTemplateId:
          case insuranceProvider.authTemplateId:
          case insuranceProvider.claimTemplateId:
          case insuranceProvider.fullyInsuredTemplateId:
          case insuranceProvider.selfFundedTemplateId:
            acc.insuranceProviderTemplates.push(curr)
            break
          default:
            acc.otherTemplates.push(curr)
        }
        return acc
      },
      {
        insuranceProviderTemplates: [] as CollectionItem<Template>[],
        otherTemplates: [] as CollectionItem<Template>[],
      },
    )
  }, [templates, insuranceProvider])

  const templateOptions = useMemo(() => {
    const options: DropdownOption[] = []
    if (insuranceProviderTemplates.length) {
      options.push({
        title: `${insuranceProvider?.name} Templates`,
        options: insuranceProviderTemplates.map(t => ({
          id: t.id,
          text: t.name,
        })),
      })
    }

    if (otherTemplates.length) {
      if (insuranceProviderTemplates.length) {
        options.push({
          title: 'Additional Templates',
          options: otherTemplates.map(t => ({
            id: t.id,
            text: t.name,
          })),
        })
      } else {
        options.push(
          ...otherTemplates.map(t => ({
            id: t.id,
            text: t.name,
          })),
        )
      }
    }

    return options
  }, [insuranceProvider, insuranceProviderTemplates, otherTemplates])

  const field = useMemo<DropdownField>(
    () => ({
      type: FieldTypes.DROPDOWN,
      placeholder: `${capitalizeFirstLetterOfEachWord(templateTypeNames[templateType])} Template`,
      searchable: true,
      options: templateOptions,
      optional: true,
    }),
    [templateOptions, templateType],
  )

  const onSelect = useCallback(
    (selected: WithId<Template>) => {
      if (selected.templateText?.length || selected.isExternalPdf) {
        onChange(selected)
      }
    },
    [onChange],
  )

  return (
    <StandaloneInput
      style={{
        boxShadow: '0 0 2px #00000077',
        borderRadius: '4px',
        paddingRight: '0.5rem',
      }}
      field={field}
      value={template?.id}
      onChange={async v => {
        const updated =
          insuranceProviderTemplates?.find(o => o.id === v) ?? otherTemplates?.find(o => o.id === v)
        if (updated) {
          onSelect(updated)
          return { success: 'success' }
        }
        return { error: `Couldn't find template with id: ${v}` }
      }}
    />
  )
}

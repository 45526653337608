// export const adminMidwifeField: FieldMap = {
//   name: 'Protected Midwife Info',
//   children: {
//     taxId: createAlternateField({
//       placeholder: 'Tax ID',
//       fieldType: FieldTypes.TEXT,
//     }),
//     npi: createAlternateField({
//       placeholder: 'NPI',
//       fieldType: FieldTypes.TEXT,
//     }),
//     address,
//     servicingAddress: { ...address, placeholder: 'Servicing Address' },
//     affiliatedHospitals: affiliatedHospitalsField,
//   },
// }

import { InsuranceProvider } from '../../types'
import { patientCollectionAccess } from '../constants'
import { INSURANCE_PROVIDERS } from '../names'
import { DerivedCollection, FirestoreCollection } from '../types'

export const insurersCollection: FirestoreCollection<InsuranceProvider> = {
  id: 'insurers',
  _type: 'firestoreCollection',
  name: 'Insurance Providers',
  refPath: INSURANCE_PROVIDERS,
  access: patientCollectionAccess,
  defaultSort: 'name',
}

export const medicaidInsurersCollection: DerivedCollection<InsuranceProvider> = {
  id: 'medicaidInsurers',
  _type: 'derivedCollection',
  name: 'Medicaid Insurers',
  access: patientCollectionAccess,
  transform: data => data.filter(i => !!i.hasMedicaidOption),
  baseCollection: insurersCollection,
}

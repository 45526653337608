import { AssessmentForm } from '../../types'
import { loqRequiredOptions } from './constants'
import { deliveryStage } from './delivery'
import { insuranceInfoStage } from './insuranceInfo'

export const introduction: AssessmentForm = {
  name: 'Introduction',
  description: 'Tell us a little bit about your pregnancy',
  stages: {
    delivery: deliveryStage,
    insuranceInfo: insuranceInfoStage,
  },
  getNextFormId: (admin, assessment) => {
    const { data, corrections } = assessment ?? {}
    const patientInsuranceInfo = {
      data: data?.['insurance-info'],
      corrections: corrections?.['insurance-info'],
    }
    const { primaryCoverage } = data?.['insurance-info']?.patientInsuranceInfo ?? {}
    const { insuranceProvider } = primaryCoverage ?? {}
    const optionCorrection = patientInsuranceInfo?.corrections?.option
    const optionData = patientInsuranceInfo?.data?.option
    const option = admin ? optionCorrection || optionData : optionData
    const coverageWillChangeData = patientInsuranceInfo?.data?.coverageWillChangeDescription
    const coverageWillChangeCorrection =
      patientInsuranceInfo?.corrections?.coverageWillChangeDescription
    const coverageWillChangeOption = admin
      ? coverageWillChangeCorrection || coverageWillChangeData
      : coverageWillChangeData

    const noCoverageData = patientInsuranceInfo?.data?.noCoverageDescription
    const noCoverageCorrection = patientInsuranceInfo?.corrections?.noCoverageDescription
    const noCoverageOption = admin ? noCoverageCorrection || noCoverageData : noCoverageData
    if (option && loqRequiredOptions.includes(option)) {
      if (insuranceProvider?.loqNotRequired) return null
      return 'call-in'
    }
    if (option === 'medicaid') {
      return null
    }
    if (option === 'coverage-will-change') {
      if (coverageWillChangeOption === 'employment-coverage-will-change') {
        return 'call-in'
      }
      if (coverageWillChangeOption === 'employment-coverage-will-change-unknown') {
        return 'schedule-call'
      }
      if (coverageWillChangeOption === 'coverage-will-drop') {
        return 'plan-options'
      }
      if (coverageWillChangeOption === 'added-to-another-plan') {
        return 'call-in'
      }
    }
    if (option === 'multiple-options') {
      return null
    }
    if (option === 'not-pregnant') {
      return 'plan-options'
    }
    if (option === 'no-coverage') {
      if (noCoverageOption === 'out-of-pocket') {
        return null
      }
      return 'plan-options'
    }
    return null
  },
}

import { AnyObject, DropdownField, FieldTypes, IdField } from '@hb/shared'
import React, { forwardRef, useMemo } from 'react'
import { useCollection } from '../../../../collections/hooks/useCollection'
import { useAuth } from '../../../../store'
import { InputElement, InputProps } from '../../../../types/fields'
import { DropdownInput } from '../Select/DropdownInput'

const DatabaseDropdown: InputElement<IdField<AnyObject>> = forwardRef<
  { focus: () => void },
  InputProps<IdField<AnyObject>>
>((props, ref) => {
  const { input, field, meta, style } = props
  const { collection, getItemText, withNoneOption } = field

  const { items } = useCollection(collection)

  const isAdmin = useAuth(s => s.admin)

  const sorted = useMemo(() => {
    const options =
      items
        ?.filter(i => isAdmin || !i.isInactive)
        .map(i => ({
          text: `${getItemText ? getItemText(i) : i.name}${i.isInactive ? ' (Inactive)' : ''}`,
          id: i.id,
        })) ?? []

    if (withNoneOption) options.push({ text: 'None', id: 'NULL' })
    return options.sort((a, b) => (b.text < a.text ? 1 : -1))
  }, [items, getItemText, isAdmin, withNoneOption])

  const dropdownField = useMemo<DropdownField>(() => {
    const renderOption = field.renderOption
    return {
      ...field,
      type: FieldTypes.DROPDOWN,
      options: sorted,
      searchable: true,
      renderOption: renderOption
        ? option => {
            const item = items.find(i => i.id === option.id)
            return item ? renderOption(item) : option.text
          }
        : undefined,
    }
  }, [field, sorted, items])
  return <DropdownInput style={style} ref={ref} meta={meta} field={dropdownField} input={input} />
})

export default DatabaseDropdown

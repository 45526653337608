import React, {
  createContext,
  createRef,
  FC,
  PropsWithChildren,
  RefObject,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'

export interface SearchBarContextData {
  query: string
  focused: boolean
  updateQuery: (q: string) => void
  searchQuery: string
  setFocusedState: (focused: boolean) => void
  inputRef: RefObject<HTMLInputElement>
}
export const SearchBarContext = createContext<SearchBarContextData>({
  query: '',
  updateQuery: () => {},
  setFocusedState: () => {},
  focused: false,
  searchQuery: '',
  inputRef: createRef(),
})
export const useSearchBarState = (
  transformQuery?: (query: string) => string,
): SearchBarContextData => {
  const [focused, setFocused] = useState(false)
  const [query, setQuery] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const searchQuery = useMemo(
    () => (transformQuery ? transformQuery(query) : query),
    [query, transformQuery],
  )
  return {
    query,
    focused,
    inputRef,
    setFocusedState: setFocused,
    searchQuery,
    updateQuery: s => setQuery(s),
  }
}

export const SearchBarProvider: FC<
  PropsWithChildren<{ transformQuery?: (query: string) => string }>
> = ({ children, transformQuery }) => (
  <SearchBarContext.Provider value={useSearchBarState(transformQuery)}>
    {children}
  </SearchBarContext.Provider>
)

export const useSearch = () => useContext(SearchBarContext)

import { Button, Flex, Text } from '@chakra-ui/react'
import {
  Assessment,
  ASSESSMENTS,
  basePriorCSectionDateField,
  eddField,
  getCorrectedValue,
  hasPriorCSectionField,
  patientLmpField,
  seenAnotherProviderField,
} from '@hb/shared'
import { doc, DocumentReference } from 'firebase/firestore'
import React, { useMemo } from 'react'
import { db } from '../../../../backend'
import { useProfile } from '../../../../contexts/ProfileContext'
import { useUpdateDoc } from '../../../../hooks/backend/useUpdateDoc'
import { EditableRow } from '../../../forms/Input'

export const PatientPregnancySummary = () => {
  const {
    profileVersion,
    mostRecentPregnancy,
    goTo: onTabSelect,
    admin,
    selectedAssessment: { populated: selectedAssessment, id: selectedPregnancyId },
  } = useProfile()

  const displayedAssessment = useMemo(
    () => selectedAssessment ?? mostRecentPregnancy,
    [selectedAssessment, mostRecentPregnancy],
  )
  const pregnancyId = useMemo(
    () => selectedPregnancyId ?? mostRecentPregnancy?.id,
    [selectedPregnancyId, mostRecentPregnancy],
  )
  const ref = useMemo(() => {
    if (!pregnancyId) return null
    return doc(db, `${ASSESSMENTS}/${pregnancyId}`) as DocumentReference<Assessment>
  }, [pregnancyId])
  const { data, corrections } = displayedAssessment ?? {}

  const hasPriorCSection = useMemo(
    () => getCorrectedValue(displayedAssessment, 'delivery.hasPriorCSection'),
    [displayedAssessment],
  )

  const update = useUpdateDoc('pregnancy')

  if (!displayedAssessment) return null
  return (
    <Flex py={1} flexFlow="column" w="100%">
      {profileVersion === 'v2' ? (
        <Button
          px={3}
          justifyContent="flex-start"
          fontFamily="Hero-New"
          fontWeight={500}
          color="gray.600"
          onClick={() => onTabSelect({ entity: { id: pregnancyId, type: 'pregnancy' }, tab: null })}
          variant="link">
          Pregnancy
        </Button>
      ) : (
        <Text fontFamily="Hero-New" fontWeight={500} px={3}>
          Pregnancy
        </Text>
      )}
      <EditableRow
        label="EDD"
        small
        field={eddField}
        value={data?.delivery?.edd}
        adminView={!!admin}
        correction={corrections?.delivery?.edd}
        onSubmit={async v => update(ref, 'corrections.delivery.edd', v)}
      />
      <EditableRow
        label="LMP"
        small
        field={patientLmpField}
        value={data?.delivery?.lmp}
        adminView={!!admin}
        correction={corrections?.delivery?.lmp}
        onSubmit={async v => update(ref, 'corrections.delivery.lmp', v)}
      />
      <EditableRow
        label="Seen another provider?"
        small
        field={seenAnotherProviderField}
        value={data?.delivery?.seenAnotherProvider}
        adminView={!!admin}
        correction={corrections?.delivery?.seenAnotherProvider}
        onSubmit={async v => update(ref, 'corrections.delivery.seenAnotherProvider', v)}
      />
      <EditableRow
        label="Prior C-section?"
        small
        field={hasPriorCSectionField}
        value={data?.delivery?.hasPriorCSection}
        adminView={!!admin}
        correction={corrections?.delivery?.hasPriorCSection}
        onSubmit={async v => update(ref, 'corrections.delivery.hasPriorCSection', v)}
      />
      {hasPriorCSection ? (
        <EditableRow
          label="Prior C-section date"
          small
          field={basePriorCSectionDateField}
          adminView={!!admin}
          value={data?.delivery?.priorCSectionDate}
          correction={corrections?.delivery?.priorCSectionDate}
          onSubmit={async v => update(ref, 'corrections.delivery.priorCSectionDate', v)}
        />
      ) : null}
    </Flex>
  )
}

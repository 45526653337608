import { ChatIcon, CloseIcon } from '@chakra-ui/icons'
import { Flex, IconButton, Text } from '@chakra-ui/react'
import { colors, MessagePushNotificationData, PopUpMessage, UpdateCallback } from '@hb/shared'
import { onMessage } from 'firebase/messaging'
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { messaging } from '../../backend/messaging'
import { ActionButton } from '../../components/Buttons/ActionButton'
import { Container } from '../../components/Container'
import { ThreadsListContext } from '../../components/Messaging/contexts'
import { useTimeout } from '../../hooks/useTimeout'
import { CustomMessagePayload } from '../../types/pushNotifications'
import { PopUpMessageContext } from './PopUpMessageContext'

export function usePopUpMessage() {
  const [message, setMessage] = useTimeout('', 5000)

  const showMessage = useCallback(
    (m: PopUpMessage) => {
      setMessage(m)
    },
    [setMessage],
  )

  const showError = useCallback(
    (text: string, subText?: string) => showMessage({ text, subText, type: 'error' }),
    [showMessage],
  )
  const showInfo = useCallback(
    (text: string, subText?: string) => showMessage({ text, type: 'info', subText }),
    [showMessage],
  )
  const showSuccess = useCallback(
    (text: string, subText?: string) => showMessage({ text, type: 'success', subText }),
    [showMessage],
  )

  const processResponse = useCallback(
    (res: UpdateCallback) => {
      if (res.success) showSuccess(res.success)
      if (res.error) showError(res.error)
      return res
    },
    [showError, showSuccess],
  )

  useEffect(
    () =>
      onMessage(messaging, payload => {
        const { data } = payload as unknown as CustomMessagePayload
        if (data) {
          showMessage({
            type: 'info',
            text: data.title ?? '',
            subText: data?.body,
            notificationData: data,
          })
        }
      }),
    [showMessage],
  )

  const closeMessage = useCallback(() => setMessage(null), [setMessage])

  return {
    message,
    showMessage,
    showSuccess,
    processResponse,
    showInfo,
    showError,
    closeMessage,
  }
}

const MessageNotificationDataView = ({ data }: { data: MessagePushNotificationData }) => {
  const { goToThread, onOpen } = useContext(ThreadsListContext)
  return (
    <ActionButton
      onClick={() => {
        onOpen()
        goToThread({ id: data.threadId, type: data.threadType, title: '' })
      }}
      size="xs"
      fontWeight={500}
      gap={1}>
      <ChatIcon />
      <Text>View Message</Text>
    </ActionButton>
  )
}

const NotificationDataView = ({ data }: { data: PopUpMessage['notificationData'] }) => {
  const { type } = data ?? {}
  if (!data) return null
  switch (type) {
    case 'message':
      return <MessageNotificationDataView data={data} />
    default:
      return null
  }
}

const PopUpMessageView = () => {
  const { message, closeMessage } = useContext(PopUpMessageContext)
  const [prevMessage, setPrevMessage] = useState(message)

  const background = useMemo(() => {
    switch (prevMessage?.type) {
      case 'error':
        return '#dd7755'
      case 'success':
        return colors.green.hex
      default:
        return 'rgb(255,255,255)'
    }
  }, [prevMessage])
  const color = useMemo(() => {
    switch (prevMessage?.type) {
      case 'error':
      case 'success':
        return 'white'
      default:
        return '#333'
    }
  }, [prevMessage])
  useEffect(() => {
    if (message) setPrevMessage(message)
  }, [message])
  return (
    <Flex
      boxShadow="1px 1px 3px #00000077"
      background={background}
      top={5}
      width="auto"
      borderRadius={6}
      maxWidth={['100%', '60%', '40%']}
      transform={`translateY(${message ? 10 : 0}px)`}
      minHeight={0}
      opacity={message ? 1 : 0}
      fontFamily="hero-new"
      padding="0.5rem 1rem"
      paddingRight="0.5rem"
      pointerEvents={message ? 'auto' : 'none'}
      overflow="hidden"
      transition="all 333ms"
      color={color}>
      <Flex maxW="100%" flexFlow="column">
        <Text fontSize="md" fontWeight={500} fontFamily="Open Sans">
          {prevMessage?.text ?? ''}
        </Text>
        {prevMessage?.subText ? (
          <Text
            pb={1}
            whiteSpace="pre-wrap"
            color={color}
            opacity={0.9}
            fontSize="sm"
            fontFamily="Open Sans">
            {prevMessage.subText}
          </Text>
        ) : null}
        <NotificationDataView data={prevMessage?.notificationData} />
      </Flex>
      <IconButton
        ml={2}
        background="blackAlpha.200"
        _hover={{ background: 'blackAlpha.500' }}
        borderRadius="full"
        size="xs"
        aria-label="close"
        onClick={closeMessage}
        variant="ghost"
        icon={<CloseIcon color="white" filter="drop-shadow(1px 1px 3px #00000077)" />}
      />
    </Flex>
  )
}

export const PopUpMessageOverlay: React.FC = () => (
  <Container
    style={{
      position: 'fixed',
      top: 20,
      whiteSpace: 'pre-wrap',
      width: '100vw',
      zIndex: 10,
      height: 'auto',
      display: 'flex',
      justifyContent: 'center',
      pointerEvents: 'none',
    }}>
    <PopUpMessageView />
  </Container>
)

export const PopUpMessageProvider = ({ children }: PropsWithChildren) => {
  const popUpMessage = usePopUpMessage()
  return (
    <PopUpMessageContext.Provider value={popUpMessage}>{children}</PopUpMessageContext.Provider>
  )
}

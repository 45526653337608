import { Center, Text, VStack } from '@chakra-ui/react'
import {
  ASSESSMENTS_ADMIN,
  getBackupPlanNextActionPath,
  getCoverageNextActions,
  getPlanNextActionPath,
  NextAction,
  PatientCoverageId,
} from '@hb/shared'
import { deleteField, doc, writeBatch } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo } from 'react'
import { db } from '../../../../backend'
import { PopUpMessageContext, ProfileContext } from '../../../../contexts'
import { Expandable } from '../../../Expandable'
import { Loading } from '../../../Loading'
import { CoverageNextActionContent } from '../NextAction'

export const PlanNextActions = () => {
  const { assessmentId, patientRef, userId, selectedAssessment } = useContext(ProfileContext)

  const { showError } = useContext(PopUpMessageContext)
  const { plans, nextActions, backupNextActions } = selectedAssessment?.populated ?? {}
  const nextActionsArr = useMemo(
    () => getCoverageNextActions(plans, nextActions, backupNextActions),
    [nextActions, plans, backupNextActions],
  )
  const onSwapBackup = useCallback(
    async (
      coverageId: PatientCoverageId,
      original: NextAction | null | undefined,
      backup: NextAction,
    ) => {
      if (!assessmentId) {
        showError('No assessmentId')
        return
      }
      const batch = writeBatch(db)
      const assessmentRef = doc(db, ASSESSMENTS_ADMIN, assessmentId)
      const nextActionPath = getPlanNextActionPath(coverageId)
      const backupNextActionPath = getBackupPlanNextActionPath(coverageId)
      try {
        batch.update(assessmentRef, nextActionPath, backup ?? deleteField())
        batch.update(assessmentRef, backupNextActionPath, original ?? deleteField())
        await batch.commit()
      } catch (e: any) {
        showError('Error swapping backup next action', e?.message)
      }
    },
    [assessmentId, showError],
  )

  return (
    <Expandable
      borderTop="1px solid #cdcdcd"
      style={{ width: '100%' }}
      initExpanded
      headerProps={{ bg: 'white' }}
      header={() => (
        <Text fontWeight={600} color="#777" w="100%" px={2} py={1}>
          Coverage Next Actions
        </Text>
      )}>
      {assessmentId && userId && patientRef ? (
        <VStack bg="#efefef" spacing={0} w="100%">
          {nextActionsArr.map(({ coverage, id, nextAction, backupNextAction }) => (
            <CoverageNextActionContent
              id={id}
              inList={false}
              coverage={coverage}
              backupNextAction={backupNextAction}
              onSwapBackup={
                backupNextAction ? () => onSwapBackup(id, nextAction, backupNextAction) : undefined
              }
              assessmentId={assessmentId}
              assessment={selectedAssessment.populated}
              key={id}
              nextAction={nextAction}
            />
          ))}
        </VStack>
      ) : (
        <Center>
          <Loading text="Loading insurance plans..." />
        </Center>
      )}
    </Expandable>
  )
}

import { FieldTypes } from '../constants/FieldTypes'
import { FieldMapValue } from '../types'
import {
  AlternateField,
  DropdownField,
  DropdownOptionItem,
  EmailField,
  FieldMap,
  PhoneField,
  TextAreaField,
  TextField,
} from '../types/forms'
import { addressToString } from '../utils/data'

export const stateOptions: DropdownOptionItem[] = [
  { id: 'AL', text: 'Alabama' },
  { id: 'AK', text: 'Alaska' },
  { id: 'AZ', text: 'Arizona' },
  { id: 'AR', text: 'Arkansas' },
  { id: 'CA', text: 'California' },
  { id: 'CO', text: 'Colorado' },
  { id: 'CT', text: 'Connecticut' },
  { id: 'DE', text: 'Delaware' },
  { id: 'DC', text: 'District Of Columbia' },
  { id: 'FL', text: 'Florida' },
  { id: 'GA', text: 'Georgia' },
  { id: 'HI', text: 'Hawaii' },
  { id: 'ID', text: 'Idaho' },
  { id: 'IL', text: 'Illinois' },
  { id: 'IN', text: 'Indiana' },
  { id: 'IA', text: 'Iowa' },
  { id: 'KS', text: 'Kansas' },
  { id: 'KY', text: 'Kentucky' },
  { id: 'LA', text: 'Louisiana' },
  { id: 'ME', text: 'Maine' },
  { id: 'MD', text: 'Maryland' },
  { id: 'MA', text: 'Massachusetts' },
  { id: 'MI', text: 'Michigan' },
  { id: 'MN', text: 'Minnesota' },
  { id: 'MS', text: 'Mississippi' },
  { id: 'MO', text: 'Missouri' },
  { id: 'MT', text: 'Montana' },
  { id: 'NE', text: 'Nebraska' },
  { id: 'NV', text: 'Nevada' },
  { id: 'NH', text: 'New Hampshire' },
  { id: 'NJ', text: 'New Jersey' },
  { id: 'NM', text: 'New Mexico' },
  { id: 'NY', text: 'New York' },
  { id: 'NC', text: 'North Carolina' },
  { id: 'ND', text: 'North Dakota' },
  { id: 'OH', text: 'Ohio' },
  { id: 'OK', text: 'Oklahoma' },
  { id: 'OR', text: 'Oregon' },
  { id: 'PA', text: 'Pennsylvania' },
  { id: 'RI', text: 'Rhode Island' },
  { id: 'SC', text: 'South Carolina' },
  { id: 'SD', text: 'South Dakota' },
  { id: 'TN', text: 'Tennessee' },
  { id: 'TX', text: 'Texas' },
  { id: 'UT', text: 'Utah' },
  { id: 'VT', text: 'Vermont' },
  { id: 'VA', text: 'Virginia' },
  { id: 'WA', text: 'Washington' },
  { id: 'WV', text: 'West Virginia' },
  { id: 'WI', text: 'Wisconsin' },
  { id: 'WY', text: 'Wyoming' },
]
export const stateField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'State',
  searchable: true,
  optional: true,
  format: v => {
    const match = stateOptions.find(o => o.id === v)
    return match?.text || (typeof v === 'string' ? v : '')
  },
  options: stateOptions,
}

export const addressField: FieldMap = {
  name: 'Address',
  toName: addressToString,
  // labels: [{ text: 'Address' }],
  children: {
    streetAddress: {
      placeholder: 'Line 1',
      type: FieldTypes.TEXT,
    },
    streetAddress2: {
      placeholder: 'Line 2',
      type: FieldTypes.TEXT,
      optional: true,
    },
    city: {
      placeholder: 'City',
      type: FieldTypes.TEXT,
    },
    state: stateField,
    zip: {
      placeholder: 'ZIP Code',
      type: FieldTypes.TEXT,
    },
  },
}

const nineDigitZipFormat = (v: any) => {
  if (!v) return v
  if (v.length > 5) return `${v.substring(0, 5)}-${v.substring(5)}`
  return v
}
export const nineDigitZipField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: '9-digit ZIP Code',
  validate: v => {
    if (v && v.length !== 9) return 'Should be 9 digits'
    return undefined
  },
  format: nineDigitZipFormat,
  parse: v => {
    if (v) return v.replace(/-/g, '')
    return v
  },
}
const nineDigitAddressToString = (address: FieldMapValue) => {
  const { streetAddress, streetAddress2, city, state, zip } = address
  const missing = []
  if (!streetAddress) missing.push('street address')
  if (!city) missing.push('city')
  if (!state) missing.push('state')
  if (!zip) missing.push('zip code')
  if (missing.length) return `Missing ${missing.join(', ')}`
  const formattedZip = nineDigitZipFormat(zip)
  return `${streetAddress}${streetAddress2 ? `, ${streetAddress2}` : ''}, ${city}, ${state} ${formattedZip}`
}
export const nineDigitZipAddress: FieldMap = {
  ...addressField,
  toName: nineDigitAddressToString,
  children: {
    ...addressField.children,
    zip: nineDigitZipField,
  },
}

export const faxField: PhoneField = {
  type: FieldTypes.PHONE,
  placeholder: 'Fax',
  optional: true,
}

export const alternateFaxField: AlternateField = {
  type: FieldTypes.ALTERNATE,
  placeholder: 'Fax',
  optional: true,
  fieldType: FieldTypes.PHONE,
}

export const fnameField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'First Name',
}
export const lnameField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Last Name',
}

export const emailField: EmailField = {
  type: FieldTypes.EMAIL,
  placeholder: 'Email',
}

export const alternateEmailField: AlternateField = {
  type: FieldTypes.ALTERNATE,
  placeholder: 'Email',
  fieldType: FieldTypes.EMAIL,
}
export const notesField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'Notes...',
  optional: true,
}

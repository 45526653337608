import {
  BaseQuery,
  Claim,
  CLAIMS,
  CollectionFilter,
  InsuranceProvider,
  LocalCollectionState,
  PracticeWithAdmin,
} from '@hb/shared'
import { DataListTab, ToSearchQuery } from '../../../types'
import { toAdminAssessmentSearchQuery } from '../../../utils/search/assessments'
import { claimsColumns } from '../../DataList/columns/claims'
import { getRowBackground } from '../../DataView/utils'
import { getPresetAssessmentFilters, getTransferredBackgroundColor, nextActionSort } from './common'
import { AssessmentSearchTip } from './search'

const getClaimBackgroundColor = (item: Claim, index: number) => {
  if (item.deliveredOn === Infinity) {
    return getTransferredBackgroundColor(index)
  }
  return getRowBackground(index)
}

const getClaimsBaseQuery = (archived: boolean, midwifeId?: string): BaseQuery<Claim> => {
  const filters: CollectionFilter<Claim>[] = [['assessmentOnClaimsList', '==', true]]
  // const filters: CollectionFilter<Claim>[] = []
  filters.push(['archived', '==', archived])
  if (midwifeId) {
    filters.push(['midwifeId', '==', midwifeId])
  }
  return {
    collection: CLAIMS,
    filters,
  }
}

export const getClaimTab = (
  midwifeId: string | undefined,
  insurers: LocalCollectionState<InsuranceProvider>,
  providers: LocalCollectionState<PracticeWithAdmin>,
): DataListTab<Claim> =>
  // TODO: remove hasComplaints global filter
  ({
    baseQuery: getClaimsBaseQuery(false, midwifeId),
    columns: claimsColumns,
    defaultSort: nextActionSort,
    // secondarySort: secondaryNextActionSort,
    searchStringPath: 'stringified',
    presetFilters: getPresetAssessmentFilters(insurers, providers),
    searchTip: AssessmentSearchTip,
    toSearchQuery: toAdminAssessmentSearchQuery as ToSearchQuery<Claim>,
    // rightJustifyTab: true,
    itemName: 'Claim',
    getItemBackgroundColor: getClaimBackgroundColor,
  })

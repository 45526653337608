import { AppName, BUG_REPORTS, BugReport, FieldMapValue, OnUploadProgress } from '@hb/shared'
import { FORM_ERROR, ValidationErrors } from 'final-form'
import { saveDoc } from '../../../backend/db'
import { addMetadata } from '../../../utils'
import { bugReportField } from './constants'

export const submitNewBugReport = async (
  appName: AppName,
  data: FieldMapValue,
  onUploadProgress: OnUploadProgress,
): Promise<ValidationErrors | string> => {
  const { title, description, priority, files } = data
  if (!title) {
    return { title: 'Title is required' }
  }
  if (!description) {
    return { description: 'Description is required' }
  }
  if (!priority) {
    return { priority: 'Priority is required' }
  }

  const bugReport: BugReport = addMetadata(
    {
      assignee: null,
      closedOn: null,
      comments: {},
      status: 'open',
      archived: false,
      files: files && Array.isArray(files) ? files : [],
      archivedOn: null,
      archivedBy: null,
      title,
      description,
      priority,
    },
    appName,
    true,
  )

  return saveDoc(BUG_REPORTS, null, bugReportField, bugReport, onUploadProgress)
    .then(id => id)
    .catch(e => ({
      [FORM_ERROR]:
        e.message || 'An error occurred submitting the bug report. Please message Elan (dev guy)',
    }))
}

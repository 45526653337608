import { ArrowForwardIcon, BellIcon } from '@chakra-ui/icons'
import {
  Center,
  Circle,
  Flex,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ScreenContext } from '../../contexts'
import { SolidActionButton } from '../Buttons'
import { useMyPendingCallInRequests } from './hooks'

export const CallInRequestsNotification = () => {
  const { isMobile } = useContext(ScreenContext)
  const callInRequests = useMyPendingCallInRequests()
  const firstCallInRequest = callInRequests[0]
  return firstCallInRequest ? (
    <Popover placement="bottom" strategy="fixed" trigger="hover">
      <PopoverTrigger>
        <Flex mr={isMobile ? '50px' : 'unset'} px={3}>
          <IconButton
            variant="ghost"
            size="sm"
            borderRadius="full"
            aria-label="Call In Requests"
            icon={
              <Flex>
                <BellIcon
                  filter="drop-shadow(1px 1px 2px #00000055)"
                  w={6}
                  h={6}
                  color="green.300"
                />
                <Circle
                  position="absolute"
                  right={0}
                  top={0}
                  bg="white"
                  boxShadow="1px 1px 3px #00000066">
                  <Center w={4} h={4}>
                    <Text fontFamily="Hero-New" fontWeight={600} fontSize="xs" color="gray.400">
                      {callInRequests.length}
                    </Text>
                  </Center>
                </Circle>
              </Flex>
            }
          />
        </Flex>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <Flex flexFlow="column" gap={2} py={2} px={3}>
            <Text fontWeight={600}>Additional Info Requested</Text>
            <Text>
              We need some extra info on your insurance coverage to provide an accurate assessment.
            </Text>
            <Link style={{ width: '100%' }} to={'/pregnancy'}>
              <SolidActionButton w="100%" size="sm" gap={2}>
                <Text>Go to your Assessment</Text>
                <ArrowForwardIcon />
              </SolidActionButton>
            </Link>
          </Flex>
        </PopoverContent>
      </Portal>
    </Popover>
  ) : null
}

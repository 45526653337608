import { Box, Text } from '@chakra-ui/react'
import {
  ASSESSMENTS,
  FieldMapValue,
  getNestedUserFields,
  makeAllFieldsAdmin,
  UpdateCallback,
} from '@hb/shared'

import { doc, updateDoc } from 'firebase/firestore'
import { set as nestedSet } from 'nested-property'
import * as React from 'react'
import { useContext, useMemo, useState } from 'react'
import { ASSESSMENTS_REF } from '../../../collections/firestoreCollections'
import { useApp } from '../../../contexts/AppContext'
// import { AssessmentInterfaceContext } from '../../contexts/AssessmentContext'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { updateAssessmentAnswers } from '../../../hooks/backend/assessments'

import { useProfile } from '../../../contexts'
import { BlueButton } from '../../Buttons/BlueButton'
import { DataView } from '../../DataView'
import { Expandable } from '../../Expandable'
import { AssessmentLog } from '../../Users/Profile/Log/AssessmentLog'
import { PatientPlansView } from '../InsurancePlansView'

export const AssessmentAnswers: React.FC<{
  // assessment: PopulatedAssessment
  // user: PopulatedUser
  assessmentId: string
  onSave?: (data: FieldMapValue) => Promise<UpdateCallback>
  onSubmit?: () => Promise<any>
  width: number
}> = ({ onSubmit, onSave, assessmentId, width }) => {
  const {
    selectedAssessment: { populated: assessment },
    pregnancyCorrections,
    pregnancyData,
    user,
  } = useProfile()
  const { appName } = useApp()
  const fields = useMemo(
    () => makeAllFieldsAdmin(getNestedUserFields(true, user, assessment)),
    [assessment, user],
  )
  const { processResponse } = useContext(PopUpMessageContext)
  const baseStoragePath = useMemo(
    () => `${ASSESSMENTS}/${assessmentId}/corrections`,
    [assessmentId],
  )
  const [submitting, setSubmitting] = useState(false)

  const assessmentRef = useMemo(() => doc(ASSESSMENTS_REF, assessmentId), [assessmentId])

  return (
    <Box w="100%" px={2} pb={2}>
      <DataView
        withEditModal
        isCorrecting={!!assessment?.data}
        baseStoragePath={baseStoragePath}
        boxProps={{ borderBottom: '1px solid #cdcdcd' }}
        field={fields}
        alwaysExpanded
        onShareCorrections={(path, shared) =>
          updateDoc(assessmentRef, `corrections.${path ? `${path}.` : ''}correctionsShared`, shared)
        }
        adminView
        data={pregnancyData}
        onSubmit={onSave}
        updateField={async (path, value) => {
          if (!assessmentId) return { error: 'internal error' }
          const updated = { ...pregnancyCorrections }
          nestedSet(updated, path, value)
          return updateAssessmentAnswers({
            id: assessmentId,
            appName,
            data: updated,
          })
            .then(() => processResponse({ success: 'Updated!' }))
            .catch(err => processResponse({ error: err.message }))
        }}
        corrections={pregnancyCorrections}
      />
      {!assessment?.submittedOn && onSubmit ? (
        <BlueButton
          mt={4}
          size="sm"
          isLoading={submitting}
          onClick={async () => {
            setSubmitting(true)
            try {
              await onSubmit()
            } catch (err) {
              console.error(err)
            }
            setSubmitting(false)
          }}>
          Submit Questionnaire
        </BlueButton>
      ) : null}
      <Expandable
        mt={3}
        bg="gray.50"
        width={`${width - 40}px`}
        borderRadius={4}
        border="1px solid #cdcdcd"
        headerProps={{ bg: 'white', borderRadius: 4 }}
        header={() => (
          <Text fontWeight={600} py={1} fontSize="lg" px={2} color="gray.600">
            Insurance Plans
          </Text>
        )}>
        <PatientPlansView />
      </Expandable>
      <Expandable
        mt={3}
        width={`${width - 40}px`}
        borderRadius={4}
        bg="gray.50"
        headerProps={{ bg: 'white', borderRadius: 4 }}
        border="1px solid #cdcdcd"
        header={() => (
          <Text fontWeight={600} py={1} fontSize="lg" px={2} color="gray.600">
            Log
          </Text>
        )}>
        <Box overflowX="hidden" w="100%" height="600px">
          <AssessmentLog maxHeight={600} />
        </Box>
      </Expandable>
    </Box>
  )
}

import { PracticeWithAdmin } from '../../types'
import { adminCollectionAccess, patientCollectionAccess } from '../constants'
import { MIDWIVES, MIDWIVES_ADMIN } from '../names'
import { CombinedCollection } from '../types'

export const midwivesCollection: CombinedCollection<PracticeWithAdmin> = {
  id: 'practices',
  _type: 'combinedCollection',
  access: patientCollectionAccess,
  name: 'Practices',
  index: {
    _type: 'firestoreCollection',
    name: 'Practices',
    refPath: MIDWIVES,
    access: patientCollectionAccess,
    defaultSort: 'name',
    propNames: ['name', 'fname', 'lname', 'prm', 'rank', 'zipCode', 'npi', 'rank', 'billedCharges'],
  },
  otherCollections: {
    adminPractices: {
      _type: 'firestoreCollection',
      name: 'Admin Practices',
      refPath: MIDWIVES_ADMIN,
      access: adminCollectionAccess,
      defaultSort: 'name',
      propNames: [
        'abilityId',
        'acceptedPaymentMethods',
        'addressNotes',
        'affiliatedHospitals',
        'billedCharges',
        'claimsAddress',
        'collaborativePhysicians',
        'corporateAddress',
        'email',
        'dob',
        'fax',
        'feinTaxClass',
        'group',
        'isGroup',
        'isInactive',
        'licenses',
        'medicaidIds',
        'notes',
        'payToAddress',
        'paymentInfo',
        'paymentsAddress',
        'phone',
        'prmNotes',
        'redFlagged',
        'redFlaggedReason',
        'renderingProviders',
        'retainer',
        'retainerNotes',
        'servicingAddresses',
        'specialty',
        'ssn',
        'taxId',
        'taxIdIsSSN',
      ],
    },
  },
}

import {
  BaseQuery,
  CollectionFilter,
  CollectionSort,
  getPracticeAssessmentCollectionPath,
  PracticeAssessmentData,
  PracticeAssessmentStage,
} from '@hb/shared'
import { DataListTab } from '../../../types'
import { getProviderAssessmentColumns } from '../../DataList'
import { getAssessmentBackgroundColor, getPostpartumBackgroundColor } from './common'

const getPracticeAssessmentFilters = (stage: PracticeAssessmentStage | null, archived: boolean) => {
  const filters: CollectionFilter<PracticeAssessmentData>[] = []
  if (stage) {
    filters.push(['stages', 'array-contains', stage])
  }
  filters.push(['archived', '==', archived])
  return filters
}
const getPracticeAssessmentBaseQuery = (
  practiceId: string,
  stage: PracticeAssessmentStage | null,
  archived: boolean,
): BaseQuery<PracticeAssessmentData> => ({
  collection: getPracticeAssessmentCollectionPath(practiceId),
  filters: getPracticeAssessmentFilters(stage, archived),
})

const eddSort: CollectionSort<PracticeAssessmentData> = {
  field: 'edd',
  order: 'asc',
}

export const getPracticeAssessmentTab = (
  practiceId: string,
  stage: PracticeAssessmentStage | null,
  archived: boolean,
  superAdmin: boolean,
): DataListTab<PracticeAssessmentData> => ({
  baseQuery: getPracticeAssessmentBaseQuery(practiceId, stage, archived),
  columns: getProviderAssessmentColumns(stage, practiceId, superAdmin),
  defaultSort: eddSort,
  searchStringPath: 'stringified',
  itemName: 'Pregnancy',
  getItemBackgroundColor:
    stage !== 'Postpartum' ? getPostpartumBackgroundColor : getAssessmentBackgroundColor,
})

import { Box } from '@chakra-ui/react'
import { InsuranceProvider, insurersCollection, omitNameField } from '@hb/shared'
import {
  EditedProps,
  FormElement,
  PageContainer,
  ScreenContext,
  useCollection,
} from '@hb/shared-frontend'
import { insuranceProviderFields } from '@hb/shared-frontend/collections/fields/insurer'
import EditCollection from '@hb/shared-frontend/components/EditCollection/EditCollection'
import React, { useContext } from 'react'

const InsuranceProviderBody: React.FC<EditedProps<InsuranceProvider>> = ({ expanded }) =>
  expanded ? (
    <Box w="100%" p={4}>
      <FormElement active={true} name="" field={omitNameField(insuranceProviderFields)} />
    </Box>
  ) : null

const InsuranceProviderSection: React.FC = () => {
  const { contentWidth } = useContext(ScreenContext)
  const providersData = useCollection(insurersCollection)
  return providersData ? (
    <PageContainer>
      <EditCollection
        collection={insurersCollection}
        data={providersData}
        width={contentWidth}
        ItemComponent={InsuranceProviderBody}
        name="Insurers"
        itemPlaceholder="Insurer Name"
        itemName="Insurer"
      />
    </PageContainer>
  ) : null
}

export default InsuranceProviderSection

import { AnyObject, BaseQuery, WithId } from '@hb/shared'
import { HttpsCallable, httpsCallable } from 'firebase/functions'
import { functions } from '../../backend/functions'

export const baseGetQueryCount = httpsCallable<BaseQuery<AnyObject>, number>(
  functions,
  'getQueryCount',
)

export const getQueryCount = <T extends AnyObject>(query: BaseQuery<T>) =>
  baseGetQueryCount(query as BaseQuery<AnyObject>)

export const baseFetchQuery = httpsCallable<BaseQuery<AnyObject>, AnyObject>(
  functions,
  'fetchQuery',
)

export const fetchQuery = <T extends AnyObject>(query: BaseQuery<T>) =>
  (baseFetchQuery as HttpsCallable<BaseQuery<T>, WithId<T>[]>)(query)
